// client/src/redux/actions/sharingActions.js
import axios from 'axios';
import { setAlert } from '../../actions/alert';
import {
  GET_SHARED_USERS,
  ADD_SHARED_USER,
  UPDATE_SHARED_USER,
  REMOVE_SHARED_USER,
  SHARING_ERROR,
  SET_LOADING
} from '../types';

// Fetch all shared users
export const fetchSharedUsers = () => async dispatch => {
  dispatch({ type: SET_LOADING });
  
  try {
    const res = await axios.get('/api/sharing/users');

    dispatch({
      type: GET_SHARED_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SHARING_ERROR,
      payload: { 
        msg: err.response?.data?.message || 'Nie udało się pobrać listy użytkowników', 
        status: err.response?.status 
      }
    });
    
    dispatch(setAlert('Nie udało się pobrać listy użytkowników', 'danger'));
  }
};

// Invite user
export const inviteUser = (userData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  
  try {
    const res = await axios.post('/api/sharing/invite', userData, config);

    dispatch({
      type: ADD_SHARED_USER,
      payload: res.data
    });
    
    dispatch(setAlert(`Zaproszenie zostało wysłane do ${userData.email}`, 'success'));
    
    return res.data;
  } catch (err) {
    dispatch({
      type: SHARING_ERROR,
      payload: { 
        msg: err.response?.data?.message || 'Nie udało się wysłać zaproszenia', 
        status: err.response?.status 
      }
    });
    
    dispatch(setAlert(err.response?.data?.message || 'Nie udało się wysłać zaproszenia', 'danger'));
    throw err;
  }
};

// Update user permissions
export const updateUserPermissions = (userId, newPermission) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  
  try {
    const res = await axios.put(`/api/sharing/users/${userId}`, { permission: newPermission }, config);

    dispatch({
      type: UPDATE_SHARED_USER,
      payload: res.data
    });
    
    dispatch(setAlert('Uprawnienia użytkownika zostały zaktualizowane', 'success'));
  } catch (err) {
    dispatch({
      type: SHARING_ERROR,
      payload: { 
        msg: err.response?.data?.message || 'Nie udało się zaktualizować uprawnień', 
        status: err.response?.status 
      }
    });
    
    dispatch(setAlert('Nie udało się zaktualizować uprawnień użytkownika', 'danger'));
  }
};

// Remove user access
export const removeUserAccess = (userId) => async dispatch => {
  try {
    await axios.delete(`/api/sharing/users/${userId}`);

    dispatch({
      type: REMOVE_SHARED_USER,
      payload: userId
    });
    
    dispatch(setAlert('Użytkownik został usunięty', 'success'));
  } catch (err) {
    dispatch({
      type: SHARING_ERROR,
      payload: { 
        msg: err.response?.data?.message || 'Nie udało się usunąć użytkownika', 
        status: err.response?.status 
      }
    });
    
    dispatch(setAlert('Nie udało się usunąć użytkownika', 'danger'));
  }
};