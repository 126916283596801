import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../actions/auth';

const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #6c5ce7;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const ErrorText = styled.div`
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #6c5ce7;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  &:disabled {
    background-color: #a29bde;
    cursor: not-allowed;
  }
`;

const LinkContainer = styled.div`
  margin-top: 1.5rem;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: #6c5ce7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TwoFactorContainer = styled.div`
  margin-top: 1.5rem;
`;

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Nieprawidłowy format adresu email')
    .required('Email jest wymagany'),
  password: Yup.string()
    .required('Hasło jest wymagane')
    .min(6, 'Hasło musi mieć co najmniej 6 znaków')
});

const TwoFactorSchema = Yup.object().shape({
  token: Yup.string()
    .required('Kod weryfikacyjny jest wymagany')
    .length(6, 'Kod weryfikacyjny musi mieć 6 cyfr')
});

const Login = ({ login, auth: { twoFactorRequired, isAuthenticated, loading } }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  // Redirect if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (values, { setSubmitting }) => {
    setFormData({
      email: values.email || formData.email,
      password: values.password || formData.password
    });
    
    try {
      await login(
        values.email || formData.email, 
        values.password || formData.password, 
        values.token
      );
      
      // Don't navigate here - let the useEffect handle it when isAuthenticated changes
      // This prevents race conditions
    } catch (err) {
      console.error('Login error:', err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <LoginContainer>
      <Title>Logowanie</Title>
      
      {!twoFactorRequired ? (
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <StyledField type="email" name="email" id="email" placeholder="Twój adres email" />
                <ErrorMessage name="email" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">Hasło</Label>
                <StyledField type="password" name="password" id="password" placeholder="Twoje hasło" />
                <ErrorMessage name="password" component={ErrorText} />
              </FormGroup>

              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Logowanie...' : 'Zaloguj się'}
              </SubmitButton>

              <LinkContainer>
                <StyledLink to="/forgot-password">Zapomniałeś hasła?</StyledLink>
              </LinkContainer>

              <LinkContainer>
                <p>
                  Nie masz jeszcze konta? <StyledLink to="/register">Zarejestruj się</StyledLink>
                </p>
              </LinkContainer>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{ token: '' }}
          validationSchema={TwoFactorSchema}
          onSubmit={(values) => handleLogin(values)}
        >
          {({ isSubmitting }) => (
            <Form>
              <TwoFactorContainer>
                <FormGroup>
                  <Label htmlFor="token">Kod weryfikacyjny</Label>
                  <StyledField type="text" name="token" id="token" placeholder="Kod z aplikacji uwierzytelniającej" />
                  <ErrorMessage name="token" component={ErrorText} />
                </FormGroup>

                <SubmitButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Weryfikacja...' : 'Zweryfikuj'}
                </SubmitButton>
              </TwoFactorContainer>
            </Form>
          )}
        </Formik>
      )}
    </LoginContainer>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { login })(Login);