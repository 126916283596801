import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { 
  fetchSharedUsers, 
  inviteUser, 
  removeUserAccess, 
  updateUserPermissions 
} from '../../redux/actions/sharingActions';
import Modal from '../../components/ui/Modal';
import Spinner from '../../components/ui/Spinner';

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${props => props.theme.textPrimary};
  margin: 0;
`;

const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.primaryDark};
  }

  &:disabled {
    background-color: ${props => props.theme.disabledBg};
    cursor: not-allowed;
  }
`;

const Card = styled.div`
  background-color: ${props => props.theme.cardBg};
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  color: ${props => props.theme.textPrimary};
  margin-top: 0;
  margin-bottom: 20px;
`;

const UsersList = styled.div`
  margin-top: 20px;
`;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.borderColor};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${props => props.theme.hoverBg};
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.primary};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 15px;
`;

const UserDetails = styled.div``;

const UserName = styled.div`
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
  margin-bottom: 4px;
`;

const UserEmail = styled.div`
  font-size: 13px;
  color: ${props => props.theme.textSecondary};
`;

const UserStatus = styled.div`
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: ${props => props.pending ? '#FFF8E1' : '#E8F5E9'};
  color: ${props => props.pending ? '#F57C00' : '#388E3C'};
  margin-left: 10px;
`;

const UserActions = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: 1px solid ${props => props.danger ? props.theme.danger : props.theme.borderColor};
  color: ${props => props.danger ? props.theme.danger : props.theme.textSecondary};
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.danger ? props.theme.dangerLight : props.theme.hoverBg};
  }
`;

const SelectWrapper = styled.div`
  margin-right: 10px;
`;

const Select = styled.select`
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 13px;
  outline: none;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  outline: none;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const ErrorText = styled.div`
  color: ${props => props.theme.danger};
  font-size: 12px;
  margin-top: 5px;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px 20px;
  color: ${props => props.theme.textSecondary};
`;

const EmptyStateTitle = styled.h3`
  margin-bottom: 10px;
  color: ${props => props.theme.textPrimary};
`;

const EmptyStateText = styled.p`
  margin-bottom: 20px;
`;

const AccountSharing = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { sharedUsers, isLoading, error } = useSelector(state => state.sharing || { sharedUsers: [], isLoading: false, error: null });
  const currentUser = useSelector(state => state.auth?.user || { name: 'Jan Kowalski', email: 'jan.kowalski@example.com' });

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [invitePermission, setInvitePermission] = useState('viewer');
  const [inviteError, setInviteError] = useState('');

  useEffect(() => {
    dispatch(fetchSharedUsers());
  }, [dispatch]);

  const handleInviteUser = () => {
    if (!inviteEmail) {
      setInviteError('Email jest wymagany');
      return;
    }

    if (!/\S+@\S+\.\S+/.test(inviteEmail)) {
      setInviteError('Podaj poprawny adres email');
      return;
    }

    dispatch(inviteUser({
      email: inviteEmail,
      permission: invitePermission
    })).then(() => {
      setShowInviteModal(false);
      setInviteEmail('');
      setInvitePermission('viewer');
      setInviteError('');
    });
  };

  const handlePermissionChange = (userId, newPermission) => {
    dispatch(updateUserPermissions(userId, newPermission));
  };

  const handleRemoveUser = (userId) => {
    if (window.confirm('Czy na pewno chcesz usunąć dostęp dla tego użytkownika?')) {
      dispatch(removeUserAccess(userId));
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase();
  };

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Współdzielenie konta</Title>
        <Button onClick={() => setShowInviteModal(true)}>
          Zaproś nową osobę
        </Button>
      </Header>

      <Card>
        <SectionTitle>Osoby z dostępem do konta</SectionTitle>
        <p>Zarządzaj dostępem innych osób do swojego konta i ustal poziom ich uprawnień.</p>
        
        <UsersList>
          {/* Current user (owner) */}
          <UserItem>
            <UserInfo>
              <Avatar>{getInitials(currentUser.name)}</Avatar>
              <UserDetails>
                <UserName>{currentUser.name} (Ty)</UserName>
                <UserEmail>{currentUser.email}</UserEmail>
              </UserDetails>
            </UserInfo>
            <UserActions>
              <div style={{ fontWeight: '500', color: theme.textPrimary }}>Właściciel</div>
            </UserActions>
          </UserItem>

          {/* Shared users */}
          {sharedUsers && sharedUsers.length > 0 ? (
            sharedUsers.map(user => (
              <UserItem key={user.id}>
                <UserInfo>
                  <Avatar>{getInitials(user.name)}</Avatar>
                  <UserDetails>
                    <UserName>{user.name}</UserName>
                    <UserEmail>{user.email}</UserEmail>
                  </UserDetails>
                  {user.pending && <UserStatus pending>Oczekujące</UserStatus>}
                </UserInfo>
                <UserActions>
                  <SelectWrapper>
                    <Select
                      value={user.permission}
                      onChange={e => handlePermissionChange(user.id, e.target.value)}
                    >
                      <option value="viewer">Tylko podgląd</option>
                      <option value="editor">Edytor</option>
                      <option value="admin">Administrator</option>
                    </Select>
                  </SelectWrapper>
                  <ActionButton danger onClick={() => handleRemoveUser(user.id)}>
                    Usuń
                  </ActionButton>
                </UserActions>
              </UserItem>
            ))
          ) : (
            <EmptyState>
              <EmptyStateTitle>Brak współdzielonych użytkowników</EmptyStateTitle>
              <EmptyStateText>
                Zaproś osoby, z którymi chcesz zarządzać swoim budżetem.
              </EmptyStateText>
              <Button onClick={() => setShowInviteModal(true)}>
                Zaproś nową osobę
              </Button>
            </EmptyState>
          )}
        </UsersList>
      </Card>

      {/* Information card */}
      <Card>
        <SectionTitle>Poziomy uprawnień</SectionTitle>
        <div>
          <p><strong>Tylko podgląd</strong> - może przeglądać Twoje dane finansowe, ale nie może ich modyfikować.</p>
          <p><strong>Edytor</strong> - może dodawać, edytować i usuwać transakcje oraz budżety, ale nie może zapraszać innych użytkowników.</p>
          <p><strong>Administrator</strong> - ma pełny dostęp do wszystkich funkcji, w tym do zarządzania dostępem innych użytkowników.</p>
        </div>
      </Card>

      {/* Invite Modal */}
      <Modal
        isOpen={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
          setInviteError('');
        }}
        title="Zaproś nowego użytkownika"
      >
        <FormGroup>
          <Label htmlFor="email">Adres email</Label>
          <Input
            id="email"
            type="email"
            placeholder="Wpisz adres email"
            value={inviteEmail}
            onChange={e => setInviteEmail(e.target.value)}
          />
          {inviteError && <ErrorText>{inviteError}</ErrorText>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="permission">Poziom uprawnień</Label>
          <Select
            id="permission"
            value={invitePermission}
            onChange={e => setInvitePermission(e.target.value)}
          >
            <option value="viewer">Tylko podgląd</option>
            <option value="editor">Edytor</option>
            <option value="admin">Administrator</option>
          </Select>
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
          <ActionButton
            onClick={() => {
              setShowInviteModal(false);
              setInviteError('');
            }}
          >
            Anuluj
          </ActionButton>
          <Button onClick={handleInviteUser}>
            Wyślij zaproszenie
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default AccountSharing;