import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TWO_FACTOR_SUCCESS,
  TWO_FACTOR_FAIL,
  TWO_FACTOR_REQUIRED,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  ACCOUNT_DELETED,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  CLEAR_PROFILE,
  GET_PROFILE,
  PROFILE_ERROR,
  UPDATE_PROFILE
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async dispatch => {
  // Check if token exists
  if (localStorage.token) {
    // Set token to Auth header
    setAuthToken(localStorage.token);
  } else {
    dispatch({
      type: AUTH_ERROR
    });
    return;
  }

  try {
    const res = await api.get('/auth/me');

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
    
    // Return the loaded user data
    return res.data;
  } catch (err) {
    console.error('Error loading user:', err);
    // Remove invalid token
    localStorage.removeItem('token');
    setAuthToken(null);
    
    dispatch({
      type: AUTH_ERROR
    });
    return null;
  }
};

// Register User
export const register = ({ name, email, password }) => async dispatch => {
  const body = JSON.stringify({ name, email, password });

  try {
    const res = await api.post('/auth/register', body);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (email, password, token) => async dispatch => {
  const body = JSON.stringify({ email, password, token });

  try {
    // Dispatch an action to set loading to true (auth login loading)
    dispatch({ type: 'SET_AUTH_LOADING', payload: true });
    
    const res = await api.post('/auth/login', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    // Only load user if login was successful
    try {
      await dispatch(loadUser());
      return { success: true };
    } catch (loadErr) {
      console.error('Error loading user after login:', loadErr);
      dispatch({
        type: AUTH_ERROR
      });
      return { success: false };
    }
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    if (err.response?.status === 429) {
      dispatch(setAlert('Zbyt wiele prób logowania. Spróbuj ponownie później.', 'danger'));
    }
    
    // Handle 2FA if required
    if (err.response?.data?.twoFactorRequired) {
      dispatch({
        type: TWO_FACTOR_REQUIRED,
        payload: err.response.data
      });
      return { twoFactorRequired: true };
    }

    // Ensure loading state is reset
    dispatch({
      type: LOGIN_FAIL
    });
    
    return { success: false };
  }
};

// Verify Two Factor
export const verifyTwoFactor = (userId, code) => async dispatch => {
  const body = JSON.stringify({ userId, code });

  try {
    const res = await api.post('/auth/2fa/verify', body);

    dispatch({
      type: TWO_FACTOR_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: TWO_FACTOR_FAIL
    });
  }
};

// Request Password Reset
export const forgotPassword = (email) => async dispatch => {
  const body = JSON.stringify({ email });

  try {
    await api.post('/auth/forgot-password', body);

    dispatch({
      type: PASSWORD_RESET_REQUEST
    });

    dispatch(setAlert('Instrukcje resetowania hasła zostały wysłane na podany adres email', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PASSWORD_RESET_FAIL
    });
  }
};

// Verify Reset Token
export const verifyResetToken = (token) => async dispatch => {
  try {
    await api.get(`/auth/reset-password/${token}`);
    
    return true;
  } catch (err) {
    dispatch(setAlert('Nieprawidłowy lub wygasły token resetowania hasła', 'danger'));
    return false;
  }
};

// Reset Password
export const resetPassword = (token, password) => async dispatch => {
  const body = JSON.stringify({ password });

  try {
    await api.post(`/auth/reset-password/${token}`, body);

    dispatch({
      type: PASSWORD_RESET_SUCCESS
    });

    dispatch(setAlert('Hasło zostało zresetowane pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PASSWORD_RESET_FAIL
    });
  }
};

// Update User Profile
export const updateProfile = (formData) => async dispatch => {
  try {
    const res = await api.put('/users/profile', formData);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Profil zaktualizowany pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_PROFILE_FAIL
    });
  }
};

// Get current profile
export const getCurrentProfile = () => async dispatch => {
  try {
    const res = await api.get('/users/profile');

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Update password
export const updatePassword = (currentPassword, newPassword) => async dispatch => {
  try {
    const body = JSON.stringify({ currentPassword, newPassword });

    const res = await api.put('/auth/updatepassword', body);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Hasło zaktualizowane pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    } else {
      dispatch(setAlert('Błąd aktualizacji hasła', 'danger'));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Delete account & profile
export const deleteAccount = () => async dispatch => {
  if (window.confirm('Czy na pewno chcesz usunąć swoje konto? Ta operacja jest nieodwracalna!')) {
    try {
      await api.delete('/users');

      dispatch({ type: CLEAR_PROFILE });
      dispatch({ type: ACCOUNT_DELETED });

      // Clear localStorage
      localStorage.removeItem('token');
      setAuthToken(null);

      dispatch(setAlert('Konto zostało trwale usunięte', 'success'));
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Wystąpił błąd podczas usuwania konta';
      dispatch(setAlert(errorMessage, 'danger'));
      
      dispatch({
        type: AUTH_ERROR,
        payload: { 
          msg: err.response?.statusText || 'Server Error', 
          status: err.response?.status || 500 
        }
      });
    }
  }
};

// Logout / Clear Profile
export const logout = () => dispatch => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
  dispatch(setAlert('Zostałeś wylogowany', 'success'));
};