// src/redux/actions/settingsActions.js

import { 
  TOGGLE_DARK_MODE,
  UPDATE_USER_SETTINGS_REQUEST,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_SECURITY_SETTINGS_REQUEST,
  UPDATE_SECURITY_SETTINGS_SUCCESS,
  UPDATE_SECURITY_SETTINGS_FAILURE
} from '../types';
import api from '../../utils/api';
import { setAlert } from '../../actions/alert';

// Toggle dark mode
export const toggleDarkMode = (isDarkMode) => (dispatch) => {
  localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
  
  dispatch({
    type: TOGGLE_DARK_MODE,
    payload: isDarkMode
  });
};

// Update user settings
export const updateUserSettings = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_SETTINGS_REQUEST
    });

    const res = await api.put('/api/users/settings', userData);

    dispatch({
      type: UPDATE_USER_SETTINGS_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Ustawienia zostały zapisane', 'success'));
    
    return Promise.resolve();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_USER_SETTINGS_FAILURE,
      payload: { msg: err.response.data.msg }
    });
    
    return Promise.reject(err);
  }
};

// Update notification settings
export const updateNotificationSettings = (notificationData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_NOTIFICATION_SETTINGS_REQUEST
    });

    const res = await api.put('/api/users/notifications', notificationData);

    dispatch({
      type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Ustawienia powiadomień zostały zapisane', 'success'));
    
    return Promise.resolve();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_NOTIFICATION_SETTINGS_FAILURE,
      payload: { msg: err.response.data.msg }
    });
    
    return Promise.reject(err);
  }
};

// Update security settings
export const updateSecuritySettings = (securityData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SECURITY_SETTINGS_REQUEST
    });

    const res = await api.put('/api/users/security', securityData);

    dispatch({
      type: UPDATE_SECURITY_SETTINGS_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Ustawienia bezpieczeństwa zostały zapisane', 'success'));
    
    return Promise.resolve();
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: UPDATE_SECURITY_SETTINGS_FAILURE,
      payload: { msg: err.response.data.msg }
    });
    
    return Promise.reject(err);
  }
};