import React, { useState, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  min-height: 44px;
  gap: 5px;
  cursor: text;

  &:focus-within {
    border-color: ${props => props.theme.primary};
    box-shadow: 0 0 0 1px ${props => props.theme.primaryLight};
  }
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.primaryLight};
  color: ${props => props.theme.primary};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 2px;
  font-size: 14px;
`;

const TagText = styled.span`
  margin-right: 6px;
`;

const TagRemoveButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  
  &:hover {
    background-color: ${props => props.theme.primary};
    color: white;
  }
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  min-width: 60px;
  padding: 6px 0;
  color: ${props => props.theme.textPrimary};
  
  &::placeholder {
    color: ${props => props.theme.textSecondary};
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  z-index: 10;
  background-color: ${props => props.theme.cardBg};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  margin-top: 4px;
`;

const SuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.theme.hoverBg};
  }
  
  ${props => props.active && `
    background-color: ${props.theme.primaryLight};
    color: ${props.theme.primary};
  `}
`;

const TagsInput = ({ 
  value = [], 
  onChange, 
  placeholder = 'Dodaj tagi...', 
  suggestions = [],
  maxTags = 10,
  validateTag = (tag) => tag.trim().length > 0
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  // Filter suggestions based on input
  useEffect(() => {
    if (inputValue.trim() === '') {
      setFilteredSuggestions([]);
      return;
    }
    
    const filtered = suggestions.filter(
      suggestion => 
        suggestion.toLowerCase().includes(inputValue.toLowerCase()) && 
        !value.includes(suggestion)
    );
    setFilteredSuggestions(filtered);
    setActiveSuggestionIndex(0);
  }, [inputValue, suggestions, value]);

  const addTag = (tag) => {
    if (!tag || !validateTag(tag) || value.includes(tag) || value.length >= maxTags) {
      return;
    }
    
    onChange([...value, tag]);
    setInputValue('');
  };

  const removeTag = (index) => {
    const newTags = [...value];
    newTags.splice(index, 1);
    onChange(newTags);
  };

  const handleKeyDown = (e) => {
    // Enter or comma
    if ((e.key === 'Enter' || e.key === ',') && inputValue) {
      e.preventDefault();
      
      if (showSuggestions && filteredSuggestions.length > 0) {
        addTag(filteredSuggestions[activeSuggestionIndex]);
      } else {
        addTag(inputValue.replace(',', '').trim());
      }
    } 
    // Backspace
    else if (e.key === 'Backspace' && !inputValue && value.length > 0) {
      removeTag(value.length - 1);
    }
    // Arrow up
    else if (e.key === 'ArrowUp' && showSuggestions) {
      e.preventDefault();
      setActiveSuggestionIndex(index => 
        index === 0 ? filteredSuggestions.length - 1 : index - 1
      );
    } 
    // Arrow down
    else if (e.key === 'ArrowDown' && showSuggestions) {
      e.preventDefault();
      setActiveSuggestionIndex(index => 
        index === filteredSuggestions.length - 1 ? 0 : index + 1
      );
    }
    // Escape
    else if (e.key === 'Escape') {
      setShowSuggestions(false);
    }
  };

  const handleContainerClick = () => {
    inputRef.current.focus();
  };

  // Handle clicks outside the component to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <TagsContainer onClick={handleContainerClick}>
        {value.map((tag, index) => (
          <Tag key={index}>
            <TagText>{tag}</TagText>
            <TagRemoveButton onClick={() => removeTag(index)}>
              ×
            </TagRemoveButton>
          </Tag>
        ))}
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setShowSuggestions(inputValue.trim() !== '')}
          placeholder={value.length === 0 ? placeholder : ''}
        />
      </TagsContainer>
      
      {showSuggestions && filteredSuggestions.length > 0 && (
        <SuggestionsContainer>
          {filteredSuggestions.map((suggestion, index) => (
            <SuggestionItem
              key={index}
              active={index === activeSuggestionIndex}
              theme={theme}
              onClick={() => {
                addTag(suggestion);
                setShowSuggestions(false);
              }}
            >
              {suggestion}
            </SuggestionItem>
          ))}
        </SuggestionsContainer>
      )}
    </div>
  );
};

export default TagsInput;