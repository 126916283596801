// client/src/components/routing/PrivateRoute.js
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { loadUser } from '../../actions/auth';

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, token },
  loadUser,
  children,
  ...rest
}) => {
  useEffect(() => {
    // If we have a token but not authenticated yet, try to load user
    if (token && !isAuthenticated && loading) {
      loadUser();
    }
  }, [token, isAuthenticated, loading, loadUser]);

  // If still loading, show spinner
  if (loading) return <Spinner message="Trwa logowanie..." />;
  
  // If not authenticated after loading finished, redirect to login
  if (!isAuthenticated) return <Navigate to="/login" />;
  
  // If authenticated, render children
  return children;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loadUser })(PrivateRoute);