// Types for settings
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';
export const UPDATE_NOTIFICATION_SETTINGS_REQUEST = 'UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_NOTIFICATION_SETTINGS_FAILURE';
export const UPDATE_SECURITY_SETTINGS_REQUEST = 'UPDATE_SECURITY_SETTINGS_REQUEST';
export const UPDATE_SECURITY_SETTINGS_SUCCESS = 'UPDATE_SECURITY_SETTINGS_SUCCESS';
export const UPDATE_SECURITY_SETTINGS_FAILURE = 'UPDATE_SECURITY_SETTINGS_FAILURE';

// Types for sharing
export const FETCH_SHARED_USERS_REQUEST = 'FETCH_SHARED_USERS_REQUEST';
export const FETCH_SHARED_USERS_SUCCESS = 'FETCH_SHARED_USERS_SUCCESS';
export const FETCH_SHARED_USERS_FAILURE = 'FETCH_SHARED_USERS_FAILURE';

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

export const REMOVE_USER_ACCESS_REQUEST = 'REMOVE_USER_ACCESS_REQUEST';
export const REMOVE_USER_ACCESS_SUCCESS = 'REMOVE_USER_ACCESS_SUCCESS';
export const REMOVE_USER_ACCESS_FAILURE = 'REMOVE_USER_ACCESS_FAILURE';

export const UPDATE_USER_PERMISSIONS_REQUEST = 'UPDATE_USER_PERMISSIONS_REQUEST';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_FAILURE = 'UPDATE_USER_PERMISSIONS_FAILURE';

export const GET_SHARED_USERS = 'GET_SHARED_USERS';
export const ADD_SHARED_USER = 'ADD_SHARED_USER';
export const UPDATE_SHARED_USER = 'UPDATE_SHARED_USER';
export const REMOVE_SHARED_USER = 'REMOVE_SHARED_USER';
export const SHARING_ERROR = 'SHARING_ERROR';
export const SET_LOADING = 'SET_LOADING';


// Types for auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

// Types for budget
export const GET_BUDGETS = 'GET_BUDGETS';
export const GET_BUDGET = 'GET_BUDGET';
export const ADD_BUDGET = 'ADD_BUDGET';
export const DELETE_BUDGET = 'DELETE_BUDGET';
export const UPDATE_BUDGET = 'UPDATE_BUDGET';
export const BUDGET_ERROR = 'BUDGET_ERROR';
export const CLEAR_BUDGET = 'CLEAR_BUDGET';

// Types for transaction
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';

// Types for goals
export const GET_GOALS = 'GET_GOALS';
export const GET_GOAL = 'GET_GOAL';
export const ADD_GOAL = 'ADD_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const GOAL_ERROR = 'GOAL_ERROR';
export const CLEAR_GOAL = 'CLEAR_GOAL';
export const CONTRIBUTE_TO_GOAL = 'CONTRIBUTE_TO_GOAL';

// Types for alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Types for AI
export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';

export const GET_CONVERSATION_REQUEST = 'GET_CONVERSATION_REQUEST';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAILURE = 'GET_CONVERSATION_FAILURE';

export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_FAILURE = 'CREATE_CONVERSATION_FAILURE';

export const DELETE_CONVERSATION_REQUEST = 'DELETE_CONVERSATION_REQUEST';
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS';
export const DELETE_CONVERSATION_FAILURE = 'DELETE_CONVERSATION_FAILURE';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const GET_AI_PREFERENCES_REQUEST = 'GET_AI_PREFERENCES_REQUEST';
export const GET_AI_PREFERENCES_SUCCESS = 'GET_AI_PREFERENCES_SUCCESS';
export const GET_AI_PREFERENCES_FAILURE = 'GET_AI_PREFERENCES_FAILURE';

export const UPDATE_AI_PREFERENCES_REQUEST = 'UPDATE_AI_PREFERENCES_REQUEST';
export const UPDATE_AI_PREFERENCES_SUCCESS = 'UPDATE_AI_PREFERENCES_SUCCESS';
export const UPDATE_AI_PREFERENCES_FAILURE = 'UPDATE_AI_PREFERENCES_FAILURE';


