import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { register } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const RegisterContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #6c5ce7;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const ErrorText = styled.div`
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #6c5ce7;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  &:disabled {
    background-color: #a29bde;
    cursor: not-allowed;
  }
`;

const LinkContainer = styled.div`
  margin-top: 1.5rem;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: #6c5ce7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Register = ({ register, setAlert }) => {
  const navigate = useNavigate();

  const registerSchema = Yup.object().shape({
    name: Yup.string()
      .required('Imię jest wymagane')
      .min(2, 'Imię musi mieć co najmniej 2 znaki'),
    email: Yup.string()
      .email('Nieprawidłowy format adresu email')
      .required('Email jest wymagany'),
    password: Yup.string()
      .required('Hasło jest wymagane')
      .min(6, 'Hasło musi mieć co najmniej 6 znaków'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same')
      .required('Potwierdzenie hasła jest wymagane')
  });

  const handleRegister = async (values, { setSubmitting }) => {
    const { name, email, password } = values;
    
    try {
      await register({ name, email, password });
      navigate('/dashboard');
    } catch (error) {
      console.error('Registration error:', error);
    }
    
    setSubmitting(false);
  };

  return (
    <RegisterContainer>
      <Title>Rejestracja</Title>
      <Formik
        initialValues={{ name: '', email: '', password: '', confirmPassword: '' }}
        validationSchema={registerSchema}
        onSubmit={handleRegister}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormGroup>
              <Label htmlFor="name">Imię</Label>
              <StyledField type="text" name="name" id="name" placeholder="Twoje imię" />
              <ErrorMessage name="name" component={ErrorText} />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <StyledField type="email" name="email" id="email" placeholder="Twój adres email" />
              <ErrorMessage name="email" component={ErrorText} />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="password">Hasło</Label>
              <StyledField type="password" name="password" id="password" placeholder="Utwórz hasło" />
              <ErrorMessage name="password" component={ErrorText} />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="confirmPassword">Potwierdź hasło</Label>
              <StyledField
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Potwierdź hasło"
              />
              <ErrorMessage name="confirmPassword" component={ErrorText} />
            </FormGroup>

            <SubmitButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Rejestracja...' : 'Zarejestruj się'}
            </SubmitButton>

            <LinkContainer>
              <p>
                Masz już konto? <StyledLink to="/login">Zaloguj się</StyledLink>
              </p>
            </LinkContainer>
          </Form>
        )}
      </Formik>
    </RegisterContainer>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default connect(null, { register, setAlert })(Register);