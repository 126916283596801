import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addBudget, updateBudget } from '../../actions/budget';
import { getCategories } from '../../actions/category';
import { setAlert } from '../../actions/alert';
import ProgressBar from '../ui/ProgressBar';

const BudgetForm = ({
  createBudget,
  updateBudget,
  getCategories,
  setAlert,
  category: { categories, loading: categoriesLoading },
  budget: { current: initialData },
  ui: { theme },
  isEdit,
  onClose
}) => {
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
    category: '',
    period: 'monthly',
    startDate: new Date().toISOString().split('T')[0],
    endDate: '',
    resetDay: '1',
    description: '',
    notifyThreshold: 80
  });

  const [submitting, setSubmitting] = useState(false);

  // Load categories when component mounts
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  // Populate form for editing
  useEffect(() => {
    if (isEdit && initialData) {
      // Format dates for date inputs
      const startDate = initialData.startDate
        ? new Date(initialData.startDate).toISOString().split('T')[0]
        : '';
      const endDate = initialData.endDate
        ? new Date(initialData.endDate).toISOString().split('T')[0]
        : '';

      setFormData({
        name: initialData.name || '',
        amount: initialData.amount || '',
        category: initialData.category?._id || initialData.category || '',
        period: initialData.period || 'monthly',
        startDate,
        endDate,
        resetDay: initialData.resetDay?.toString() || '1',
        description: initialData.description || '',
        notifyThreshold: initialData.notifyThreshold || 80
      });
    }
  }, [isEdit, initialData]);

  const {
    name,
    amount,
    category,
    period,
    startDate,
    endDate,
    resetDay,
    description,
    notifyThreshold
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!name || !amount || !category) {
      return setAlert('Proszę wypełnić wszystkie wymagane pola', 'danger');
    }

    try {
      setSubmitting(true);
      const budgetData = {
        ...formData,
        amount: parseFloat(amount),
        notifyThreshold: parseInt(notifyThreshold, 10),
        resetDay: period === 'monthly' ? parseInt(resetDay, 10) : undefined
      };

      if (isEdit && initialData) {
        await updateBudget(initialData._id, budgetData);
        setAlert('Budżet zaktualizowany pomyślnie', 'success');
      } else {
        await createBudget(budgetData);
        setAlert('Budżet utworzony pomyślnie', 'success');
      }

      if (onClose) onClose();
    } catch (err) {
      setAlert(
        `Błąd ${isEdit ? 'aktualizacji' : 'tworzenia'} budżetu`,
        'danger'
      );
    } finally {
      setSubmitting(false);
    }
  };

  // Filtrowanie kategorii (tylko wydatki)
  const expenseCategories = categories.filter(
    (cat) => cat.type === 'expense' || cat.type === 'both'
  );

  return (
    <FormContainer>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label theme={theme} htmlFor="name">Nazwa budżetu*</Label>
          <Input
            theme={theme}
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={onChange}
            required
            placeholder="np. Jedzenie, Transport, itp."
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="amount">Kwota limitu*</Label>
          <Input
            theme={theme}
            type="number"
            id="amount"
            name="amount"
            value={amount}
            onChange={onChange}
            required
            step="0.01"
            min="0.01"
            placeholder="0.00"
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="category">Kategoria*</Label>
          <Select
            theme={theme}
            id="category"
            name="category"
            value={category}
            onChange={onChange}
            required
          >
            <option value="">Wybierz kategorię</option>
            {expenseCategories.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
            <option value="all">Wszystkie kategorie</option>
          </Select>
          <HelpText theme={theme}>
            Wybierz kategorię, dla której chcesz utworzyć budżet
          </HelpText>
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="period">Okres budżetowy</Label>
          <Select
            theme={theme}
            id="period"
            name="period"
            value={period}
            onChange={onChange}
          >
            <option value="daily">Dzienny</option>
            <option value="weekly">Tygodniowy</option>
            <option value="monthly">Miesięczny</option>
            <option value="yearly">Roczny</option>
            <option value="custom">Niestandardowy</option>
          </Select>
        </FormGroup>

        {period === 'monthly' && (
          <FormGroup>
            <Label theme={theme} htmlFor="resetDay">Dzień resetowania budżetu</Label>
            <Select
              theme={theme}
              id="resetDay"
              name="resetDay"
              value={resetDay}
              onChange={onChange}
            >
              {[...Array(28)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
              <option value="last">Ostatni dzień miesiąca</option>
            </Select>
            <HelpText theme={theme}>
              Dzień miesiąca, w którym budżet będzie resetowany
            </HelpText>
          </FormGroup>
        )}

        <FormGroup>
          <Label theme={theme} htmlFor="startDate">Data rozpoczęcia</Label>
          <Input
            theme={theme}
            type="date"
            id="startDate"
            name="startDate"
            value={startDate}
            onChange={onChange}
          />
        </FormGroup>

        {period === 'custom' && (
          <FormGroup>
            <Label theme={theme} htmlFor="endDate">Data zakończenia</Label>
            <Input
              theme={theme}
              type="date"
              id="endDate"
              name="endDate"
              value={endDate}
              onChange={onChange}
              min={startDate}
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label theme={theme} htmlFor="description">Opis (opcjonalnie)</Label>
          <Textarea
            theme={theme}
            id="description"
            name="description"
            value={description}
            onChange={onChange}
            rows="3"
            placeholder="Dodatkowe informacje o budżecie"
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="notifyThreshold">
            Próg powiadomień: {notifyThreshold}%
          </Label>
          <RangeInput
            type="range"
            id="notifyThreshold"
            name="notifyThreshold"
            min="1"
            max="100"
            value={notifyThreshold}
            onChange={onChange}
          />
          <ProgressBarContainer>
            <ProgressBar
              progress={parseInt(notifyThreshold, 10)}
              height="10px"
              thresholds={[
                { value: 60, color: '#4caf50' },
                { value: 80, color: '#ff9800' },
                { value: 100, color: '#f44336' }
              ]}
            />
          </ProgressBarContainer>
          <HelpText theme={theme}>
            Otrzymasz powiadomienie, gdy wykorzystanie budżetu przekroczy ten próg
          </HelpText>
        </FormGroup>

        <ButtonsGroup>
          <SubmitButton type="submit" disabled={submitting}>
            {submitting
              ? 'Zapisywanie...'
              : isEdit
              ? 'Zapisz zmiany'
              : 'Utwórz budżet'}
          </SubmitButton>
          {onClose && (
            <CancelButton theme={theme} type="button" onClick={onClose}>
              Anuluj
            </CancelButton>
          )}
        </ButtonsGroup>
      </Form>
    </FormContainer>
  );
};

BudgetForm.propTypes = {
  createBudget: PropTypes.func.isRequired,
  updateBudget: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func
};

BudgetForm.defaultProps = {
  isEdit: false
};

const mapStateToProps = (state) => ({
  category: state.category,
  budget: state.budget,
  ui: state.ui
});

// Styled components
const FormContainer = styled.div`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${(props) => (props.theme === 'dark' ? '#ddd' : '#666')};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const RangeInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  height: 6px;
  border-radius: 3px;
  background: #d7d7d7;
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #4a90e2;
    cursor: pointer;
  }
  
  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #4a90e2;
    cursor: pointer;
    border: none;
  }
`;

const ProgressBarContainer = styled.div`
  margin: 8px 0;
`;

const HelpText = styled.small`
  color: ${(props) => (props.theme === 'dark' ? '#aaa' : '#999')};
  font-size: 0.8rem;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }

  &:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${(props) => (props.theme === 'dark' ? '#444' : '#e0e0e0')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => (props.theme === 'dark' ? '#555' : '#ccc')};
  }
`;

export default connect(mapStateToProps, {
  addBudget,
  updateBudget,
  getCategories,
  setAlert
})(BudgetForm);