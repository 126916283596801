import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { 
  FaDownload, 
  FaCalendarAlt, 
  FaChartPie, 
  FaChartLine, 
  FaChartBar,
  FaFileExport 
} from 'react-icons/fa';
import { getReports, generateReport } from '../../actions/report';
import { getTransactions } from '../../actions/transaction';
import { getCategories } from '../../actions/category';
import { setAlert } from '../../actions/alert';
import Spinner from '../../components/ui/Spinner';
import DateRangePicker from '../../components/forms/DateRangePicker';
import PieChart from '../../components/charts/PieChart';
import LineChart from '../../components/charts/LineChart';
import BarChart from '../../components/charts/BarChart';
import { formatCurrency } from '../../utils/formatting';

const Reports = ({
  getReports,
  generateReport,
  getTransactions,
  getCategories,
  setAlert,
  report: { reports, loading: reportsLoading },
  transaction: { transactions, loading: transactionsLoading },
  category: { categories, loading: categoriesLoading },
  auth: { user },
  ui: { theme }
}) => {
  const [activeTab, setActiveTab] = useState('overview'); // 'overview', 'income', 'expense', 'trends', 'comparison'
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date()
  });
  const [comparisonPeriod, setComparisonPeriod] = useState('month'); // 'month', 'quarter', 'year'
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [memberFilter, setMemberFilter] = useState('all'); // 'all' lub ID użytkownika

  useEffect(() => {
    getTransactions();
    getCategories();
    getReports();
  }, [getTransactions, getCategories, getReports]);

  useEffect(() => {
    if (!transactionsLoading && !categoriesLoading && transactions.length > 0) {
      generateReportData();
    }
  }, [transactions, categories, dateRange, activeTab, memberFilter, transactionsLoading, categoriesLoading]);

  const generateReportData = () => {
    setLoading(true);

    // Filtrowanie transakcji według zakresu dat
    const filteredTransactions = transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date);
      return transactionDate >= dateRange.startDate && transactionDate <= dateRange.endDate;
    });

    // Filtrowanie po użytkowniku (dla współdzielonych kont)
    const memberFilteredTransactions = memberFilter === 'all'
      ? filteredTransactions
      : filteredTransactions.filter(transaction => {
          const transactionPersonId = 
            typeof transaction.person === 'object'
              ? transaction.person._id
              : transaction.person;
          
          return transactionPersonId === memberFilter;
        });

    // Przygotowanie danych do raportu w zależności od aktywnej zakładki
    let data = {};

    if (activeTab === 'overview' || activeTab === 'income' || activeTab === 'expense') {
      // Dane dla przeglądu, przychodów lub wydatków
      // Filtrowanie transakcji według typu
      const typeFilteredTransactions = activeTab === 'overview'
        ? memberFilteredTransactions
        : memberFilteredTransactions.filter(transaction => 
            transaction.type === (activeTab === 'income' ? 'income' : 'expense')
          );

      // Grupowanie transakcji według kategorii
      const categoryData = [];
      
      categories.forEach(category => {
        // Filtrujemy tylko kategorie odpowiedniego typu
        if (activeTab === 'overview' || 
            category.type === activeTab || 
            category.type === 'both') {
          
          const categoryTransactions = typeFilteredTransactions.filter(transaction => {
            const transactionCategoryId = 
              typeof transaction.category === 'object'
                ? transaction.category._id
                : transaction.category;
            
            return transactionCategoryId === category._id;
          });
          
          if (categoryTransactions.length > 0) {
            const amount = categoryTransactions.reduce(
              (sum, transaction) => sum + transaction.amount, 
              0
            );
            
            categoryData.push({
              name: category.name,
              value: amount,
              color: category.color,
              id: category._id,
              transactions: categoryTransactions
            });
          }
        }
      });
      
      // Sortowanie według kwoty (malejąco)
      categoryData.sort((a, b) => b.value - a.value);
      
      // Obliczanie sum
      const totalAmount = categoryData.reduce((sum, item) => sum + item.value, 0);
      
      data = {
        categoryData,
        totalAmount,
        transactionsCount: typeFilteredTransactions.length
      };
    } else if (activeTab === 'trends') {
      // Dane dla trendów (miesięcznych)
      
      // Grupowanie transakcji według miesiąca
      const monthlyData = {};
      
      memberFilteredTransactions.forEach(transaction => {
        const transactionDate = new Date(transaction.date);
        const monthKey = `${transactionDate.getFullYear()}-${String(transactionDate.getMonth() + 1).padStart(2, '0')}`;
        
        if (!monthlyData[monthKey]) {
          monthlyData[monthKey] = {
            income: 0,
            expense: 0,
            balance: 0,
            month: monthKey,
            transactions: []
          };
        }
        
        if (transaction.type === 'income') {
          monthlyData[monthKey].income += transaction.amount;
        } else {
          monthlyData[monthKey].expense += transaction.amount;
        }
        
        monthlyData[monthKey].balance = 
          monthlyData[monthKey].income - monthlyData[monthKey].expense;
        
        monthlyData[monthKey].transactions.push(transaction);
      });
      
      // Konwersja obiektu na tablicę i sortowanie według daty
      const trendsData = Object.values(monthlyData).sort(
        (a, b) => new Date(a.month) - new Date(b.month)
      );
      
      data = {
        trendsData
      };
    } else if (activeTab === 'comparison') {
      // Dane dla porównania miesiąc do miesiąca, kwartał do kwartału lub rok do roku
      
      // Określenie funkcji grupowania według wybranego okresu
      const getGroupKey = (date) => {
        if (comparisonPeriod === 'month') {
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        } else if (comparisonPeriod === 'quarter') {
          const quarter = Math.floor(date.getMonth() / 3) + 1;
          return `${date.getFullYear()}-Q${quarter}`;
        } else if (comparisonPeriod === 'year') {
          return `${date.getFullYear()}`;
        }
      };
      
      // Grupowanie według okresów i typów transakcji
      const comparisonData = {};
      
      transactions.forEach(transaction => {
        const transactionDate = new Date(transaction.date);
        const groupKey = getGroupKey(transactionDate);
        
        if (!comparisonData[groupKey]) {
          comparisonData[groupKey] = {
            period: groupKey,
            income: 0,
            expense: 0,
            balance: 0,
            transactions: []
          };
        }
        
        // Filtrowanie po użytkowniku
        if (memberFilter === 'all' || 
            typeof transaction.person === 'object' 
              ? transaction.person?._id === memberFilter
              : transaction.person === memberFilter) {
              
          if (transaction.type === 'income') {
            comparisonData[groupKey].income += transaction.amount;
          } else {
            comparisonData[groupKey].expense += transaction.amount;
          }
          
          comparisonData[groupKey].balance = 
            comparisonData[groupKey].income - comparisonData[groupKey].expense;
          
          comparisonData[groupKey].transactions.push(transaction);
        }
      });
      
      // Konwersja na tablicę i sortowanie według daty
      let comparisons = Object.values(comparisonData);
      
      // Sortowanie według nazwy okresu (zawiera informację o dacie)
      comparisons.sort((a, b) => {
        if (comparisonPeriod === 'month') {
          return a.period.localeCompare(b.period);
        } else if (comparisonPeriod === 'quarter') {
          return a.period.localeCompare(b.period);
        } else {
          return parseInt(a.period) - parseInt(b.period);
        }
      });
      
      data = {
        comparisons
      };
    }
    
    setReportData(data);
    setLoading(false);
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleComparisonPeriodChange = (period) => {
    setComparisonPeriod(period);
  };

  const handleMemberFilterChange = (e) => {
    setMemberFilter(e.target.value);
  };

  const exportAsPDF = async () => {
    try {
      setLoading(true);
      
      // Wywołujemy akcję generowania raportu PDF
      await generateReport({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        type: activeTab,
        format: 'pdf',
        memberId: memberFilter === 'all' ? null : memberFilter
      });
      
      setAlert('Raport został wygenerowany', 'success');
    } catch (err) {
      setAlert('Błąd generowania raportu', 'danger');
    } finally {
      setLoading(false);
    }
  };

  const exportAsCSV = async () => {
    try {
      setLoading(true);
      
      // Wywołujemy akcję generowania raportu CSV
      await generateReport({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        type: activeTab,
        format: 'csv',
        memberId: memberFilter === 'all' ? null : memberFilter
      });
      
      setAlert('Raport CSV został wygenerowany', 'success');
    } catch (err) {
      setAlert('Błąd generowania raportu CSV', 'danger');
    } finally {
      setLoading(false);
    }
  };

  if (reportsLoading || transactionsLoading || categoriesLoading) {
    return (
      <Container>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Raporty i analizy</h1>
        <ActionButtons>
          <ButtonSecondary onClick={exportAsCSV}>
            <FaFileExport /> Eksportuj do CSV
          </ButtonSecondary>
          <ButtonPrimary onClick={exportAsPDF}>
            <FaDownload /> Eksportuj do PDF
          </ButtonPrimary>
        </ActionButtons>
      </Header>

      <FiltersRow>
        <DateRangeContainer>
          <DateRangeLabel>
            <FaCalendarAlt />
            <span>Zakres dat:</span>
          </DateRangeLabel>
          <DateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onChange={handleDateRangeChange}
          />
        </DateRangeContainer>

        {user?.group?.members && user.group.members.length > 0 && (
          <MemberFilterContainer>
            <MemberFilterLabel>Filtruj po osobie:</MemberFilterLabel>
            <MemberFilterSelect 
              value={memberFilter}
              onChange={handleMemberFilterChange}
            >
              <option value="all">Wszyscy</option>
              {user.group.members.map(member => (
                <option key={member._id} value={member._id}>
                  {member.name}
                </option>
              ))}
            </MemberFilterSelect>
          </MemberFilterContainer>
        )}
      </FiltersRow>

      <TabsContainer>
        <TabButton 
          active={activeTab === 'overview'} 
          onClick={() => handleTabChange('overview')}
        >
          <FaChartPie />
          <span>Podsumowanie</span>
        </TabButton>
        <TabButton 
          active={activeTab === 'income'} 
          onClick={() => handleTabChange('income')}
        >
          <FaChartPie />
          <span>Przychody</span>
        </TabButton>
        <TabButton 
          active={activeTab === 'expense'} 
          onClick={() => handleTabChange('expense')}
        >
          <FaChartPie />
          <span>Wydatki</span>
        </TabButton>
        <TabButton 
          active={activeTab === 'trends'} 
          onClick={() => handleTabChange('trends')}
        >
          <FaChartLine />
          <span>Trendy</span>
        </TabButton>
        <TabButton 
          active={activeTab === 'comparison'} 
          onClick={() => handleTabChange('comparison')}
        >
          <FaChartBar />
          <span>Porównanie</span>
        </TabButton>
      </TabsContainer>

      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <ReportContent>
          {activeTab === 'overview' && reportData && (
            <ReportOverview>
              <SummaryCards>
                <SummaryCard>
                  <SummaryCardTitle>Łączna kwota</SummaryCardTitle>
                  <SummaryCardValue>
                    {formatCurrency(reportData.totalAmount)}
                  </SummaryCardValue>
                  <SummaryCardSubtitle>
                    Liczba transakcji: {reportData.transactionsCount}
                  </SummaryCardSubtitle>
                </SummaryCard>
                
                <SummaryCard className="income">
                  <SummaryCardTitle>Przychody</SummaryCardTitle>
                  <SummaryCardValue>
                    {formatCurrency(
                      reportData.categoryData
                        .filter(item => 
                          categories.find(cat => 
                            cat._id === item.id && 
                            (cat.type === 'income' || cat.type === 'both')
                          )
                        )
                        .reduce((sum, item) => sum + item.value, 0)
                    )}
                  </SummaryCardValue>
                </SummaryCard>
                
                <SummaryCard className="expense">
                  <SummaryCardTitle>Wydatki</SummaryCardTitle>
                  <SummaryCardValue>
                    {formatCurrency(
                      reportData.categoryData
                        .filter(item => 
                          categories.find(cat => 
                            cat._id === item.id && 
                            (cat.type === 'expense' || cat.type === 'both')
                          )
                        )
                        .reduce((sum, item) => sum + item.value, 0)
                    )}
                  </SummaryCardValue>
                </SummaryCard>
              </SummaryCards>
              
              <ChartsSection>
                <ChartContainer>
                  <ChartTitle>Podział według kategorii</ChartTitle>
                  <PieChart 
                    data={reportData.categoryData} 
                    colors={reportData.categoryData.map(item => item.color)}
                  />
                </ChartContainer>
                
                <CategoryListContainer>
                  <ChartTitle>Top kategorie</ChartTitle>
                  <CategoryList>
                    {reportData.categoryData.slice(0, 10).map((category, index) => (
                      <CategoryItem key={category.id}>
                        <CategoryRank>{index + 1}</CategoryRank>
                        <CategoryColor style={{ backgroundColor: category.color }} />
                        <CategoryName>{category.name}</CategoryName>
                        <CategoryAmount>
                          {formatCurrency(category.value)}
                        </CategoryAmount>
                        <CategoryPercentage>
                          {((category.value / reportData.totalAmount) * 100).toFixed(1)}%
                        </CategoryPercentage>
                      </CategoryItem>
                    ))}
                  </CategoryList>
                </CategoryListContainer>
              </ChartsSection>
            </ReportOverview>
          )}

          {(activeTab === 'income' || activeTab === 'expense') && reportData && (
            <ReportTypeSection>
              <SummaryCards>
                <SummaryCard className={activeTab}>
                  <SummaryCardTitle>
                    {activeTab === 'income' ? 'Łączne przychody' : 'Łączne wydatki'}
                  </SummaryCardTitle>
                  <SummaryCardValue>
                    {formatCurrency(reportData.totalAmount)}
                  </SummaryCardValue>
                  <SummaryCardSubtitle>
                    Liczba transakcji: {reportData.transactionsCount}
                  </SummaryCardSubtitle>
                </SummaryCard>
              </SummaryCards>
              
              <ChartsSection>
                <ChartContainer>
                  <ChartTitle>
                    {activeTab === 'income' ? 'Przychody według kategorii' : 'Wydatki według kategorii'}
                  </ChartTitle>
                  <PieChart 
                    data={reportData.categoryData} 
                    colors={reportData.categoryData.map(item => item.color)}
                  />
                </ChartContainer>
                
                <CategoryListContainer>
                  <ChartTitle>Szczegóły kategorii</ChartTitle>
                  <CategoryList>
                    {reportData.categoryData.map((category, index) => (
                      <CategoryItem key={category.id}>
                        <CategoryRank>{index + 1}</CategoryRank>
                        <CategoryColor style={{ backgroundColor: category.color }} />
                        <CategoryName>{category.name}</CategoryName>
                        <CategoryAmount>
                          {formatCurrency(category.value)}
                        </CategoryAmount>
                        <CategoryPercentage>
                          {((category.value / reportData.totalAmount) * 100).toFixed(1)}%
                        </CategoryPercentage>
                      </CategoryItem>
                    ))}
                  </CategoryList>
                </CategoryListContainer>
              </ChartsSection>
            </ReportTypeSection>
          )}

          {activeTab === 'trends' && reportData && (
            <ReportTrendsSection>
              <ChartContainer fullWidth>
                <ChartTitle>Trend miesięczny</ChartTitle>
                <LineChart 
                  data={reportData.trendsData.map(item => ({
                    name: item.month,
                    income: item.income,
                    expense: item.expense,
                    balance: item.balance
                  }))}
                />
              </ChartContainer>
              
              <TrendsTable>
                <thead>
                  <tr>
                    <th>Miesiąc</th>
                    <th>Przychody</th>
                    <th>Wydatki</th>
                    <th>Bilans</th>
                    <th>Liczba transakcji</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.trendsData.map(item => (
                    <tr key={item.month}>
                      <td>{new Date(item.month + '-01').toLocaleDateString('pl-PL', { year: 'numeric', month: 'long' })}</td>
                      <td className="income">{formatCurrency(item.income)}</td>
                      <td className="expense">{formatCurrency(item.expense)}</td>
                      <td className={item.balance >= 0 ? 'income' : 'expense'}>
                        {formatCurrency(item.balance)}
                      </td>
                      <td>{item.transactions.length}</td>
                    </tr>
                  ))}
                </tbody>
              </TrendsTable>
            </ReportTrendsSection>
          )}

          {activeTab === 'comparison' && reportData && (
            <ReportComparisonSection>
              <ComparisonPeriodSelector>
                <ComparisonPeriodButton
                  active={comparisonPeriod === 'month'}
                  onClick={() => handleComparisonPeriodChange('month')}
                >
                  Miesiące
                </ComparisonPeriodButton>
                <ComparisonPeriodButton
                  active={comparisonPeriod === 'quarter'}
                  onClick={() => handleComparisonPeriodChange('quarter')}
                >
                  Kwartały
                </ComparisonPeriodButton>
                <ComparisonPeriodButton
                  active={comparisonPeriod === 'year'}
                  onClick={() => handleComparisonPeriodChange('year')}
                >
                  Lata
                </ComparisonPeriodButton>
              </ComparisonPeriodSelector>
              
              <ChartContainer fullWidth>
                <ChartTitle>
                  Porównanie {comparisonPeriod === 'month' ? 'miesięczne' : comparisonPeriod === 'quarter' ? 'kwartalne' : 'roczne'}
                </ChartTitle>
                <BarChart 
                  data={reportData.comparisons.map(item => ({
                    name: formatPeriodLabel(item.period, comparisonPeriod),
                    income: item.income,
                    expense: item.expense,
                    balance: item.balance
                  }))}
                />
              </ChartContainer>
              
              <ComparisonTable>
                <thead>
                  <tr>
                    <th>Okres</th>
                    <th>Przychody</th>
                    <th>Wydatki</th>
                    <th>Bilans</th>
                    <th>Liczba transakcji</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.comparisons.map(item => (
                    <tr key={item.period}>
                      <td>{formatPeriodLabel(item.period, comparisonPeriod)}</td>
                      <td className="income">{formatCurrency(item.income)}</td>
                      <td className="expense">{formatCurrency(item.expense)}</td>
                      <td className={item.balance >= 0 ? 'income' : 'expense'}>
                        {formatCurrency(item.balance)}
                      </td>
                      <td>{item.transactions.length}</td>
                    </tr>
                  ))}
                </tbody>
              </ComparisonTable>
            </ReportComparisonSection>
          )}
        </ReportContent>
      )}
    </Container>
  );
};

// Helper funkcja do formatowania etykiet okresów
const formatPeriodLabel = (period, periodType) => {
  if (periodType === 'month') {
    const [year, month] = period.split('-');
    return new Date(year, parseInt(month) - 1, 1).toLocaleDateString('pl-PL', { 
      year: 'numeric', 
      month: 'long' 
    });
  } else if (periodType === 'quarter') {
    const [year, quarter] = period.split('-Q');
    return `${quarter} kwartał ${year}`;
  } else {
    return period; // rok
  }
};

Reports.propTypes = {
  getReports: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  report: state.report,
  transaction: state.transaction,
  category: state.category,
  auth: state.auth,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const ButtonPrimary = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;

  &:hover {
    background-color: #3a70b2;
  }
`;

const ButtonSecondary = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const FiltersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;
`;

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
`;

const DateRangeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
`;

const MemberFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const MemberFilterLabel = styled.label`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
`;

const MemberFilterSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const TabButton = styled.button`
  background-color: ${props => 
    props.active 
      ? props.theme === 'dark' ? '#4a90e2' : '#4a90e2'
      : props.theme === 'dark' ? '#333' : '#e0e0e0'
  };
  color: ${props => 
    props.active 
      ? 'white'
      : props.theme === 'dark' ? 'white' : '#333'
  };
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => 
      props.active 
        ? '#3a70b2'
        : props.theme === 'dark' ? '#444' : '#ccc'
    };
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const ReportContent = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const SummaryCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const SummaryCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  &.income {
    border-left: 4px solid #4caf50;
  }
  
  &.expense {
    border-left: 4px solid #f44336;
  }
`;

const SummaryCardTitle = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const SummaryCardValue = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const SummaryCardSubtitle = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.8rem;
`;

const ChartsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  
  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`;

const ChartContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  ${props => props.fullWidth && `
    grid-column: 1 / -1;
  `}
`;

const ChartTitle = styled.h3`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: 1rem;
`;

const CategoryListContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const CategoryItem = styled.div`
  display: grid;
  grid-template-columns: 30px 16px 1fr auto auto;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const CategoryRank = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
`;

const CategoryColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;

const CategoryName = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const CategoryAmount = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 600;
`;

const CategoryPercentage = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
`;

const ReportOverview = styled.div``;

const ReportTypeSection = styled.div``;

const ReportTrendsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const TrendsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  }
  
  th {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
    font-weight: 600;
    background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  }
  
  td {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
  
  .income {
    color: #4caf50;
  }
  
  .expense {
    color: #f44336;
  }
`;

const ReportComparisonSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ComparisonPeriodSelector = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
`;

const ComparisonPeriodButton = styled.button`
  background-color: ${props => 
    props.active 
      ? props.theme === 'dark' ? '#4a90e2' : '#4a90e2'
      : props.theme === 'dark' ? '#333' : '#e0e0e0'
  };
  color: ${props => 
    props.active 
      ? 'white'
      : props.theme === 'dark' ? 'white' : '#333'
  };
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => 
      props.active 
        ? '#3a70b2'
        : props.theme === 'dark' ? '#444' : '#ccc'
    };
  }
`;

const ComparisonTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  }
  
  th {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
    font-weight: 600;
    background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  }
  
  td {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
  
  .income {
    color: #4caf50;
  }
  
  .expense {
    color: #f44336;
  }
`;

export default connect(mapStateToProps, {
  getReports,
  generateReport,
  getTransactions,
  getCategories,
  setAlert
})(Reports);
