// Update Sidebar.js to include Groups link
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { 
  FaHome, 
  FaExchangeAlt, 
  FaCalendarAlt, 
  FaChartPie, 
  FaReceipt, 
  FaBullseye, 
  FaRobot, 
  FaUsers, 
  FaCrown,
  FaTrophy,
  FaGraduationCap
} from 'react-icons/fa';

const SidebarContainer = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: ${props => props.isOpen ? '250px' : '70px'};
  height: 100vh;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : '#6c5ce7'};
  color: white;
  z-index: 1001;
  transition: all 0.3s ease;
  overflow-x: hidden;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: ${props => props.isOpen ? '250px' : '0'};
    transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.isOpen ? 'flex-start' : 'center'};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: ${props => props.isOpen ? '1.5rem' : '1.2rem'};
`;

const NavMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  margin-bottom: 0.5rem;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s;
  background-color: ${props => props.active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  svg {
    font-size: 1.2rem;
    min-width: 1.2rem;
  }
`;

const NavText = styled.span`
  margin-left: 1rem;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const ProBadge = styled.span`
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  background-color: #ffc107;
  color: #333;
  border-radius: 50px;
  margin-left: auto;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const SectionDivider = styled.div`
  margin: 1.5rem 0;
  padding: 0 1.5rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const UpgradeButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  padding: 0.75rem;
  background-color: #ffc107;
  color: #333;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0aa00;
  }

  display: ${props => props.isOpen ? 'flex' : 'none'};
`;

const Sidebar = ({ ui: { sidebarOpen, theme }, auth: { user } }) => {
  const location = useLocation();
  const isPro = user && user.isPro;

  // Funkcja sprawdzająca czy link jest aktywny
  const isActive = (path) => {
    return location.pathname === path || location.pathname.startsWith(`${path}/`);
  };

  // Menu głównej nawigacji
  const mainNavItems = [
    {
      path: '/dashboard',
      icon: <FaHome />,
      text: 'Dashboard',
      exact: true
    },
    {
      path: '/dashboard/transactions',
      icon: <FaExchangeAlt />,
      text: 'Transakcje'
    },
    {
      path: '/dashboard/calendar',
      icon: <FaCalendarAlt />,
      text: 'Kalendarz'
    },
    {
      path: '/dashboard/budgets',
      icon: <FaChartPie />,
      text: 'Budżety'
    },
    {
      path: '/dashboard/reports',
      icon: <FaReceipt />,
      text: 'Raporty'
    }
  ];

  // Menu dodatkowych funkcji
  const featureNavItems = [
    {
      path: '/dashboard/receipts',
      icon: <FaReceipt />,
      text: 'Skanowanie paragonów'
    },
    {
      path: '/dashboard/goals',
      icon: <FaBullseye />,
      text: 'Cele oszczędnościowe'
    },
    {
      path: '/dashboard/groups',  // Nowa pozycja menu
      icon: <FaUsers />,
      text: 'Grupy i wspólne finanse'
    },
    {
      path: '/dashboard/sharing',
      icon: <FaUsers />,
      text: 'Współdzielenie'
    }
  ];

  // Menu funkcji PRO
  const proNavItems = [
    {
      path: '/dashboard/ai-assistant',
      icon: <FaRobot />,
      text: 'Asystent AI',
      pro: true
    }
  ];

  // Menu osiągnięć i edukacji
  const achievementNavItems = [
    {
      path: '/dashboard/achievements',
      icon: <FaTrophy />,
      text: 'Osiągnięcia',
      pro: false
    },
    {
      path: '/dashboard/education',
      icon: <FaGraduationCap />,
      text: 'Strefa edukacyjna',
      pro: false
    }
  ];

  return (
    <SidebarContainer isOpen={sidebarOpen} theme={theme}>
      <Logo isOpen={sidebarOpen}>
        {sidebarOpen ? 'Oszczędzam.com' : 'O.'}
      </Logo>

      <NavMenu>
        {mainNavItems.map((item, index) => (
          <NavItem key={index}>
            <NavLink to={item.path} active={isActive(item.path) ? 1 : 0}>
              {item.icon}
              <NavText isOpen={sidebarOpen}>{item.text}</NavText>
            </NavLink>
          </NavItem>
        ))}


        <SectionDivider isOpen={sidebarOpen}>Funkcje Pro</SectionDivider>

        {proNavItems.map((item, index) => (
          <NavItem key={index}>
            <NavLink to={item.path} active={isActive(item.path) ? 1 : 0}>
              {item.icon}
              <NavText isOpen={sidebarOpen}>
                {item.text}
                {item.pro && !isPro && <ProBadge isOpen={sidebarOpen}>PRO</ProBadge>}
              </NavText>
            </NavLink>
          </NavItem>
        ))}

        <SectionDivider isOpen={sidebarOpen}>Rozwój i nauka</SectionDivider>

        {achievementNavItems.map((item, index) => (
          <NavItem key={index}>
            <NavLink to={item.path} active={isActive(item.path) ? 1 : 0}>
              {item.icon}
              <NavText isOpen={sidebarOpen}>
                {item.text}
                {item.pro && !isPro && <ProBadge isOpen={sidebarOpen}>PRO</ProBadge>}
              </NavText>
            </NavLink>
          </NavItem>
        ))}
      </NavMenu>

      {!isPro && (
        <UpgradeButton to="/dashboard/settings/upgrade" isOpen={sidebarOpen}>
          <FaCrown style={{ marginRight: '0.5rem' }} />
          Przejdź na PRO
        </UpgradeButton>
      )}
    </SidebarContainer>
  );
};

Sidebar.propTypes = {
  ui: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui,
  auth: state.auth
});

export default connect(mapStateToProps)(Sidebar);