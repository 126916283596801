// client/src/actions/report.js
import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_REPORTS,
  REPORT_ERROR,
  GENERATE_REPORT,
  CLEAR_REPORT
} from './types';

// Get all reports
export const getReports = () => async dispatch => {
  try {
    const res = await axios.get('/api/reports');

    dispatch({
      type: GET_REPORTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: REPORT_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Generate report
export const generateReport = (reportParams) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/reports/generate', reportParams, config);

    dispatch({
      type: GENERATE_REPORT,
      payload: res.data
    });

    dispatch(setAlert('Raport został wygenerowany pomyślnie', 'success'));

    return res.data;
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REPORT_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });

    return null;
  }
};

// Clear report
export const clearReport = () => dispatch => {
  dispatch({ type: CLEAR_REPORT });
};