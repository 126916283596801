import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaRegCalendarAlt, FaInfoCircle } from 'react-icons/fa';
import { createGoal, updateGoal } from '../../actions/goal';
import { setAlert } from '../../actions/alert';
import ProgressBar from '../ui/ProgressBar';
import TagsInput from './TagsInput';

const GoalForm = ({
  createGoal,
  updateGoal,
  setAlert,
  goal: { current: initialData },
  ui: { theme },
  isEdit,
  onClose
}) => {
  const [formData, setFormData] = useState({
    name: '',
    targetAmount: '',
    currentAmount: '0',
    deadline: '',
    priority: 'medium',
    description: '',
    icon: 'piggy-bank'
  });

  const [tags, setTags] = useState([]);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Populate form for editing
  useEffect(() => {
    if (isEdit && initialData) {
      // Format date for date input
      const deadline = initialData.deadline
        ? new Date(initialData.deadline).toISOString().split('T')[0]
        : '';

      setFormData({
        name: initialData.name || '',
        targetAmount: initialData.targetAmount || '',
        currentAmount: initialData.currentAmount || '0',
        deadline,
        priority: initialData.priority || 'medium',
        description: initialData.description || '',
        icon: initialData.icon || 'piggy-bank'
      });

      setTags(initialData.tags || []);
    }
  }, [isEdit, initialData]);

  const {
    name,
    targetAmount,
    currentAmount,
    deadline,
    priority,
    description,
    icon
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!name || !targetAmount) {
      return setAlert('Proszę wypełnić wszystkie wymagane pola', 'danger');
    }

    try {
      setSubmitting(true);
      const goalData = {
        ...formData,
        targetAmount: parseFloat(targetAmount),
        currentAmount: parseFloat(currentAmount),
        tags
      };

      if (isEdit && initialData) {
        await updateGoal(initialData._id, goalData);
        setAlert('Cel zaktualizowany pomyślnie', 'success');
      } else {
        await createGoal(goalData);
        setAlert('Cel utworzony pomyślnie', 'success');
      }

      if (onClose) onClose();
    } catch (err) {
      setAlert(`Błąd ${isEdit ? 'aktualizacji' : 'tworzenia'} celu`, 'danger');
    } finally {
      setSubmitting(false);
    }
  };

  // Dostępne ikony
  const icons = [
    { value: 'piggy-bank', label: 'Skarbonka' },
    { value: 'home', label: 'Dom' },
    { value: 'car', label: 'Samochód' },
    { value: 'plane', label: 'Podróż' },
    { value: 'graduation-cap', label: 'Edukacja' },
    { value: 'gift', label: 'Prezent' },
    { value: 'laptop', label: 'Elektronika' },
    { value: 'heartbeat', label: 'Zdrowie' },
    { value: 'money-bill', label: 'Pieniądze' },
    { value: 'user-alt', label: 'Osoba' }
  ];

  // Obliczanie procentu realizacji celu
  const progressPercentage = targetAmount
    ? Math.min(
        Math.round((parseFloat(currentAmount || 0) / parseFloat(targetAmount)) * 100),
        100
      )
    : 0;

  // Obliczanie pozostałej kwoty do celu
  const remainingAmount = Math.max(
    parseFloat(targetAmount || 0) - parseFloat(currentAmount || 0),
    0
  );

  return (
    <FormContainer>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label theme={theme} htmlFor="name">Nazwa celu*</Label>
          <Input
            theme={theme}
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={onChange}
            required
            placeholder="np. Wakacje, Nowy laptop, itp."
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="targetAmount">Kwota docelowa*</Label>
          <Input
            theme={theme}
            type="number"
            id="targetAmount"
            name="targetAmount"
            value={targetAmount}
            onChange={onChange}
            required
            step="0.01"
            min="0.01"
            placeholder="0.00"
          />
        </FormGroup>

        {isEdit && (
          <FormGroup>
            <Label theme={theme} htmlFor="currentAmount">
              Aktualna kwota
              <InfoIcon
                onMouseEnter={() => setShowInfoTooltip(true)}
                onMouseLeave={() => setShowInfoTooltip(false)}
              >
                <FaInfoCircle />
                {showInfoTooltip && (
                  <Tooltip>
                    Ta kwota odzwierciedla obecny stan oszczędności. Możesz ją zmienić
                    bezpośrednio tutaj lub dodawać wpłaty poprzez stronę szczegółów celu.
                  </Tooltip>
                )}
              </InfoIcon>
            </Label>
            <Input
              theme={theme}
              type="number"
              id="currentAmount"
              name="currentAmount"
              value={currentAmount}
              onChange={onChange}
              step="0.01"
              min="0"
              max={targetAmount}
              placeholder="0.00"
            />
          </FormGroup>
        )}

        {(isEdit && targetAmount && currentAmount) && (
          <ProgressSection>
            <ProgressInfo>
              <ProgressPercentage>{progressPercentage}%</ProgressPercentage>
              <ProgressText>
                Pozostało: {remainingAmount.toFixed(2)} zł
              </ProgressText>
            </ProgressInfo>
            <ProgressBar
              progress={progressPercentage}
              height="10px"
              showLabel={false}
              color="#4caf50"
            />
          </ProgressSection>
        )}

        <FormGroup>
          <Label theme={theme} htmlFor="deadline">
            <div className="label-with-icon">
              <FaRegCalendarAlt />
              <span>Termin realizacji</span>
            </div>
          </Label>
          <Input
            theme={theme}
            type="date"
            id="deadline"
            name="deadline"
            value={deadline}
            onChange={onChange}
            min={new Date().toISOString().split('T')[0]}
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="priority">Priorytet</Label>
          <Select
            theme={theme}
            id="priority"
            name="priority"
            value={priority}
            onChange={onChange}
          >
            <option value="low">Niski</option>
            <option value="medium">Średni</option>
            <option value="high">Wysoki</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="icon">Ikona</Label>
          <Select
            theme={theme}
            id="icon"
            name="icon"
            value={icon}
            onChange={onChange}
          >
            {icons.map((iconOption) => (
              <option key={iconOption.value} value={iconOption.value}>
                {iconOption.label}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="description">Opis (opcjonalnie)</Label>
          <Textarea
            theme={theme}
            id="description"
            name="description"
            value={description}
            onChange={onChange}
            rows="3"
            placeholder="Dodatkowe informacje o celu"
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme}>Tagi (opcjonalnie)</Label>
          <TagsInput tags={tags} setTags={setTags} />
        </FormGroup>

        <ButtonsGroup>
          <SubmitButton type="submit" disabled={submitting}>
            {submitting
              ? 'Zapisywanie...'
              : isEdit
              ? 'Zapisz zmiany'
              : 'Utwórz cel'}
          </SubmitButton>
          {onClose && (
            <CancelButton theme={theme} type="button" onClick={onClose}>
              Anuluj
            </CancelButton>
          )}
        </ButtonsGroup>
      </Form>
    </FormContainer>
  );
};

GoalForm.propTypes = {
  createGoal: PropTypes.func.isRequired,
  updateGoal: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  goal: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func
};

GoalForm.defaultProps = {
  isEdit: false
};

const mapStateToProps = (state) => ({
  goal: state.goal,
  ui: state.ui
});

// Styled components
const FormContainer = styled.div`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .label-with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const Label = styled.label`
  color: ${(props) => (props.theme === 'dark' ? '#ddd' : '#666')};
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const InfoIcon = styled.span`
  position: relative;
  display: inline-flex;
  margin-left: 0.5rem;
  color: #4a90e2;
  cursor: help;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  width: 250px;
  z-index: 10;
  font-size: 0.8rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  
  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const ProgressSection = styled.div`
  margin: 0.5rem 0;
`;

const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const ProgressPercentage = styled.span`
  font-weight: 600;
  color: #4caf50;
`;

const ProgressText = styled.span`
  color: #666;
  font-size: 0.9rem;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }

  &:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${(props) => (props.theme === 'dark' ? '#444' : '#e0e0e0')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => (props.theme === 'dark' ? '#555' : '#ccc')};
  }
`;

export default connect(
  mapStateToProps,
  { createGoal, updateGoal, setAlert }
)(GoalForm);