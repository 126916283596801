// client/src/components/layout/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaExclamationTriangle, FaArrowLeft } from 'react-icons/fa';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 2rem;
  text-align: center;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f5f8fa'};
  color: ${props => props.theme === 'dark' ? '#f1f1f1' : '#333'};
`;

const Icon = styled.div`
  font-size: 4rem;
  color: #e74c3c;
  margin-bottom: 1.5rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const BackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #6c5ce7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const NotFound = ({ ui: { theme } }) => {
  return (
    <NotFoundContainer theme={theme}>
      <Icon>
        <FaExclamationTriangle />
      </Icon>
      <Title>404 - Strona nie znaleziona</Title>
      <Subtitle theme={theme}>
        Przepraszamy, ale strona, której szukasz, nie istnieje lub została przeniesiona.
      </Subtitle>
      <BackButton to="/dashboard">
        <FaArrowLeft />
        Powrót do strony głównej
      </BackButton>
    </NotFoundContainer>
  );
};

NotFound.propTypes = {
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps)(NotFound);