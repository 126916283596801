// client/src/pages/AIAssistant/AIAssistant.js
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { 
  FaRobot, 
  FaPlus, 
  FaTrash, 
  FaPaperPlane, 
  FaUser, 
  FaCog, 
  FaSpinner
} from 'react-icons/fa';

import {
  getConversations,
  getConversation,
  createConversation,
  deleteConversation,
  sendMessage,
  getAIPreferences
} from '../../actions/ai';
import AIPreferencesModal from './AIPreferencesModal';
import Spinner from '../../components/layout/Spinner';

// Styled Components
const Container = styled.div`
  display: flex;
  height: calc(100vh - 160px);
  overflow: hidden;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Sidebar = styled.div`
  width: 280px;
  border-right: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};

  @media (max-width: 768px) {
    width: ${props => props.isOpen ? '280px' : '0'};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease;
  }
`;

const SidebarHeader = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SidebarTitle = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: #6c5ce7;
  }
`;

const NewConversationButton = styled.button`
  background: none;
  border: none;
  color: #6c5ce7;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #5649c0;
  }
`;

const ConversationList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const ConversationItem = styled.div`
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.active 
    ? (props.theme === 'dark' ? '#444' : '#f0f0ff') 
    : 'transparent'
  };
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  
  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f0f0ff'};
  }
`;

const ConversationTitle = styled.div`
  font-weight: ${props => props.active ? '600' : '400'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  cursor: pointer;
  font-size: 0.875rem;
  opacity: 0.7;
  display: none;
  
  ${ConversationItem}:hover & {
    display: block;
  }
  
  &:hover {
    color: #e74c3c;
    opacity: 1;
  }
`;

const ChatArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f9f9f9'};
`;

const ChatHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
`;

const ChatTitle = styled.h3`
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const PreferencesButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #6c5ce7;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ChatMessages = styled.div`
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const MessageGroup = styled.div`
  margin-bottom: 1.5rem;
  max-width: 80%;
  align-self: ${props => props.isUser ? 'flex-end' : 'flex-start'};
`;

const MessageBubble = styled.div`
  background-color: ${props => props.isUser 
    ? '#6c5ce7' 
    : props.theme === 'dark' ? '#444' : 'white'
  };
  color: ${props => props.isUser 
    ? 'white' 
    : props.theme === 'dark' ? 'white' : '#333'
  };
  padding: 0.75rem 1rem;
  border-radius: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: ${props => props.isUser ? '4px' : '18px'};
  border-bottom-left-radius: ${props => props.isUser ? '18px' : '4px'};
  white-space: pre-wrap;
`;

const MessageSender = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.isUser ? '#6c5ce7' : '#27ae60'};
  }
`;

const MessageTime = styled.span`
  font-size: 0.75rem;
  margin-left: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#999'};
`;

const InputArea = styled.div`
  padding: 1rem;
  border-top: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  display: flex;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
`;

const MessageInput = styled.textarea`
  flex: 1;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  resize: none;
  height: 60px;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  
  &:focus {
    outline: none;
    border-color: #6c5ce7;
  }
`;

const SendButton = styled.button`
  background-color: #6c5ce7;
  color: white;
  border: none;
  margin-left: 0.75rem;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #5649c0;
  }
  
  &:disabled {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
    cursor: not-allowed;
  }
`;

const WelcomeScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const WelcomeIcon = styled.div`
  font-size: 4rem;
  color: #6c5ce7;
  margin-bottom: 1.5rem;
`;

const WelcomeTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const WelcomeText = styled.p`
  margin-bottom: 2rem;
  max-width: 500px;
`;

const StartButton = styled.button`
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: #5649c0;
  }
`;

const MobileSidebarToggle = styled.button`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    padding: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

const AIAssistant = ({
  getConversations,
  getConversation,
  createConversation,
  deleteConversation,
  sendMessage,
  getAIPreferences,
  ai: { conversations, currentConversation, loading },
  ui: { theme }
}) => {
  const [message, setMessage] = useState('');
  const [showPreferences, setShowPreferences] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const messagesEndRef = useRef(null);

  // Pobierz konwersacje przy pierwszym renderowaniu
  useEffect(() => {
    getConversations();
    getAIPreferences();
  }, [getConversations, getAIPreferences]);

  // Przewiń do najnowszej wiadomości
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentConversation]);

  // Obsługa nowej konwersacji
  const handleNewConversation = async () => {
    await createConversation();
  };

  // Obsługa wyboru konwersacji
  const handleSelectConversation = (id) => {
    getConversation(id);
  };

  // Obsługa usunięcia konwersacji
  const handleDeleteConversation = (e, id) => {
    e.stopPropagation();
    if (window.confirm('Czy na pewno chcesz usunąć tę konwersację?')) {
      deleteConversation(id);
    }
  };

  // Obsługa wysyłania wiadomości
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    // Jeśli nie ma aktywnej konwersacji, utwórz nową
    if (!currentConversation) {
      const newConversationId = await createConversation();
      if (newConversationId) {
        await sendMessage(message, newConversationId);
      }
    } else {
      await sendMessage(message, currentConversation._id);
    }

    setMessage('');
  };

  // Obsługa klawisza Enter w polu tekstowym
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  // Przełącz widoczność sidebara na urządzeniach mobilnych
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Container theme={theme}>
      <Sidebar theme={theme} isOpen={sidebarOpen}>
        <SidebarHeader theme={theme}>
          <SidebarTitle theme={theme}>
            <FaRobot />
            Konwersacje
          </SidebarTitle>
          <NewConversationButton onClick={handleNewConversation}>
            <FaPlus />
          </NewConversationButton>
        </SidebarHeader>
        <ConversationList>
          {conversations.map(conv => (
            <ConversationItem
              key={conv._id}
              active={currentConversation && currentConversation._id === conv._id}
              onClick={() => handleSelectConversation(conv._id)}
              theme={theme}
            >
              <ConversationTitle active={currentConversation && currentConversation._id === conv._id}>
                {conv.title || `Rozmowa ${new Date(conv.createdAt).toLocaleDateString('pl-PL')}`}
              </ConversationTitle>
              <DeleteButton 
                onClick={(e) => handleDeleteConversation(e, conv._id)}
                theme={theme}
              >
                <FaTrash />
              </DeleteButton>
            </ConversationItem>
          ))}
        </ConversationList>
      </Sidebar>

      <ChatArea theme={theme}>
        <ChatHeader theme={theme}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MobileSidebarToggle onClick={toggleSidebar} theme={theme}>
              <FaRobot />
            </MobileSidebarToggle>
            <ChatTitle theme={theme}>
              {currentConversation 
                ? (currentConversation.title || `Rozmowa ${new Date(currentConversation.createdAt).toLocaleDateString('pl-PL')}`)
                : 'Asystent Finansowy AI'
              }
            </ChatTitle>
          </div>
          <PreferencesButton onClick={() => setShowPreferences(true)} theme={theme}>
            <FaCog />
            Preferencje
          </PreferencesButton>
        </ChatHeader>

        {currentConversation ? (
          <>
            <ChatMessages>
              {currentConversation.messages.map((msg, index) => (
                <MessageGroup 
                  key={index} 
                  isUser={msg.role === 'user'}
                >
                  <MessageSender theme={theme} isUser={msg.role === 'user'}>
                    {msg.role === 'user' ? <FaUser /> : <FaRobot />}
                    {msg.role === 'user' ? 'Ty' : 'Asystent AI'}
                    <MessageTime theme={theme}>
                      {formatTime(msg.timestamp)}
                    </MessageTime>
                  </MessageSender>
                  <MessageBubble 
                    isUser={msg.role === 'user'}
                    theme={theme}
                  >
                    {msg.content}
                  </MessageBubble>
                </MessageGroup>
              ))}
              {loading && (
                <MessageGroup isUser={false}>
                  <MessageSender theme={theme} isUser={false}>
                    <FaRobot />
                    Asystent AI
                  </MessageSender>
                  <MessageBubble theme={theme} isUser={false}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaSpinner style={{ animation: 'spin 1s linear infinite', marginRight: '0.5rem' }} />
                      Myślę...
                    </div>
                  </MessageBubble>
                </MessageGroup>
              )}
              <div ref={messagesEndRef} />
            </ChatMessages>

            <InputArea theme={theme}>
              <MessageInput
                placeholder="Wpisz wiadomość..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                theme={theme}
              />
              <SendButton 
                onClick={handleSendMessage} 
                disabled={loading || !message.trim()}
                theme={theme}
              >
                {loading ? <FaSpinner style={{ animation: 'spin 1s linear infinite' }} /> : <FaPaperPlane />}
              </SendButton>
            </InputArea>
          </>
        ) : (
          <WelcomeScreen theme={theme}>
            <WelcomeIcon>
              <FaRobot />
            </WelcomeIcon>
            <WelcomeTitle theme={theme}>
              Witaj w Asystencie Finansowym AI
            </WelcomeTitle>
            <WelcomeText>
              Jestem Twoim osobistym asystentem finansowym. Mogę pomóc Ci przeanalizować Twoje wydatki, 
              doradzić w kwestii oszczędzania, ocenić Twoje możliwości zakupowe i wiele więcej. 
              Po prostu zadaj mi pytanie!
            </WelcomeText>
            <StartButton onClick={handleNewConversation}>
              <FaPlus />
              Rozpocznij nową rozmowę
            </StartButton>
          </WelcomeScreen>
        )}
      </ChatArea>

      {showPreferences && (
        <AIPreferencesModal 
          onClose={() => setShowPreferences(false)}
          theme={theme}
        />
      )}
    </Container>
  );
};

AIAssistant.propTypes = {
  getConversations: PropTypes.func.isRequired,
  getConversation: PropTypes.func.isRequired,
  createConversation: PropTypes.func.isRequired,
  deleteConversation: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  getAIPreferences: PropTypes.func.isRequired,
  ai: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ai: state.ai,
  ui: state.ui
});

export default connect(
  mapStateToProps,
  { getConversations, getConversation, createConversation, deleteConversation, sendMessage, getAIPreferences }
)(AIAssistant);