// client/src/reducers/ui.js
import {
  TOGGLE_THEME,
  SET_THEME,
  TOGGLE_SIDEBAR,
  SET_SIDEBAR,
  SET_LANGUAGE,
  SET_CURRENCY,
  SET_LOADING
} from '../actions/types';

const initialState = {
  theme: localStorage.getItem('theme') || 'light',
  sidebarOpen: localStorage.getItem('sidebarOpen') === 'true',
  language: localStorage.getItem('language') || 'pl',
  currency: localStorage.getItem('currency') || 'PLN',
  loading: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_THEME:
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return {
        ...state,
        theme: newTheme
      };
    case SET_THEME:
      localStorage.setItem('theme', payload);
      return {
        ...state,
        theme: payload
      };
    case TOGGLE_SIDEBAR:
      const newSidebarState = !state.sidebarOpen;
      localStorage.setItem('sidebarOpen', newSidebarState);
      return {
        ...state,
        sidebarOpen: newSidebarState
      };
    case SET_SIDEBAR:
      localStorage.setItem('sidebarOpen', payload);
      return {
        ...state,
        sidebarOpen: payload
      };
    case SET_LANGUAGE:
      localStorage.setItem('language', payload);
      return {
        ...state,
        language: payload
      };
    case SET_CURRENCY:
      localStorage.setItem('currency', payload);
      return {
        ...state,
        currency: payload
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
}