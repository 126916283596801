import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Redux store
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

// Layouts
import DashboardLayout from './layouts/DashboardLayout';
import AuthLayout from './layouts/AuthLayout';

// Pages - Auth
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';

// Pages - Dashboard
import Dashboard from './pages/Dashboard/Dashboard';
import Transactions from './pages/Transactions/Transactions';
import AddTransaction from './pages/Transactions/AddTransaction';
import EditTransaction from './pages/Transactions/EditTransaction';
import Calendar from './pages/Calendar/Calendar';
import Budgets from './pages/Budgets/Budgets';
import Reports from './pages/Reports/Reports';
import ReceiptScanner from './pages/ReceiptScanner/ReceiptScanner';
import Goals from './pages/Goals/Goals';
import AIAssistant from './pages/AIAssistant/AIAssistant';
import Achievements from './pages/Achievements/Achievements'; // Nowa strona
import UserProfile from './pages/Profile/UserProfile';
import AccountSharing from './pages/AccountSharing/AccountSharing';
import Settings from './pages/Settings/Settings';

// Pages - Groups
import GroupsList from './pages/Groups/GroupsList';
import CreateGroup from './pages/Groups/CreateGroup';
import GroupDetails from './pages/Groups/GroupDetails';

// Pages - Education
import EducationHub from './pages/Education/EducationHub'; // Nowa strona

// Components
import PrivateRoute from './components/routing/PrivateRoute';
import NotFound from './components/layout/NotFound';

// Setup happens in loadUser action
const App = () => {
  useEffect(() => {
    // Set auth token for first load if available
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        <Routes>
          {/* Auth Routes */}
          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:resetToken" element={<ResetPassword />} />
          </Route>

          {/* Dashboard Routes - Protected */}
          <Route path="/dashboard" element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
            <Route index element={<Dashboard />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transactions/add" element={<AddTransaction />} />
            <Route path="transactions/edit/:id" element={<EditTransaction />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="budgets" element={<Budgets />} />
            <Route path="reports" element={<Reports />} />
            <Route path="receipts" element={<ReceiptScanner />} />
            <Route path="goals" element={<Goals />} />
            <Route path="ai-assistant" element={<AIAssistant />} />
            <Route path="achievements" element={<Achievements />} />
            <Route path="education" element={<EducationHub />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="sharing" element={<AccountSharing />} />
            <Route path="settings" element={<Settings />} />

            {/* Nowe trasy dla grup */}
            <Route path="groups" element={<GroupsList />} />
            <Route path="groups/create" element={<CreateGroup />} />
            <Route path="groups/:id" element={<GroupDetails />} />
          </Route>

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;