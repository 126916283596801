import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaPlus, FaFilter, FaSort, FaSearch, FaFileExport } from 'react-icons/fa';
import { getTransactions, deleteTransaction } from '../../actions/transaction';
import { getCategories } from '../../actions/category';
import TransactionItem from '../../components/transactions/TransactionItem';
import Spinner from '../../components/ui/Spinner';
import { formatDate, formatCurrency } from '../../utils/formatting';

const Transactions = ({
  getTransactions,
  getCategories,
  deleteTransaction,
  transaction: { transactions, loading },
  category: { categories },
  auth: { user },
  ui: { theme }
}) => {
  const [filter, setFilter] = useState({
    type: 'all', // 'income', 'expense', 'all'
    category: 'all',
    date: 'all', // 'today', 'week', 'month', 'year', 'all'
    search: '',
    tag: 'all',
    person: 'all', // dla współdzielenia
    startDate: '',
    endDate: '',
    minAmount: '',
    maxAmount: '',
    sortBy: 'date', // 'date', 'amount', 'name'
    sortOrder: 'desc' // 'asc', 'desc'
  });

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    getTransactions();
    getCategories();
  }, [getTransactions, getCategories]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const applyFilter = (e) => {
    e.preventDefault();
    // Tutaj można wywołać getTransactions z parametrami filtrowania
    // getTransactions(filter);
  };

  const clearFilters = () => {
    setFilter({
      type: 'all',
      category: 'all',
      date: 'all',
      search: '',
      tag: 'all',
      person: 'all',
      startDate: '',
      endDate: '',
      minAmount: '',
      maxAmount: '',
      sortBy: 'date',
      sortOrder: 'desc'
    });
    // Pobierz wszystkie transakcje bez filtrów
    getTransactions();
  };

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const onSort = (sortBy) => {
    if (filter.sortBy === sortBy) {
      // Jeśli już sortujemy po tej kolumnie, zmieniamy kierunek sortowania
      setFilter({
        ...filter,
        sortOrder: filter.sortOrder === 'asc' ? 'desc' : 'asc'
      });
    } else {
      // Sortujemy po nowej kolumnie z domyślnym kierunkiem (desc)
      setFilter({
        ...filter,
        sortBy,
        sortOrder: 'desc'
      });
    }
  };

  const filteredTransactions = transactions.filter(transaction => {
    // Filtrowanie po typie (przychód/wydatek)
    if (filter.type !== 'all' && transaction.type !== filter.type) {
      return false;
    }

    // Filtrowanie po kategorii
    if (filter.category !== 'all' && transaction.category !== filter.category) {
      return false;
    }

    // Filtrowanie po dacie
    if (filter.date !== 'all') {
      const today = new Date();
      const transactionDate = new Date(transaction.date);
      
      if (filter.date === 'today') {
        if (
          transactionDate.getDate() !== today.getDate() ||
          transactionDate.getMonth() !== today.getMonth() ||
          transactionDate.getFullYear() !== today.getFullYear()
        ) {
          return false;
        }
      } else if (filter.date === 'week') {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        if (transactionDate < oneWeekAgo) {
          return false;
        }
      } else if (filter.date === 'month') {
        if (
          transactionDate.getMonth() !== today.getMonth() ||
          transactionDate.getFullYear() !== today.getFullYear()
        ) {
          return false;
        }
      } else if (filter.date === 'year') {
        if (transactionDate.getFullYear() !== today.getFullYear()) {
          return false;
        }
      }
    }

    // Filtrowanie po zakresie dat
    if (filter.startDate && new Date(transaction.date) < new Date(filter.startDate)) {
      return false;
    }
    if (filter.endDate && new Date(transaction.date) > new Date(filter.endDate)) {
      return false;
    }

    // Filtrowanie po zakresie kwot
    if (filter.minAmount && transaction.amount < parseFloat(filter.minAmount)) {
      return false;
    }
    if (filter.maxAmount && transaction.amount > parseFloat(filter.maxAmount)) {
      return false;
    }

    // Filtrowanie po wyszukiwaniu (nazwa, opis)
    if (filter.search) {
      const searchLower = filter.search.toLowerCase();
      if (
        !transaction.name.toLowerCase().includes(searchLower) &&
        !(transaction.description && transaction.description.toLowerCase().includes(searchLower))
      ) {
        return false;
      }
    }

    // Filtrowanie po tagu
    if (filter.tag !== 'all' && 
        (!transaction.tags || !transaction.tags.includes(filter.tag))) {
      return false;
    }

    // Filtrowanie po osobie (dla współdzielenia)
    if (filter.person !== 'all' && transaction.person !== filter.person) {
      return false;
    }

    return true;
  });

  // Sortowanie
  const sortedTransactions = [...filteredTransactions].sort((a, b) => {
    if (filter.sortBy === 'date') {
      if (filter.sortOrder === 'asc') {
        return new Date(a.date) - new Date(b.date);
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    } else if (filter.sortBy === 'amount') {
      if (filter.sortOrder === 'asc') {
        return a.amount - b.amount;
      } else {
        return b.amount - a.amount;
      }
    } else if (filter.sortBy === 'name') {
      if (filter.sortOrder === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    }
    return 0;
  });

  // Pobieranie listy wszystkich tagów z transakcji
  const allTags = [...new Set(
    transactions
      .filter(transaction => transaction.tags && transaction.tags.length > 0)
      .flatMap(transaction => transaction.tags)
  )];

  // Pobieranie listy wszystkich osób (dla współdzielenia)
  const allPersons = user?.group?.members || [];

  // Eksport do CSV
  const exportToCSV = () => {
    const headers = ['Data', 'Nazwa', 'Kategoria', 'Kwota', 'Typ', 'Opis', 'Tagi'];
    
    const csvContent = [
      headers.join(','),
      ...sortedTransactions.map(t => [
        formatDate(t.date),
        `"${t.name.replace(/"/g, '""')}"`,
        t.category,
        t.amount,
        t.type,
        t.description ? `"${t.description.replace(/"/g, '""')}"` : '',
        t.tags ? `"${t.tags.join(', ')}"` : ''
      ].join(','))
    ].join('\n');
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `transactions_${new Date().toISOString().split('T')[0]}.csv`);
    link.click();
  };

  return (
    <Container>
      <Header>
        <h1>Transakcje</h1>
        <ActionButtons>
          <SearchWrapper>
            <SearchInput
              type="text"
              placeholder="Szukaj..."
              name="search"
              value={filter.search}
              onChange={onChange}
            />
            <SearchIcon />
          </SearchWrapper>
          <ButtonSecondary onClick={toggleFilters}>
            <FaFilter /> Filtry
          </ButtonSecondary>
          <ButtonSecondary onClick={exportToCSV}>
            <FaFileExport /> Export
          </ButtonSecondary>
          <ButtonPrimary as={Link} to="/transactions/add">
            <FaPlus /> Dodaj transakcję
          </ButtonPrimary>
        </ActionButtons>
      </Header>

      {showFilters && (
        <FiltersContainer>
          <FiltersForm onSubmit={applyFilter}>
            <FilterGroup>
              <FilterLabel>Typ</FilterLabel>
              <FilterSelect name="type" value={filter.type} onChange={onChange}>
                <option value="all">Wszystkie</option>
                <option value="income">Przychody</option>
                <option value="expense">Wydatki</option>
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Kategoria</FilterLabel>
              <FilterSelect name="category" value={filter.category} onChange={onChange}>
                <option value="all">Wszystkie</option>
                {categories.map(category => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Okres</FilterLabel>
              <FilterSelect name="date" value={filter.date} onChange={onChange}>
                <option value="all">Wszystkie</option>
                <option value="today">Dzisiaj</option>
                <option value="week">Ostatni tydzień</option>
                <option value="month">Bieżący miesiąc</option>
                <option value="year">Bieżący rok</option>
                <option value="custom">Niestandardowy</option>
              </FilterSelect>
            </FilterGroup>

            {filter.date === 'custom' && (
              <>
                <FilterGroup>
                  <FilterLabel>Od</FilterLabel>
                  <FilterInput
                    type="date"
                    name="startDate"
                    value={filter.startDate}
                    onChange={onChange}
                  />
                </FilterGroup>

                <FilterGroup>
                  <FilterLabel>Do</FilterLabel>
                  <FilterInput
                    type="date"
                    name="endDate"
                    value={filter.endDate}
                    onChange={onChange}
                  />
                </FilterGroup>
              </>
            )}

            <FilterGroup>
              <FilterLabel>Min. kwota</FilterLabel>
              <FilterInput
                type="number"
                step="0.01"
                name="minAmount"
                value={filter.minAmount}
                onChange={onChange}
                placeholder="0.00"
              />
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Maks. kwota</FilterLabel>
              <FilterInput
                type="number"
                step="0.01"
                name="maxAmount"
                value={filter.maxAmount}
                onChange={onChange}
                placeholder="∞"
              />
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Tag</FilterLabel>
              <FilterSelect name="tag" value={filter.tag} onChange={onChange}>
                <option value="all">Wszystkie</option>
                {allTags.map(tag => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </FilterSelect>
            </FilterGroup>

            {user?.group && (
              <FilterGroup>
                <FilterLabel>Osoba</FilterLabel>
                <FilterSelect name="person" value={filter.person} onChange={onChange}>
                  <option value="all">Wszyscy</option>
                  {allPersons.map(person => (
                    <option key={person._id} value={person._id}>
                      {person.name}
                    </option>
                  ))}
                </FilterSelect>
              </FilterGroup>
            )}

            <FilterButtonsGroup>
              <ButtonPrimary type="submit">Zastosuj filtry</ButtonPrimary>
              <ButtonSecondary type="button" onClick={clearFilters}>
                Wyczyść filtry
              </ButtonSecondary>
            </FilterButtonsGroup>
          </FiltersForm>
        </FiltersContainer>
      )}

      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : sortedTransactions.length === 0 ? (
        <NoTransactions>
          <p>Brak transakcji</p>
          <ButtonPrimary as={Link} to="/transactions/add">
            Dodaj pierwszą transakcję
          </ButtonPrimary>
        </NoTransactions>
      ) : (
        <>
          <Summary>
            <SummaryItem>
              <SummaryLabel>Łącznie transakcji:</SummaryLabel>
              <SummaryValue>{filteredTransactions.length}</SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>Suma przychodów:</SummaryLabel>
              <SummaryValue className="income">
                {formatCurrency(
                  filteredTransactions
                    .filter(t => t.type === 'income')
                    .reduce((acc, t) => acc + t.amount, 0)
                )}
              </SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>Suma wydatków:</SummaryLabel>
              <SummaryValue className="expense">
                {formatCurrency(
                  filteredTransactions
                    .filter(t => t.type === 'expense')
                    .reduce((acc, t) => acc + t.amount, 0)
                )}
              </SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>Bilans:</SummaryLabel>
              <SummaryValue className={
                filteredTransactions.reduce(
                  (acc, t) => acc + (t.type === 'income' ? t.amount : -t.amount),
                  0
                ) >= 0
                  ? 'income'
                  : 'expense'
              }>
                {formatCurrency(
                  filteredTransactions.reduce(
                    (acc, t) => acc + (t.type === 'income' ? t.amount : -t.amount),
                    0
                  )
                )}
              </SummaryValue>
            </SummaryItem>
          </Summary>

          <TransactionsTable>
            <TransactionsTableHeader>
              <tr>
                <th onClick={() => onSort('date')}>
                  Data 
                  {filter.sortBy === 'date' && (
                    <SortIcon sortOrder={filter.sortOrder} />
                  )}
                </th>
                <th onClick={() => onSort('name')}>
                  Nazwa 
                  {filter.sortBy === 'name' && (
                    <SortIcon sortOrder={filter.sortOrder} />
                  )}
                </th>
                <th>Kategoria</th>
                <th onClick={() => onSort('amount')}>
                  Kwota 
                  {filter.sortBy === 'amount' && (
                    <SortIcon sortOrder={filter.sortOrder} />
                  )}
                </th>
                <th>Tagi</th>
                <th>Akcje</th>
              </tr>
            </TransactionsTableHeader>
            <tbody>
              {sortedTransactions.map(transaction => (
                <TransactionItem
                  key={transaction._id}
                  transaction={transaction}
                  onDelete={deleteTransaction}
                  categories={categories}
                  users={user?.group?.members || []}
                />
              ))}
            </tbody>
          </TransactionsTable>
        </>
      )}
    </Container>
  );
};

Transactions.propTypes = {
  getTransactions: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  transaction: state.transaction,
  category: state.category,
  auth: state.auth,
  ui: state.ui
});

// Komponenty stylowane
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ButtonPrimary = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    background-color: #3a70b2;
  }
`;

const ButtonSecondary = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  width: 200px;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  left: 0.7rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
`;

const SortIcon = styled(FaSort)`
  margin-left: 0.3rem;
  transform: ${props => props.sortOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0)'};
`;

const FiltersContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  padding: 1.5rem;
`;

const FiltersForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const FilterLabel = styled.label`
  font-size: 0.9rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
`;

const FilterSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const FilterInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const FilterButtonsGroup = styled.div`
  grid-column: 1 / -1;
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const NoTransactions = styled.div`
  text-align: center;
  padding: 3rem 0;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};

  p {
    margin-bottom: 1.5rem;
  }
`;

const Summary = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const SummaryItem = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 200px;
`;

const SummaryLabel = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  margin-bottom: 0.5rem;
`;

const SummaryValue = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};

  &.income {
    color: #4caf50;
  }

  &.expense {
    color: #f44336;
  }
`;

const TransactionsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TransactionsTableHeader = styled.thead`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f5f5f5'};

  th {
    padding: 1rem;
    text-align: left;
    color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    &:last-child {
      text-align: center;
      cursor: default;
    }
  }
`;

export default connect(mapStateToProps, {
  getTransactions,
  getCategories,
  deleteTransaction
})(Transactions);