import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransaction, updateTransaction, clearTransaction } from '../../actions/transaction';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../components/ui/Spinner';
import TagsInput from '../../components/forms/TagsInput';

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${props => props.theme.textPrimary};
  margin: 0;
`;

const Card = styled.div`
  background-color: ${props => props.theme.cardBg};
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  outline: none;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  outline: none;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  outline: none;
  min-height: 100px;
  resize: vertical;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SwitchLabel = styled.div`
  flex: 1;
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
`;

const SwitchDescription = styled.div`
  color: ${props => props.theme.textSecondary};
  font-size: 12px;
  margin-top: 4px;
`;

const SwitchButton = styled.div`
  position: relative;
  width: 46px;
  height: 24px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.checked ? props.theme.primary : props.theme.borderColor};
  transition: 0.4s;
  border-radius: 24px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${props => props.checked ? 'translateX(22px)' : 'translateX(0)'};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  
  ${props => props.primary ? `
    background-color: ${props.theme.primary};
    color: white;
    border: none;
    
    &:hover {
      background-color: ${props.theme.primaryDark};
    }
  ` : `
    background-color: transparent;
    color: ${props.theme.textPrimary};
    border: 1px solid ${props.theme.borderColor};
    
    &:hover {
      background-color: ${props.theme.hoverBg};
    }
  `}
  
  &:disabled {
    background-color: ${props => props.theme.disabledBg};
    cursor: not-allowed;
  }
`;

const EditTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  
  const { transaction, loading } = useSelector(state => state.transaction);
  
  const [formData, setFormData] = useState({
    title: '',
    amount: '',
    date: '',
    category: '',
    type: 'expense',
    tags: [],
    notes: '',
    isRecurring: false,
    recurringFrequency: 'monthly',
    recurringDay: '1'
  });
  
  useEffect(() => {
    dispatch(getTransaction(id));
    
    return () => {
      dispatch(clearTransaction());
    };
  }, [dispatch, id]);
  
  useEffect(() => {
    if (transaction) {
      setFormData({
        title: transaction.title || '',
        amount: transaction.amount || '',
        date: transaction.date ? new Date(transaction.date).toISOString().substr(0, 10) : '',
        category: transaction.category || '',
        type: transaction.type || 'expense',
        tags: transaction.tags || [],
        notes: transaction.notes || '',
        isRecurring: transaction.isRecurring || false,
        recurringFrequency: transaction.recurringFrequency || 'monthly',
        recurringDay: transaction.recurringDay || '1'
      });
    }
  }, [transaction]);
  
  const {
    title,
    amount,
    date,
    category,
    type,
    tags,
    notes,
    isRecurring,
    recurringFrequency,
    recurringDay
  } = formData;
  
  const onChange = e => {
    const { name, value, type: fieldType, checked } = e.target;
    setFormData({
      ...formData,
      [name]: fieldType === 'checkbox' ? checked : value
    });
  };
  
  const handleTagsChange = newTags => {
    setFormData({
      ...formData,
      tags: newTags
    });
  };
  
  const onSubmit = e => {
    e.preventDefault();
    dispatch(updateTransaction(id, formData));
    navigate(-1);
  };
  
  // Przykładowe kategorie
  const expenseCategories = [
    'Jedzenie', 'Transport', 'Mieszkanie', 'Rozrywka', 'Zdrowie', 'Ubrania', 'Edukacja', 'Prezenty', 'Inne'
  ];
  
  const incomeCategories = [
    'Wypłata', 'Premia', 'Zwrot podatku', 'Prezent', 'Sprzedaż', 'Inwestycje', 'Inne'
  ];
  
  // Przykładowe tagi
  const suggestedTags = [
    'Dom', 'Praca', 'Dzieci', 'Samochód', 'Wakacje', 'Oszczędności', 'Kredyt', 'Inwestycje'
  ];
  
  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }
  
  return (
    <Container>
      <Header>
        <Title>Edytuj transakcję</Title>
      </Header>
      
      <Card>
        <form onSubmit={onSubmit}>
          <FormGroup>
            <Label htmlFor="title">Tytuł</Label>
            <Input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={onChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="type">Typ transakcji</Label>
            <Select
              id="type"
              name="type"
              value={type}
              onChange={onChange}
            >
              <option value="expense">Wydatek</option>
              <option value="income">Przychód</option>
            </Select>
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="amount">Kwota</Label>
            <Input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={onChange}
              step="0.01"
              min="0"
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="date">Data</Label>
            <Input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={onChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="category">Kategoria</Label>
            <Select
              id="category"
              name="category"
              value={category}
              onChange={onChange}
              required
            >
              <option value="">Wybierz kategorię</option>
              {type === 'expense' ? (
                expenseCategories.map(cat => (
                  <option key={cat} value={cat}>{cat}</option>
                ))
              ) : (
                incomeCategories.map(cat => (
                  <option key={cat} value={cat}>{cat}</option>
                ))
              )}
            </Select>
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="tags">Tagi</Label>
            <TagsInput
              value={tags}
              onChange={handleTagsChange}
              placeholder="Dodaj tagi..."
              suggestions={suggestedTags}
            />
          </FormGroup>
          
          <SwitchContainer>
            <div>
              <SwitchLabel>Płatność cykliczna</SwitchLabel>
              <SwitchDescription>
                Włącz dla regularnych płatności, które powtarzają się w określonych odstępach czasu
              </SwitchDescription>
            </div>
            <SwitchButton>
              <SwitchInput
                type="checkbox"
                name="isRecurring"
                checked={isRecurring}
                onChange={onChange}
              />
              <SwitchSlider checked={isRecurring} />
            </SwitchButton>
          </SwitchContainer>
          
          {isRecurring && (
            <>
              <FormGroup>
                <Label htmlFor="recurringFrequency">Częstotliwość</Label>
                <Select
                  id="recurringFrequency"
                  name="recurringFrequency"
                  value={recurringFrequency}
                  onChange={onChange}
                >
                  <option value="daily">Codziennie</option>
                  <option value="weekly">Co tydzień</option>
                  <option value="monthly">Co miesiąc</option>
                  <option value="yearly">Co rok</option>
                </Select>
              </FormGroup>
              
              {recurringFrequency === 'monthly' && (
                <FormGroup>
                  <Label htmlFor="recurringDay">Dzień miesiąca</Label>
                  <Select
                    id="recurringDay"
                    name="recurringDay"
                    value={recurringDay}
                    onChange={onChange}
                  >
                    {[...Array(31)].map((_, i) => (
                      <option key={i + 1} value={(i + 1).toString()}>
                        {i + 1}
                      </option>
                    ))}
                  </Select>
                </FormGroup>
              )}
            </>
          )}
          
          <FormGroup>
            <Label htmlFor="notes">Notatki</Label>
            <TextArea
              id="notes"
              name="notes"
              value={notes}
              onChange={onChange}
              placeholder="Dodatkowe informacje o transakcji..."
            />
          </FormGroup>
          
          <ButtonContainer>
            <Button
              type="button"
              onClick={() => navigate(-1)}
            >
              Anuluj
            </Button>
            <Button
              type="submit"
              primary
            >
              Zapisz zmiany
            </Button>
          </ButtonContainer>
        </form>
      </Card>
    </Container>
  );
};

export default EditTransaction;