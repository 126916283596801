// client/src/components/layout/Alert.js
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaInfoCircle, FaExclamationTriangle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const AlertContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const AlertItem = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  animation: slideIn 0.3s ease-out forwards;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: ${props => {
    switch (props.type) {
      case 'success':
        return '#d4edda';
      case 'danger':
        return '#f8d7da';
      case 'warning':
        return '#fff3cd';
      case 'info':
      default:
        return '#d1ecf1';
    }
  }};
  color: ${props => {
    switch (props.type) {
      case 'success':
        return '#155724';
      case 'danger':
        return '#721c24';
      case 'warning':
        return '#856404';
      case 'info':
      default:
        return '#0c5460';
    }
  }};
  border: 1px solid ${props => {
    switch (props.type) {
      case 'success':
        return '#c3e6cb';
      case 'danger':
        return '#f5c6cb';
      case 'warning':
        return '#ffeeba';
      case 'info':
      default:
        return '#bee5eb';
    }
  }};

  @keyframes slideIn {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const AlertIcon = styled.div`
  margin-right: 0.5rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
`;

const AlertMessage = styled.p`
  margin: 0;
  flex: 1;
`;

const Alert = ({ alerts }) => {
  // Jeśli nie ma alertów, nie renderuj nic
  if (!alerts || alerts.length === 0) return null;

  // Ikony dla różnych typów alertów
  const getAlertIcon = (type) => {
    switch (type) {
      case 'success':
        return <FaCheckCircle />;
      case 'danger':
        return <FaTimesCircle />;
      case 'warning':
        return <FaExclamationTriangle />;
      case 'info':
      default:
        return <FaInfoCircle />;
    }
  };

  return (
    <AlertContainer>
      {alerts.map(alert => (
        <AlertItem key={alert.id} type={alert.alertType}>
          <AlertIcon>{getAlertIcon(alert.alertType)}</AlertIcon>
          <AlertMessage>{alert.msg}</AlertMessage>
        </AlertItem>
      ))}
    </AlertContainer>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);