// client/src/components/dashboard/BalanceCard.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaArrowUp, FaArrowDown, FaPiggyBank } from 'react-icons/fa';

const Card = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: #6c5ce7;
  }
`;

const Period = styled.span`
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const BalanceAmount = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin: 1rem 0;
  color: ${props => {
    if (props.value > 0) return '#27ae60';
    if (props.value < 0) return '#e74c3c';
    return props.theme === 'dark' ? 'white' : '#333';
  }};
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: auto;
`;

const StatCard = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
  border-radius: 8px;
`;

const StatTitle = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  margin-bottom: 0.5rem;
`;

const StatValue = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => {
    if (props.type === 'income') return '#27ae60';
    if (props.type === 'expense') return '#e74c3c';
    return props.theme === 'dark' ? 'white' : '#333';
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChangeIndicator = styled.span`
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background-color: ${props => {
    if (props.value > 0) return props.type === 'income' ? 'rgba(39, 174, 96, 0.1)' : 'rgba(231, 76, 60, 0.1)';
    if (props.value < 0) return props.type === 'income' ? 'rgba(231, 76, 60, 0.1)' : 'rgba(39, 174, 96, 0.1)';
    return 'rgba(128, 128, 128, 0.1)';
  }};
  color: ${props => {
    if (props.value > 0) return props.type === 'income' ? '#27ae60' : '#e74c3c';
    if (props.value < 0) return props.type === 'income' ? '#e74c3c' : '#27ae60';
    return '#888';
  }};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const BalanceCard = ({
  income,
  expense,
  balance,
  incomeChange,
  expenseChange,
  balanceChange,
  currency = 'PLN',
  theme
}) => {
  // Formatowanie liczb
  const formatNumber = (num) => {
    return num.toLocaleString('pl-PL', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  // Formatowanie zmian procentowych
  const formatChange = (value) => {
    const prefix = value > 0 ? '+' : '';
    return `${prefix}${value.toFixed(1)}%`;
  };

  // Komponent strzałki dla wskaźnika zmiany
  const ChangeArrow = ({ value, type }) => {
    // Dla wydatków, interpretujemy wzrost jako negatywny, a spadek jako pozytywny
    const isPositive = type === 'income' ? value > 0 : value < 0;
    
    return isPositive ? <FaArrowUp style={{ marginRight: '4px' }} /> : <FaArrowDown style={{ marginRight: '4px' }} />;
  };

  return (
    <Card theme={theme}>
      <CardHeader>
        <Title theme={theme}>
          <FaPiggyBank />
          Bilans miesiąca
        </Title>
        <Period theme={theme}>Bieżący miesiąc</Period>
      </CardHeader>

      <BalanceAmount value={balance} theme={theme}>
        {formatNumber(balance)} {currency}
      </BalanceAmount>

      <StatsGrid>
        <StatCard theme={theme}>
          <StatTitle theme={theme}>Przychody</StatTitle>
          <StatValue type="income" theme={theme}>
            {formatNumber(income)} {currency}
            <ChangeIndicator value={incomeChange} type="income">
              <ChangeArrow value={incomeChange} type="income" />
              {formatChange(incomeChange)}
            </ChangeIndicator>
          </StatValue>
        </StatCard>

        <StatCard theme={theme}>
          <StatTitle theme={theme}>Wydatki</StatTitle>
          <StatValue type="expense" theme={theme}>
            {formatNumber(expense)} {currency}
            <ChangeIndicator value={expenseChange} type="expense">
              <ChangeArrow value={expenseChange} type="expense" />
              {formatChange(expenseChange)}
            </ChangeIndicator>
          </StatValue>
        </StatCard>
      </StatsGrid>
    </Card>
  );
};

BalanceCard.propTypes = {
  income: PropTypes.number.isRequired,
  expense: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  incomeChange: PropTypes.number,
  expenseChange: PropTypes.number,
  balanceChange: PropTypes.number,
  currency: PropTypes.string,
  theme: PropTypes.string.isRequired
};

export default BalanceCard;