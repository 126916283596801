// client/src/pages/Dashboard/Dashboard.js
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaPlus, FaPiggyBank, FaChartLine, FaExchangeAlt, FaCalendarAlt } from 'react-icons/fa';

// Components
import BalanceCard from '../../components/dashboard/BalanceCard';
import TransactionsList from '../../components/dashboard/TransactionsList';
import BudgetProgress from '../../components/dashboard/BudgetProgress';
import MonthlyChart from '../../components/dashboard/MonthlyChart';
import CategoryPieChart from '../../components/dashboard/CategoryPieChart';
import GoalsList from '../../components/dashboard/GoalsList';
import UpcomingTransactions from '../../components/dashboard/UpcomingTransactions';
import AchievementCard from '../../components/dashboard/AchievementCard';
import Spinner from '../../components/layout/Spinner';
import EducationalTips from '../../components/dashboard/EducationalTips';

// Actions
import { getTransactionSummary, getUpcomingTransactions } from '../../actions/transaction';
import { getBudgets } from '../../actions/budget';
import { getGoals } from '../../actions/goal';

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1.5rem;
`;

const WelcomeMessage = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Greeting = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const AddButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #6c5ce7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const QuickActions = styled.div`
  grid-column: 1 / -1;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const ActionButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f5f5f5'};
    transform: translateY(-2px);
  }

  svg {
    margin-right: 0.5rem;
    color: #6c5ce7;
  }
`;

const FullWidthSection = styled.div`
  grid-column: 1 / -1;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: #6c5ce7;
  }
`;

const TwoColumnSection = styled.div`
  grid-column: span 2;

  @media (max-width: 768px) {
    grid-column: 1 / -1;
  }
`;

const OneColumnSection = styled.div`
  grid-column: span 1;

  @media (max-width: 768px) {
    grid-column: 1 / -1;
  }
`;

const MotivationalMessage = styled.div`
  grid-column: 1 / -1;
  padding: 1rem;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  text-align: center;
  
  h3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #6c5ce7;
  }
  
  p {
    color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  }
`;

const Dashboard = ({
  getTransactionSummary,
  getUpcomingTransactions,
  getBudgets,
  getGoals,
  auth: { user },
  transaction: { summary, upcomingTransactions, loading: transactionLoading },
  budget: { budgets, loading: budgetLoading },
  goal: { goals, loading: goalLoading },
  ui: { theme }
}) => {
  useEffect(() => {
    getTransactionSummary();
    getUpcomingTransactions();
    getBudgets();
    getGoals();
  }, [getTransactionSummary, getUpcomingTransactions, getBudgets, getGoals]);

  if (transactionLoading || budgetLoading || goalLoading) {
    return <Spinner />;
  }

  // Uzyskaj imię użytkownika do powitania
  const firstName = user ? user.name.split(' ')[0] : 'Użytkowniku';
  
  // Aktualna godzina dla dynamicznego powitania
  const hour = new Date().getHours();
  let greeting;
  
  if (hour < 12) {
    greeting = 'Dzień dobry';
  } else if (hour < 18) {
    greeting = 'Dzień dobry';
  } else {
    greeting = 'Dobry wieczór';
  }

  // Losowa wiadomość motywacyjna
  const motivationalMessages = [
    'Każda zaoszczędzona złotówka to krok w stronę finansowej wolności!',
    'Małe oszczędności codziennie prowadzą do wielkich rezultatów w przyszłości.',
    'Kontrolowanie wydatków to kontrolowanie swojej przyszłości.',
    'Oszczędzanie to nie wyrzeczenia, to inwestycja w swoje jutro.',
    'Twoje finanse się poprawiają! Kontynuuj dobrą pracę!'
  ];
  
  const randomMessage = motivationalMessages[Math.floor(Math.random() * motivationalMessages.length)];

  return (
    <DashboardContainer>
      <WelcomeMessage>
        <Greeting theme={theme}>
          {greeting}, {firstName}!
        </Greeting>
        <AddButton to="/dashboard/transactions/add">
          <FaPlus />
          Dodaj transakcję
        </AddButton>
      </WelcomeMessage>

      <QuickActions>
        <ActionButton to="/dashboard/transactions" theme={theme}>
          <FaExchangeAlt />
          Przejdź do transakcji
        </ActionButton>
        <ActionButton to="/dashboard/calendar" theme={theme}>
          <FaCalendarAlt />
          Kalendarz finansowy
        </ActionButton>
        <ActionButton to="/dashboard/reports" theme={theme}>
          <FaChartLine />
          Raporty i analizy
        </ActionButton>
        <ActionButton to="/dashboard/goals" theme={theme}>
          <FaPiggyBank />
          Cele oszczędnościowe
        </ActionButton>
      </QuickActions>

      <OneColumnSection>
        <BalanceCard 
          income={summary?.monthly?.currentMonth?.income || 0}
          expense={summary?.monthly?.currentMonth?.expense || 0}
          balance={summary?.monthly?.currentMonth?.balance || 0}
          incomeChange={summary?.monthly?.change?.income || 0}
          expenseChange={summary?.monthly?.change?.expense || 0}
          balanceChange={summary?.monthly?.change?.balance || 0}
          currency="PLN"
          theme={theme}
        />
      </OneColumnSection>

      <TwoColumnSection>
        <SectionTitle theme={theme}>
          <FaChartLine />
          Przychody vs. Wydatki
        </SectionTitle>
        <MonthlyChart data={summary?.timeline || []} theme={theme} />
      </TwoColumnSection>

      <OneColumnSection>
        <SectionTitle theme={theme}>
          <FaChartLine />
          Struktura wydatków
        </SectionTitle>
        <CategoryPieChart 
          categories={summary?.categories?.filter(cat => cat.type === 'expense') || []} 
          theme={theme} 
        />
      </OneColumnSection>

      <TwoColumnSection>
        <SectionTitle theme={theme}>
          <FaExchangeAlt />
          Ostatnie transakcje
        </SectionTitle>
        <TransactionsList limit={5} theme={theme} />
      </TwoColumnSection>

      <OneColumnSection>
        <SectionTitle theme={theme}>
          <FaCalendarAlt />
          Nadchodzące transakcje
        </SectionTitle>
        <UpcomingTransactions 
          transactions={upcomingTransactions} 
          limit={5} 
          theme={theme} 
        />
      </OneColumnSection>

      <FullWidthSection>
        <SectionTitle theme={theme}>
          <FaChartLine />
          Postęp budżetów
        </SectionTitle>
        <BudgetProgress budgets={budgets} theme={theme} />
      </FullWidthSection>

      <TwoColumnSection>
        <SectionTitle theme={theme}>
          <FaPiggyBank />
          Cele oszczędnościowe
        </SectionTitle>
        <GoalsList goals={goals} limit={3} theme={theme} />
      </TwoColumnSection>

      <OneColumnSection>
        <SectionTitle theme={theme}>
          Twoje osiągnięcia
        </SectionTitle>
        <AchievementCard achievements={user?.achievements || []} theme={theme} />
      </OneColumnSection>

      <EducationalTips theme={theme} />
    </DashboardContainer>
  );
};

Dashboard.propTypes = {
  getTransactionSummary: PropTypes.func.isRequired,
  getUpcomingTransactions: PropTypes.func.isRequired,
  getBudgets: PropTypes.func.isRequired,
  getGoals: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  goal: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  transaction: state.transaction,
  budget: state.budget,
  goal: state.goal,
  ui: state.ui
});

export default connect(mapStateToProps, {
  getTransactionSummary,
  getUpcomingTransactions,
  getBudgets,
  getGoals
})(Dashboard);