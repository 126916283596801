// client/src/layouts/DashboardLayout.js
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import Navbar from '../components/layout/Navbar';
import Sidebar from '../components/layout/Sidebar';
import Footer from '../components/layout/Footer';
import Alert from '../components/layout/Alert';

const DashboardLayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
`;

const Content = styled.main`
  flex: 1;
  padding: 1rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f5f8fa'};
  transition: all 0.3s ease;
  position: relative;
  margin-left: ${props => props.sidebarOpen ? '250px' : '70px'};
  width: calc(100% - ${props => props.sidebarOpen ? '250px' : '70px'});
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const MainContent = styled.div`
  padding: 1rem;
  margin-top: 60px;
  min-height: calc(100vh - 60px - 50px); /* Wysokość widoku - (navbar + footer) */
`;

const DashboardLayout = ({ auth: { user }, ui: { sidebarOpen, theme } }) => {
  return (
    <DashboardLayoutWrapper>
      <Sidebar sidebarOpen={sidebarOpen} />
      <Content sidebarOpen={sidebarOpen} theme={theme}>
        <Navbar user={user} />
        <Alert />
        <MainContent>
          <Outlet />
        </MainContent>
        <Footer />
      </Content>
    </DashboardLayoutWrapper>
  );
};

DashboardLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui
});

export default connect(mapStateToProps)(DashboardLayout);