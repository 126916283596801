import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaArrowLeft, FaCamera } from 'react-icons/fa';
import { addTransaction } from '../../actions/transaction';
import { getCategories } from '../../actions/category';
import { setAlert } from '../../actions/alert';
import TagsInput from '../../components/forms/TagsInput';
import Spinner from '../../components/ui/Spinner';

const AddTransaction = ({
  addTransaction,
  getCategories,
  setAlert,
  category: { categories, loading: categoriesLoading },
  auth: { user },
  ui: { theme }
}) => {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
    date: new Date().toISOString().split('T')[0],
    type: 'expense',
    category: '',
    description: '',
    isRecurring: false,
    recurringFrequency: 'monthly',
    recurringEndDate: '',
    person: user?._id || '',
    receipt: null
  });

  const [tags, setTags] = useState([]);
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    name,
    amount,
    date,
    type,
    category,
    description,
    isRecurring,
    recurringFrequency,
    recurringEndDate,
    person
  } = formData;

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, receipt: file });
      
      // Podgląd wybranego pliku
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearReceipt = () => {
    setFormData({ ...formData, receipt: null });
    setPreviewUrl('');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    if (!name || !amount || !date || !category) {
      return setAlert('Proszę wypełnić wszystkie wymagane pola', 'danger');
    }

    if (isRecurring && !recurringEndDate) {
      return setAlert('Proszę podać datę zakończenia dla transakcji cyklicznej', 'danger');
    }

    try {
      setLoading(true);
      // Konwersja kwoty na liczbę
      const amountNumber = parseFloat(amount);
      
      // Stworzenie formularza dla załącznika
      const transactionData = new FormData();
      transactionData.append('name', name);
      transactionData.append('amount', amountNumber);
      transactionData.append('date', date);
      transactionData.append('type', type);
      transactionData.append('category', category);
      
      if (description) transactionData.append('description', description);
      if (isRecurring) {
        transactionData.append('isRecurring', isRecurring);
        transactionData.append('recurringFrequency', recurringFrequency);
        transactionData.append('recurringEndDate', recurringEndDate);
      }
      if (tags.length > 0) transactionData.append('tags', JSON.stringify(tags));
      if (person) transactionData.append('person', person);
      if (formData.receipt) transactionData.append('receipt', formData.receipt);

      await addTransaction(transactionData);
      
      setAlert(
        `Transakcja ${isRecurring ? 'cykliczna ' : ''}dodana pomyślnie`,
        'success'
      );
      
      navigate('/transactions');
    } catch (err) {
      setAlert('Błąd dodawania transakcji', 'danger');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Filtrowanie kategorii w zależności od typu transakcji
  const filteredCategories = categories.filter(
    cat => cat.type === type || cat.type === 'both'
  );

  return (
    <Container>
      <Header>
        <BackButton onClick={() => navigate('/transactions')}>
          <FaArrowLeft /> Wróć
        </BackButton>
        <h1>Dodaj transakcję</h1>
      </Header>

      {categoriesLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <FormContainer>
          <Form onSubmit={onSubmit}>
            <FormSection>
              <h2>Podstawowe informacje</h2>
              
              <FormGroup>
                <RadioGroup>
                  <RadioLabel>
                    <RadioInput
                      type="radio"
                      name="type"
                      value="expense"
                      checked={type === 'expense'}
                      onChange={onChange}
                    />
                    <RadioText className="expense">Wydatek</RadioText>
                  </RadioLabel>
                  <RadioLabel>
                    <RadioInput
                      type="radio"
                      name="type"
                      value="income"
                      checked={type === 'income'}
                      onChange={onChange}
                    />
                    <RadioText className="income">Przychód</RadioText>
                  </RadioLabel>
                </RadioGroup>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="name">Nazwa*</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={onChange}
                  required
                  placeholder="Np. Zakupy w Biedronce"
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="amount">Kwota*</Label>
                <Input
                  type="number"
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={onChange}
                  required
                  step="0.01"
                  min="0.01"
                  placeholder="0.00"
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="date">Data*</Label>
                <Input
                  type="date"
                  id="date"
                  name="date"
                  value={date}
                  onChange={onChange}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="category">Kategoria*</Label>
                <Select
                  id="category"
                  name="category"
                  value={category}
                  onChange={onChange}
                  required
                >
                  <option value="">Wybierz kategorię</option>
                  {filteredCategories.map(cat => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            </FormSection>

            <FormSection>
              <h2>Dodatkowe informacje</h2>

              <FormGroup>
                <Label htmlFor="description">Opis</Label>
                <Textarea
                  id="description"
                  name="description"
                  value={description}
                  onChange={onChange}
                  placeholder="Dodatkowe informacje o transakcji"
                  rows="3"
                />
              </FormGroup>

              <FormGroup>
                <Label>Tagi</Label>
                <TagsInput tags={tags} setTags={setTags} />
                <HelpText>
                  Tagi pomagają w filtrowaniu i organizacji transakcji
                </HelpText>
              </FormGroup>

              {user?.group && user.group.members.length > 0 && (
                <FormGroup>
                  <Label htmlFor="person">Osoba</Label>
                  <Select
                    id="person"
                    name="person"
                    value={person}
                    onChange={onChange}
                  >
                    <option value={user._id}>Ja ({user.name})</option>
                    {user.group.members
                      .filter(member => member._id !== user._id)
                      .map(member => (
                        <option key={member._id} value={member._id}>
                          {member.name}
                        </option>
                      ))}
                    <option value="shared">Wspólne</option>
                  </Select>
                </FormGroup>
              )}
            </FormSection>

            <FormSection>
              <h2>Cykliczność</h2>

              <FormGroup>
                <CheckboxLabel>
                  <CheckboxInput
                    type="checkbox"
                    name="isRecurring"
                    checked={isRecurring}
                    onChange={onCheckboxChange}
                  />
                  <CheckboxText>Transakcja cykliczna</CheckboxText>
                </CheckboxLabel>
                <HelpText>
                  Zaznacz, jeśli jest to transakcja powtarzająca się regularnie
                </HelpText>
              </FormGroup>

              {isRecurring && (
                <>
                  <FormGroup>
                    <Label htmlFor="recurringFrequency">Częstotliwość</Label>
                    <Select
                      id="recurringFrequency"
                      name="recurringFrequency"
                      value={recurringFrequency}
                      onChange={onChange}
                    >
                      <option value="daily">Codziennie</option>
                      <option value="weekly">Co tydzień</option>
                      <option value="biweekly">Co dwa tygodnie</option>
                      <option value="monthly">Co miesiąc</option>
                      <option value="quarterly">Co kwartał</option>
                      <option value="yearly">Co rok</option>
                    </Select>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="recurringEndDate">Data zakończenia*</Label>
                    <Input
                      type="date"
                      id="recurringEndDate"
                      name="recurringEndDate"
                      value={recurringEndDate}
                      onChange={onChange}
                      min={date}
                      required={isRecurring}
                    />
                  </FormGroup>
                </>
              )}
            </FormSection>

            <FormSection>
              <h2>Paragon / Faktura</h2>

              <FormGroup>
                <FileInputLabel htmlFor="receipt">
                  <FaCamera /> Dodaj zdjęcie paragonu lub faktury
                </FileInputLabel>
                <FileInput
                  type="file"
                  id="receipt"
                  name="receipt"
                  accept="image/*"
                  onChange={onFileChange}
                />
                <HelpText>
                  Dodanie zdjęcia ułatwi późniejsze wyszukiwanie i weryfikację
                </HelpText>
              </FormGroup>

              {previewUrl && (
                <ReceiptPreviewContainer>
                  <ReceiptPreviewImage src={previewUrl} alt="Podgląd paragonu" />
                  <ClearReceiptButton type="button" onClick={clearReceipt}>
                    Usuń
                  </ClearReceiptButton>
                </ReceiptPreviewContainer>
              )}
            </FormSection>

            <ButtonsGroup>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? 'Dodawanie...' : 'Dodaj transakcję'}
              </SubmitButton>
              <CancelButton type="button" onClick={() => navigate('/transactions')}>
                Anuluj
              </CancelButton>
            </ButtonsGroup>
          </Form>
        </FormContainer>
      )}
    </Container>
  );
};

AddTransaction.propTypes = {
  addTransaction: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  category: state.category,
  auth: state.auth,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#4a90e2' : '#3a70b2'};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? 'rgba(74, 144, 226, 0.1)' : 'rgba(58, 112, 178, 0.1)'};
  }
`;

const FormContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};

  h2 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    font-size: 1.2rem;
    margin: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  resize: vertical;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const RadioInput = styled.input`
  cursor: pointer;
`;

const RadioText = styled.span`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  
  &.income {
    color: #4caf50;
  }
  
  &.expense {
    color: #f44336;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  cursor: pointer;
`;

const CheckboxText = styled.span`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const HelpText = styled.small`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#999'};
  font-size: 0.8rem;
`;

const FileInputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const FileInput = styled.input`
  display: none;
`;

const ReceiptPreviewContainer = styled.div`
  position: relative;
  margin-top: 1rem;
  display: inline-block;
`;

const ReceiptPreviewImage = styled.img`
  max-width: 300px;
  max-height: 300px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
`;

const ClearReceiptButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }

  &:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

export default connect(mapStateToProps, {
  addTransaction,
  getCategories,
  setAlert
})(AddTransaction);