// client/src/components/dashboard/AchievementCard.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { 
  FaTrophy, 
  FaStar, 
  FaChartLine, 
  FaRegCheckCircle, 
  FaRegMoneyBillAlt, 
  FaPiggyBank,
  FaMedal,
  FaInfoCircle
} from 'react-icons/fa';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

const Container = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ScoreBadge = styled.div`
  padding: 0.25rem 0.5rem;
  background-color: #6c5ce7;
  color: white;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const AchievementGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Achievement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${props => 
    props.unlocked 
      ? (props.theme === 'dark' ? 'rgba(108, 92, 231, 0.2)' : 'rgba(108, 92, 231, 0.1)')
      : (props.theme === 'dark' ? '#383f41' : '#f1f1f1')
  };
  transition: all 0.3s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
`;

const AchievementIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 0.75rem;
  color: ${props => 
    props.unlocked 
      ? '#6c5ce7' 
      : (props.theme === 'dark' ? '#666' : '#ccc')
  };
`;

const AchievementTitle = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  color: ${props => 
    props.unlocked 
      ? (props.theme === 'dark' ? 'white' : '#333') 
      : (props.theme === 'dark' ? '#999' : '#888')
  };
`;

const AchievementLevel = styled.div`
  font-size: 0.75rem;
  color: ${props => 
    props.unlocked 
      ? (props.theme === 'dark' ? '#ccc' : '#666') 
      : (props.theme === 'dark' ? '#777' : '#aaa')
  };
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.3rem;
    color: ${props => 
      props.level >= 3 ? '#ffd700' : // złoty
      props.level >= 2 ? '#c0c0c0' : // srebrny
                        '#cd7f32'    // brązowy
    };
  }
`;

const AchievementDate = styled.div`
  font-size: 0.7rem;
  color: ${props => props.theme === 'dark' ? '#999' : '#888'};
  margin-top: 0.25rem;
`;

const NoAchievements = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  
  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: ${props => props.theme === 'dark' ? '#666' : '#ddd'};
  }
`;

const ViewMoreLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 0.75rem;
  margin-top: auto;
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
    border-radius: 4px;
  }
`;

const AchievementCard = ({ achievements, theme }) => {
  // Funkcja zwracająca ikonę dla osiągnięcia
  const getAchievementIcon = (type) => {
    switch (type) {
      case 'budget_master':
        return <FaChartLine />;
      case 'saving_star':
        return <FaStar />;
      case 'transaction_tracker':
        return <FaRegMoneyBillAlt />;
      case 'goal_achiever':
        return <FaPiggyBank />;
      case 'expense_cutter':
        return <FaRegCheckCircle />;
      case 'consistent_saver':
        return <FaChartLine />;
      default:
        return <FaTrophy />;
    }
  };

  // Funkcja formatująca tytuł osiągnięcia
  const getAchievementTitle = (type) => {
    switch (type) {
      case 'budget_master':
        return 'Mistrz budżetu';
      case 'saving_star':
        return 'Oszczędności';
      case 'transaction_tracker':
        return 'Śledzenie';
      case 'goal_achiever':
        return 'Celownik';
      case 'expense_cutter':
        return 'Redukcja';
      case 'consistent_saver':
        return 'Wytrwałość';
      default:
        return 'Osiągnięcie';
    }
  };

  // Funkcja formatująca datę
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'd MMM yyyy', { locale: pl });
  };

  // Osiągnięcia do wyświetlenia
  const unlockedAchievements = achievements.filter(a => a.unlockedAt);
  
  // Wszystkie możliwe typy osiągnięć
  const allAchievementTypes = [
    'budget_master', 
    'saving_star', 
    'transaction_tracker', 
    'goal_achiever',
    'expense_cutter',
    'consistent_saver'
  ];
  
  // Tworzymy pełną listę osiągnięć (odblokowanych + zablokowanych)
  const displayAchievements = allAchievementTypes.map(type => {
    const achievement = unlockedAchievements.find(a => a.type === type);
    return achievement 
      ? { ...achievement, unlocked: true } 
      : { type, level: 0, unlocked: false };
  });

  // Obliczamy wynik finansowy na podstawie osiągnięć
  const financialScore = unlockedAchievements.reduce((total, achievement) => {
    return total + (achievement.level * 100);
  }, 0);

  return (
    <Container theme={theme}>
      <Header>
        <Title theme={theme}>Twoje osiągnięcia</Title>
        <ScoreBadge>
          <FaTrophy />
          {financialScore} pkt
        </ScoreBadge>
      </Header>

      {unlockedAchievements.length > 0 ? (
        <>
          <AchievementGrid>
            {displayAchievements.map((achievement) => (
              <Achievement key={achievement.type} unlocked={achievement.unlocked} theme={theme}>
                <AchievementIcon unlocked={achievement.unlocked} theme={theme}>
                  {getAchievementIcon(achievement.type)}
                </AchievementIcon>
                <AchievementTitle unlocked={achievement.unlocked} theme={theme}>
                  {getAchievementTitle(achievement.type)}
                </AchievementTitle>
                <AchievementLevel 
                  unlocked={achievement.unlocked} 
                  level={achievement.level}
                  theme={theme}
                >
                  {achievement.unlocked ? (
                    <>
                      <FaMedal />
                      Poziom {achievement.level}
                    </>
                  ) : (
                    'Zablokowane'
                  )}
                </AchievementLevel>
                {achievement.unlocked && achievement.unlockedAt && (
                  <AchievementDate theme={theme}>
                    {formatDate(achievement.unlockedAt)}
                  </AchievementDate>
                )}
              </Achievement>
            ))}
          </AchievementGrid>
          
          <ViewMoreLink to="/dashboard/achievements" theme={theme}>
            Zobacz wszystkie osiągnięcia
          </ViewMoreLink>
        </>
      ) : (
        <NoAchievements theme={theme}>
          <FaInfoCircle />
          <p>
            Nie masz jeszcze żadnych osiągnięć. Zacznij aktywnie korzystać z aplikacji, 
            aby odblokowywać osiągnięcia i zdobywać punkty!
          </p>
        </NoAchievements>
      )}
    </Container>
  );
};

AchievementCard.propTypes = {
  achievements: PropTypes.array,
  theme: PropTypes.string.isRequired
};

AchievementCard.defaultProps = {
  achievements: []
};

export default AchievementCard;