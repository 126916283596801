import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaCopy, FaEye, FaCalendarAlt, FaReceipt, FaTags } from 'react-icons/fa';
import { deleteTransaction, duplicateTransaction } from '../../actions/transaction';
import { setAlert } from '../../actions/alert';
import Modal from '../ui/Modal';
import { formatCurrency, formatDate } from '../../utils/formatting';

const TransactionItem = ({
  transaction,
  deleteTransaction,
  duplicateTransaction,
  setAlert,
  showActions = true,
  ui: { theme }
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    _id,
    name,
    amount,
    date,
    type,
    category,
    description,
    isRecurring,
    recurringFrequency,
    recurringEndDate,
    person,
    tags,
    receipt
  } = transaction;

  const handleDelete = async () => {
    try {
      setSubmitting(true);
      await deleteTransaction(_id);
      setAlert('Transakcja została usunięta', 'success');
      setShowDeleteModal(false);
    } catch (err) {
      setAlert('Błąd podczas usuwania transakcji', 'danger');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDuplicate = async () => {
    try {
      await duplicateTransaction(_id);
      setAlert('Transakcja została zduplikowana', 'success');
    } catch (err) {
      setAlert('Błąd podczas duplikowania transakcji', 'danger');
    }
  };

  // Formatowanie daty cyklicznej
  const formatRecurringInfo = () => {
    const frequencyMap = {
      daily: 'Codziennie',
      weekly: 'Co tydzień',
      biweekly: 'Co dwa tygodnie',
      monthly: 'Co miesiąc',
      quarterly: 'Co kwartał',
      yearly: 'Co rok'
    };

    const formattedEndDate = recurringEndDate
      ? ` do ${formatDate(recurringEndDate)}`
      : '';

    return `${frequencyMap[recurringFrequency] || recurringFrequency}${formattedEndDate}`;
  };

  return (
    <>
      <TransactionCard theme={theme} type={type}>
        <TransactionMain>
          <CategoryIcon theme={theme} categoryColor={category?.color || '#999'}>
            <span>{category?.icon || '💰'}</span>
          </CategoryIcon>
          
          <TransactionInfo>
            <TransactionTitle>{name}</TransactionTitle>
            <TransactionMeta>
              <MetaItem>
                <FaCalendarAlt /> {formatDate(date)}
              </MetaItem>
              
              {category && (
                <MetaItem>
                  <CategoryBadge color={category.color || '#999'}>
                    {category.name}
                  </CategoryBadge>
                </MetaItem>
              )}
              
              {isRecurring && (
                <RecurringBadge theme={theme}>
                  Cykliczna: {formatRecurringInfo()}
                </RecurringBadge>
              )}
              
              {receipt && (
                <MetaItem>
                  <FaReceipt />
                </MetaItem>
              )}
              
              {person && person.name && (
                <MetaItem>👤 {person.name}</MetaItem>
              )}
            </TransactionMeta>
            
            {tags && tags.length > 0 && (
              <TagsContainer>
                <FaTags />
                {tags.map((tag, index) => (
                  <Tag key={index} theme={theme}>
                    {tag}
                  </Tag>
                ))}
              </TagsContainer>
            )}
          </TransactionInfo>
          
          <TransactionAmount type={type}>
            {type === 'expense' ? '-' : '+'}{formatCurrency(amount)}
          </TransactionAmount>
          
          {showActions && (
            <ActionsContainer>
              <ActionButton
                theme={theme}
                title="Pokaż szczegóły"
                onClick={() => setShowDetailsModal(true)}
              >
                <FaEye />
              </ActionButton>
              
              <ActionButton
                theme={theme}
                title="Edytuj"
                as={Link}
                to={`/transactions/edit/${_id}`}
              >
                <FaEdit />
              </ActionButton>
              
              <ActionButton
                theme={theme}
                title="Duplikuj"
                onClick={handleDuplicate}
              >
                <FaCopy />
              </ActionButton>
              
              <ActionButton
                theme={theme}
                title="Usuń"
                onClick={() => setShowDeleteModal(true)}
                className="delete"
              >
                <FaTrash />
              </ActionButton>
            </ActionsContainer>
          )}
        </TransactionMain>
      </TransactionCard>

      {/* Modal usuwania transakcji */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Usuwanie transakcji"
      >
        <DeleteModalContent>
          <p>Czy na pewno chcesz usunąć tę transakcję?</p>
          {isRecurring && (
            <RecurringWarning>
              Uwaga: Ta transakcja jest cykliczna. Usunięcie jej spowoduje usunięcie wszystkich przyszłych wystąpień.
            </RecurringWarning>
          )}
          
          <ButtonsGroup>
            <DeleteButton
              onClick={handleDelete}
              disabled={submitting}
            >
              {submitting ? 'Usuwanie...' : 'Tak, usuń'}
            </DeleteButton>
            <CancelButton
              theme={theme}
              onClick={() => setShowDeleteModal(false)}
            >
              Anuluj
            </CancelButton>
          </ButtonsGroup>
        </DeleteModalContent>
      </Modal>

      {/* Modal ze szczegółami transakcji */}
      <Modal
        isOpen={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        title="Szczegóły transakcji"
      >
        <DetailsModalContent>
          <DetailSection>
            <DetailHeader theme={theme}>Podstawowe informacje</DetailHeader>
            <DetailRow>
              <DetailLabel theme={theme}>Nazwa:</DetailLabel>
              <DetailValue theme={theme}>{name}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel theme={theme}>Kwota:</DetailLabel>
              <DetailValue theme={theme} className={type === 'expense' ? 'expense' : 'income'}>
                {type === 'expense' ? '-' : '+'}{formatCurrency(amount)}
              </DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel theme={theme}>Data:</DetailLabel>
              <DetailValue theme={theme}>{formatDate(date)}</DetailValue>
            </DetailRow>
            <DetailRow>
              <DetailLabel theme={theme}>Kategoria:</DetailLabel>
              <DetailValue theme={theme}>
                {category ? (
                  <CategoryBadge color={category.color || '#999'}>
                    {category.name}
                  </CategoryBadge>
                ) : (
                  'Brak kategorii'
                )}
              </DetailValue>
            </DetailRow>
            {description && (
              <DetailRow>
                <DetailLabel theme={theme}>Opis:</DetailLabel>
                <DetailValue theme={theme}>{description}</DetailValue>
              </DetailRow>
            )}
          </DetailSection>
          
          {isRecurring && (
            <DetailSection>
              <DetailHeader theme={theme}>Informacje o cykliczności</DetailHeader>
              <DetailRow>
                <DetailLabel theme={theme}>Częstotliwość:</DetailLabel>
                <DetailValue theme={theme}>{formatRecurringInfo()}</DetailValue>
              </DetailRow>
            </DetailSection>
          )}
          
          {tags && tags.length > 0 && (
            <DetailSection>
              <DetailHeader theme={theme}>Tagi</DetailHeader>
              <TagsContainer className="detail">
                {tags.map((tag, index) => (
                  <Tag key={index} theme={theme}>
                    {tag}
                  </Tag>
                ))}
              </TagsContainer>
            </DetailSection>
          )}
          
          {person && person.name && (
            <DetailSection>
              <DetailHeader theme={theme}>Przypisano do</DetailHeader>
              <DetailValue theme={theme}>{person.name}</DetailValue>
            </DetailSection>
          )}
          
          {receipt && (
            <DetailSection>
              <DetailHeader theme={theme}>Paragon / Faktura</DetailHeader>
              <ReceiptImage src={receipt.url || `/api/transactions/${_id}/receipt`} alt="Paragon" />
            </DetailSection>
          )}
          
          <ButtonsGroup>
            <ActionButton
              theme={theme}
              as={Link}
              to={`/transactions/edit/${_id}`}
              className="full-width"
            >
              <FaEdit /> Edytuj transakcję
            </ActionButton>
          </ButtonsGroup>
        </DetailsModalContent>
      </Modal>
    </>
  );
};

TransactionItem.propTypes = {
  transaction: PropTypes.object.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  duplicateTransaction: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  ui: state.ui
});

// Styled components
const TransactionCard = styled.div`
  background-color: ${(props) => (props.theme === 'dark' ? '#2c2c2c' : 'white')};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  overflow: hidden;
  border-left: 4px solid ${(props) =>
    props.type === 'expense' ? '#f44336' : '#4caf50'};
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const TransactionMain = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
`;

const CategoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.categoryColor};
  color: white;
  border-radius: 50%;
  font-size: 1.2rem;
  flex-shrink: 0;
  
  span {
    line-height: 1;
  }
`;

const TransactionInfo = styled.div`
  flex: 1;
  min-width: 0;
`;

const TransactionTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TransactionMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: #666;
`;

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const CategoryBadge = styled.span`
  display: inline-block;
  padding: 0.2rem 0.5rem;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 4px;
  font-size: 0.75rem;
`;

const RecurringBadge = styled.div`
  background-color: ${(props) => (props.theme === 'dark' ? '#444' : '#f0f0f0')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
  font-size: 0.8rem;
  color: #666;
  
  &.detail {
    margin-top: 0;
  }
`;

const Tag = styled.span`
  background-color: ${(props) => (props.theme === 'dark' ? '#444' : '#f0f0f0')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  font-size: 0.75rem;
`;

const TransactionAmount = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${(props) => (props.type === 'expense' ? '#f44336' : '#4caf50')};
  white-space: nowrap;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: 0.5rem;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${(props) => (props.theme === 'dark' ? '#444' : '#f0f0f0')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${(props) => (props.theme === 'dark' ? '#555' : '#e0e0e0')};
  }
  
  &.delete:hover {
    background-color: #f44336;
    color: white;
  }
  
  &.full-width {
    width: auto;
    padding: 0.5rem 1rem;
    display: flex;
    gap: 0.5rem;
  }
`;

const DeleteModalContent = styled.div`
  p {
    margin-bottom: 1.5rem;
  }
`;

const RecurringWarning = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const DeleteButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #d32f2f;
  }
  
  &:disabled {
    background-color: #e57373;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) => (props.theme === 'dark' ? '#444' : '#e0e0e0')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${(props) => (props.theme === 'dark' ? '#555' : '#ccc')};
  }
`;

const DetailsModalContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 0.5rem;
`;

const DetailSection = styled.div`
  margin-bottom: 1.5rem;
`;

const DetailHeader = styled.h4`
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  font-size: 1rem;
  margin: 0 0 0.75rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#eee')};
`;

const DetailRow = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailLabel = styled.span`
  color: ${(props) => (props.theme === 'dark' ? '#bbb' : '#666')};
  font-size: 0.9rem;
  width: 100px;
  flex-shrink: 0;
`;

const DetailValue = styled.span`
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  flex: 1;
  
  &.expense {
    color: #f44336;
  }
  
  &.income {
    color: #4caf50;
  }
`;

const ReceiptImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

export default connect(mapStateToProps, {
  deleteTransaction,
  duplicateTransaction,
  setAlert
})(TransactionItem);