import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import './Modal.css';

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  size, 
  showFooter, 
  footerContent,
  closeOnBackdropClick,
  className
}) => {
  const modalRef = useRef(null);

  // Close modal when pressing ESC key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && isOpen) onClose();
    };
    
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  // Handle backdrop click
  const handleBackdropClick = (e) => {
    if (closeOnBackdropClick && modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  // Size class mapping
  const sizeClass = {
    small: 'modal-small',
    medium: 'modal-medium',
    large: 'modal-large',
    fullscreen: 'modal-fullscreen'
  }[size] || 'modal-medium';

  return ReactDOM.createPortal(
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div 
        ref={modalRef}
        className={`modal ${sizeClass} ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          <h3>{title}</h3>
          <button className="modal-close" onClick={onClose}>×</button>
        </div>
        
        <div className="modal-body">
          {children}
        </div>
        
        {showFooter && (
          <div className="modal-footer">
            {footerContent || (
              <button className="btn btn-secondary" onClick={onClose}>
                Zamknij
              </button>
            )}
          </div>
        )}
      </div>
    </div>,
    document.getElementById('modal-root') || document.body
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullscreen']),
  showFooter: PropTypes.bool,
  footerContent: PropTypes.node,
  closeOnBackdropClick: PropTypes.bool,
  className: PropTypes.string
};

Modal.defaultProps = {
  title: '',
  size: 'medium',
  showFooter: true,
  closeOnBackdropClick: true,
  className: ''
};

export default Modal;