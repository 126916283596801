import React, { useState, useRef, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

const DateRangeContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  padding: 10px 15px;
  background-color: ${props => props.theme.inputBg};
  cursor: pointer;
  
  &:focus-within {
    border-color: ${props => props.theme.primary};
    box-shadow: 0 0 0 1px ${props => props.theme.primaryLight};
  }
`;

const InputText = styled.div`
  flex: 1;
  color: ${props => props.placeholder ? props.theme.textSecondary : props.theme.textPrimary};
`;

const DropdownIcon = styled.div`
  color: ${props => props.theme.textSecondary};
  transition: transform 0.2s;
  
  ${props => props.open && `
    transform: rotate(180deg);
  `}
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.cardBg};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const PickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const PickerTitle = styled.div`
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  padding: 5px;
  
  &:hover {
    color: ${props => props.theme.primaryDark};
  }
  
  &:disabled {
    color: ${props => props.theme.textSecondary};
    cursor: not-allowed;
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
`;

const WeekdayLabel = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.textSecondary};
  padding: 5px 0;
`;

const DateCell = styled.div`
  text-align: center;
  padding: 8px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  ${props => props.isToday && `
    border: 1px solid ${props.theme.primary};
  `}
  
  ${props => props.isInRange && `
    background-color: ${props.theme.primaryLight};
    color: ${props.theme.primary};
  `}
  
  ${props => props.isStartOrEnd && `
    background-color: ${props.theme.primary};
    color: white;
  `}
  
  ${props => props.isDisabled && `
    color: ${props.theme.textDisabled};
    cursor: not-allowed;
  `}
  
  ${props => props.isOtherMonth && `
    color: ${props.theme.textDisabled};
  `}
  
  ${props => !props.isDisabled && !props.isStartOrEnd && `
    &:hover {
      background-color: ${props.theme.hoverBg};
    }
  `}
`;

const PresetContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const PresetButton = styled.button`
  background-color: ${props => props.theme.buttonBg};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  
  &:hover {
    background-color: ${props => props.theme.hoverBg};
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  ${props => props.primary ? `
    background-color: ${props.theme.primary};
    color: white;
    border: none;
    
    &:hover {
      background-color: ${props.theme.primaryDark};
    }
  ` : `
    background-color: transparent;
    color: ${props.theme.textPrimary};
    border: 1px solid ${props.theme.borderColor};
    
    &:hover {
      background-color: ${props.theme.hoverBg};
    }
  `}
`;

const DateRangePicker = ({ 
  startDate, 
  endDate, 
  onChange, 
  placeholder = 'Wybierz zakres dat',
  minDate = null,
  maxDate = null
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(
    startDate ? new Date(startDate) : new Date()
  );
  const [tempStart, setTempStart] = useState(startDate ? new Date(startDate) : null);
  const [tempEnd, setTempEnd] = useState(endDate ? new Date(endDate) : null);
  const [selectionMode, setSelectionMode] = useState('start'); // 'start' or 'end'
  
  const containerRef = useRef(null);
  
  const weekdays = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb', 'Nd'];
  
  // Format dates for display
  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('pl-PL', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit' 
    });
  };
  
  // Get the text to display in the input
  const getDisplayText = () => {
    if (startDate && endDate) {
      return `${formatDate(new Date(startDate))} - ${formatDate(new Date(endDate))}`;
    }
    if (startDate) {
      return `${formatDate(new Date(startDate))} - Wybierz datę końcową`;
    }
    return placeholder;
  };
  
  // Get the days to display in the calendar
  const getDaysInMonth = (year, month) => {
    // Get days in the current month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    
    // Get the first day of the month (0 = Sunday, 1 = Monday, etc.)
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    
    // Adjust for Monday as the first day of the week
    const startDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    
    // Get days from the previous month
    const daysFromPrevMonth = startDay;
    const prevMonthDays = [];
    if (daysFromPrevMonth > 0) {
      const prevMonth = month === 0 ? 11 : month - 1;
      const prevMonthYear = month === 0 ? year - 1 : year;
      const daysInPrevMonth = new Date(prevMonthYear, prevMonth + 1, 0).getDate();
      
      for (let i = daysInPrevMonth - daysFromPrevMonth + 1; i <= daysInPrevMonth; i++) {
        prevMonthDays.push({
          date: new Date(prevMonthYear, prevMonth, i),
          isOtherMonth: true
        });
      }
    }
    
    // Get days from the current month
    const currentMonthDays = [];
    for (let i = 1; i <= daysInMonth; i++) {
      currentMonthDays.push({
        date: new Date(year, month, i),
        isOtherMonth: false
      });
    }
    
    // Get days from the next month (to fill the grid)
    const totalDaysDisplayed = Math.ceil((daysFromPrevMonth + daysInMonth) / 7) * 7;
    const daysFromNextMonth = totalDaysDisplayed - (daysFromPrevMonth + daysInMonth);
    const nextMonthDays = [];
    if (daysFromNextMonth > 0) {
      const nextMonth = month === 11 ? 0 : month + 1;
      const nextMonthYear = month === 11 ? year + 1 : year;
      
      for (let i = 1; i <= daysFromNextMonth; i++) {
        nextMonthDays.push({
          date: new Date(nextMonthYear, nextMonth, i),
          isOtherMonth: true
        });
      }
    }
    
    return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
  };
  
  // Check if a date is today
  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };
  
  // Check if a date is in the selected range
  const isInRange = (date) => {
    if (!tempStart || !tempEnd) return false;
    
    return date > tempStart && date < tempEnd;
  };
  
  // Check if a date is the start or end date
  const isStartOrEnd = (date) => {
    if (!date) return false;
    
    if (tempStart && (
      date.getDate() === tempStart.getDate() &&
      date.getMonth() === tempStart.getMonth() &&
      date.getFullYear() === tempStart.getFullYear()
    )) return true;
    
    if (tempEnd && (
      date.getDate() === tempEnd.getDate() &&
      date.getMonth() === tempEnd.getMonth() &&
      date.getFullYear() === tempEnd.getFullYear()
    )) return true;
    
    return false;
  };
  
  // Check if a date is disabled
  const isDisabled = (date) => {
    if (minDate && date < new Date(minDate)) return true;
    if (maxDate && date > new Date(maxDate)) return true;
    return false;
  };
  
  // Handle date click
  const handleDateClick = (date) => {
    if (isDisabled(date)) return;
    
    if (selectionMode === 'start') {
      setTempStart(date);
      setTempEnd(null);
      setSelectionMode('end');
    } else {
      // Ensure end date is after start date
      if (date < tempStart) {
        setTempEnd(tempStart);
        setTempStart(date);
      } else {
        setTempEnd(date);
      }
      setSelectionMode('start');
    }
  };
  
  // Handle month navigation
  const prevMonth = () => {
    setCurrentMonth(new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    ));
  };
  
  const nextMonth = () => {
    setCurrentMonth(new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    ));
  };
  
  // Preset date ranges
  const presets = [
    {
      label: 'Ostatnie 7 dni',
      getRange: () => {
        const end = new Date();
        const start = new Date();
        start.setDate(end.getDate() - 6);
        return { start, end };
      }
    },
    {
      label: 'Ostatnie 30 dni',
      getRange: () => {
        const end = new Date();
        const start = new Date();
        start.setDate(end.getDate() - 29);
        return { start, end };
      }
    },
    {
      label: 'Ten miesiąc',
      getRange: () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        const end = new Date();
        return { start, end };
      }
    },
    {
      label: 'Poprzedni miesiąc',
      getRange: () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const end = new Date(now.getFullYear(), now.getMonth(), 0);
        return { start, end };
      }
    },
    {
      label: 'Ten rok',
      getRange: () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), 0, 1);
        const end = new Date();
        return { start, end };
      }
    }
  ];
  
  // Handle preset click
  const handlePresetClick = (preset) => {
    const { start, end } = preset.getRange();
    setTempStart(start);
    setTempEnd(end);
    setCurrentMonth(start);
  };
  
  // Apply the selected date range
  const applySelection = () => {
    if (tempStart && tempEnd) {
      onChange(tempStart, tempEnd);
      setIsOpen(false);
    }
  };
  
  // Clear the selection
  const clearSelection = () => {
    setTempStart(null);
    setTempEnd(null);
    onChange(null, null);
  };
  
  // Handle clicks outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Render the calendar
  const renderCalendar = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const days = getDaysInMonth(year, month);
    
    return (
      <div>
        <PickerHeader>
          <NavButton onClick={prevMonth}>
            &lt;
          </NavButton>
          <PickerTitle>
            {currentMonth.toLocaleDateString('pl-PL', { month: 'long', year: 'numeric' })}
          </PickerTitle>
          <NavButton onClick={nextMonth}>
            &gt;
          </NavButton>
        </PickerHeader>
        
        <CalendarGrid>
          {weekdays.map(day => (
            <WeekdayLabel key={day}>
              {day}
            </WeekdayLabel>
          ))}
          
          {days.map((day, index) => (
            <DateCell
              key={index}
              isToday={isToday(day.date)}
              isInRange={isInRange(day.date)}
              isStartOrEnd={isStartOrEnd(day.date)}
              isDisabled={isDisabled(day.date)}
              isOtherMonth={day.isOtherMonth}
              theme={theme}
              onClick={() => handleDateClick(day.date)}
            >
              {day.date.getDate()}
            </DateCell>
          ))}
        </CalendarGrid>
        
        <PresetContainer>
          {presets.map(preset => (
            <PresetButton
              key={preset.label}
              theme={theme}
              onClick={() => handlePresetClick(preset)}
            >
              {preset.label}
            </PresetButton>
          ))}
        </PresetContainer>
        
        <ActionButtons>
          <Button
            theme={theme}
            onClick={clearSelection}
          >
            Wyczyść
          </Button>
          <Button
            primary
            theme={theme}
            onClick={applySelection}
            disabled={!tempStart || !tempEnd}
          >
            Zastosuj
          </Button>
        </ActionButtons>
      </div>
    );
  };
  
  return (
    <DateRangeContainer ref={containerRef}>
      <InputContainer onClick={() => setIsOpen(!isOpen)}>
        <InputText placeholder={!startDate && !endDate}>
          {getDisplayText()}
        </InputText>
        <DropdownIcon open={isOpen}>▼</DropdownIcon>
      </InputContainer>
      
      {isOpen && (
        <PickerContainer>
          {renderCalendar()}
        </PickerContainer>
      )}
    </DateRangeContainer>
  );
};

export default DateRangePicker;