import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { contributeToGoal } from '../../actions/goal';
import { setAlert } from '../../actions/alert';

const ContributeForm = ({
  contributeToGoal,
  setAlert,
  goalId,
  goalName,
  currentAmount,
  targetAmount,
  onSuccess,
  ui: { theme }
}) => {
  const [formData, setFormData] = useState({
    amount: '',
    date: new Date().toISOString().split('T')[0],
    note: '',
    fromSavings: false
  });

  const [submitting, setSubmitting] = useState(false);

  const { amount, date, note, fromSavings } = formData;

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!amount || parseFloat(amount) <= 0) {
      return setAlert('Proszę podać prawidłową kwotę', 'danger');
    }

    const amountValue = parseFloat(amount);
    const remainingToTarget = targetAmount - currentAmount;

    // Sprawdzenie czy wpłata nie przekracza pozostałej kwoty do celu
    if (amountValue > remainingToTarget) {
      return setAlert(
        `Wpłata przekracza pozostałą kwotę do celu (${remainingToTarget.toFixed(
          2
        )} zł)`,
        'warning'
      );
    }

    try {
      setSubmitting(true);
      await contributeToGoal(goalId, {
        amount: amountValue,
        date,
        note,
        fromSavings
      });

      setAlert('Wpłata została dodana', 'success');
      setFormData({
        amount: '',
        date: new Date().toISOString().split('T')[0],
        note: '',
        fromSavings: false
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setAlert('Błąd podczas dodawania wpłaty', 'danger');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <FormTitle theme={theme}>Wpłata na cel: {goalName}</FormTitle>
      
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label theme={theme} htmlFor="amount">Kwota*</Label>
          <Input
            theme={theme}
            type="number"
            id="amount"
            name="amount"
            value={amount}
            onChange={onChange}
            required
            step="0.01"
            min="0.01"
            max={targetAmount - currentAmount}
            placeholder="0.00"
          />
          <HelpText theme={theme}>
            Pozostało do celu: {(targetAmount - currentAmount).toFixed(2)} zł
          </HelpText>
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="date">Data wpłaty</Label>
          <Input
            theme={theme}
            type="date"
            id="date"
            name="date"
            value={date}
            onChange={onChange}
            max={new Date().toISOString().split('T')[0]}
          />
        </FormGroup>

        <FormGroup>
          <Label theme={theme} htmlFor="note">Notatka (opcjonalnie)</Label>
          <Textarea
            theme={theme}
            id="note"
            name="note"
            value={note}
            onChange={onChange}
            rows="3"
            placeholder="np. Premia z pracy, Oszczędności z budżetu itp."
          />
        </FormGroup>

        <CheckboxGroup>
          <CheckboxLabel theme={theme}>
            <CheckboxInput
              type="checkbox"
              name="fromSavings"
              checked={fromSavings}
              onChange={onChange}
            />
            <CheckboxText theme={theme}>
              Przenieś z głównego konta oszczędności
            </CheckboxText>
          </CheckboxLabel>
          <HelpText theme={theme}>
            Zaznacz, jeśli chcesz przenieść środki z ogólnych oszczędności na ten cel
          </HelpText>
        </CheckboxGroup>

        <ButtonsGroup>
          <SubmitButton type="submit" disabled={submitting}>
            {submitting ? 'Dodawanie...' : 'Dodaj wpłatę'}
          </SubmitButton>
        </ButtonsGroup>
      </Form>
    </FormContainer>
  );
};

ContributeForm.propTypes = {
  contributeToGoal: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  goalId: PropTypes.string.isRequired,
  goalName: PropTypes.string.isRequired,
  currentAmount: PropTypes.number.isRequired,
  targetAmount: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  ui: state.ui
});

// Styled components
const FormContainer = styled.div`
  width: 100%;
`;

const FormTitle = styled.h3`
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  margin: 0 0 1.5rem 0;
  font-size: 1.1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${(props) => (props.theme === 'dark' ? '#ddd' : '#666')};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'dark' ? '#444' : '#ddd')};
  border-radius: 4px;
  background-color: ${(props) => (props.theme === 'dark' ? '#333' : 'white')};
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const CheckboxGroup = styled.div`
  margin-bottom: 0.5rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
`;

const CheckboxText = styled.span`
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#333')};
`;

const HelpText = styled.small`
  color: ${(props) => (props.theme === 'dark' ? '#aaa' : '#999')};
  font-size: 0.8rem;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }

  &:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
  }
`;

export default connect(mapStateToProps, { contributeToGoal, setAlert })(
  ContributeForm
);