// client/src/actions/budget.js
import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_BUDGETS,
  GET_BUDGET,
  BUDGET_ERROR,
  ADD_BUDGET,
  DELETE_BUDGET,
  UPDATE_BUDGET,
  CLEAR_BUDGET
} from './types';

// Get all budgets
export const getBudgets = () => async dispatch => {
  try {
    const res = await axios.get('/api/budgets');

    dispatch({
      type: GET_BUDGETS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BUDGET_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get budget by ID
export const getBudget = id => async dispatch => {
  try {
    const res = await axios.get(`/api/budgets/${id}`);

    dispatch({
      type: GET_BUDGET,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BUDGET_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Add budget
export const addBudget = formData => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/budgets', formData, config);

    dispatch({
      type: ADD_BUDGET,
      payload: res.data
    });

    dispatch(setAlert('Budżet dodany pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: BUDGET_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Update budget
export const updateBudget = (id, formData) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/budgets/${id}`, formData, config);

    dispatch({
      type: UPDATE_BUDGET,
      payload: res.data
    });

    dispatch(setAlert('Budżet zaktualizowany pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: BUDGET_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Delete budget
export const deleteBudget = id => async dispatch => {
  if (window.confirm('Czy na pewno chcesz usunąć ten budżet?')) {
    try {
      await axios.delete(`/api/budgets/${id}`);

      dispatch({
        type: DELETE_BUDGET,
        payload: id
      });

      dispatch(setAlert('Budżet usunięty pomyślnie', 'success'));
    } catch (err) {
      dispatch({
        type: BUDGET_ERROR,
        payload: { msg: err.response?.statusText, status: err.response?.status }
      });
    }
  }
};

// Clear budget
export const clearBudget = () => dispatch => {
  dispatch({ type: CLEAR_BUDGET });
};