// client/src/pages/Groups/GroupsList.js
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaUsers, FaPlus, FaPlusCircle, FaUserPlus, FaSearch, FaEllipsisH } from 'react-icons/fa';

import { getGroups, joinGroup } from '../../actions/group';
import Spinner from '../../components/layout/Spinner';

const GroupsContainer = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
    color: #6c5ce7;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  background-color: #6c5ce7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    background-color: #5649c0;
    transform: translateY(-2px);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const SecondaryButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f1f1f1'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#e1e1e1'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const SearchBar = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
  border-radius: 50px;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 500px;

  svg {
    color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
    margin-right: 0.75rem;
  }
`;

const SearchInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  width: 100%;
  font-size: 1rem;
`;

const GroupsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const GroupCard = styled(Link)`
  text-decoration: none;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`;

const GroupHeader = styled.div`
  padding: 1.2rem;
  background-color: #6c5ce7;
  color: white;
`;

const GroupName = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
`;

const GroupBody = styled.div`
  padding: 1.2rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const MemberCount = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  margin-bottom: 0.5rem;
`;

const GroupDescription = styled.p`
  font-size: 0.95rem;
  margin: 0.5rem 0;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const GroupFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.2rem;
  border-top: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
`;

const JoinGroupModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.h2`
  margin-top: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-bottom: 1.5rem;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1rem;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const SubmitButton = styled.button`
  padding: 0.6rem 1.2rem;
  background-color: #6c5ce7;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  &:disabled {
    background-color: #a29bde;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  padding: 0.6rem 1.2rem;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f1f1f1'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#e1e1e1'};
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const EmptyIcon = styled.div`
  font-size: 3rem;
  color: #6c5ce7;
  margin-bottom: 1.5rem;
`;

const EmptyText = styled.p`
  font-size: 1.1rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  margin-bottom: 2rem;
`;

const GroupsList = ({ getGroups, joinGroup, group: { groups, loading }, ui: { theme }, history }) => {
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleJoinSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    const success = await joinGroup(inviteCode);
    
    setSubmitting(false);
    if (success) {
      setShowJoinModal(false);
      setInviteCode('');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredGroups = searchTerm 
    ? groups.filter(group => 
        group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (group.description && group.description.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    : groups;

  if (loading) {
    return <Spinner />;
  }

  return (
    <GroupsContainer>
      <Header>
        <Title theme={theme}>
          <FaUsers />
          Grupy i wspólne finanse
        </Title>
        <ActionButtons>
          <SecondaryButton 
            theme={theme}
            onClick={() => setShowJoinModal(true)}
          >
            <FaUserPlus />
            Dołącz do grupy
          </SecondaryButton>
          <Button to="/dashboard/groups/create">
            <FaPlus />
            Utwórz grupę
          </Button>
        </ActionButtons>
      </Header>

      <SearchBar theme={theme}>
        <FaSearch />
        <SearchInput 
          type="text" 
          placeholder="Szukaj grup..." 
          value={searchTerm}
          onChange={handleSearch}
          theme={theme}
        />
      </SearchBar>

      {filteredGroups.length > 0 ? (
        <GroupsGrid>
          {filteredGroups.map(group => (
            <GroupCard key={group._id} to={`/dashboard/groups/${group._id}`} theme={theme}>
              <GroupHeader>
                <GroupName>{group.name}</GroupName>
              </GroupHeader>
              <GroupBody theme={theme}>
                <MemberCount theme={theme}>
                  {group.members.length} {group.members.length === 1 ? 'członek' : 
                  group.members.length < 5 ? 'członków' : 'członków'}
                </MemberCount>
                <GroupDescription theme={theme}>
                  {group.description || 'Brak opisu'}
                </GroupDescription>
              </GroupBody>
              <GroupFooter theme={theme}>
                <span>Utworzono: {new Date(group.createdAt).toLocaleDateString('pl-PL')}</span>
                <FaEllipsisH />
              </GroupFooter>
            </GroupCard>
          ))}
        </GroupsGrid>
      ) : (
        <EmptyState theme={theme}>
          <EmptyIcon>
            <FaUsers />
          </EmptyIcon>
          <EmptyText theme={theme}>
            {searchTerm 
              ? 'Nie znaleziono grup pasujących do wyszukiwania.'
              : 'Nie należysz jeszcze do żadnej grupy. Utwórz swoją pierwszą grupę lub dołącz do istniejącej!'}
          </EmptyText>
          <Button to="/dashboard/groups/create">
            <FaPlusCircle />
            Utwórz pierwszą grupę
          </Button>
        </EmptyState>
      )}

      {showJoinModal && (
        <JoinGroupModal>
          <ModalContent theme={theme}>
            <ModalTitle theme={theme}>Dołącz do grupy</ModalTitle>
            <ModalForm onSubmit={handleJoinSubmit}>
              <FormGroup>
                <Label theme={theme}>Kod zaproszenia</Label>
                <Input
                  type="text"
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                  placeholder="Wpisz kod zaproszenia"
                  required
                  theme={theme}
                />
              </FormGroup>
              <ModalButtons>
                <CancelButton 
                  type="button"
                  onClick={() => setShowJoinModal(false)}
                  theme={theme}
                >
                  Anuluj
                </CancelButton>
                <SubmitButton 
                  type="submit"
                  disabled={!inviteCode || submitting}
                >
                  {submitting ? 'Dołączanie...' : 'Dołącz do grupy'}
                </SubmitButton>
              </ModalButtons>
            </ModalForm>
          </ModalContent>
        </JoinGroupModal>
      )}
    </GroupsContainer>
  );
};

GroupsList.propTypes = {
  getGroups: PropTypes.func.isRequired,
  joinGroup: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  group: state.group,
  ui: state.ui
});

export default connect(mapStateToProps, { getGroups, joinGroup })(GroupsList);