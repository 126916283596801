// client/src/actions/ui.js
import {
  TOGGLE_THEME,
  SET_THEME,
  TOGGLE_SIDEBAR,
  SET_SIDEBAR,
  SET_LANGUAGE,
  SET_CURRENCY,
  SET_LOADING
} from './types';

// Toggle theme (light/dark)
export const toggleTheme = () => dispatch => {
  dispatch({ type: TOGGLE_THEME });
};

// Set theme (light/dark)
export const setTheme = (theme) => dispatch => {
  dispatch({
    type: SET_THEME,
    payload: theme
  });
};

// Toggle sidebar
export const toggleSidebar = () => dispatch => {
  dispatch({ type: TOGGLE_SIDEBAR });
};

// Set sidebar state
export const setSidebar = (isOpen) => dispatch => {
  dispatch({
    type: SET_SIDEBAR,
    payload: isOpen
  });
};

// Set language
export const setLanguage = (language) => dispatch => {
  dispatch({
    type: SET_LANGUAGE,
    payload: language
  });
};

// Set currency
export const setCurrency = (currency) => dispatch => {
  dispatch({
    type: SET_CURRENCY,
    payload: currency
  });
};

// Set loading state
export const setLoading = (isLoading) => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: isLoading
  });
};