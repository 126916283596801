// client/src/utils/formatting.js

/**
 * Format a number as currency
 * 
 * @param {number} amount - The amount to format
 * @param {string} currencyCode - The currency code (default: 'PLN')
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, currencyCode = 'PLN') => {
    // Check if we have a valid number
    if (amount === null || amount === undefined || isNaN(amount)) {
      return '0,00 zł';
    }
  
    // Format based on currency code
    switch (currencyCode) {
      case 'PLN':
        return amount.toLocaleString('pl-PL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + ' zł';
      case 'USD':
        return '$' + amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      case 'EUR':
        return amount.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + ' €';
      case 'GBP':
        return '£' + amount.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      default:
        return amount.toLocaleString('pl-PL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + ' ' + currencyCode;
    }
  };
  
  /**
   * Format a date
   * 
   * @param {string|Date} date - The date to format
   * @param {string} locale - The locale for formatting (default: 'pl-PL')
   * @param {object} options - The formatting options (default: day, month, year)
   * @returns {string} Formatted date string
   */
  export const formatDate = (date, locale = 'pl-PL', options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }) => {
    if (!date) return '';
  
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    
    // Check if date is valid
    if (isNaN(dateObj.getTime())) {
      return '';
    }
  
    return dateObj.toLocaleDateString(locale, options);
  };
  
  /**
   * Format a date and time
   * 
   * @param {string|Date} date - The date to format
   * @param {string} locale - The locale for formatting (default: 'pl-PL')
   * @returns {string} Formatted date and time string
   */
  export const formatDateTime = (date, locale = 'pl-PL') => {
    if (!date) return '';
  
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    
    // Check if date is valid
    if (isNaN(dateObj.getTime())) {
      return '';
    }
  
    return dateObj.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  /**
   * Truncate text to a specified length
   * 
   * @param {string} text - The text to truncate
   * @param {number} length - The maximum length
   * @returns {string} Truncated text
   */
  export const truncateText = (text, length = 50) => {
    if (!text) return '';
    
    return text.length > length ? text.substring(0, length) + '...' : text;
  };
  
  /**
   * Get relative time from date (e.g. "2 hours ago")
   * 
   * @param {string|Date} date - The date
   * @param {string} locale - The locale for formatting (default: 'pl-PL')
   * @returns {string} Relative time string
   */
  export const getRelativeTime = (date, locale = 'pl-PL') => {
    if (!date) return '';
  
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    
    // Check if date is valid
    if (isNaN(dateObj.getTime())) {
      return '';
    }
  
    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
    const now = new Date();
    const diffInSeconds = Math.floor((dateObj - now) / 1000);
    
    if (Math.abs(diffInSeconds) < 60) {
      return rtf.format(diffInSeconds, 'second');
    }
    
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (Math.abs(diffInMinutes) < 60) {
      return rtf.format(diffInMinutes, 'minute');
    }
    
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (Math.abs(diffInHours) < 24) {
      return rtf.format(diffInHours, 'hour');
    }
    
    const diffInDays = Math.floor(diffInHours / 24);
    if (Math.abs(diffInDays) < 30) {
      return rtf.format(diffInDays, 'day');
    }
    
    const diffInMonths = Math.floor(diffInDays / 30);
    if (Math.abs(diffInMonths) < 12) {
      return rtf.format(diffInMonths, 'month');
    }
    
    const diffInYears = Math.floor(diffInDays / 365);
    return rtf.format(diffInYears, 'year');
  };