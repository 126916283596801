// client/src/components/dashboard/TransactionsList.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { FaArrowUp, FaArrowDown, FaEllipsisH, FaReceipt, FaSync } from 'react-icons/fa';

// Actions
import { getTransactions } from '../../actions/transaction';

const TransactionsContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  height: 100%;
`;

const TransactionTable = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Transaction = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f9f9f9'};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => {
    if (props.type === 'income') return 'rgba(39, 174, 96, 0.1)';
    if (props.type === 'expense') return 'rgba(231, 76, 60, 0.1)';
    return 'rgba(108, 92, 231, 0.1)';
  }};
  color: ${props => {
    if (props.type === 'income') return '#27ae60';
    if (props.type === 'expense') return '#e74c3c';
    return '#6c5ce7';
  }};
  margin-right: 1rem;
  flex-shrink: 0;
`;

const TransactionDetails = styled.div`
  flex: 1;
  min-width: 0;
`;

const TransactionTitle = styled.div`
  font-weight: 500;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TransactionMeta = styled.div`
  display: flex;
  font-size: 0.8rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
`;

const Category = styled.span`
  padding: 0.2rem 0.5rem;
  background-color: ${props => props.color ? `${props.color}20` : '#f1f1f1'};
  color: ${props => props.color || (props.theme === 'dark' ? 'white' : '#333')};
  border-radius: 4px;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  white-space: nowrap;
`;

const Date = styled.span`
  white-space: nowrap;
`;

const Amount = styled.div`
  font-weight: 600;
  color: ${props => {
    if (props.type === 'income') return '#27ae60';
    if (props.type === 'expense') return '#e74c3c';
    return props.theme === 'dark' ? 'white' : '#333';
  }};
  margin: 0 1rem;
  white-space: nowrap;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
`;

const RecurringBadge = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #6c5ce7;
  margin-left: 0.5rem;

  svg {
    margin-right: 0.25rem;
  }
`;

const NoTransactions = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
`;

const ViewAllLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 0.75rem;
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s;
  margin-top: 0.5rem;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
    border-radius: 4px;
  }
`;

const TransactionsList = ({ 
  getTransactions, 
  transaction: { transactions, loading }, 
  limit = 5, 
  theme 
}) => {
  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  // Formatowanie kwoty
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN'
    }).format(amount);
  };

  // Formatowanie daty
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'd MMM yyyy', { locale: pl });
  };

  // Limitowanie transakcji do wyświetlenia
  const displayedTransactions = transactions?.slice(0, limit) || [];

  if (loading) return <p>Ładowanie transakcji...</p>;

  return (
    <TransactionsContainer theme={theme}>
      <TransactionTable>
        {displayedTransactions.length > 0 ? (
          displayedTransactions.map(transaction => (
            <Transaction key={transaction._id} theme={theme}>
              <IconWrapper type={transaction.type}>
                {transaction.type === 'income' ? <FaArrowUp /> : <FaArrowDown />}
              </IconWrapper>
              
              <TransactionDetails>
                <TransactionTitle theme={theme}>{transaction.description}</TransactionTitle>
                <TransactionMeta theme={theme}>
                  {transaction.category && (
                    <Category 
                      color={transaction.category.color}
                      theme={theme}
                    >
                      {transaction.category.name}
                    </Category>
                  )}
                  <Date>{formatDate(transaction.date)}</Date>
                  {transaction.isRecurring && (
                    <RecurringBadge>
                      <FaSync />
                      Cykliczne
                    </RecurringBadge>
                  )}
                </TransactionMeta>
              </TransactionDetails>
              
              <Amount type={transaction.type} theme={theme}>
                {transaction.type === 'income' ? '+' : '-'} {formatAmount(transaction.amount)}
              </Amount>
              
              {transaction.receipt && (
                <ActionButton theme={theme} title="Zobacz paragon">
                  <FaReceipt />
                </ActionButton>
              )}
              
              <ActionButton theme={theme} title="Więcej opcji">
                <FaEllipsisH />
              </ActionButton>
            </Transaction>
          ))
        ) : (
          <NoTransactions theme={theme}>
            Brak transakcji do wyświetlenia
          </NoTransactions>
        )}
      </TransactionTable>
      
      {displayedTransactions.length > 0 && (
        <ViewAllLink to="/dashboard/transactions">
          Zobacz wszystkie transakcje
        </ViewAllLink>
      )}
    </TransactionsContainer>
  );
};

TransactionsList.propTypes = {
  getTransactions: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  limit: PropTypes.number,
  theme: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  transaction: state.transaction
});

export default connect(mapStateToProps, { getTransactions })(TransactionsList);