import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { 
  updateUserSettings, 
  updateNotificationSettings, 
  updateSecuritySettings,
  toggleDarkMode
} from '../../redux/actions/SettingsActions';
import Spinner from '../../components/ui/Spinner';

// Definicja stałych motywów (do usunięcia błędu z useTheme)
const lightTheme = {
  textPrimary: '#333',
  textSecondary: '#666',
  cardBg: 'white',
  primary: '#4a90e2',
  primaryDark: '#3a70b2',
  borderColor: '#ddd',
  inputBg: 'white',
  danger: '#e74c3c',
  success: '#2ecc71',
  disabledBg: '#cccccc'
};

const darkTheme = {
  textPrimary: 'white',
  textSecondary: '#aaa',
  cardBg: '#2c2c2c',
  primary: '#4a90e2',
  primaryDark: '#3a70b2',
  borderColor: '#444',
  inputBg: '#333',
  danger: '#e74c3c',
  success: '#2ecc71',
  disabledBg: '#555555'
};

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${props => props.theme.textPrimary};
  margin: 0;
`;

const Card = styled.div`
  background-color: ${props => props.theme.cardBg};
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  color: ${props => props.theme.textPrimary};
  margin-top: 0;
  margin-bottom: 20px;
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 12px 20px;
  cursor: pointer;
  border-bottom: 2px solid ${props => 
    props.active ? props.theme.primary : 'transparent'};
  color: ${props => 
    props.active ? props.theme.primary : props.theme.textSecondary};
  font-weight: ${props => props.active ? '600' : '400'};
  transition: all 0.2s;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  outline: none;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  background-color: ${props => props.theme.inputBg};
  color: ${props => props.theme.textPrimary};
  outline: none;

  &:focus {
    border-color: ${props => props.theme.primary};
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const SwitchLabel = styled.div`
  color: ${props => props.theme.textPrimary};
  font-weight: 500;
`;

const SwitchDescription = styled.div`
  color: ${props => props.theme.textSecondary};
  font-size: 12px;
  margin-top: 4px;
`;

const SwitchButton = styled.div`
  position: relative;
  width: 46px;
  height: 24px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.checked ? props.theme.primary : props.theme.borderColor};
  transition: 0.4s;
  border-radius: 24px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${props => props.checked ? 'translateX(22px)' : 'translateX(0)'};
  }
`;

const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.primaryDark};
  }

  &:disabled {
    background-color: ${props => props.theme.disabledBg};
    cursor: not-allowed;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const ErrorText = styled.div`
  color: ${props => props.theme.danger};
  font-size: 12px;
  margin-top: 5px;
`;

const SuccessText = styled.div`
  color: ${props => props.theme.success};
  font-size: 12px;
  margin-top: 5px;
`;

const LabelInfo = styled.div`
  color: ${props => props.theme.textSecondary};
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 5px;
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${props => props.theme.borderColor};
  margin: 20px 0;
`;

const Settings = () => {
  const dispatch = useDispatch();
  // Zamiast useTheme używamy bezpośrednio wartości z redux
  const { isLoading } = useSelector(state => state.settings || { isLoading: false });
  const darkMode = useSelector(state => state.settings?.darkMode || false);
  // Używamy odpowiedniego motywu na podstawie wartości darkMode
  const theme = darkMode ? darkTheme : lightTheme;
  const currentUser = useSelector(state => state.auth?.user || null);
  
  const [activeTab, setActiveTab] = useState('general');
  const [saveStatus, setSaveStatus] = useState({ success: false, error: false, message: '' });
  
  // General settings
  const [name, setName] = useState(currentUser?.name || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [currency, setCurrency] = useState(currentUser?.settings?.currency || 'PLN');
  const [language, setLanguage] = useState(currentUser?.settings?.language || 'pl');
  const [isDarkMode, setIsDarkMode] = useState(darkMode);
  
  // Notification settings
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [pushNotifications, setPushNotifications] = useState(true);
  const [reminderFrequency, setReminderFrequency] = useState('daily');
  const [budgetAlerts, setBudgetAlerts] = useState(true);
  const [goalUpdates, setGoalUpdates] = useState(true);
  
  // Security settings
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [privateMode, setPrivateMode] = useState(false);
  const [autoLogout, setAutoLogout] = useState('30');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    // Reset save status after 3 seconds
    if (saveStatus.success || saveStatus.error) {
      const timer = setTimeout(() => {
        setSaveStatus({ success: false, error: false, message: '' });
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const handleSaveGeneralSettings = () => {
    dispatch(updateUserSettings({
      name,
      email,
      settings: {
        currency,
        language
      }
    })).then(() => {
      setSaveStatus({
        success: true,
        error: false,
        message: 'Ustawienia zostały zapisane'
      });
    }).catch(error => {
      setSaveStatus({
        success: false,
        error: true,
        message: error.message || 'Wystąpił błąd podczas zapisywania ustawień'
      });
    });
  };

  const handleSaveNotificationSettings = () => {
    dispatch(updateNotificationSettings({
      emailNotifications,
      pushNotifications,
      reminderFrequency,
      budgetAlerts,
      goalUpdates
    })).then(() => {
      setSaveStatus({
        success: true,
        error: false,
        message: 'Ustawienia powiadomień zostały zapisane'
      });
    }).catch(error => {
      setSaveStatus({
        success: false,
        error: true,
        message: error.message || 'Wystąpił błąd podczas zapisywania ustawień powiadomień'
      });
    });
  };

  const handleSaveSecuritySettings = () => {
    // Validate passwords if attempting to change password
    if (newPassword) {
      if (newPassword !== confirmPassword) {
        setPasswordError('Hasła nie są identyczne');
        return;
      }
      
      if (newPassword.length < 8) {
        setPasswordError('Hasło musi mieć co najmniej 8 znaków');
        return;
      }
      
      if (!currentPassword) {
        setPasswordError('Aktualne hasło jest wymagane');
        return;
      }
    }
    
    setPasswordError('');
    
    dispatch(updateSecuritySettings({
      twoFactorEnabled,
      privateMode,
      autoLogout,
      passwordChange: newPassword ? {
        currentPassword,
        newPassword
      } : null
    })).then(() => {
      setSaveStatus({
        success: true,
        error: false,
        message: 'Ustawienia bezpieczeństwa zostały zapisane'
      });
      
      // Reset password fields
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }).catch(error => {
      setSaveStatus({
        success: false,
        error: true,
        message: error.message || 'Wystąpił błąd podczas zapisywania ustawień bezpieczeństwa'
      });
    });
  };

  const handleToggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    dispatch(toggleDarkMode(newMode));
  };

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Ustawienia</Title>
      </Header>

      <Card>
        <TabsContainer>
          <Tab 
            active={activeTab === 'general'} 
            onClick={() => setActiveTab('general')}
          >
            Ogólne
          </Tab>
          <Tab 
            active={activeTab === 'notifications'} 
            onClick={() => setActiveTab('notifications')}
          >
            Powiadomienia
          </Tab>
          <Tab 
            active={activeTab === 'security'} 
            onClick={() => setActiveTab('security')}
          >
            Bezpieczeństwo
          </Tab>
        </TabsContainer>

        {/* General Settings */}
        {activeTab === 'general' && (
          <div>
            <FormGroup>
              <Label htmlFor="name">Imię i nazwisko</Label>
              <Input
                id="name"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="email">Adres e-mail</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="currency">Waluta</Label>
              <Select
                id="currency"
                value={currency}
                onChange={e => setCurrency(e.target.value)}
              >
                <option value="PLN">Polski złoty (PLN)</option>
                <option value="EUR">Euro (EUR)</option>
                <option value="USD">Dolar amerykański (USD)</option>
                <option value="GBP">Funt brytyjski (GBP)</option>
              </Select>
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="language">Język</Label>
              <Select
                id="language"
                value={language}
                onChange={e => setLanguage(e.target.value)}
              >
                <option value="pl">Polski</option>
                <option value="en">Angielski</option>
                <option value="de">Niemiecki</option>
              </Select>
            </FormGroup>
            
            <Divider />
            
            <SwitchContainer>
              <div>
                <SwitchLabel>Tryb ciemny</SwitchLabel>
                <SwitchDescription>Przełącz pomiędzy jasnym i ciemnym motywem</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={isDarkMode}
                  onChange={handleToggleDarkMode}
                />
                <SwitchSlider checked={isDarkMode} />
              </SwitchButton>
            </SwitchContainer>
            
            <ButtonsContainer>
              <Button onClick={handleSaveGeneralSettings}>
                Zapisz ustawienia
              </Button>
            </ButtonsContainer>
            
            {saveStatus.success && activeTab === 'general' && (
              <SuccessText>{saveStatus.message}</SuccessText>
            )}
            {saveStatus.error && activeTab === 'general' && (
              <ErrorText>{saveStatus.message}</ErrorText>
            )}
          </div>
        )}

        {/* Notification Settings */}
        {activeTab === 'notifications' && (
          <div>
            <SwitchContainer>
              <div>
                <SwitchLabel>Powiadomienia e-mail</SwitchLabel>
                <SwitchDescription>Otrzymuj powiadomienia na swój adres e-mail</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={emailNotifications}
                  onChange={() => setEmailNotifications(!emailNotifications)}
                />
                <SwitchSlider checked={emailNotifications} />
              </SwitchButton>
            </SwitchContainer>
            
            <SwitchContainer>
              <div>
                <SwitchLabel>Powiadomienia push</SwitchLabel>
                <SwitchDescription>Otrzymuj powiadomienia w przeglądarce</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={pushNotifications}
                  onChange={() => setPushNotifications(!pushNotifications)}
                />
                <SwitchSlider checked={pushNotifications} />
              </SwitchButton>
            </SwitchContainer>
            
            <FormGroup>
              <Label htmlFor="reminderFrequency">Częstotliwość przypomnień</Label>
              <Select
                id="reminderFrequency"
                value={reminderFrequency}
                onChange={e => setReminderFrequency(e.target.value)}
              >
                <option value="never">Nigdy</option>
                <option value="daily">Codziennie</option>
                <option value="weekly">Co tydzień</option>
                <option value="monthly">Co miesiąc</option>
              </Select>
            </FormGroup>
            
            <Divider />
            
            <SectionTitle>Typy powiadomień</SectionTitle>
            
            <SwitchContainer>
              <div>
                <SwitchLabel>Alerty o przekroczeniu budżetu</SwitchLabel>
                <SwitchDescription>Powiadomienie gdy zbliżasz się do limitu budżetu</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={budgetAlerts}
                  onChange={() => setBudgetAlerts(!budgetAlerts)}
                />
                <SwitchSlider checked={budgetAlerts} />
              </SwitchButton>
            </SwitchContainer>
            
            <SwitchContainer>
              <div>
                <SwitchLabel>Aktualizacje celów</SwitchLabel>
                <SwitchDescription>Powiadomienia o postępach w realizacji celów</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={goalUpdates}
                  onChange={() => setGoalUpdates(!goalUpdates)}
                />
                <SwitchSlider checked={goalUpdates} />
              </SwitchButton>
            </SwitchContainer>
            
            <ButtonsContainer>
              <Button onClick={handleSaveNotificationSettings}>
                Zapisz ustawienia powiadomień
              </Button>
            </ButtonsContainer>
            
            {saveStatus.success && activeTab === 'notifications' && (
              <SuccessText>{saveStatus.message}</SuccessText>
            )}
            {saveStatus.error && activeTab === 'notifications' && (
              <ErrorText>{saveStatus.message}</ErrorText>
            )}
          </div>
        )}

        {/* Security Settings */}
        {activeTab === 'security' && (
          <div>
            <SwitchContainer>
              <div>
                <SwitchLabel>Weryfikacja dwuetapowa (2FA)</SwitchLabel>
                <SwitchDescription>Dodatkowa warstwa zabezpieczeń dla Twojego konta</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={twoFactorEnabled}
                  onChange={() => setTwoFactorEnabled(!twoFactorEnabled)}
                />
                <SwitchSlider checked={twoFactorEnabled} />
              </SwitchButton>
            </SwitchContainer>
            
            <SwitchContainer>
              <div>
                <SwitchLabel>Tryb prywatny</SwitchLabel>
                <SwitchDescription>Ukrywa kwoty i dane finansowe przed spojrzeniami innych osób</SwitchDescription>
              </div>
              <SwitchButton>
                <SwitchInput
                  type="checkbox"
                  checked={privateMode}
                  onChange={() => setPrivateMode(!privateMode)}
                />
                <SwitchSlider checked={privateMode} />
              </SwitchButton>
            </SwitchContainer>
            
            <FormGroup>
              <Label htmlFor="autoLogout">Automatyczne wylogowanie</Label>
              <Select
                id="autoLogout"
                value={autoLogout}
                onChange={e => setAutoLogout(e.target.value)}
              >
                <option value="never">Nigdy</option>
                <option value="15">Po 15 minutach</option>
                <option value="30">Po 30 minutach</option>
                <option value="60">Po 1 godzinie</option>
              </Select>
            </FormGroup>
            
            <Divider />
            
            <SectionTitle>Zmiana hasła</SectionTitle>
            
            <FormGroup>
              <Label htmlFor="currentPassword">Aktualne hasło</Label>
              <Input
                id="currentPassword"
                type="password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
              />
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="newPassword">Nowe hasło</Label>
              <Input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
              <LabelInfo>Minimum 8 znaków</LabelInfo>
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="confirmPassword">Potwierdź nowe hasło</Label>
              <Input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </FormGroup>
            
            {passwordError && <ErrorText>{passwordError}</ErrorText>}
            
            <ButtonsContainer>
              <Button onClick={handleSaveSecuritySettings}>
                Zapisz ustawienia bezpieczeństwa
              </Button>
            </ButtonsContainer>
            
            {saveStatus.success && activeTab === 'security' && (
              <SuccessText>{saveStatus.message}</SuccessText>
            )}
            {saveStatus.error && activeTab === 'security' && (
              <ErrorText>{saveStatus.message}</ErrorText>
            )}
          </div>
        )}
      </Card>
    </Container>
  );
};

export default Settings;