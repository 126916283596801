import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
  TRANSACTION_ERROR,
  CLEAR_TRANSACTION
} from './types';

// Get all transactions
export const getTransactions = (filters = {}) => async dispatch => {
  try {
    let queryParams = new URLSearchParams();
    
    // Add filters to query params
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        queryParams.append(key, filters[key]);
      }
    });
    
    const res = await axios.get(`/api/transactions?${queryParams.toString()}`);

    dispatch({
      type: GET_TRANSACTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get transaction by ID
export const getTransaction = id => async dispatch => {
  try {
    const res = await axios.get(`/api/transactions/${id}`);

    dispatch({
      type: GET_TRANSACTION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Add transaction
export const addTransaction = (formData) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/transactions', formData, config);

    dispatch({
      type: ADD_TRANSACTION,
      payload: res.data
    });

    dispatch(setAlert('Transakcja dodana pomyślnie', 'success'));
    
    return res.data;
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
    
    throw err;
  }
};

// Update transaction
export const updateTransaction = (id, formData) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/transactions/${id}`, formData, config);

    dispatch({
      type: UPDATE_TRANSACTION,
      payload: res.data
    });

    dispatch(setAlert('Transakcja zaktualizowana pomyślnie', 'success'));
    
    return res.data;
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
    
    throw err;
  }
};

// Delete transaction
export const deleteTransaction = id => async dispatch => {
  if (window.confirm('Czy na pewno chcesz usunąć tę transakcję?')) {
    try {
      await axios.delete(`/api/transactions/${id}`);

      dispatch({
        type: DELETE_TRANSACTION,
        payload: id
      });

      dispatch(setAlert('Transakcja usunięta pomyślnie', 'success'));
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: { msg: err.response?.statusText, status: err.response?.status }
      });
    }
  }
};

// Clear current transaction
export const clearTransaction = () => dispatch => {
  dispatch({ type: CLEAR_TRANSACTION });
};

// Duplicate transaction
export const duplicateTransaction = (transactionId) => async dispatch => {
  try {
    const res = await axios.post(`/api/transactions/${transactionId}/duplicate`);

    dispatch({
      type: ADD_TRANSACTION,
      payload: res.data
    });

    dispatch(setAlert('Transakcja zduplikowana pomyślnie', 'success'));
    return res.data;
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get transaction summary 
export const getTransactionSummary = (dateRange) => async dispatch => {
  try {
    let url = '/api/transactions/summary';
    if (dateRange) {
      url += `?start=${dateRange.start}&end=${dateRange.end}`;
    }
    
    const res = await axios.get(url);
    
    return res.data;
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get upcoming transactions
export const getUpcomingTransactions = () => async dispatch => {
  try {
    const res = await axios.get('/api/transactions/upcoming');
    
    return res.data;
  } catch (err) {
    dispatch({
      type: TRANSACTION_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};