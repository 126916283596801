// src/reducers/index.js

import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import budget from './budget';
import goal from './goal';
import profile from './profile';
import ui from './ui';
import transaction from './transaction';
import ai from './ai';
import sharing from './sharing';

export default combineReducers({
  alert,
  auth,
  budget,
  goal,
  profile,
  ui,
  transaction,
  ai,
  sharing
});