// client/src/actions/achievement.js
import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_ACHIEVEMENTS,
  CHECK_ACHIEVEMENTS,
  UNLOCK_ACHIEVEMENT,
  UPDATE_ACHIEVEMENT_PREFERENCES,
  ACHIEVEMENT_ERROR,
  SET_ACHIEVEMENT_LOADING
} from './types';

// Pobierz osiągnięcia użytkownika
export const getAchievements = () => async dispatch => {
  try {
    dispatch({ type: SET_ACHIEVEMENT_LOADING });

    const res = await axios.get('/api/achievements');

    dispatch({
      type: GET_ACHIEVEMENTS,
      payload: res.data.data
    });
  } catch (err) {
    const message = err.response && err.response.data.message
      ? err.response.data.message
      : 'Błąd podczas pobierania osiągnięć';

    dispatch(setAlert(message, 'danger'));
    dispatch({
      type: ACHIEVEMENT_ERROR,
      payload: { message }
    });
  }
};

// Sprawdź i zaktualizuj osiągnięcia
export const checkAchievements = () => async dispatch => {
  try {
    dispatch({ type: SET_ACHIEVEMENT_LOADING });

    const res = await axios.post('/api/achievements/check');

    dispatch({
      type: CHECK_ACHIEVEMENTS,
      payload: res.data.data
    });

    // Powiadomienie o nowych osiągnięciach
    if (res.data.data.unlockedAchievements.length > 0) {
      res.data.data.unlockedAchievements.forEach(achievement => {
        dispatch({
          type: UNLOCK_ACHIEVEMENT,
          payload: achievement
        });
        
        dispatch(setAlert(`Odblokowano osiągnięcie: ${achievement.description}!`, 'success'));
      });
    }

    return res.data.data;
  } catch (err) {
    const message = err.response && err.response.data.message
      ? err.response.data.message
      : 'Błąd podczas sprawdzania osiągnięć';

    dispatch(setAlert(message, 'danger'));
    dispatch({
      type: ACHIEVEMENT_ERROR,
      payload: { message }
    });
    return null;
  }
};

// Aktualizuj preferencje osiągnięć
export const updateAchievementPreferences = preferences => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put('/api/achievements/preferences', preferences, config);

    dispatch({
      type: UPDATE_ACHIEVEMENT_PREFERENCES,
      payload: res.data.data
    });

    dispatch(setAlert('Preferencje osiągnięć zaktualizowane', 'success'));
  } catch (err) {
    const message = err.response && err.response.data.message
      ? err.response.data.message
      : 'Błąd podczas aktualizacji preferencji';

    dispatch(setAlert(message, 'danger'));
    dispatch({
      type: ACHIEVEMENT_ERROR,
      payload: { message }
    });
  }
};