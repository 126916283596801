// client/src/components/dashboard/GoalsList.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaPlus, FaInfoCircle, FaBullseye, FaRegCalendarAlt } from 'react-icons/fa';
import { format, differenceInDays } from 'date-fns';
import { pl } from 'date-fns/locale';

const Container = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const AddButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: #6c5ce7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const GoalCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  padding: 1.25rem;
  margin-bottom: 1rem;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const GoalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const GoalInfo = styled.div`
  flex: 1;
`;

const GoalTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: ${props => props.color || '#6c5ce7'};
  }
`;

const GoalMeta = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const GoalAmount = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ProgressSection = styled.div`
  margin-top: 1rem;
`;

const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const CurrentAmount = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const ProgressText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ProgressBarContainer = styled.div`
  height: 8px;
  background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0.75rem;
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${props => Math.min(props.percentage, 100)}%;
  background-color: ${props => {
    if (props.onTrack) return '#27ae60';
    if (props.nearComplete) return '#f39c12';
    return '#6c5ce7';
  }};
  border-radius: 4px;
  transition: width 0.3s ease;
`;

const ActionButton = styled(Link)`
  display: block;
  text-align: center;
  width: 100%;
  padding: 0.5rem 0;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f1f1f1'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    background-color: #6c5ce7;
    color: white;
  }
`;

const NoGoalsMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  
  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: ${props => props.theme === 'dark' ? '#666' : '#ddd'};
  }
`;

const ViewAllLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 0.75rem;
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
    border-radius: 4px;
  }
`;

const GraduatedBar = styled.div`
  display: flex;
  height: 8px;
  margin-bottom: 0.75rem;
`;

const MilestoneSegment = styled.div`
  height: 100%;
  flex: ${props => props.flex};
  background-color: ${props => props.reached ? '#27ae60' : (props.theme === 'dark' ? '#444' : '#f1f1f1')};
  border-right: ${props => props.isLast ? 'none' : '2px solid white'};
  border-top-left-radius: ${props => props.isFirst ? '4px' : '0'};
  border-bottom-left-radius: ${props => props.isFirst ? '4px' : '0'};
  border-top-right-radius: ${props => props.isLast ? '4px' : '0'};
  border-bottom-right-radius: ${props => props.isLast ? '4px' : '0'};
`;

const Milestones = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 5px;
`;

const Milestone = styled.div`
  text-align: center;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 5px;
    background-color: ${props => props.theme === 'dark' ? '#666' : '#ccc'};
  }
`;

const MilestoneLabel = styled.div`
  font-size: 0.7rem;
  color: ${props => props.reached ? '#27ae60' : (props.theme === 'dark' ? '#ccc' : '#888')};
  font-weight: ${props => props.reached ? '600' : 'normal'};
`;

const GoalsList = ({ goals, limit = 3, theme }) => {
  // Funkcja formatująca kwoty
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN'
    }).format(amount);
  };

  // Funkcja obliczająca procent realizacji celu
  const calculatePercentage = (current, target) => {
    if (target === 0) return 0;
    return (current / target) * 100;
  };

  // Funkcja formatująca datę
  const formatDate = (dateString) => {
    if (!dateString) return 'Bez terminu';
    const date = new Date(dateString);
    return format(date, 'd MMMM yyyy', { locale: pl });
  };

  // Funkcja obliczająca pozostałe dni
  const getRemainingDays = (targetDate) => {
    if (!targetDate) return null;
    const today = new Date();
    const target = new Date(targetDate);
    return differenceInDays(target, today);
  };

  // Sortowanie celów (priorytetowe, niezakończone najpierw)
  const sortedGoals = [...goals]
    .filter(goal => !goal.completed)
    .sort((a, b) => {
      // Najpierw według priorytetu
      const priorityOrder = { high: 0, medium: 1, low: 2 };
      const priorityDiff = priorityOrder[a.priority] - priorityOrder[b.priority];
      if (priorityDiff !== 0) return priorityDiff;
      
      // Potem według terminów (najbliższe najpierw)
      if (a.targetDate && b.targetDate) {
        return new Date(a.targetDate) - new Date(b.targetDate);
      }
      // Cele z terminem przed celami bez terminu
      if (a.targetDate) return -1;
      if (b.targetDate) return 1;
      
      // Na koniec według procentu realizacji (od największego)
      const percentA = calculatePercentage(a.currentAmount, a.targetAmount);
      const percentB = calculatePercentage(b.currentAmount, b.targetAmount);
      return percentB - percentA;
    })
    .slice(0, limit);

  const defaultMilestones = [25, 50, 75, 100];

  return (
    <Container theme={theme}>
      <Header>
        <Title theme={theme}>Cele oszczędnościowe</Title>
        <AddButton to="/dashboard/goals/add">
          <FaPlus />
          Dodaj cel
        </AddButton>
      </Header>

      {sortedGoals.length > 0 ? (
        <>
          {sortedGoals.map((goal) => {
            const percentage = calculatePercentage(goal.currentAmount, goal.targetAmount);
            const remainingDays = getRemainingDays(goal.targetDate);
            const isOnTrack = goal.currentAmount > 0 && remainingDays !== null ? 
              (goal.targetAmount - goal.currentAmount) / remainingDays <= (goal.targetAmount / getRemainingDays(new Date(goal.startDate), goal.targetDate)) : 
              true;
            const nearComplete = percentage >= 90;

            // Używamy milestones z celu lub domyślnych
            const milestones = goal.milestones?.length > 0 
              ? goal.milestones.map(m => ({ percentage: m.percentage, reached: m.reached }))
              : defaultMilestones.map(p => ({ 
                  percentage: p, 
                  reached: percentage >= p 
                }));

            return (
              <GoalCard key={goal._id} theme={theme}>
                <GoalHeader>
                  <GoalInfo>
                    <GoalTitle theme={theme} color={goal.color}>
                      <FaBullseye />
                      {goal.name}
                    </GoalTitle>
                    {goal.targetDate && (
                      <GoalMeta theme={theme}>
                        <FaRegCalendarAlt />
                        {remainingDays > 0 ? (
                          `Pozostało ${remainingDays} dni (do ${formatDate(goal.targetDate)})`
                        ) : (
                          `Termin minął: ${formatDate(goal.targetDate)}`
                        )}
                      </GoalMeta>
                    )}
                  </GoalInfo>
                  <GoalAmount theme={theme}>{formatCurrency(goal.targetAmount)}</GoalAmount>
                </GoalHeader>

                <ProgressSection>
                  <ProgressDetails>
                    <CurrentAmount theme={theme}>
                      Zaoszczędzono: {formatCurrency(goal.currentAmount)}
                    </CurrentAmount>
                    <ProgressText theme={theme}>
                      {percentage.toFixed(0)}%
                    </ProgressText>
                  </ProgressDetails>

                  {goal.milestones?.length > 0 ? (
                    <>
                      <GraduatedBar>
                        {milestones.map((milestone, index) => {
                          const prevPercentage = index === 0 ? 0 : milestones[index - 1].percentage;
                          const segmentSize = milestone.percentage - prevPercentage;
                          
                          return (
                            <MilestoneSegment
                              key={index}
                              flex={segmentSize}
                              reached={milestone.reached}
                              theme={theme}
                              isFirst={index === 0}
                              isLast={index === milestones.length - 1}
                            />
                          );
                        })}
                      </GraduatedBar>
                      <Milestones>
                        {milestones.map((milestone, index) => (
                          <Milestone key={index} theme={theme}>
                            <MilestoneLabel reached={milestone.reached} theme={theme}>
                              {milestone.percentage}%
                            </MilestoneLabel>
                          </Milestone>
                        ))}
                      </Milestones>
                    </>
                  ) : (
                    <ProgressBarContainer theme={theme}>
                      <ProgressBar 
                        percentage={percentage} 
                        onTrack={isOnTrack}
                        nearComplete={nearComplete}
                      />
                    </ProgressBarContainer>
                  )}

                  <ActionButton to={`/dashboard/goals/${goal._id}`} theme={theme}>
                    Wpłać na cel
                  </ActionButton>
                </ProgressSection>
              </GoalCard>
            );
          })}

          {goals.length > limit && (
            <ViewAllLink to="/dashboard/goals">
              Zobacz wszystkie cele ({goals.length})
            </ViewAllLink>
          )}
        </>
      ) : (
        <NoGoalsMessage theme={theme}>
          <FaInfoCircle />
          <p>Nie masz jeszcze żadnych celów oszczędnościowych. Zacznij oszczędzać na coś konkretnego!</p>
          <AddButton to="/dashboard/goals/add" style={{ display: 'inline-flex', margin: '0 auto' }}>
            <FaPlus />
            Dodaj pierwszy cel
          </AddButton>
        </NoGoalsMessage>
      )}
    </Container>
  );
};

GoalsList.propTypes = {
  goals: PropTypes.array,
  limit: PropTypes.number,
  theme: PropTypes.string.isRequired
};

GoalsList.defaultProps = {
  goals: [],
  limit: 3
};

export default GoalsList;