// src/reducers/ai.js

import {
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_FAILURE,
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILURE,
  DELETE_CONVERSATION_REQUEST,
  DELETE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  GET_AI_PREFERENCES_REQUEST,
  GET_AI_PREFERENCES_SUCCESS,
  GET_AI_PREFERENCES_FAILURE,
  UPDATE_AI_PREFERENCES_REQUEST,
  UPDATE_AI_PREFERENCES_SUCCESS,
  UPDATE_AI_PREFERENCES_FAILURE
} from '../types';

const initialState = {
  conversations: [],
  currentConversation: null,
  preferences: null,
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONVERSATIONS_REQUEST:
    case GET_CONVERSATION_REQUEST:
    case CREATE_CONVERSATION_REQUEST:
    case DELETE_CONVERSATION_REQUEST:
    case SEND_MESSAGE_REQUEST:
    case GET_AI_PREFERENCES_REQUEST:
    case UPDATE_AI_PREFERENCES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversations: payload,
        loading: false
      };

    case GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        currentConversation: payload,
        loading: false
      };

    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: [payload, ...state.conversations],
        currentConversation: payload,
        loading: false
      };

    case DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.filter(
          conversation => conversation._id !== payload
        ),
        currentConversation: state.currentConversation && 
                              state.currentConversation._id === payload ? 
                              null : state.currentConversation,
        loading: false
      };

    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          messages: [
            ...(state.currentConversation ? state.currentConversation.messages : []),
            payload
          ]
        },
        loading: false
      };

    case GET_AI_PREFERENCES_SUCCESS:
    case UPDATE_AI_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: payload,
        loading: false
      };

    case GET_CONVERSATIONS_FAILURE:
    case GET_CONVERSATION_FAILURE:
    case CREATE_CONVERSATION_FAILURE:
    case DELETE_CONVERSATION_FAILURE:
    case SEND_MESSAGE_FAILURE:
    case GET_AI_PREFERENCES_FAILURE:
    case UPDATE_AI_PREFERENCES_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false
      };

    default:
      return state;
  }
}

// Action creators exported for use
export const getConversations = () => ({ type: GET_CONVERSATIONS_REQUEST });
export const getConversation = (id) => ({ type: GET_CONVERSATION_REQUEST, payload: id });
export const createConversation = (title) => ({ type: CREATE_CONVERSATION_REQUEST, payload: title });
export const deleteConversation = (id) => ({ type: DELETE_CONVERSATION_REQUEST, payload: id });
export const sendMessage = (conversationId, message) => ({ 
  type: SEND_MESSAGE_REQUEST, 
  payload: { conversationId, message } 
});
export const getAIPreferences = () => ({ type: GET_AI_PREFERENCES_REQUEST });
export const updateAIPreferences = (preferences) => ({ 
  type: UPDATE_AI_PREFERENCES_REQUEST, 
  payload: preferences 
});