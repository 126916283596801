// client/src/actions/group.js
import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_GROUPS,
  GET_GROUP,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GROUP_ERROR,
  CLEAR_GROUP,
  ADD_MEMBER,
  REMOVE_MEMBER,
  UPDATE_MEMBER,
  GENERATE_INVITE,
  JOIN_GROUP,
  GET_SHARED_TRANSACTIONS,
  GET_SHARED_TRANSACTION,
  ADD_SHARED_TRANSACTION,
  UPDATE_SHARED_TRANSACTION,
  DELETE_SHARED_TRANSACTION,
  APPROVE_TRANSACTION,
  SHARED_TRANSACTION_ERROR,
  GET_SETTLEMENTS,
  GENERATE_SETTLEMENTS,
  CONFIRM_SETTLEMENT,
  REJECT_SETTLEMENT,
  CANCEL_SETTLEMENT,
  SETTLEMENT_ERROR
} from './types';

// Pobierz wszystkie grupy użytkownika
export const getGroups = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/groups');

    dispatch({
      type: GET_GROUPS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Pobierz pojedynczą grupę
export const getGroup = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_GROUP });
    
    const res = await axios.get(`/api/groups/${id}`);

    dispatch({
      type: GET_GROUP,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Utwórz nową grupę
export const createGroup = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/groups', formData, config);

    dispatch({
      type: CREATE_GROUP,
      payload: res.data.data
    });

    dispatch(setAlert('Grupa została utworzona', 'success'));
    
    navigate(`/dashboard/groups/${res.data.data._id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else if (err.response.data.message) {
      dispatch(setAlert(err.response.data.message, 'danger'));
    }

    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Aktualizuj grupę
export const updateGroup = (id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/groups/${id}`, formData, config);

    dispatch({
      type: UPDATE_GROUP,
      payload: res.data.data
    });

    dispatch(setAlert('Grupa została zaktualizowana', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else if (err.response.data.message) {
      dispatch(setAlert(err.response.data.message, 'danger'));
    }

    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Usuń grupę
export const deleteGroup = (id, navigate) => async (dispatch) => {
  if (window.confirm('Czy na pewno chcesz usunąć tę grupę? Ta operacja jest nieodwracalna.')) {
    try {
      await axios.delete(`/api/groups/${id}`);

      dispatch({
        type: DELETE_GROUP,
        payload: id
      });

      dispatch(setAlert('Grupa została usunięta', 'success'));
      
      navigate('/dashboard/groups');
    } catch (err) {
      dispatch({
        type: GROUP_ERROR,
        payload: { 
          msg: err.response.data.message, 
          status: err.response.status 
        }
      });
    }
  }
};

// Dodaj członka do grupy
export const addMember = (groupId, email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(`/api/groups/${groupId}/members`, { email }, config);

    dispatch({
      type: ADD_MEMBER,
      payload: res.data.data
    });

    dispatch(setAlert('Użytkownik został dodany do grupy', 'success'));
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Usuń członka grupy
export const removeMember = (groupId, userId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/groups/${groupId}/members/${userId}`);

    dispatch({
      type: REMOVE_MEMBER,
      payload: res.data.data
    });

    dispatch(setAlert('Użytkownik został usunięty z grupy', 'success'));
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Aktualizuj rolę członka
export const updateMemberRole = (groupId, userId, role) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(
      `/api/groups/${groupId}/members/${userId}`, 
      { role }, 
      config
    );

    dispatch({
      type: UPDATE_MEMBER,
      payload: res.data.data
    });

    dispatch(setAlert('Rola użytkownika została zaktualizowana', 'success'));
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Wygeneruj kod zaproszenia
export const generateInviteCode = (groupId) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/groups/${groupId}/invite`);

    dispatch({
      type: GENERATE_INVITE,
      payload: res.data.data
    });

    return res.data.data;
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    return null;
  }
};

// Dołącz do grupy przez kod zaproszenia
export const joinGroup = (inviteCode, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/groups/join', { inviteCode }, config);

    dispatch({
      type: JOIN_GROUP,
      payload: res.data.data
    });

    dispatch(setAlert('Dołączyłeś do grupy', 'success'));
    
    navigate(`/dashboard/groups/${res.data.data._id}`);
    return true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, 'danger'));
    
    dispatch({
      type: GROUP_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    return false;
  }
};

// Pobierz transakcje grupowe
export const getGroupTransactions = (groupId, params = {}) => async (dispatch) => {
  try {
    // Zbuduj query string
    const queryString = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
    
    const url = `/api/groups/${groupId}/transactions${queryString ? `?${queryString}` : ''}`;
    
    const res = await axios.get(url);

    dispatch({
      type: GET_SHARED_TRANSACTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SHARED_TRANSACTION_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Pobierz pojedynczą transakcję grupową
export const getGroupTransaction = (groupId, transactionId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/groups/${groupId}/transactions/${transactionId}`);

    dispatch({
      type: GET_SHARED_TRANSACTION,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: SHARED_TRANSACTION_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
  }
};

// Dodaj transakcję grupową
export const addGroupTransaction = (groupId, formData, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(`/api/groups/${groupId}/transactions`, formData, config);

    dispatch({
      type: ADD_SHARED_TRANSACTION,
      payload: res.data.data
    });

    dispatch(setAlert('Transakcja grupowa została dodana', 'success'));
    
    if (navigate) {
      navigate(`/dashboard/groups/${groupId}`);
    }
    
    return res.data.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else if (err.response.data.message) {
      dispatch(setAlert(err.response.data.message, 'danger'));
    }

    dispatch({
      type: SHARED_TRANSACTION_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Aktualizuj transakcję grupową
export const updateGroupTransaction = (groupId, transactionId, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(
      `/api/groups/${groupId}/transactions/${transactionId}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_SHARED_TRANSACTION,
      payload: res.data.data
    });

    dispatch(setAlert('Transakcja grupowa została zaktualizowana', 'success'));
    
    return res.data.data;
  } catch (err) {
    dispatch({
      type: SHARED_TRANSACTION_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Usuń transakcję grupową
export const deleteGroupTransaction = (groupId, transactionId) => async (dispatch) => {
  if (window.confirm('Czy na pewno chcesz usunąć tę transakcję? Ta operacja jest nieodwracalna.')) {
    try {
      await axios.delete(`/api/groups/${groupId}/transactions/${transactionId}`);

      dispatch({
        type: DELETE_SHARED_TRANSACTION,
        payload: transactionId
      });

      dispatch(setAlert('Transakcja grupowa została usunięta', 'success'));
    } catch (err) {
      dispatch({
        type: SHARED_TRANSACTION_ERROR,
        payload: { 
          msg: err.response.data.message, 
          status: err.response.status 
        }
      });
    }
  }
};

// Zatwierdź transakcję grupową
export const approveTransaction = (groupId, transactionId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/groups/${groupId}/transactions/${transactionId}/approve`);

    dispatch({
      type: APPROVE_TRANSACTION,
      payload: res.data.data
    });

    dispatch(setAlert('Transakcja została zatwierdzona', 'success'));
    
    return res.data.data;
  } catch (err) {
    dispatch({
      type: SHARED_TRANSACTION_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Pobierz rozliczenia grupowe
export const getSettlements = (groupId, params = {}) => async (dispatch) => {
  try {
    // Zbuduj query string
    const queryString = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
    
    const url = `/api/groups/${groupId}/settlements${queryString ? `?${queryString}` : ''}`;
    
    const res = await axios.get(url);

    dispatch({
      type: GET_SETTLEMENTS,
      payload: res.data
    });
    
    return res.data;
  } catch (err) {
    dispatch({
      type: SETTLEMENT_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Wygeneruj rozliczenia dla grupy
export const generateSettlements = (groupId) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/groups/${groupId}/settlements/generate`);

    dispatch({
      type: GENERATE_SETTLEMENTS,
      payload: res.data.data
    });

    dispatch(setAlert('Rozliczenia zostały wygenerowane', 'success'));
    
    return res.data.data;
  } catch (err) {
    dispatch({
      type: SETTLEMENT_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Potwierdź rozliczenie
export const confirmSettlement = (groupId, settlementId, notes = '') => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(
      `/api/groups/${groupId}/settlements/${settlementId}/confirm`,
      { notes },
      config
    );

    dispatch({
      type: CONFIRM_SETTLEMENT,
      payload: res.data.data
    });

    dispatch(setAlert('Rozliczenie zostało potwierdzone', 'success'));
    
    return res.data.data;
  } catch (err) {
    dispatch({
      type: SETTLEMENT_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Odrzuć rozliczenie
export const rejectSettlement = (groupId, settlementId, reason = '') => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(
      `/api/groups/${groupId}/settlements/${settlementId}/reject`,
      { reason },
      config
    );

    dispatch({
      type: REJECT_SETTLEMENT,
      payload: res.data.data
    });

    dispatch(setAlert('Rozliczenie zostało odrzucone', 'success'));
    
    return res.data.data;
  } catch (err) {
    dispatch({
      type: SETTLEMENT_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};

// Anuluj rozliczenie
export const cancelSettlement = (groupId, settlementId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/groups/${groupId}/settlements/${settlementId}/cancel`);

    dispatch({
      type: CANCEL_SETTLEMENT,
      payload: res.data.data
    });

    dispatch(setAlert('Rozliczenie zostało anulowane', 'success'));
    
    return res.data.data;
  } catch (err) {
    dispatch({
      type: SETTLEMENT_ERROR,
      payload: { 
        msg: err.response.data.message, 
        status: err.response.status 
      }
    });
    
    return null;
  }
};