// client/src/components/dashboard/CategoryPieChart.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Rejestracja komponentów Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const ChartContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ChartTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ChartContent = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDataMessage = styled.div`
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  text-align: center;
  padding: 2rem;
`;

const CategoryPieChart = ({ categories = [], theme }) => {
  // Kolory do wykresu kołowego (niezależne od motywu)
  const chartColors = [
    '#FF6384', // różowy
    '#36A2EB', // niebieski
    '#FFCE56', // żółty
    '#4BC0C0', // turkusowy
    '#9966FF', // fioletowy
    '#FF9F40', // pomarańczowy
    '#7ED321', // zielony
    '#F5A623', // bursztynowy
    '#50E3C2', // miętowy
    '#B8E986', // limonkowy
    '#BD10E0', // magenta
    '#4A90E2'  // jasnoniebieski
  ];

  // Filtrowanie kategorii bez wydatków
  const filteredCategories = categories.filter(cat => cat.total > 0);

  // Jeśli nie ma danych, wyświetl komunikat
  if (filteredCategories.length === 0) {
    return (
      <ChartContainer theme={theme}>
        <ChartHeader>
          <ChartTitle theme={theme}>Struktura wydatków</ChartTitle>
        </ChartHeader>
        <NoDataMessage theme={theme}>
          Brak danych do wyświetlenia. Dodaj transakcje, aby zobaczyć strukturę wydatków.
        </NoDataMessage>
      </ChartContainer>
    );
  }

  // Sortowanie kategorii według kwoty (od największej)
  const sortedCategories = [...filteredCategories].sort((a, b) => b.total - a.total);

  // Kolorowanie kategorii bazując na zdefiniowanych kolorach lub na własnych kolorach kategorii
  const categoryColors = sortedCategories.map((cat, index) => 
    cat.color || chartColors[index % chartColors.length]
  );

  // Przygotowanie danych do wykresu
  const chartData = {
    labels: sortedCategories.map(cat => cat.category),
    datasets: [
      {
        data: sortedCategories.map(cat => cat.total),
        backgroundColor: categoryColors,
        borderColor: theme === 'dark' ? '#2d3436' : 'white',
        borderWidth: 2,
        hoverBackgroundColor: categoryColors.map(color => {
          // Rozjaśnij kolor na hover
          const r = parseInt(color.slice(1, 3), 16);
          const g = parseInt(color.slice(3, 5), 16);
          const b = parseInt(color.slice(5, 7), 16);
          return `rgba(${r}, ${g}, ${b}, 0.8)`;
        })
      }
    ]
  };

  // Opcje konfiguracyjne wykresu
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          color: theme === 'dark' ? 'white' : '#666',
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 11
          },
          // Dostosuj, aby pokazać tylko najważniejsze kategorie, jeśli jest ich wiele
          filter: (legendItem, data) => {
            const dataset = data.datasets[0];
            const total = dataset.data.reduce((sum, val) => sum + val, 0);
            const value = dataset.data[legendItem.index];
            const percentage = ((value / total) * 100).toFixed(1);
            
            // Dodaj wartość procentową do etykiety
            legendItem.text = `${legendItem.text} (${percentage}%)`;
            
            // Pokaż tylko kategorie powyżej 5% całości, jeśli jest ich więcej niż 7
            if (dataset.data.length > 7) {
              return (value / total) * 100 >= 5;
            }
            return true;
          }
        }
      },
      tooltip: {
        backgroundColor: theme === 'dark' ? '#2d3436' : 'white',
        titleColor: theme === 'dark' ? 'white' : '#333',
        bodyColor: theme === 'dark' ? '#ccc' : '#666',
        borderColor: theme === 'dark' ? '#444' : '#ddd',
        borderWidth: 1,
        padding: 12,
        boxPadding: 6,
        callbacks: {
          label: function(context) {
            const value = context.raw;
            const total = context.dataset.data.reduce((sum, val) => sum + val, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${new Intl.NumberFormat('pl-PL', {
              style: 'currency',
              currency: 'PLN'
            }).format(value)} (${percentage}%)`;
          }
        }
      }
    }
  };

  return (
    <ChartContainer theme={theme}>
      <ChartHeader>
        <ChartTitle theme={theme}>Struktura wydatków</ChartTitle>
      </ChartHeader>
      <ChartContent>
        <Pie data={chartData} options={chartOptions} />
      </ChartContent>
    </ChartContainer>
  );
};

CategoryPieChart.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      color: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  theme: PropTypes.string.isRequired
};

export default CategoryPieChart;