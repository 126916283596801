// client/src/actions/goal.js
import api from '../utils/api';
import { setAlert } from './alert';
import {
  GET_GOALS,
  GET_GOAL,
  GOAL_ERROR,
  ADD_GOAL,
  DELETE_GOAL,
  UPDATE_GOAL,
  CONTRIBUTE_TO_GOAL,
  CLEAR_GOAL
} from './types';

// Get all goals
export const getGoals = () => async dispatch => {
  try {
    const res = await api.get('/goals');

    dispatch({
      type: GET_GOALS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GOAL_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get goal by ID
export const getGoal = id => async dispatch => {
  try {
    const res = await api.get(`/goals/${id}`);

    dispatch({
      type: GET_GOAL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GOAL_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Create goal
export const createGoal = formData => async dispatch => {
  try {
    const res = await api.post('/goals', formData);

    dispatch({
      type: ADD_GOAL,
      payload: res.data
    });

    dispatch(setAlert('Cel utworzony pomyślnie', 'success'));
    
    return res.data;
  } catch (err) {
    const errors = err.response?.data?.errors;
    const errorMessage = err.response?.data?.error;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    } else if (errorMessage) {
      dispatch(setAlert(errorMessage, 'danger'));
    } else {
      dispatch(setAlert('Wystąpił błąd podczas tworzenia celu', 'danger'));
    }

    dispatch({
      type: GOAL_ERROR,
      payload: { 
        msg: err.response?.statusText || 'Server Error', 
        status: err.response?.status || 500 
      }
    });
    
    throw err;
  }
};

// Update goal
export const updateGoal = (id, formData) => async dispatch => {
  try {
    const res = await api.put(`/goals/${id}`, formData);

    dispatch({
      type: UPDATE_GOAL,
      payload: res.data
    });

    dispatch(setAlert('Cel zaktualizowany pomyślnie', 'success'));
    
    return res.data;
  } catch (err) {
    const errors = err.response?.data?.errors;
    const errorMessage = err.response?.data?.error;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    } else if (errorMessage) {
      dispatch(setAlert(errorMessage, 'danger'));
    } else {
      dispatch(setAlert('Wystąpił błąd podczas aktualizacji celu', 'danger'));
    }

    dispatch({
      type: GOAL_ERROR,
      payload: { 
        msg: err.response?.statusText || 'Server Error', 
        status: err.response?.status || 500 
      }
    });
    
    throw err;
  }
};

// Contribute to goal
export const contributeToGoal = (id, amount) => async dispatch => {
  try {
    const res = await api.post(`/goals/${id}/deposit`, { amount });

    dispatch({
      type: CONTRIBUTE_TO_GOAL,
      payload: res.data
    });

    dispatch(setAlert('Wpłata dodana pomyślnie', 'success'));
    
    return res.data;
  } catch (err) {
    const errors = err.response?.data?.errors;
    const errorMessage = err.response?.data?.error;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    } else if (errorMessage) {
      dispatch(setAlert(errorMessage, 'danger'));
    } else {
      dispatch(setAlert('Wystąpił błąd podczas dodawania wpłaty', 'danger'));
    }

    dispatch({
      type: GOAL_ERROR,
      payload: { 
        msg: err.response?.statusText || 'Server Error', 
        status: err.response?.status || 500 
      }
    });
    
    throw err;
  }
};

// Delete goal
export const deleteGoal = id => async dispatch => {
  if (window.confirm('Czy na pewno chcesz usunąć ten cel?')) {
    try {
      await api.delete(`/goals/${id}`);

      dispatch({
        type: DELETE_GOAL,
        payload: id
      });

      dispatch(setAlert('Cel usunięty pomyślnie', 'success'));
    } catch (err) {
      const errors = err.response?.data?.errors;
      const errorMessage = err.response?.data?.error;

      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      } else if (errorMessage) {
        dispatch(setAlert(errorMessage, 'danger'));
      } else {
        dispatch(setAlert('Wystąpił błąd podczas usuwania celu', 'danger'));
      }
      
      dispatch({
        type: GOAL_ERROR,
        payload: { 
          msg: err.response?.statusText || 'Server Error', 
          status: err.response?.status || 500 
        }
      });
    }
  }
};

// Clear current goal
export const clearGoal = () => dispatch => {
  dispatch({ type: CLEAR_GOAL });
};