import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { forgotPassword } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const ForgotPassword = ({ forgotPassword, setAlert }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setAlert('Proszę podać adres email', 'danger');
      return;
    }
    
    await forgotPassword(email);
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Container>
        <Card>
          <h1>Sprawdź swój email</h1>
          <p>
            Jeśli konto z podanym adresem email istnieje, wysłaliśmy link do resetowania hasła.
            Sprawdź swoją skrzynkę odbiorczą.
          </p>
          <Link to="/login">Wróć do logowania</Link>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <h1>Resetowanie hasła</h1>
        <p>Wprowadź swój adres email, aby otrzymać link do zresetowania hasła</p>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <label htmlFor="email">Adres email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onChange}
              required
            />
          </FormGroup>
          <ButtonGroup>
            <Button type="submit">Wyślij link resetujący</Button>
            <Link to="/login">Wróć do logowania</Link>
          </ButtonGroup>
        </Form>
      </Card>
    </Container>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
`;

const Card = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 480px;
  text-align: center;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin-bottom: 1rem;
  }

  p {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
    margin-bottom: 2rem;
  }
`;

const Form = styled.form`
  text-align: left;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
    border-radius: 4px;
    background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    text-align: center;
    color: ${props => props.theme === 'dark' ? '#4a90e2' : '#3a70b2'};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Button = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }
`;

export default connect(null, { forgotPassword, setAlert })(ForgotPassword);