import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCamera, FaFileUpload, FaCheckCircle, FaPen, FaSave } from 'react-icons/fa';
import { scanReceipt, clearScanData } from '../../actions/receipt';
import { getCategories } from '../../actions/category';
import { addTransaction } from '../../actions/transaction';
import { setAlert } from '../../actions/alert';
import Spinner from '../../components/ui/Spinner';
import Modal from '../../components/ui/Modal';

const ReceiptScanner = ({
  scanReceipt,
  clearScanData,
  getCategories,
  addTransaction,
  setAlert,
  receipt: { scanData, loading },
  category: { categories, loading: categoriesLoading },
  ui: { theme }
}) => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [scanSuccess, setScanSuccess] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [transactionData, setTransactionData] = useState({
    name: '',
    amount: '',
    date: new Date().toISOString().split('T')[0],
    type: 'expense',
    category: '',
    description: ''
  });
  
  // Efekt czyszczący dane skanu przy zamknięciu/otwarciu komponentu
  useEffect(() => {
    return () => {
      clearScanData();
    };
  }, [clearScanData]);
  
  // Efekt pobierający kategorie
  useEffect(() => {
    getCategories();
  }, [getCategories]);
  
  // Efekt aktualizujący dane transakcji po otrzymaniu wyniku skanowania
  useEffect(() => {
    if (scanData && !loading) {
      const updatedData = { ...transactionData };
      
      if (scanData.amount) {
        updatedData.amount = parseFloat(scanData.amount);
      }
      
      if (scanData.date) {
        try {
          const scanDate = new Date(scanData.date);
          updatedData.date = scanDate.toISOString().split('T')[0];
        } catch (err) {
          console.error('Error parsing date:', err);
        }
      }
      
      if (scanData.merchant) {
        updatedData.name = scanData.merchant;
      }
      
      if (scanData.category && categories.length > 0) {
        // Próba dopasowania kategorii na podstawie rozpozanej nazwy kategorii
        const matchedCategory = categories.find(
          cat => cat.type === 'expense' && 
          cat.name.toLowerCase().includes(scanData.category.toLowerCase())
        );
        
        if (matchedCategory) {
          updatedData.category = matchedCategory._id;
        }
      }
      
      if (scanData.items && scanData.items.length > 0) {
        updatedData.description = `Zawartość paragonu: ${scanData.items.map(item => 
          `${item.name} (${item.quantity} x ${item.price}zł)`
        ).join(', ')}`;
      }
      
      setTransactionData(updatedData);
      setScanSuccess(true);
    }
  }, [scanData, loading, categories, transactionData]);
  
  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      setFile(selectedFile);
      
      // Tworzenie podglądu zdjęcia
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      
      setScanSuccess(false);
    }
  };
  
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  
  const handleScan = async () => {
    if (!file) {
      setAlert('Proszę wybrać zdjęcie paragonu', 'danger');
      return;
    }
    
    try {
      // Przekazanie pliku do akcji skanowania
      const formData = new FormData();
      formData.append('receipt', file);
      
      await scanReceipt(formData);
    } catch (err) {
      setAlert('Błąd skanowania paragonu', 'danger');
    }
  };
  
  const handleChange = (e) => {
    setTransactionData({
      ...transactionData,
      [e.target.name]: e.target.value
    });
  };
  
  const handleSubmit = () => {
    // Walidacja
    if (!transactionData.name || !transactionData.amount || !transactionData.date || !transactionData.category) {
      setAlert('Proszę wypełnić wszystkie wymagane pola', 'danger');
      return;
    }
    
    setShowConfirmModal(true);
  };
  
  const confirmAddTransaction = async () => {
    try {
      // Tworzenie obiektu transakcji z załącznikiem
      const formData = new FormData();
      Object.keys(transactionData).forEach(key => {
        formData.append(key, transactionData[key]);
      });
      
      // Dodanie zdjęcia paragonu
      if (file) {
        formData.append('receipt', file);
      }
      
      await addTransaction(formData);
      setAlert('Transakcja została dodana', 'success');
      navigate('/transactions');
    } catch (err) {
      setAlert('Błąd dodawania transakcji', 'danger');
    } finally {
      setShowConfirmModal(false);
    }
  };
  
  const resetForm = () => {
    setFile(null);
    setPreviewUrl(null);
    setScanSuccess(false);
    clearScanData();
    setTransactionData({
      name: '',
      amount: '',
      date: new Date().toISOString().split('T')[0],
      type: 'expense',
      category: '',
      description: ''
    });
  };
  
  return (
    <Container>
      <Header>
        <h1>Skanowanie paragonu</h1>
      </Header>
      
      <ContentWrapper>
        <ScanSection>
          <SectionTitle>Zdjęcie paragonu</SectionTitle>
          
          <ScannerBox onClick={triggerFileInput}>
            {previewUrl ? (
              <ReceiptPreview src={previewUrl} alt="Podgląd paragonu" />
            ) : (
              <PlaceholderContent>
                <FaCamera size={48} />
                <p>Kliknij, aby dodać zdjęcie paragonu</p>
              </PlaceholderContent>
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
          </ScannerBox>
          
          <ButtonsContainer>
            <ButtonUpload onClick={triggerFileInput}>
              <FaFileUpload /> Wybierz zdjęcie
            </ButtonUpload>
            
            <ButtonScan onClick={handleScan} disabled={!file || loading}>
              <FaCamera /> {loading ? 'Skanowanie...' : 'Skanuj paragon'}
            </ButtonScan>
          </ButtonsContainer>
          
          {loading && (
            <SpinnerWrapper>
              <Spinner />
              <p>Analizowanie paragonu, proszę czekać...</p>
            </SpinnerWrapper>
          )}
          
          {scanSuccess && (
            <ScanResultBox>
              <ScanSuccessHeader>
                <FaCheckCircle />
                <span>Skanowanie zakończone pomyślnie!</span>
              </ScanSuccessHeader>
              
              {scanData && (
                <ScanDataList>
                  {scanData.merchant && (
                    <ScanDataItem>
                      <ScanDataLabel>Sprzedawca:</ScanDataLabel>
                      <ScanDataValue>{scanData.merchant}</ScanDataValue>
                    </ScanDataItem>
                  )}
                  
                  {scanData.amount && (
                    <ScanDataItem>
                      <ScanDataLabel>Kwota:</ScanDataLabel>
                      <ScanDataValue>{scanData.amount} zł</ScanDataValue>
                    </ScanDataItem>
                  )}
                  
                  {scanData.date && (
                    <ScanDataItem>
                      <ScanDataLabel>Data:</ScanDataLabel>
                      <ScanDataValue>
                        {new Date(scanData.date).toLocaleDateString('pl-PL')}
                      </ScanDataValue>
                    </ScanDataItem>
                  )}
                  
                  {scanData.items && scanData.items.length > 0 && (
                    <ScanDataItem>
                      <ScanDataLabel>Elementy ({scanData.items.length}):</ScanDataLabel>
                      <ScanItemsList>
                        {scanData.items.map((item, index) => (
                          <ScanItemRow key={index}>
                            <span>{item.name}</span>
                            <span>{item.quantity} x {item.price} zł</span>
                          </ScanItemRow>
                        ))}
                      </ScanItemsList>
                    </ScanDataItem>
                  )}
                </ScanDataList>
              )}
            </ScanResultBox>
          )}
        </ScanSection>
        
        <FormSection>
          <SectionTitle>
            <span>Dane transakcji</span>
            <EditHint>
              <FaPen size={14} />
              <span>Możesz edytować dane przed zapisaniem</span>
            </EditHint>
          </SectionTitle>
          
          <FormContainer>
            <FormGroup>
              <Label htmlFor="name">Nazwa*</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={transactionData.name}
                onChange={handleChange}
                placeholder="Np. Zakupy w Biedronce"
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="amount">Kwota*</Label>
              <Input
                type="number"
                id="amount"
                name="amount"
                value={transactionData.amount}
                onChange={handleChange}
                placeholder="0.00"
                step="0.01"
                min="0.01"
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="date">Data*</Label>
              <Input
                type="date"
                id="date"
                name="date"
                value={transactionData.date}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="category">Kategoria*</Label>
              <Select
                id="category"
                name="category"
                value={transactionData.category}
                onChange={handleChange}
                required
              >
                <option value="">Wybierz kategorię</option>
                {categories
                  .filter(cat => cat.type === 'expense' || cat.type === 'both')
                  .map(category => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
              </Select>
            </FormGroup>
            
            <FormGroup>
              <Label htmlFor="description">Opis</Label>
              <Textarea
                id="description"
                name="description"
                value={transactionData.description}
                onChange={handleChange}
                placeholder="Dodatkowe informacje"
                rows="4"
              />
            </FormGroup>
            
            <ButtonsGroup>
              <ButtonSubmit onClick={handleSubmit}>
                <FaSave /> Zapisz transakcję
              </ButtonSubmit>
              <ButtonReset onClick={resetForm}>
                Resetuj
              </ButtonReset>
            </ButtonsGroup>
          </FormContainer>
        </FormSection>
      </ContentWrapper>
      
      {/* Modal potwierdzenia */}
      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Potwierdzenie dodania transakcji"
      >
        <ConfirmContent>
          <p>Czy na pewno chcesz dodać tę transakcję?</p>
          
          <ConfirmDetails>
            <ConfirmItem>
              <ConfirmLabel>Nazwa:</ConfirmLabel>
              <ConfirmValue>{transactionData.name}</ConfirmValue>
            </ConfirmItem>
            
            <ConfirmItem>
              <ConfirmLabel>Kwota:</ConfirmLabel>
              <ConfirmValue>{transactionData.amount} zł</ConfirmValue>
            </ConfirmItem>
            
            <ConfirmItem>
              <ConfirmLabel>Data:</ConfirmLabel>
              <ConfirmValue>
                {new Date(transactionData.date).toLocaleDateString('pl-PL')}
              </ConfirmValue>
            </ConfirmItem>
            
            <ConfirmItem>
              <ConfirmLabel>Kategoria:</ConfirmLabel>
              <ConfirmValue>
                {categories.find(c => c._id === transactionData.category)?.name || 'Brak kategorii'}
              </ConfirmValue>
            </ConfirmItem>
          </ConfirmDetails>
          
          <ConfirmButtons>
            <ButtonPrimary onClick={confirmAddTransaction}>
              Tak, dodaj transakcję
            </ButtonPrimary>
            <ButtonSecondary onClick={() => setShowConfirmModal(false)}>
              Anuluj
            </ButtonSecondary>
          </ConfirmButtons>
        </ConfirmContent>
      </Modal>
    </Container>
  );
};

ReceiptScanner.propTypes = {
  scanReceipt: PropTypes.func.isRequired,
  clearScanData: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  addTransaction: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  receipt: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  receipt: state.receipt,
  category: state.category,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  margin-bottom: 2rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  
  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`;

const SectionTitle = styled.h2`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditHint = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ScanSection = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const FormSection = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const ScannerBox = styled.div`
  border: 2px dashed ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 8px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 1.5rem;
  transition: border-color 0.2s;
  
  &:hover {
    border-color: ${props => props.theme === 'dark' ? '#4a90e2' : '#4a90e2'};
  }
`;

const PlaceholderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  
  p {
    margin: 0;
  }
`;

const ReceiptPreview = styled.img`
  max-width: 100%;
  max-height: 280px;
  object-fit: contain;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const ButtonUpload = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const ButtonScan = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3a70b2;
  }
  
  &:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  
  p {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
    margin: 0;
  }
`;

const ScanResultBox = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;
`;

const ScanSuccessHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #4caf50;
  margin-bottom: 1rem;
  
  svg {
    font-size: 1.2rem;
  }
`;

const ScanDataList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const ScanDataItem = styled.div`
  
`;

const ScanDataLabel = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  margin-bottom: 0.25rem;
`;

const ScanDataValue = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ScanItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
`;

const ScanItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  font-size: 0.9rem;
  
  &:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

// Brakujący styled-component Select
const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  width: 100%;
  appearance: auto;
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  resize: vertical;
  min-height: 100px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const ButtonSubmit = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3a70b2;
  }
`;

const ButtonReset = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const ConfirmContent = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  
  p {
    margin-bottom: 1.5rem;
  }
`;

const ConfirmDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 8px;
  padding: 1rem;
`;

const ConfirmItem = styled.div`
  display: flex;
  justify-content: space-between;
  
  &:not(:last-child) {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  }
`;

const ConfirmLabel = styled.span`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
`;

const ConfirmValue = styled.span`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 500;
`;

const ConfirmButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const ButtonPrimary = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3a70b2;
  }
`;

const ButtonSecondary = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

export default connect(mapStateToProps, {
    scanReceipt,
    clearScanData,
    getCategories,
    addTransaction,
    setAlert
  })(ReceiptScanner);