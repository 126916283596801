// client/src/components/layout/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: ${props => props.sidebarOpen ? '250px' : '70px'};
  right: 0;
  height: 50px;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  color: ${props => props.theme === 'dark' ? '#f1f1f1' : '#666'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  border-top: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  z-index: 999;
  transition: left 0.3s ease;

  @media (max-width: 768px) {
    left: 0;
    padding: 0 1rem;
  }
`;

const FooterLeft = styled.div`
  font-size: 0.875rem;

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

const FooterRight = styled.div`
  display: flex;
  align-items: center;
`;

const FooterLink = styled(Link)`
  color: ${props => props.theme === 'dark' ? '#f1f1f1' : '#666'};
  text-decoration: none;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  
  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }

  @media (max-width: 480px) {
    margin-left: 0.75rem;
    font-size: 0.75rem;
  }
`;

const VersionBadge = styled.span`
  padding: 0.1rem 0.4rem;
  background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  border-radius: 4px;
  font-size: 0.7rem;
  margin-left: 0.5rem;
`;

const Footer = ({ ui: { sidebarOpen, theme } }) => {
  const currentYear = new Date().getFullYear();
  const appVersion = '1.0.0'; // Wersja aplikacji

  return (
    <FooterContainer sidebarOpen={sidebarOpen} theme={theme}>
      <FooterLeft>
        &copy; {currentYear} Oszczędzam.com. Wszystkie prawa zastrzeżone.
        <VersionBadge theme={theme}>v{appVersion}</VersionBadge>
      </FooterLeft>
      <FooterRight>
        <FooterLink to="/privacy" theme={theme}>
          Prywatność
        </FooterLink>
        <FooterLink to="/terms" theme={theme}>
          Warunki
        </FooterLink>
        <FooterLink to="/help" theme={theme}>
          Pomoc
        </FooterLink>
      </FooterRight>
    </FooterContainer>
  );
};

Footer.propTypes = {
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps)(Footer);