// client/src/pages/Groups/GroupDetails.js
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { 
  FaUsers, 
  FaArrowLeft, 
  FaCog, 
  FaUserPlus,
  FaExchangeAlt, 
  FaFileInvoiceDollar, 
  FaChartPie,
  FaMoneyBillWave,
  FaLink,
  FaCopy,
  FaPlus,
  FaEllipsisH,
  FaTrash,
  FaCheck,
  FaTimes
} from 'react-icons/fa';

import { 
  getGroup, 
  generateInviteCode, 
  deleteGroup,
  addMember,
  removeMember,
  updateMemberRole,
  getGroupTransactions,
  generateSettlements,
  getSettlements,
  addGroupTransaction
} from '../../actions/group';
import Spinner from '../../components/layout/Spinner';

const Container = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
    color: #6c5ce7;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  
  svg {
    margin-right: 0.5rem;
  }
`;

const PrimaryButton = styled(Button)`
  background-color: #6c5ce7;
  color: white;

  &:hover {
    background-color: #5649c0;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f1f1f1'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#e1e1e1'};
  }
`;

const LinkButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none;
  background-color: #6c5ce7;
  color: white;
  transition: all 0.3s;
  
  &:hover {
    background-color: #5649c0;
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const DangerButton = styled(Button)`
  background-color: #e74c3c;
  color: white;

  &:hover {
    background-color: #c0392b;
  }
`;

const SettingsButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f1f1f1'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#e1e1e1'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.5rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Card = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const CardHeader = styled.div`
  padding: 1.25rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
    color: #6c5ce7;
  }
`;

const CardContent = styled.div`
  padding: 1.25rem;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const StatCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f8f9fa'};
  border-radius: 8px;
  padding: 1.25rem;
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: ${props => props.color || (props.theme === 'dark' ? 'white' : '#333')};
`;

const StatLabel = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const MembersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};

  &:last-child {
    border-bottom: none;
  }
`;

const MemberInfo = styled.div`
  display: flex;
  align-items: center;
`;

const MemberAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #6c5ce7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  margin-right: 1rem;
`;

const MemberName = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 500;
`;

const MemberSubtext = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#888'};
`;

const MemberRoleBadge = styled.span`
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  background-color: ${props => props.isAdmin ? '#6c5ce7' : props.theme === 'dark' ? '#444' : '#e1e1e1'};
  color: ${props => props.isAdmin ? 'white' : props.theme === 'dark' ? '#ccc' : '#666'};
  margin-left: 0.5rem;
`;

const MemberActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const MemberActionButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.danger ? '#e74c3c' : props.theme === 'dark' ? '#ccc' : '#666'};
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: 4px;
  
  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
    color: ${props => props.danger ? '#c0392b' : props.theme === 'dark' ? 'white' : '#333'};
  }
`;

const InviteCodeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InviteCodeDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f8f9fa'};
  border-radius: 4px;
  padding: 0.75rem;
  border: 1px dashed ${props => props.theme === 'dark' ? '#666' : '#ddd'};
`;

const InviteCode = styled.div`
  font-family: monospace;
  flex: 1;
  font-size: 1.2rem;
  letter-spacing: 2px;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: #6c5ce7;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #5649c0;
  }
  
  svg {
    margin-right: 0.25rem;
  }
`;

const TransactionList = styled.div`
  display: flex;
  flex-direction: column;
`;

const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};

  &:last-child {
    border-bottom: none;
  }
`;

const TransactionInfo = styled.div`
  flex: 1;
`;

const TransactionTitle = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const TransactionMeta = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#888'};
`;

const TransactionAmount = styled.div`
  font-weight: 600;
  color: ${props => props.positive ? '#27ae60' : '#e74c3c'};
`;

const PendingBadge = styled.span`
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 50px;
  background-color: ${props => props.theme === 'dark' ? '#4d3c00' : '#fff8e1'};
  color: ${props => props.theme === 'dark' ? '#ffc107' : '#ff8f00'};
  margin-left: 0.5rem;
`;

const ApprovedBadge = styled.span`
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 50px;
  background-color: ${props => props.theme === 'dark' ? '#003300' : '#e8f5e9'};
  color: ${props => props.theme === 'dark' ? '#4caf50' : '#2e7d32'};
  margin-left: 0.5rem;
`;

const SettlementList = styled.div`
  display: flex;
  flex-direction: column;
`;

const SettlementItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};

  &:last-child {
    border-bottom: none;
  }
`;

const SettlementInfo = styled.div`
  flex: 1;
`;

const SettlementTitle = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const SettlementMeta = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#888'};
`;

const SettlementAmount = styled.div`
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const SettlementActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  width: 90%;
  max-width: 500px;
`;

const ModalTitle = styled.h2`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1rem;
`;

const ConfirmText = styled.p`
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  margin-bottom: 2rem;
`;

const GroupDetails = ({ 
  getGroup, 
  generateInviteCode, 
  deleteGroup,
  addMember,
  removeMember,
  updateMemberRole,
  getGroupTransactions,
  generateSettlements,
  getSettlements,
  addGroupTransaction,
  group: { 
    group, 
    sharedTransactions, 
    settlements,
    loading,
    inviteCode 
  }, 
  auth: { user },
  ui: { theme } 
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [modalTab, setModalTab] = useState('basic'); // basic, splits
  
  // Stany dla formularza transakcji
  const [transactionForm, setTransactionForm] = useState({
    description: '',
    amount: '',
    date: new Date().toISOString().substr(0, 10),
    splitMethod: 'equal',
    participants: []
  });

  useEffect(() => {
    getGroup(id);
    getGroupTransactions(id);
    getSettlements(id);
  }, [getGroup, getGroupTransactions, getSettlements, id]);

  useEffect(() => {
    // Inicjalizacja uczestników transakcji gdy grupa zostanie załadowana
    if (group && group.members) {
      const activeMembers = group.members.filter(m => m.status === 'active');
      setTransactionForm(prev => ({
        ...prev,
        participants: activeMembers.map(m => ({
          user: m.user._id,
          name: m.user.name,
          amount: 0,
          percentage: 0,
          shares: 1
        }))
      }));
    }
  }, [group]);

  const handleGenerateInviteCode = async () => {
    const result = await generateInviteCode(id);
    if (result) {
      setShowInviteCode(true);
    }
  };

  const copyInviteCodeToClipboard = () => {
    navigator.clipboard.writeText(inviteCode.code);
    // Tutaj możesz dodać powiadomienie o skopiowaniu kodu
  };

  const handleDeleteGroup = () => {
    deleteGroup(id, navigate);
  };

  const handleAddMember = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage('');
    
    try {
      await addMember(id, email);
      setEmail('');
      setShowAddMemberModal(false);
    } catch (err) {
      setErrorMessage('Nie udało się dodać użytkownika. Sprawdź, czy adres email jest poprawny.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleRemoveMember = (memberId) => {
    if (window.confirm('Czy na pewno chcesz usunąć tego członka z grupy?')) {
      removeMember(id, memberId);
    }
  };

  const handleChangeRole = async (memberId, newRole) => {
    await updateMemberRole(id, memberId, newRole);
  };

  const handleAddTransaction = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    try {
      const formData = { ...transactionForm };
      
      // Konwersja liczb
      formData.amount = parseFloat(formData.amount);
      
      // Przygotowanie uczestników zależnie od metody podziału
      if (formData.splitMethod === 'equal') {
        const equalShare = formData.amount / formData.participants.length;
        formData.participants = formData.participants.map(p => ({
          user: p.user,
          amount: equalShare
        }));
      } else if (formData.splitMethod === 'percentage') {
        formData.participants = formData.participants.map(p => ({
          user: p.user,
          amount: (formData.amount * p.percentage) / 100,
          percentage: parseFloat(p.percentage)
        }));
      } else if (formData.splitMethod === 'fixed') {
        formData.participants = formData.participants.map(p => ({
          user: p.user,
          amount: parseFloat(p.amount)
        }));
      } else if (formData.splitMethod === 'shares') {
        const totalShares = formData.participants.reduce((sum, p) => sum + parseFloat(p.shares || 1), 0);
        formData.participants = formData.participants.map(p => ({
          user: p.user,
          amount: (formData.amount * parseFloat(p.shares || 1)) / totalShares,
          shares: parseFloat(p.shares || 1)
        }));
      }
      
      await addGroupTransaction(id, formData);
      
      // Reset formularza
      setTransactionForm({
        description: '',
        amount: '',
        date: new Date().toISOString().substr(0, 10),
        splitMethod: 'equal',
        participants: group.members
          .filter(m => m.status === 'active')
          .map(m => ({
            user: m.user._id,
            name: m.user.name,
            amount: 0,
            percentage: 0,
            shares: 1
          }))
      });
      
      setShowAddTransactionModal(false);
    } catch (err) {
      console.error('Error adding transaction:', err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleTransactionFormChange = (e) => {
    const { name, value } = e.target;
    setTransactionForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleParticipantChange = (index, field, value) => {
    setTransactionForm(prev => {
      const updatedParticipants = [...prev.participants];
      updatedParticipants[index] = {
        ...updatedParticipants[index],
        [field]: value
      };
      return {
        ...prev,
        participants: updatedParticipants
      };
    });
  };

  const handleGenerateSettlements = async () => {
    await generateSettlements(id);
  };

  // Sprawdzenie, czy użytkownik jest administratorem
  const isAdmin = group?.members?.some(
    member => 
      member.user._id === user._id && 
      member.status === 'active' && 
      (member.role === 'admin' || group.creator === user._id)
  );

  // Sprawdzenie, czy użytkownik jest twórcą grupy
  const isCreator = group?.creator === user._id;

  // Pobieranie inicjałów użytkownika
  const getInitials = (name) => {
    if (!name) return '?';
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  if (loading || !group) {
    return <Spinner />;
  }

  // Obliczenie bilansu grupy
  const calculateBalance = () => {
    if (!sharedTransactions || sharedTransactions.length === 0) {
      return { totalSpent: 0, myExpenses: 0, myPayments: 0 };
    }

    const totalSpent = sharedTransactions.reduce((sum, tx) => sum + tx.amount, 0);
    
    // Ile użytkownik zapłacił za innych
    const myPayments = sharedTransactions
      .filter(tx => tx.createdBy._id === user._id)
      .reduce((sum, tx) => sum + tx.amount, 0);
    
    // Ile użytkownik jest winien innym
    const myExpenses = sharedTransactions
      .flatMap(tx => 
        tx.participants
          .filter(p => p.user._id === user._id)
          .map(p => p.amount)
      )
      .reduce((sum, amount) => sum + amount, 0);
    
    return { totalSpent, myExpenses, myPayments };
  };

  const { totalSpent, myExpenses, myPayments } = calculateBalance();
  const myBalance = myPayments - myExpenses;

  return (
    <Container>
      <Header>
        <BackLink to="/dashboard/groups" theme={theme}>
          <FaArrowLeft />
          Powrót do listy grup
        </BackLink>
        <Title theme={theme}>
          <FaUsers />
          {group.name}
        </Title>
        <ActionButtons>
          {isAdmin && (
            <SettingsButton to={`/dashboard/groups/${id}/settings`} theme={theme}>
              <FaCog />
              Ustawienia
            </SettingsButton>
          )}
        </ActionButtons>
      </Header>

      <ContentGrid>
        <MainContent>
          {/* Karta podsumowania */}
          <Card theme={theme}>
            <CardHeader theme={theme}>
              <CardTitle theme={theme}>
                <FaChartPie />
                Podsumowanie grupy
              </CardTitle>
            </CardHeader>
            <CardContent>
              <StatsGrid>
                <StatCard theme={theme}>
                  <StatValue theme={theme}>{sharedTransactions?.length || 0}</StatValue>
                  <StatLabel theme={theme}>Transakcje</StatLabel>
                </StatCard>
                <StatCard theme={theme}>
                  <StatValue theme={theme}>{group.members.length}</StatValue>
                  <StatLabel theme={theme}>Członkowie</StatLabel>
                </StatCard>
                <StatCard theme={theme}>
                  <StatValue theme={theme}>
                    {new Intl.NumberFormat('pl-PL', {
                      style: 'currency',
                      currency: group.settings?.defaultCurrency || 'PLN'
                    }).format(totalSpent)}
                  </StatValue>
                  <StatLabel theme={theme}>Suma wydatków</StatLabel>
                </StatCard>
                <StatCard theme={theme}>
                  <StatValue 
                    color={myBalance > 0 ? '#27ae60' : myBalance < 0 ? '#e74c3c' : undefined}
                    theme={theme}
                  >
                    {new Intl.NumberFormat('pl-PL', {
                      style: 'currency',
                      currency: group.settings?.defaultCurrency || 'PLN'
                    }).format(myBalance)}
                  </StatValue>
                  <StatLabel theme={theme}>Twój bilans</StatLabel>
                </StatCard>
              </StatsGrid>

              <p style={{ color: theme === 'dark' ? '#ccc' : '#666' }}>
                {group.description || 'Ta grupa nie ma jeszcze opisu.'}
              </p>

              <div style={{ marginTop: '1.5rem', display: 'flex', gap: '1rem' }}>
                <LinkButton to={`/dashboard/groups/${id}/transactions/add`}>
                  <FaPlus />
                  Dodaj transakcję
                </LinkButton>
                <SecondaryButton 
                  theme={theme} 
                  onClick={handleGenerateSettlements}
                >
                  <FaMoneyBillWave />
                  Generuj rozliczenia
                </SecondaryButton>
              </div>
            </CardContent>
          </Card>

          {/* Karta transakcji */}
          <Card theme={theme}>
            <CardHeader theme={theme}>
              <CardTitle theme={theme}>
                <FaExchangeAlt />
                Ostatnie transakcje
              </CardTitle>
              <Button 
                as="button" 
                onClick={() => setShowAddTransactionModal(true)}
                style={{ backgroundColor: '#6c5ce7', color: 'white' }}
              >
                <FaPlus />
                Dodaj
              </Button>
            </CardHeader>
            <CardContent>
              {sharedTransactions && sharedTransactions.length > 0 ? (
                <TransactionList>
                  {sharedTransactions.slice(0, 5).map(transaction => {
                    // Sprawdź czy użytkownik jest uczestnikiem transakcji
                    const userParticipant = transaction.participants.find(
                      p => p.user._id === user._id
                    );
                    
                    // Czy użytkownik jest twórcą transakcji
                    const isPayer = transaction.createdBy._id === user._id;
                    
                    // Kwota, którą użytkownik musi zapłacić lub otrzymać
                    const amount = userParticipant ? userParticipant.amount : 0;
                    
                    // Czy wszystkie płatności są zatwierdzone
                    const allApproved = transaction.participants.every(p => p.approvedBy);
                    
                    return (
                      <TransactionItem key={transaction._id} theme={theme}>
                        <TransactionInfo>
                          <TransactionTitle theme={theme}>
                            {transaction.description}
                            {!allApproved && <PendingBadge theme={theme}>Oczekuje</PendingBadge>}
                            {allApproved && <ApprovedBadge theme={theme}>Zatwierdzona</ApprovedBadge>}
                          </TransactionTitle>
                          <TransactionMeta theme={theme}>
                            Dodane przez: {transaction.createdBy.name} • 
                            {new Date(transaction.date).toLocaleDateString('pl-PL')}
                          </TransactionMeta>
                        </TransactionInfo>
                        <TransactionAmount positive={isPayer}>
                          {isPayer 
                            ? `+${new Intl.NumberFormat('pl-PL', {
                                style: 'currency',
                                currency: group.settings?.defaultCurrency || 'PLN'
                              }).format(transaction.amount)}`
                            : `-${new Intl.NumberFormat('pl-PL', {
                                style: 'currency',
                                currency: group.settings?.defaultCurrency || 'PLN'
                              }).format(amount)}`
                          }
                        </TransactionAmount>
                      </TransactionItem>
                    );
                  })}
                </TransactionList>
              ) : (
                <div style={{ textAlign: 'center', padding: '2rem', color: theme === 'dark' ? '#ccc' : '#666' }}>
                  Nie ma jeszcze żadnych transakcji w tej grupie.
                </div>
              )}
              
              {sharedTransactions && sharedTransactions.length > 0 && (
                <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                  <Link 
                    to={`/dashboard/groups/${id}/transactions`}
                    style={{ 
                      color: '#6c5ce7', 
                      textDecoration: 'none',
                      fontWeight: '500'
                    }}
                  >
                    Zobacz wszystkie transakcje
                  </Link>
                </div>
              )}
            </CardContent>
          </Card>

          {/* Karta rozliczeń */}
          <Card theme={theme}>
            <CardHeader theme={theme}>
              <CardTitle theme={theme}>
                <FaFileInvoiceDollar />
                Rozliczenia
              </CardTitle>
              <SecondaryButton 
                theme={theme} 
                onClick={handleGenerateSettlements}
              >
                <FaMoneyBillWave />
                Generuj
              </SecondaryButton>
            </CardHeader>
            <CardContent>
              {settlements && settlements.length > 0 ? (
                <SettlementList>
                  {settlements.slice(0, 5).map(settlement => {
                    const isPayer = settlement.fromUser._id === user._id;
                    const isReceiver = settlement.toUser._id === user._id;
                    
                    return (
                      <SettlementItem key={settlement._id} theme={theme}>
                        <SettlementInfo>
                          <SettlementTitle theme={theme}>
                            {isPayer 
                              ? `Musisz zapłacić użytkownikowi ${settlement.toUser.name}` 
                              : isReceiver 
                                ? `${settlement.fromUser.name} musi Ci zapłacić`
                                : `${settlement.fromUser.name} -> ${settlement.toUser.name}`
                            }
                          </SettlementTitle>
                          <SettlementMeta theme={theme}>
                            Status: {
                              settlement.status === 'pending' ? 'Oczekuje' :
                              settlement.status === 'confirmed' ? 'Potwierdzone' :
                              settlement.status === 'rejected' ? 'Odrzucone' : 
                              'Anulowane'
                            }
                          </SettlementMeta>
                        </SettlementInfo>
                        <SettlementAmount theme={theme}>
                          {new Intl.NumberFormat('pl-PL', {
                            style: 'currency',
                            currency: settlement.currency || group.settings?.defaultCurrency || 'PLN'
                          }).format(settlement.amount)}
                        </SettlementAmount>
                        {(isPayer || isReceiver) && settlement.status === 'pending' && (
                          <SettlementActions>
                            {isReceiver && (
                              <MemberActionButton theme={theme}>
                                <FaCheck />
                              </MemberActionButton>
                            )}
                            <MemberActionButton theme={theme} danger>
                              <FaTimes />
                            </MemberActionButton>
                          </SettlementActions>
                        )}
                      </SettlementItem>
                    );
                  })}
                </SettlementList>
              ) : (
                <div style={{ textAlign: 'center', padding: '2rem', color: theme === 'dark' ? '#ccc' : '#666' }}>
                  Nie ma jeszcze żadnych rozliczeń w tej grupie.
                </div>
              )}
              
              {settlements && settlements.length > 0 && (
                <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                  <Link 
                    to={`/dashboard/groups/${id}/settlements`}
                    style={{ 
                      color: '#6c5ce7', 
                      textDecoration: 'none',
                      fontWeight: '500'
                    }}
                  >
                    Zobacz wszystkie rozliczenia
                  </Link>
                </div>
              )}
            </CardContent>
          </Card>
        </MainContent>

        <Sidebar>
          {/* Karta członków */}
          <Card theme={theme}>
            <CardHeader theme={theme}>
              <CardTitle theme={theme}>
                <FaUsers />
                Członkowie ({group.members.length})
              </CardTitle>
              {isAdmin && (
                <Button 
                  as="button" 
                  onClick={() => setShowAddMemberModal(true)}
                  style={{ backgroundColor: '#6c5ce7', color: 'white' }}
                >
                  <FaUserPlus />
                  Dodaj
                </Button>
              )}
            </CardHeader>
            <CardContent>
              <MembersList>
                {group.members
                  .filter(member => member.status === 'active')
                  .map(member => (
                    <MemberItem key={member.user._id} theme={theme}>
                      <MemberInfo>
                        <MemberAvatar>
                          {getInitials(member.user.name)}
                        </MemberAvatar>
                        <div>
                          <MemberName theme={theme}>
                            {member.user.name}
                            <MemberRoleBadge 
                              isAdmin={member.role === 'admin'} 
                              theme={theme}
                            >
                              {member.role === 'admin' ? 'Admin' : 'Członek'}
                            </MemberRoleBadge>
                          </MemberName>
                          <MemberSubtext theme={theme}>
                            {new Date(member.joinDate).toLocaleDateString('pl-PL')}
                          </MemberSubtext>
                        </div>
                      </MemberInfo>
                      
                      {/* Akcje członka (tylko dla admina) */}
                      {isAdmin && member.user._id !== user._id && (
                        <MemberActions>
                          <MemberActionButton 
                            onClick={() => handleChangeRole(
                              member.user._id, 
                              member.role === 'admin' ? 'member' : 'admin'
                            )}
                            theme={theme}
                          >
                            <FaCog />
                          </MemberActionButton>
                          <MemberActionButton 
                            onClick={() => handleRemoveMember(member.user._id)}
                            theme={theme}
                            danger
                          >
                            <FaTrash />
                          </MemberActionButton>
                        </MemberActions>
                      )}
                    </MemberItem>
                  ))}
              </MembersList>
            </CardContent>
          </Card>

          {/* Kod zaproszenia */}
          <Card theme={theme}>
            <CardHeader theme={theme}>
              <CardTitle theme={theme}>
                <FaLink />
                Zaproś znajomych
              </CardTitle>
            </CardHeader>
            <CardContent>
              {inviteCode && showInviteCode ? (
                <InviteCodeSection>
                  <p style={{ color: theme === 'dark' ? '#ccc' : '#666' }}>
                    Udostępnij ten kod znajomym, aby mogli dołączyć do grupy:
                  </p>
                  <InviteCodeDisplay theme={theme}>
                    <InviteCode theme={theme}>{inviteCode.code}</InviteCode>
                    <CopyButton 
                      onClick={copyInviteCodeToClipboard}
                      title="Kopiuj kod"
                    >
                      <FaCopy />
                    </CopyButton>
                  </InviteCodeDisplay>
                  <div style={{ fontSize: '0.8rem', color: theme === 'dark' ? '#aaa' : '#888' }}>
                    Kod jest ważny do: {new Date(inviteCode.expiresAt).toLocaleDateString('pl-PL')}
                  </div>
                </InviteCodeSection>
              ) : (
                <div>
                  <p style={{ color: theme === 'dark' ? '#ccc' : '#666', marginBottom: '1rem' }}>
                    Wygeneruj kod zaproszenia, aby zaprosić znajomych do tej grupy.
                  </p>
                  <SecondaryButton 
                    theme={theme} 
                    onClick={handleGenerateInviteCode}
                  >
                    <FaLink />
                    Wygeneruj kod zaproszenia
                  </SecondaryButton>
                </div>
              )}
            </CardContent>
          </Card>

          {/* Usuń grupę (tylko dla twórcy) */}
          {isCreator && (
            <Card theme={theme}>
              <CardHeader theme={theme}>
                <CardTitle theme={theme} style={{ color: '#e74c3c' }}>
                  <FaTrash />
                  Zarządzanie grupą
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p style={{ color: theme === 'dark' ? '#ccc' : '#666', marginBottom: '1rem' }}>
                  Usunięcie grupy spowoduje trwałe usunięcie wszystkich danych, transakcji i rozliczeń.
                  Ta operacja jest nieodwracalna.
                </p>
                <DangerButton onClick={handleDeleteGroup}>
                  <FaTrash />
                  Usuń grupę
                </DangerButton>
              </CardContent>
            </Card>
          )}
        </Sidebar>
      </ContentGrid>

      {/* Modal dodawania członka */}
      {showAddMemberModal && (
        <ModalOverlay>
          <ModalContent theme={theme}>
            <ModalTitle theme={theme}>Dodaj członka</ModalTitle>
            <ModalForm onSubmit={handleAddMember}>
              <FormGroup>
                <Label theme={theme}>Adres email</Label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Wpisz adres email użytkownika"
                  required
                  theme={theme}
                />
                {errorMessage && (
                  <div style={{ color: '#e74c3c', fontSize: '0.85rem', marginTop: '0.5rem' }}>
                    {errorMessage}
                  </div>
                )}
              </FormGroup>
              <ModalButtons>
                <SecondaryButton
                  type="button"
                  onClick={() => setShowAddMemberModal(false)}
                  theme={theme}
                >
                  Anuluj
                </SecondaryButton>
                <PrimaryButton type="submit" disabled={!email || submitting}>
                  {submitting ? 'Dodawanie...' : 'Dodaj członka'}
                </PrimaryButton>
              </ModalButtons>
            </ModalForm>
          </ModalContent>
        </ModalOverlay>
      )}

      {/* Modal dodawania transakcji */}
      {showAddTransactionModal && (
        <ModalOverlay>
          <ModalContent theme={theme} style={{ maxWidth: '600px' }}>
            <ModalTitle theme={theme}>Dodaj transakcję</ModalTitle>
            
            <div style={{ marginBottom: '1.5rem' }}>
              <button 
                onClick={() => setModalTab('basic')}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: modalTab === 'basic' ? '#6c5ce7' : theme === 'dark' ? '#383f41' : '#f1f1f1',
                  color: modalTab === 'basic' ? 'white' : theme === 'dark' ? 'white' : '#333',
                  border: 'none',
                  borderRadius: '4px 0 0 4px',
                  cursor: 'pointer'
                }}
              >
                Podstawowe
              </button>
              <button 
                onClick={() => setModalTab('splits')}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: modalTab === 'splits' ? '#6c5ce7' : theme === 'dark' ? '#383f41' : '#f1f1f1',
                  color: modalTab === 'splits' ? 'white' : theme === 'dark' ? 'white' : '#333',
                  border: 'none',
                  borderRadius: '0 4px 4px 0',
                  cursor: 'pointer'
                }}
              >
                Podział kosztów
              </button>
            </div>
            
            <ModalForm onSubmit={handleAddTransaction}>
              {modalTab === 'basic' ? (
                <>
                  <FormGroup>
                    <Label theme={theme}>Opis*</Label>
                    <Input
                      type="text"
                      name="description"
                      value={transactionForm.description}
                      onChange={handleTransactionFormChange}
                      placeholder="Np. Zakupy spożywcze, Rachunek za prąd"
                      required
                      theme={theme}
                    />
                  </FormGroup>
                  
                  <FormGroup>
                    <Label theme={theme}>Kwota*</Label>
                    <Input
                      type="number"
                      name="amount"
                      value={transactionForm.amount}
                      onChange={handleTransactionFormChange}
                      placeholder="0.00"
                      step="0.01"
                      min="0.01"
                      required
                      theme={theme}
                    />
                  </FormGroup>
                  
                  <FormGroup>
                    <Label theme={theme}>Data*</Label>
                    <Input
                      type="date"
                      name="date"
                      value={transactionForm.date}
                      onChange={handleTransactionFormChange}
                      required
                      theme={theme}
                    />
                  </FormGroup>
                  
                  <FormGroup>
                    <Label theme={theme}>Metoda podziału*</Label>
                    <Select
                      name="splitMethod"
                      value={transactionForm.splitMethod}
                      onChange={handleTransactionFormChange}
                      theme={theme}
                    >
                      <option value="equal">Równo (po równej części)</option>
                      <option value="percentage">Procentowo</option>
                      <option value="fixed">Kwoty stałe</option>
                      <option value="shares">Udziały</option>
                    </Select>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup>
                    <Label theme={theme}>Podział kosztów</Label>
                    <p style={{ fontSize: '0.85rem', color: theme === 'dark' ? '#ccc' : '#666', marginBottom: '1rem' }}>
                      Metoda podziału: <strong>
                        {transactionForm.splitMethod === 'equal' ? 'Równo' : 
                         transactionForm.splitMethod === 'percentage' ? 'Procentowo' :
                         transactionForm.splitMethod === 'fixed' ? 'Kwoty stałe' : 'Udziały'}
                      </strong>
                    </p>
                    
                    {transactionForm.participants.map((participant, index) => (
                      <div 
                        key={participant.user} 
                        style={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          marginBottom: '0.75rem',
                          gap: '1rem'
                        }}
                      >
                        <div style={{ flex: '1' }}>
                          {participant.name}
                        </div>
                        
                        {transactionForm.splitMethod === 'equal' ? (
                          <div style={{ color: theme === 'dark' ? '#ccc' : '#666' }}>
                            Równy podział
                          </div>
                        ) : transactionForm.splitMethod === 'percentage' ? (
                          <Input
                            type="number"
                            value={participant.percentage || 0}
                            onChange={(e) => handleParticipantChange(index, 'percentage', e.target.value)}
                            placeholder="%"
                            min="0"
                            max="100"
                            step="0.1"
                            style={{ width: '80px' }}
                            theme={theme}
                          />
                        ) : transactionForm.splitMethod === 'fixed' ? (
                          <Input
                            type="number"
                            value={participant.amount || 0}
                            onChange={(e) => handleParticipantChange(index, 'amount', e.target.value)}
                            placeholder="Kwota"
                            min="0"
                            step="0.01"
                            style={{ width: '100px' }}
                            theme={theme}
                          />
                        ) : (
                          <Input
                            type="number"
                            value={participant.shares || 1}
                            onChange={(e) => handleParticipantChange(index, 'shares', e.target.value)}
                            placeholder="Udziały"
                            min="1"
                            step="1"
                            style={{ width: '80px' }}
                            theme={theme}
                          />
                        )}
                      </div>
                    ))}
                  </FormGroup>
                </>
              )}
              
              <ModalButtons>
                <SecondaryButton
                  type="button"
                  onClick={() => setShowAddTransactionModal(false)}
                  theme={theme}
                >
                  Anuluj
                </SecondaryButton>
                {modalTab === 'basic' ? (
                  <PrimaryButton 
                    type="button" 
                    onClick={() => setModalTab('splits')}
                    disabled={!transactionForm.description || !transactionForm.amount}
                  >
                    Dalej
                  </PrimaryButton>
                ) : (
                  <>
                    <SecondaryButton
                      type="button"
                      onClick={() => setModalTab('basic')}
                      theme={theme}
                    >
                      Wstecz
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={submitting}>
                      {submitting ? 'Dodawanie...' : 'Dodaj transakcję'}
                    </PrimaryButton>
                  </>
                )}
              </ModalButtons>
            </ModalForm>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

GroupDetails.propTypes = {
  getGroup: PropTypes.func.isRequired,
  generateInviteCode: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  updateMemberRole: PropTypes.func.isRequired,
  getGroupTransactions: PropTypes.func.isRequired,
  generateSettlements: PropTypes.func.isRequired,
  getSettlements: PropTypes.func.isRequired,
  addGroupTransaction: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  group: state.group,
  auth: state.auth,
  ui: state.ui
});

export default connect(
  mapStateToProps, 
  { 
    getGroup, 
    generateInviteCode, 
    deleteGroup,
    addMember,
    removeMember,
    updateMemberRole,
    getGroupTransactions,
    generateSettlements,
    getSettlements,
    addGroupTransaction
  }
)(GroupDetails);