import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

const Spinner = ({ size, color, text, fullPage }) => {
  const spinnerStyle = {
    width: size,
    height: size,
    borderColor: `${color} transparent transparent transparent`
  };

  const containerClass = fullPage ? 'spinner-container fullpage' : 'spinner-container';

  return (
    <div className={containerClass}>
      <div className="spinner" style={spinnerStyle}></div>
      {text && <p className="spinner-text">{text}</p>}
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  fullPage: PropTypes.bool
};

Spinner.defaultProps = {
  size: '40px',
  color: '#3498db',
  text: 'Ładowanie...',
  fullPage: false
};

export default Spinner;