import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaSave, FaTimes, FaCog } from 'react-icons/fa';
import { updateAIPreferences } from '../../actions/ai';
import { setAlert } from '../../actions/alert';

const AIPreferencesModal = ({
  isOpen,
  onClose,
  updateAIPreferences,
  setAlert,
  ai: { preferences },
  ui: { theme }
}) => {
  const [formData, setFormData] = useState({
    enableFinancialAdvice: true,
    enableSavingTips: true,
    enableBudgetSuggestions: true,
    enableInvestmentSuggestions: false,
    enableAutomaticPlanning: true,
    enableGoalTracking: true,
    dataUsageLevel: 'all', // 'all', 'limited', 'minimal'
    privacyLevel: 'standard', // 'standard', 'high', 'max'
    language: 'pl' // 'pl', 'en', 'de', etc.
  });

  // Aktualizuj formularz gdy zmienią się preferencje
  useEffect(() => {
    if (preferences) {
      setFormData(prevData => ({
        ...prevData,
        ...preferences
      }));
    }
  }, [preferences]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await updateAIPreferences(formData);
      setAlert('Preferencje asystenta AI zostały zaktualizowane', 'success');
      onClose();
    } catch (err) {
      setAlert('Błąd aktualizacji preferencji', 'danger');
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            <FaCog />
            <span>Ustawienia Asystenta AI</span>
          </ModalTitle>
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </ModalHeader>
        
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormSection>
              <SectionTitle>Funkcje asystenta</SectionTitle>
              
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="enableFinancialAdvice"
                    checked={formData.enableFinancialAdvice}
                    onChange={handleChange}
                  />
                  <span>Porady finansowe</span>
                </CheckboxLabel>
                <CheckboxDescription>
                  Asystent będzie analizował Twoje wydatki i sugerował optymalizacje finansowe
                </CheckboxDescription>
              </CheckboxGroup>
              
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="enableSavingTips"
                    checked={formData.enableSavingTips}
                    onChange={handleChange}
                  />
                  <span>Wskazówki oszczędnościowe</span>
                </CheckboxLabel>
                <CheckboxDescription>
                  Regularnie otrzymasz wskazówki jak oszczędzać na codziennych wydatkach
                </CheckboxDescription>
              </CheckboxGroup>
              
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="enableBudgetSuggestions"
                    checked={formData.enableBudgetSuggestions}
                    onChange={handleChange}
                  />
                  <span>Sugestie budżetowe</span>
                </CheckboxLabel>
                <CheckboxDescription>
                  Asystent zaproponuje limity budżetowe dostosowane do Twoich wydatków
                </CheckboxDescription>
              </CheckboxGroup>
              
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="enableInvestmentSuggestions"
                    checked={formData.enableInvestmentSuggestions}
                    onChange={handleChange}
                  />
                  <span>Sugestie inwestycyjne</span>
                </CheckboxLabel>
                <CheckboxDescription>
                  Otrzymasz podstawowe sugestie dotyczące możliwości inwestycyjnych
                </CheckboxDescription>
              </CheckboxGroup>
              
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="enableAutomaticPlanning"
                    checked={formData.enableAutomaticPlanning}
                    onChange={handleChange}
                  />
                  <span>Automatyczne planowanie</span>
                </CheckboxLabel>
                <CheckboxDescription>
                  Asystent pomoże w planowaniu przyszłych wydatków i oszczędności
                </CheckboxDescription>
              </CheckboxGroup>
              
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="enableGoalTracking"
                    checked={formData.enableGoalTracking}
                    onChange={handleChange}
                  />
                  <span>Śledzenie celów</span>
                </CheckboxLabel>
                <CheckboxDescription>
                  Asystent będzie monitorował postępy w osiąganiu Twoich celów finansowych
                </CheckboxDescription>
              </CheckboxGroup>
            </FormSection>
            
            <FormSection>
              <SectionTitle>Prywatność i bezpieczeństwo</SectionTitle>
              
              <RadioGroup>
                <GroupLabel>Poziom wykorzystania danych:</GroupLabel>
                
                <RadioLabel>
                  <Radio
                    type="radio"
                    name="dataUsageLevel"
                    value="all"
                    checked={formData.dataUsageLevel === 'all'}
                    onChange={handleChange}
                  />
                  <RadioText>
                    <strong>Pełny</strong>
                    <span>Dostęp do wszystkich danych dla najlepszych porad</span>
                  </RadioText>
                </RadioLabel>
                
                <RadioLabel>
                  <Radio
                    type="radio"
                    name="dataUsageLevel"
                    value="limited"
                    checked={formData.dataUsageLevel === 'limited'}
                    onChange={handleChange}
                  />
                  <RadioText>
                    <strong>Ograniczony</strong>
                    <span>Dostęp tylko do ogólnych danych bez szczegółów transakcji</span>
                  </RadioText>
                </RadioLabel>
                
                <RadioLabel>
                  <Radio
                    type="radio"
                    name="dataUsageLevel"
                    value="minimal"
                    checked={formData.dataUsageLevel === 'minimal'}
                    onChange={handleChange}
                  />
                  <RadioText>
                    <strong>Minimalny</strong>
                    <span>Dostęp tylko do zagregowanych statystyk</span>
                  </RadioText>
                </RadioLabel>
              </RadioGroup>
              
              <RadioGroup>
                <GroupLabel>Poziom prywatności:</GroupLabel>
                
                <RadioLabel>
                  <Radio
                    type="radio"
                    name="privacyLevel"
                    value="standard"
                    checked={formData.privacyLevel === 'standard'}
                    onChange={handleChange}
                  />
                  <RadioText>
                    <strong>Standardowy</strong>
                    <span>Dane są analizowane w chmurze z zachowaniem prywatności</span>
                  </RadioText>
                </RadioLabel>
                
                <RadioLabel>
                  <Radio
                    type="radio"
                    name="privacyLevel"
                    value="high"
                    checked={formData.privacyLevel === 'high'}
                    onChange={handleChange}
                  />
                  <RadioText>
                    <strong>Wysoki</strong>
                    <span>Dane są analizowane lokalnie gdy to możliwe</span>
                  </RadioText>
                </RadioLabel>
                
                <RadioLabel>
                  <Radio
                    type="radio"
                    name="privacyLevel"
                    value="max"
                    checked={formData.privacyLevel === 'max'}
                    onChange={handleChange}
                  />
                  <RadioText>
                    <strong>Maksymalny</strong>
                    <span>Wszystkie dane są analizowane wyłącznie lokalnie</span>
                  </RadioText>
                </RadioLabel>
              </RadioGroup>
            </FormSection>
            
            <FormSection>
              <SectionTitle>Preferencje językowe</SectionTitle>
              
              <SelectGroup>
                <GroupLabel>Język:</GroupLabel>
                <Select
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                >
                  <option value="pl">Polski</option>
                  <option value="en">English</option>
                  <option value="de">Deutsch</option>
                  <option value="fr">Français</option>
                  <option value="es">Español</option>
                </Select>
              </SelectGroup>
            </FormSection>
            
            <ButtonsContainer>
              <SubmitButton type="submit">
                <FaSave />
                <span>Zapisz ustawienia</span>
              </SubmitButton>
              <CancelButton type="button" onClick={onClose}>
                <FaTimes />
                <span>Anuluj</span>
              </CancelButton>
            </ButtonsContainer>
          </Form>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

AIPreferencesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateAIPreferences: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  ai: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  ai: state.ai,
  ui: state.ui
});

// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  
  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
`;

const ModalBody = styled.div`
  padding: 1.5rem;
  overflow-y: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SectionTitle = styled.h3`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.1rem;
  margin: 0 0 0.5rem 0;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  cursor: pointer;
`;

const Checkbox = styled.input`
  cursor: pointer;
`;

const CheckboxDescription = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.85rem;
  margin-left: 1.75rem;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
`;

const GroupLabel = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-bottom: 0.5rem;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  cursor: pointer;
`;

const Radio = styled.input`
  cursor: pointer;
  margin-top: 0.25rem;
`;

const RadioText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  
  strong {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
  
  span {
    color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
    font-size: 0.85rem;
  }
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3a70b2;
  }
`;

const CancelButton = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

export default connect(mapStateToProps, {
  updateAIPreferences,
  setAlert
})(AIPreferencesModal);