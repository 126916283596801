// client/src/components/dashboard/MonthlyChart.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend,
  Filler
} from 'chart.js';

// Rejestracja komponentów Chart.js
ChartJS.register(
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend,
  Filler
);

const ChartContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ChartTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const PeriodSelect = styled.select`
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 0.875rem;
  outline: none;
`;

const MonthlyChart = ({ data, theme }) => {
  const [period, setPeriod] = useState('6'); // Domyślnie 6 miesięcy
  
  // Formatowanie daty do wyświetlenia na osi X
  const formatPeriod = (period) => {
    if (!period) return '';
    
    // Dla formatu YYYY-MM (np. 2023-01)
    if (period.match(/^\d{4}-\d{2}$/)) {
      const [year, month] = period.split('-');
      const monthNames = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'];
      return `${monthNames[parseInt(month) - 1]} ${year}`;
    }
    
    // Dla formatu YYYY (np. 2023)
    if (period.match(/^\d{4}$/)) {
      return period;
    }
    
    return period;
  };

  // Filtrowanie danych do wyświetlenia na podstawie wybranego okresu
  const filteredData = data
    .slice(-parseInt(period))
    .sort((a, b) => a.period.localeCompare(b.period));

  // Przygotowanie danych do wykresu
  const chartData = {
    labels: filteredData.map(item => formatPeriod(item.period)),
    datasets: [
      {
        label: 'Przychody',
        data: filteredData.map(item => item.income),
        borderColor: '#27ae60',
        backgroundColor: 'rgba(39, 174, 96, 0.1)',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointBackgroundColor: '#27ae60',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6
      },
      {
        label: 'Wydatki',
        data: filteredData.map(item => item.expense),
        borderColor: '#e74c3c',
        backgroundColor: 'rgba(231, 76, 60, 0.1)',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointBackgroundColor: '#e74c3c',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6
      },
      {
        label: 'Bilans',
        data: filteredData.map(item => item.balance),
        borderColor: '#6c5ce7',
        backgroundColor: 'rgba(108, 92, 231, 0.1)',
        borderWidth: 2,
        fill: {
          target: 'origin',
          above: 'rgba(108, 92, 231, 0.1)',
          below: 'rgba(231, 76, 60, 0.1)'
        },
        tension: 0.4,
        pointBackgroundColor: '#6c5ce7',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6
      }
    ]
  };

  // Opcje konfiguracyjne wykresu
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: theme === 'dark' ? 'white' : '#666',
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: theme === 'dark' ? '#2d3436' : 'white',
        titleColor: theme === 'dark' ? 'white' : '#333',
        bodyColor: theme === 'dark' ? '#ccc' : '#666',
        borderColor: theme === 'dark' ? '#444' : '#ddd',
        borderWidth: 1,
        padding: 12,
        boxPadding: 6,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('pl-PL', {
                style: 'currency',
                currency: 'PLN',
                minimumFractionDigits: 2
              }).format(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'
        },
        ticks: {
          color: theme === 'dark' ? '#ccc' : '#666'
        }
      },
      y: {
        grid: {
          color: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'
        },
        ticks: {
          color: theme === 'dark' ? '#ccc' : '#666',
          callback: function(value) {
            return new Intl.NumberFormat('pl-PL', {
              style: 'currency',
              currency: 'PLN',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(value);
          }
        }
      }
    }
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  return (
    <ChartContainer theme={theme}>
      <ChartHeader>
        <ChartTitle theme={theme}>Przychody vs. Wydatki</ChartTitle>
        <PeriodSelect 
          value={period} 
          onChange={handlePeriodChange}
          theme={theme}
        >
          <option value="3">Ostatnie 3 miesiące</option>
          <option value="6">Ostatnie 6 miesięcy</option>
          <option value="12">Ostatni rok</option>
          <option value="24">Ostatnie 2 lata</option>
        </PeriodSelect>
      </ChartHeader>
      <div style={{ height: '300px' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    </ChartContainer>
  );
};

MonthlyChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string.isRequired,
      income: PropTypes.number.isRequired,
      expense: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired
    })
  ).isRequired,
  theme: PropTypes.string.isRequired
};

// Wartości domyślne dla komponentu
MonthlyChart.defaultProps = {
  data: []
};

export default MonthlyChart;