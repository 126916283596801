// client/src/components/dashboard/EducationalTips.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaGraduationCap, FaLightbulb, FaArrowRight, FaSync } from 'react-icons/fa';
// Importowanie wskazówek z modułu edukacyjnego - przesunięte na początek pliku
import { getTips } from '../../pages/Education/EducationHub';

const Container = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 4px solid #6c5ce7;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: #6c5ce7;
  }
`;

const RefreshButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #6c5ce7;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TipIcon = styled.div`
  font-size: 1.5rem;
  color: #6c5ce7;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TipTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const TipText = styled.p`
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  font-size: 0.9rem;
  margin-bottom: 1rem;
  flex: 1;
`;

const LearnMoreLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 500;
  margin-top: auto;
  align-self: flex-start;
  
  svg {
    margin-left: 0.5rem;
    transition: transform 0.3s;
  }
  
  &:hover {
    svg {
      transform: translateX(3px);
    }
  }
`;

// Kolekcja wskazówek edukacyjnych
const educationalTips = [
  {
    id: 1,
    title: 'Zasada 50/30/20',
    text: 'Podziel swój budżet na 50% na potrzeby, 30% na zachcianki i 20% na oszczędności - to prosty sposób na zdrowe finanse.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 2,
    title: 'Poduszka finansowa',
    text: 'Zgromadź oszczędności na 3-6 miesięcy wydatków. Taka rezerwa pomoże Ci przetrwać niespodziewane sytuacje.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 3,
    title: 'Cele SMART',
    text: 'Twoje cele finansowe powinny być Specyficzne, Mierzalne, Osiągalne, Realistyczne i Określone w czasie.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 4,
    title: 'Automatyczne oszczędzanie',
    text: 'Ustaw automatyczny przelew na konto oszczędnościowe tuż po otrzymaniu wynagrodzenia. Oszczędzaj, zanim zaczniesz wydawać.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 5,
    title: 'Zasada 24 godzin',
    text: 'Przy większych zakupach daj sobie 24 godziny do namysłu. To pomoże uniknąć impulsywnych decyzji.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 6,
    title: 'Najpierw spłać długi',
    text: 'Zanim zaczniesz agresywnie oszczędzać, spłać wszystkie długi o wysokim oprocentowaniu, np. z kart kredytowych.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 7,
    title: 'Porównuj oferty',
    text: 'Regularnie sprawdzaj i porównuj ceny za usługi, z których korzystasz (internet, telefon, ubezpieczenia).',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  {
    id: 8,
    title: 'Mierz postępy',
    text: 'Regularne śledzenie postępów w realizacji budżetu zwiększa szansę na sukces finansowy o ponad 40%.',
    icon: <FaLightbulb />,
    link: '/dashboard/education'
  },
  // Dynamicznie pobierane wskazówki z modułu edukacyjnego
  ...getTips()
];

const EducationalTips = ({ theme }) => {
  // Stan dla aktualnie wyświetlanej porady
  const [currentTip, setCurrentTip] = useState(0);
  
  // Efekt zmieniający poradę co 7 dni
  useEffect(() => {
    // Sprawdź ostatnią zmianę z localStorage
    const lastChange = localStorage.getItem('lastTipChange');
    const now = new Date().getTime();
    
    if (!lastChange || (now - parseInt(lastChange)) > 7 * 24 * 60 * 60 * 1000) {
      // Losuj nową poradę
      const randomTip = Math.floor(Math.random() * educationalTips.length);
      setCurrentTip(randomTip);
      
      // Zapisz czas zmiany
      localStorage.setItem('lastTipChange', now.toString());
    } else {
      // Wczytaj ostatnią poradę z localStorage
      const savedTip = localStorage.getItem('currentTip');
      if (savedTip !== null) {
        setCurrentTip(parseInt(savedTip));
      }
    }
  }, []);
  
  // Zapisz aktualną poradę do localStorage przy zmianie
  useEffect(() => {
    localStorage.setItem('currentTip', currentTip.toString());
  }, [currentTip]);
  
  // Zmień poradę na żądanie
  const handleRefresh = () => {
    let newTip;
    do {
      newTip = Math.floor(Math.random() * educationalTips.length);
    } while (newTip === currentTip);
    
    setCurrentTip(newTip);
    localStorage.setItem('lastTipChange', new Date().getTime().toString());
  };
  
  const tip = educationalTips[currentTip];
  
  return (
    <Container theme={theme}>
      <Header>
        <Title theme={theme}>
          <FaGraduationCap />
          Wskazówka finansowa
        </Title>
        <RefreshButton onClick={handleRefresh} theme={theme}>
          <FaSync />
        </RefreshButton>
      </Header>
      
      <Content>
        <TipIcon>{tip.icon}</TipIcon>
        <TipTitle theme={theme}>{tip.title}</TipTitle>
        <TipText theme={theme}>{tip.text}</TipText>
        <LearnMoreLink to={tip.link}>
          Dowiedz się więcej
          <FaArrowRight />
        </LearnMoreLink>
      </Content>
    </Container>
  );
};

EducationalTips.propTypes = {
  theme: PropTypes.string.isRequired
};

export default EducationalTips;