// client/src/pages/Groups/CreateGroup.js
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUsers, FaArrowLeft } from 'react-icons/fa';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { createGroup } from '../../actions/group';

const Container = styled.div`
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
    color: #6c5ce7;
  }
`;

const Card = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  font-weight: 500;
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  background-color: ${props => props.theme === 'dark' ? '#383f41' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;
`;

const ErrorText = styled.div`
  color: #e74c3c;
  font-size: 0.85rem;
  margin-top: 0.25rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
`;

const PrimaryButton = styled(Button)`
  background-color: #6c5ce7;
  color: white;
  border: none;

  &:hover {
    background-color: #5649c0;
  }

  &:disabled {
    background-color: #a29bde;
    cursor: not-allowed;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
  }
`;

const HelpText = styled.p`
  margin-top: 0.25rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#888'};
  font-size: 0.85rem;
`;

// Schema walidacji formularza
const GroupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nazwa grupy jest wymagana')
    .min(3, 'Nazwa grupy musi mieć co najmniej 3 znaki')
    .max(50, 'Nazwa grupy nie może być dłuższa niż 50 znaków'),
  description: Yup.string()
    .max(200, 'Opis nie może być dłuższy niż 200 znaków')
});

const CreateGroup = ({ createGroup, ui: { theme } }) => {
  const navigate = useNavigate();
  
  // Stan formularza
  const initialValues = {
    name: '',
    description: '',
    settings: {
      autoApproveTransactions: false,
      visibilityLevel: 'all',
      defaultCurrency: 'PLN'
    }
  };

  // Obsługa wysyłania formularza
  const handleSubmit = async (values, { setSubmitting }) => {
    await createGroup(values, navigate);
    setSubmitting(false);
  };

  return (
    <Container>
      <Header>
        <BackLink to="/dashboard/groups" theme={theme}>
          <FaArrowLeft />
          Powrót do listy grup
        </BackLink>
        <Title theme={theme}>
          <FaUsers />
          Utwórz nową grupę
        </Title>
      </Header>

      <Card theme={theme}>
        <Formik
          initialValues={initialValues}
          validationSchema={GroupSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="name" theme={theme}>Nazwa grupy *</Label>
                <StyledField
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Np. Współlokatorzy, Rodzina, Wyjazd w góry"
                  theme={theme}
                />
                <ErrorMessage name="name" component={ErrorText} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="description" theme={theme}>Opis grupy</Label>
                <Field name="description">
                  {({ field }) => (
                    <StyledTextarea
                      {...field}
                      id="description"
                      placeholder="Opisz cel grupy i jak będzie wykorzystywana"
                      theme={theme}
                    />
                  )}
                </Field>
                <ErrorMessage name="description" component={ErrorText} />
                <HelpText theme={theme}>
                  Opcjonalny opis pomoże członkom zrozumieć cel grupy.
                </HelpText>
              </FormGroup>

              <FormGroup>
                <Label theme={theme}>Ustawienia grupy</Label>
                
                <div style={{ marginBottom: '1rem' }}>
                  <Field name="settings.autoApproveTransactions">
                    {({ field }) => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          id="autoApproveTransactions"
                          {...field}
                          checked={field.value}
                        />
                        <label 
                          htmlFor="autoApproveTransactions" 
                          style={{ marginLeft: '0.5rem', color: theme === 'dark' ? '#ccc' : '#666' }}
                        >
                          Automatycznie zatwierdzaj transakcje
                        </label>
                      </div>
                    )}
                  </Field>
                  <HelpText theme={theme}>
                    Jeśli włączone, transakcje nie będą wymagały zatwierdzenia przez innych członków grupy.
                  </HelpText>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                  <Label htmlFor="visibilityLevel" theme={theme}>Poziom widoczności</Label>
                  <Field name="settings.visibilityLevel">
                    {({ field }) => (
                      <select
                        {...field}
                        id="visibilityLevel"
                        style={{
                          width: '100%',
                          padding: '0.75rem',
                          borderRadius: '4px',
                          border: `1px solid ${theme === 'dark' ? '#444' : '#ddd'}`,
                          backgroundColor: theme === 'dark' ? '#383f41' : 'white',
                          color: theme === 'dark' ? 'white' : '#333'
                        }}
                      >
                        <option value="all">Pełna widoczność (wszystkie transakcje)</option>
                        <option value="summary">Tylko podsumowania</option>
                        <option value="limited">Ograniczona (tylko własne transakcje)</option>
                      </select>
                    )}
                  </Field>
                  <HelpText theme={theme}>
                    Określa, ile informacji widzi każdy członek grupy.
                  </HelpText>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                  <Label htmlFor="defaultCurrency" theme={theme}>Domyślna waluta</Label>
                  <Field name="settings.defaultCurrency">
                    {({ field }) => (
                      <select
                        {...field}
                        id="defaultCurrency"
                        style={{
                          width: '100%',
                          padding: '0.75rem',
                          borderRadius: '4px',
                          border: `1px solid ${theme === 'dark' ? '#444' : '#ddd'}`,
                          backgroundColor: theme === 'dark' ? '#383f41' : 'white',
                          color: theme === 'dark' ? 'white' : '#333'
                        }}
                      >
                        <option value="PLN">Polski złoty (PLN)</option>
                        <option value="EUR">Euro (EUR)</option>
                        <option value="USD">Dolar amerykański (USD)</option>
                        <option value="GBP">Funt brytyjski (GBP)</option>
                      </select>
                    )}
                  </Field>
                </div>
              </FormGroup>

              <ActionButtons>
                <SecondaryButton
                  type="button"
                  onClick={() => navigate('/dashboard/groups')}
                  theme={theme}
                >
                  Anuluj
                </SecondaryButton>
                <PrimaryButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Tworzenie...' : 'Utwórz grupę'}
                </PrimaryButton>
              </ActionButtons>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};

CreateGroup.propTypes = {
  createGroup: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps, { createGroup })(CreateGroup);