// client/src/components/layout/Navbar.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaBars, FaBell, FaUser, FaSignOutAlt, FaCog, FaMoon, FaSun } from 'react-icons/fa';
import { logout } from '../../actions/auth';
import { toggleSidebar, setTheme } from '../../actions/ui';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  left: ${props => props.sidebarOpen ? '250px' : '70px'};
  height: 60px;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    left: 0;
  }
`;

const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MenuToggle = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

const SearchBar = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  border-radius: 50px;
  padding: 0.3rem 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  background: none;
  border: none;
  outline: none;
  color: inherit;
  padding: 0.5rem;
  width: 200px;
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e74c3c;
  color: white;
  font-size: 0.7rem;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserName = styled.span`
  margin-left: 0.5rem;
  font-weight: 500;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 200px;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1001;
`;

const DropdownItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 0.75rem 1rem;
  color: inherit;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #6c5ce7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
`;

const Navbar = ({ 
  user, 
  logout, 
  toggleSidebar, 
  setTheme, 
  ui: { sidebarOpen, theme } 
}) => {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [notificationsDropdownOpen, setNotificationsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
    setNotificationsDropdownOpen(false);
  };

  const toggleNotificationsDropdown = () => {
    setNotificationsDropdownOpen(!notificationsDropdownOpen);
    setUserDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setUserDropdownOpen(false);
      setNotificationsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
  };

  const toggleThemeMode = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const getInitials = (name) => {
    if (!name) return 'U';
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  return (
    <Nav sidebarOpen={sidebarOpen} theme={theme}>
      <NavLeft>
        <MenuToggle onClick={toggleSidebar}>
          <FaBars />
        </MenuToggle>
        <SearchBar theme={theme}>
          <SearchInput 
            type="text" 
            placeholder="Szukaj..." 
            theme={theme} 
          />
        </SearchBar>
      </NavLeft>

      <NavRight>
        <IconButton onClick={toggleThemeMode}>
          {theme === 'dark' ? <FaSun /> : <FaMoon />}
        </IconButton>

        <IconButton onClick={toggleNotificationsDropdown}>
          <FaBell />
          <NotificationBadge>3</NotificationBadge>
          <Dropdown isOpen={notificationsDropdownOpen} ref={dropdownRef} theme={theme}>
            <DropdownItem to="#" theme={theme}>
              Nowa transakcja: Pensja +5000 PLN
            </DropdownItem>
            <DropdownItem to="#" theme={theme}>
              Przekroczony budżet: Jedzenie
            </DropdownItem>
            <DropdownItem to="#" theme={theme}>
              Zbliża się termin płatności: Czynsz
            </DropdownItem>
          </Dropdown>
        </IconButton>

        <UserProfile onClick={toggleUserDropdown}>
          <Avatar>
            {user && getInitials(user.name)}
          </Avatar>
          <UserName>{user && user.name}</UserName>
          <Dropdown isOpen={userDropdownOpen} ref={dropdownRef} theme={theme}>
            <DropdownItem to="/dashboard/profile" theme={theme}>
              <FaUser />
              Mój profil
            </DropdownItem>
            <DropdownItem to="/dashboard/settings" theme={theme}>
              <FaCog />
              Ustawienia
            </DropdownItem>
            <DropdownButton onClick={handleLogout} theme={theme}>
              <FaSignOutAlt />
              Wyloguj się
            </DropdownButton>
          </Dropdown>
        </UserProfile>
      </NavRight>
    </Nav>
  );
};

Navbar.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps, { logout, toggleSidebar, setTheme })(Navbar);