import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaPlus, FaEdit, FaTrash, FaCheckCircle, FaClock, FaPiggyBank, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getGoals, deleteGoal, contributeToGoal, updateGoal, createGoal } from '../../actions/goal';
import { getTransactions } from '../../actions/transaction';
import { setAlert } from '../../actions/alert';
import Spinner from '../../components/ui/Spinner';
import Modal from '../../components/ui/Modal';
import ProgressBar from '../../components/ui/ProgressBar';
import GoalForm from '../../components/forms/GoalForm';
import ContributeForm from '../../components/forms/ContributeForm';
import { formatCurrency, formatDate } from '../../utils/formatting';

const Goals = ({
  getGoals,
  createGoal,
  updateGoal,
  deleteGoal,
  contributeToGoal,
  getTransactions,
  setAlert,
  goal: { goals, loading },
  transaction: { transactions, loading: transactionsLoading },
  auth: { user },
  ui: { theme }
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showContributeModal, setShowContributeModal] = useState(false);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [expandedGoals, setExpandedGoals] = useState({});

  useEffect(() => {
    getGoals();
    getTransactions();
  }, [getGoals, getTransactions]);

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (goal) => {
    setCurrentGoal(goal);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setCurrentGoal(null);
  };

  const openDeleteModal = (goal) => {
    setCurrentGoal(goal);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentGoal(null);
  };

  const openContributeModal = (goal) => {
    setCurrentGoal(goal);
    setShowContributeModal(true);
  };

  const closeContributeModal = () => {
    setShowContributeModal(false);
    setCurrentGoal(null);
  };

  const handleDeleteGoal = async () => {
    try {
      await deleteGoal(currentGoal._id);
      closeDeleteModal();
      setAlert('Cel został usunięty', 'success');
    } catch (err) {
      setAlert('Błąd usuwania celu', 'danger');
    }
  };

  const toggleExpand = (goalId) => {
    setExpandedGoals(prev => ({
      ...prev,
      [goalId]: !prev[goalId]
    }));
  };

  // Obliczanie progresu dla każdego celu
  const calculateProgress = (goal) => {
    // Filtrowanie transakcji przypisanych do tego celu
    const goalTransactions = transactions.filter(
      transaction => transaction.goal === goal._id
    );
    
    // Suma wpłat na cel
    const contributed = goalTransactions.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
    
    const percentage = (contributed / goal.targetAmount) * 100;
    
    return {
      contributed,
      percentage: Math.min(percentage, 100), // Maksymalnie 100%
      remaining: goal.targetAmount - contributed
    };
  };

  // Sortowanie celów
  const sortedGoals = [...goals].sort((a, b) => {
    // Najpierw cele w trakcie, potem zakończone
    if (a.completed !== b.completed) {
      return a.completed ? 1 : -1;
    }
    
    // Następnie według daty docelowej (najbliższe najpierw)
    if (a.targetDate && b.targetDate) {
      return new Date(a.targetDate) - new Date(b.targetDate);
    }
    
    return 0;
  });

  if (loading || transactionsLoading) {
    return (
      <Container>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Cele oszczędnościowe</h1>
        <ButtonPrimary onClick={openAddModal}>
          <FaPlus /> Dodaj cel
        </ButtonPrimary>
      </Header>

      <Summary>
        <SummaryCard>
          <SummaryTitle>Aktywne cele</SummaryTitle>
          <SummaryValue>{goals.filter(goal => !goal.completed).length}</SummaryValue>
        </SummaryCard>
        
        <SummaryCard>
          <SummaryTitle>Łączna kwota celów</SummaryTitle>
          <SummaryValue>
            {formatCurrency(
              goals.reduce((sum, goal) => sum + goal.targetAmount, 0)
            )}
          </SummaryValue>
        </SummaryCard>
        
        <SummaryCard>
          <SummaryTitle>Osiągnięte cele</SummaryTitle>
          <SummaryValue>
            {goals.filter(goal => goal.completed).length}
          </SummaryValue>
        </SummaryCard>
      </Summary>

      {goals.length === 0 ? (
        <NoGoalsContainer>
          <NoItemsMessage>
            <div>Nie masz jeszcze żadnych celów oszczędnościowych</div>
            <ButtonPrimary onClick={openAddModal}>Dodaj pierwszy cel</ButtonPrimary>
          </NoItemsMessage>
        </NoGoalsContainer>
      ) : (
        <GoalsGrid>
          {sortedGoals.map(goal => {
            const progress = calculateProgress(goal);
            const isExpanded = expandedGoals[goal._id] || false;
            
            return (
              <GoalCard key={goal._id} completed={goal.completed}>
                {goal.completed && (
                  <CompletedBadge>
                    <FaCheckCircle />
                    <span>Osiągnięty</span>
                  </CompletedBadge>
                )}
                
                <GoalHeader>
                  <GoalTitle>{goal.name}</GoalTitle>
                  <GoalActions>
                    {!goal.completed && (
                      <ActionButton onClick={() => openContributeModal(goal)}>
                        <FaPiggyBank />
                      </ActionButton>
                    )}
                    <ActionButton onClick={() => openEditModal(goal)}>
                      <FaEdit />
                    </ActionButton>
                    <ActionButton onClick={() => openDeleteModal(goal)}>
                      <FaTrash />
                    </ActionButton>
                    <ExpandButton onClick={() => toggleExpand(goal._id)}>
                      {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                    </ExpandButton>
                  </GoalActions>
                </GoalHeader>
                
                <GoalAmount>
                  <span>Cel: </span>
                  {formatCurrency(goal.targetAmount)}
                </GoalAmount>
                
                <ProgressSection>
                  <ProgressBar
                    percentage={progress.percentage}
                    status={
                      goal.completed 
                        ? 'success' 
                        : progress.percentage < 50 
                          ? 'normal' 
                          : progress.percentage < 80 
                            ? 'warning' 
                            : 'success'
                    }
                  />
                  <ProgressText>
                    {formatCurrency(progress.contributed)} z {formatCurrency(goal.targetAmount)}
                    {' '}({progress.percentage.toFixed(0)}%)
                  </ProgressText>
                </ProgressSection>
                
                {goal.targetDate && (
                  <GoalDeadline>
                    <FaClock />
                    <span>
                      {goal.completed 
                        ? 'Osiągnięty: ' 
                        : 'Termin: '}
                      {formatDate(goal.targetDate)}
                    </span>
                  </GoalDeadline>
                )}
                
                {isExpanded && (
                  <GoalDetails>
                    {goal.description && (
                      <GoalDescription>{goal.description}</GoalDescription>
                    )}
                    
                    <GoalDetailItem>
                      <span>Pozostało: </span>
                      {goal.completed 
                        ? 'Cel osiągnięty!' 
                        : formatCurrency(progress.remaining)}
                    </GoalDetailItem>
                    
                    {goal.targetDate && !goal.completed && (
                      <GoalDetailItem>
                        <span>Dni do celu: </span>
                        {Math.max(
                          0, 
                          Math.ceil(
                            (new Date(goal.targetDate) - new Date()) / (1000 * 60 * 60 * 24)
                          )
                        )}
                      </GoalDetailItem>
                    )}
                    
                    {!goal.completed && progress.contributed > 0 && (
                      <GoalDetailItem>
                        <span>Oszczędzasz dziennie: </span>
                        {formatCurrency(
                          progress.contributed / 
                          Math.max(
                            1, 
                            Math.ceil(
                              (new Date() - new Date(goal.createdAt)) / 
                              (1000 * 60 * 60 * 24)
                            )
                          )
                        )}
                      </GoalDetailItem>
                    )}
                    
                    {!goal.completed && progress.contributed > 0 && goal.targetDate && (
                      <GoalDetailItem>
                        <span>Potrzebne dziennie: </span>
                        {formatCurrency(
                          progress.remaining / 
                          Math.max(
                            1, 
                            Math.ceil(
                              (new Date(goal.targetDate) - new Date()) / 
                              (1000 * 60 * 60 * 24)
                            )
                          )
                        )}
                      </GoalDetailItem>
                    )}
                    
                    <GoalContributions>
                      <GoalDetailItem>
                        <span>Historia wpłat:</span>
                      </GoalDetailItem>
                      
                      <ContributionsList>
                        {transactions
                          .filter(transaction => transaction.goal === goal._id)
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .slice(0, 5)
                          .map(transaction => (
                            <ContributionItem key={transaction._id}>
                              <ContributionDate>
                                {formatDate(transaction.date)}
                              </ContributionDate>
                              <ContributionAmount>
                                {formatCurrency(transaction.amount)}
                              </ContributionAmount>
                            </ContributionItem>
                          ))}
                          
                        {transactions.filter(t => t.goal === goal._id).length === 0 && (
                          <NoContributions>
                            Brak wpłat na ten cel
                          </NoContributions>
                        )}
                      </ContributionsList>
                      
                      {!goal.completed && (
                        <ContributeButton onClick={() => openContributeModal(goal)}>
                          <FaPiggyBank /> Dodaj wpłatę
                        </ContributeButton>
                      )}
                    </GoalContributions>
                  </GoalDetails>
                )}
              </GoalCard>
            );
          })}
        </GoalsGrid>
      )}

      {/* Modal dodawania celu */}
      <Modal
        isOpen={showAddModal}
        onClose={closeAddModal}
        title="Dodaj nowy cel oszczędnościowy"
      >
        <GoalForm onClose={closeAddModal} />
      </Modal>

      {/* Modal edycji celu */}
      <Modal
        isOpen={showEditModal}
        onClose={closeEditModal}
        title="Edytuj cel oszczędnościowy"
      >
        {currentGoal && (
          <GoalForm
            onClose={closeEditModal}
            editMode={true}
            goalData={currentGoal}
          />
        )}
      </Modal>

      {/* Modal usuwania celu */}
      <Modal
        isOpen={showDeleteModal}
        onClose={closeDeleteModal}
        title="Usuń cel oszczędnościowy"
      >
        <ConfirmDeleteContent>
          <p>Czy na pewno chcesz usunąć ten cel oszczędnościowy?</p>
          {currentGoal && (
            <p>
              <strong>Nazwa:</strong> {currentGoal.name}
              <br />
              <strong>Kwota:</strong> {formatCurrency(currentGoal.targetAmount)}
            </p>
          )}
          <ConfirmDeleteButtons>
            <ButtonDanger onClick={handleDeleteGoal}>
              Tak, usuń
            </ButtonDanger>
            <ButtonSecondary onClick={closeDeleteModal}>
              Anuluj
            </ButtonSecondary>
          </ConfirmDeleteButtons>
        </ConfirmDeleteContent>
      </Modal>

      {/* Modal dodawania wpłaty */}
      <Modal
        isOpen={showContributeModal}
        onClose={closeContributeModal}
        title="Dodaj wpłatę na cel"
      >
        {currentGoal && (
          <ContributeForm
            onClose={closeContributeModal}
            goal={currentGoal}
          />
        )}
      </Modal>
    </Container>
  );
};

Goals.propTypes = {
  getGoals: PropTypes.func.isRequired,
  createGoal: PropTypes.func.isRequired,
  updateGoal: PropTypes.func.isRequired,
  deleteGoal: PropTypes.func.isRequired,
  contributeToGoal: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  goal: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  goal: state.goal,
  transaction: state.transaction,
  auth: state.auth,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonPrimary = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  align-self: flex-start;
  margin-top: 0.5rem;
  
  &:hover {
    background-color: #3d9840;
  }
`;

const ConfirmDeleteContent = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  
  p {
    margin-bottom: 1rem;
  }
`;

const ConfirmDeleteButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export default connect(mapStateToProps, {
  getGoals,
  deleteGoal,
  contributeToGoal,
  updateGoal,
  createGoal,
  getTransactions,
  setAlert
})(Goals);

const ButtonSecondary = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const ButtonDanger = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const Summary = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const SummaryCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SummaryTitle = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
`;

const SummaryValue = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.5rem;
  font-weight: 600;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const NoGoalsContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const NoItemsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  text-align: center;
`;

const GoalsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const GoalCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  position: relative;
  border-left: 4px solid ${props => 
    props.completed 
      ? '#4caf50' 
      : '#4a90e2'
  };
  
  ${props => props.completed && `
    opacity: 0.8;
  `}
`;

const CompletedBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #4caf50;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const GoalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const GoalTitle = styled.h3`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin: 0;
  font-size: 1.2rem;
`;

const GoalActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#999'};
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  
  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    background-color: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
  }
`;

const ExpandButton = styled(ActionButton)`
  margin-left: 0.5rem;
`;

const GoalAmount = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  
  span {
    font-weight: normal;
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  }
`;

const ProgressSection = styled.div`
  margin-bottom: 1rem;
`;

const ProgressText = styled.div`
  font-size: 0.85rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  margin-top: 0.5rem;
  text-align: right;
`;

const GoalDeadline = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const GoalDetails = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GoalDescription = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-style: italic;
  margin-bottom: 0.5rem;
`;

const GoalDetailItem = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  
  span {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  }
`;

const GoalContributions = styled.div`
  margin-top: 0.5rem;
`;

const ContributionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
`;

const ContributionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 4px;
`;

const ContributionDate = styled.span`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-size: 0.9rem;
`;

const ContributionAmount = styled.span`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 500;
`;

const NoContributions = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#999'};
  font-style: italic;
  padding: 0.5rem;
  text-align: center;
`;

const ContributeButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3d9840;
  }
`;