// client/src/actions/category.js
import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_CATEGORIES,
  GET_CATEGORY,
  CATEGORY_ERROR,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  CLEAR_CATEGORY
} from './types';

// Get all categories
export const getCategories = () => async dispatch => {
  try {
    const res = await axios.get('/api/categories');

    dispatch({
      type: GET_CATEGORIES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get category by ID
export const getCategory = id => async dispatch => {
  try {
    const res = await axios.get(`/api/categories/${id}`);

    dispatch({
      type: GET_CATEGORY,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Add category
export const addCategory = formData => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/categories', formData, config);

    dispatch({
      type: ADD_CATEGORY,
      payload: res.data
    });

    dispatch(setAlert('Kategoria dodana pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Update category
export const updateCategory = (id, formData) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put(`/api/categories/${id}`, formData, config);

    dispatch({
      type: UPDATE_CATEGORY,
      payload: res.data
    });

    dispatch(setAlert('Kategoria zaktualizowana pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Delete category
export const deleteCategory = id => async dispatch => {
  if (window.confirm('Czy na pewno chcesz usunąć tę kategorię? Wszystkie powiązane transakcje zostaną zaktualizowane.')) {
    try {
      await axios.delete(`/api/categories/${id}`);

      dispatch({
        type: DELETE_CATEGORY,
        payload: id
      });

      dispatch(setAlert('Kategoria usunięta pomyślnie', 'success'));
    } catch (err) {
      dispatch({
        type: CATEGORY_ERROR,
        payload: { msg: err.response?.statusText, status: err.response?.status }
      });
    }
  }
};

// Clear category
export const clearCategory = () => dispatch => {
  dispatch({ type: CLEAR_CATEGORY });
};