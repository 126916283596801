import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.css';

const ProgressBar = ({ 
  progress, 
  height, 
  color, 
  backgroundColor, 
  showLabel, 
  labelPosition,
  customLabel,
  animate,
  radius,
  className,
  thresholds
}) => {
  // Ensure progress stays between 0-100
  const normalizedProgress = Math.min(Math.max(progress, 0), 100);
  
  // Compute color based on thresholds if they're provided
  let currentColor = color;
  if (thresholds && thresholds.length > 0) {
    for (const threshold of thresholds) {
      if (normalizedProgress <= threshold.value) {
        currentColor = threshold.color;
        break;
      }
    }
  }

  const progressBarStyle = {
    height,
    backgroundColor,
    borderRadius: radius
  };

  const progressStyle = {
    width: `${normalizedProgress}%`,
    backgroundColor: currentColor,
    borderRadius: radius,
    transition: animate ? 'width 0.5s ease-in-out' : 'none'
  };

  const labelStyle = {
    position: labelPosition === 'inside' ? 'absolute' : 'static',
    color: labelPosition === 'inside' ? '#fff' : '#333',
    textAlign: labelPosition === 'inside' ? 'right' : 'center',
    padding: labelPosition === 'inside' ? '0 10px' : '5px 0',
    width: labelPosition === 'inside' ? '100%' : 'auto'
  };

  return (
    <div className={`progress-container ${className}`}>
      {showLabel && labelPosition === 'top' && (
        <div className="progress-label top">
          {customLabel || `${normalizedProgress.toFixed(1)}%`}
        </div>
      )}
      
      <div className="progress-bar" style={progressBarStyle}>
        <div className="progress-fill" style={progressStyle}>
          {showLabel && labelPosition === 'inside' && (
            <div className="progress-label inside" style={labelStyle}>
              {customLabel || `${normalizedProgress.toFixed(1)}%`}
            </div>
          )}
        </div>
      </div>
      
      {showLabel && labelPosition === 'bottom' && (
        <div className="progress-label bottom">
          {customLabel || `${normalizedProgress.toFixed(1)}%`}
        </div>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  height: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  showLabel: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['top', 'inside', 'bottom']),
  customLabel: PropTypes.node,
  animate: PropTypes.bool,
  radius: PropTypes.string,
  className: PropTypes.string,
  thresholds: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    })
  )
};

ProgressBar.defaultProps = {
  height: '20px',
  color: '#4caf50',
  backgroundColor: '#e0e0e0',
  showLabel: true,
  labelPosition: 'top',
  customLabel: null,
  animate: true,
  radius: '4px',
  className: '',
  thresholds: []
};

export default ProgressBar;