// client/src/pages/Education/EducationHub.js
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { 
  FaGraduationCap, 
  FaBook, 
  FaPiggyBank, 
  FaChartLine, 
  FaMoneyBillWave,
  FaCreditCard,
  FaHome,
  FaBriefcase,
  FaCheck,
  FaArrowRight
} from 'react-icons/fa';

// Styled components
const Container = styled.div`
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
    color: #6c5ce7;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  margin-bottom: 2rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const Tab = styled.button`
  padding: 1rem 1.5rem;
  background: none;
  border: none;
  border-bottom: 3px solid ${props => 
    props.active ? '#6c5ce7' : 'transparent'
  };
  color: ${props => 
    props.active 
      ? (props.theme === 'dark' ? 'white' : '#333')
      : (props.theme === 'dark' ? '#ccc' : '#666')
  };
  font-weight: ${props => props.active ? '600' : '400'};
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    background-color: ${props => props.theme === 'dark' ? '#444' : '#f5f5f5'};
  }
`;

const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const ArticleCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ArticleImage = styled.div`
  height: 150px;
  background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#999'};
  font-size: 3rem;
`;

const ArticleContent = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ArticleTitle = styled.h3`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-top: 0;
  margin-bottom: 1rem;
`;

const ArticleDescription = styled.p`
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  font-size: 0.9rem;
  flex: 1;
`;

const ArticleFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`;

const ArticleCategory = styled.span`
  font-size: 0.8rem;
  color: #6c5ce7;
`;

const ReadButton = styled.button`
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: #5649c0;
  }
`;

// Export dla potrzeb komponentu EducationalTips.js
export const getTips = () => educationalArticles.map(article => ({
  id: article.id,
  title: article.title,
  text: article.description,
  icon: article.icon,
  link: '/dashboard/education'
}));

const GuidesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
`;

const GuideCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const GuideHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const GuideIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(108, 92, 231, 0.1);
  color: #6c5ce7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 1rem;
`;

const GuideTitle = styled.h3`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin: 0;
`;

const GuideSteps = styled.div`
  margin-top: 1.5rem;
`;

const GuideStep = styled.div`
  display: flex;
  margin-bottom: 1rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const StepNumber = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #6c5ce7;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  flex: 1;
`;

const StepTitle = styled.div`
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-bottom: 0.25rem;
`;

const StepDescription = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const ChecklistGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
`;

const ChecklistCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const ChecklistTitle = styled.h3`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-top: 0;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.75rem;
    color: #6c5ce7;
  }
`;

const ChecklistItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChecklistItem = styled.div`
  display: flex;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  
  &:last-child {
    border-bottom: none;
  }
`;

const CheckBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid ${props => props.checked ? '#6c5ce7' : props.theme === 'dark' ? '#666' : '#ddd'};
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${props => props.checked ? '#6c5ce7' : 'transparent'};
  cursor: pointer;
  flex-shrink: 0;
`;

const ItemText = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  text-decoration: ${props => props.checked ? 'line-through' : 'none'};
  opacity: ${props => props.checked ? 0.7 : 1};
`;

// Dane artykułów edukacyjnych
const educationalArticles = [
  {
    id: 1,
    title: 'Podstawy budżetowania osobistego',
    description: 'Dowiedz się, jak stworzyć skuteczny budżet osobisty i trzymać się go na co dzień.',
    category: 'Budżetowanie',
    icon: <FaChartLine />
  },
  {
    id: 2,
    title: 'Jak zacząć oszczędzać pieniądze',
    description: 'Praktyczne wskazówki, jak zacząć oszczędzać nawet przy niewielkich dochodach.',
    category: 'Oszczędzanie',
    icon: <FaPiggyBank />
  },
  {
    id: 3,
    title: 'Inwestowanie dla początkujących',
    description: 'Wprowadzenie do podstawowych metod inwestowania dostępnych dla zwykłych ludzi.',
    category: 'Inwestowanie',
    icon: <FaMoneyBillWave />
  },
  {
    id: 4,
    title: 'Jak wyjść z długów',
    description: 'Strategie i praktyczne kroki do pozbycia się zadłużenia i odzyskania kontroli nad finansami.',
    category: 'Zarządzanie długiem',
    icon: <FaCreditCard />
  },
  {
    id: 5,
    title: 'Planowanie finansowe na przyszłość',
    description: 'Jak planować przyszłość finansową, zabezpieczyć się na wypadek nieprzewidzianych zdarzeń.',
    category: 'Planowanie',
    icon: <FaHome />
  },
  {
    id: 6,
    title: 'Finanse w związku i rodzinie',
    description: 'Jak zarządzać pieniędzmi w związku i rodzinie, unikając konfliktów i nieporozumień.',
    category: 'Rodzinne finanse',
    icon: <FaBriefcase />
  }
];

// Dane przewodników
const financialGuides = [
  {
    id: 1,
    title: 'Tworzenie budżetu krok po kroku',
    icon: <FaChartLine />,
    steps: [
      {
        title: 'Zbierz informacje o dochodach',
        description: 'Zsumuj wszystkie źródła dochodu, takie jak wypłata, freelancing, czy inne'
      },
      {
        title: 'Zidentyfikuj stałe wydatki',
        description: 'Sprawdź rachunki, subskrypcje, raty kredytów i inne powtarzalne płatności'
      },
      {
        title: 'Przeanalizuj zmienne wydatki',
        description: 'Przejrzyj historię wydatków z ostatnich 3 miesięcy, aby zidentyfikować wzorce'
      },
      {
        title: 'Ustaw limity dla kategorii',
        description: 'Podziel wydatki na kategorie i ustal realistyczne limity dla każdej z nich'
      },
      {
        title: 'Monitoruj swoje postępy',
        description: 'Regularnie sprawdzaj, czy trzymasz się ustalonych limitów i dostosowuj je w razie potrzeby'
      }
    ]
  },
  {
    id: 2,
    title: 'Strategia oszczędzania 50/30/20',
    icon: <FaPiggyBank />,
    steps: [
      {
        title: 'Oblicz swój miesięczny dochód netto',
        description: 'Sprawdź ile faktycznie wpływa na Twoje konto po odliczeniu podatków i składek'
      },
      {
        title: 'Przeznacz 50% na potrzeby',
        description: 'Mieszkanie, jedzenie, transport, ubezpieczenia, niezbędne rachunki, itp.'
      },
      {
        title: 'Przeznacz 30% na zachcianki',
        description: 'Rozrywka, jedzenie na mieście, zakupy, hobby, itp.'
      },
      {
        title: 'Przeznacz 20% na oszczędności i spłatę długów',
        description: 'Budowanie poduszki finansowej, inwestycje, spłacanie kart kredytowych, itp.'
      },
      {
        title: 'Dostosuj proporcje do swoich potrzeb',
        description: 'Jeśli masz wysokie długi lub cele oszczędnościowe, zwiększ procent na oszczędności'
      }
    ]
  },
  {
    id: 3,
    title: 'Plan redukcji zadłużenia',
    icon: <FaCreditCard />,
    steps: [
      {
        title: 'Sporządź listę wszystkich długów',
        description: 'Zanotuj kwoty, oprocentowanie i minimalne miesięczne raty każdego długu'
      },
      {
        title: 'Wybierz metodę spłaty',
        description: 'Metoda lawiny (od najwyższego oprocentowania) lub metoda kuli śnieżnej (od najmniejszej kwoty)'
      },
      {
        title: 'Przygotuj budżet spłaty',
        description: 'Określ, ile dodatkowo możesz przeznaczyć na szybszą spłatę wybranego długu'
      },
      {
        title: 'Skontaktuj się z wierzycielami',
        description: 'Negocjuj warunki spłaty, obniżenie oprocentowania lub rozłożenie na raty'
      },
      {
        title: 'Konsekwentnie realizuj plan',
        description: 'Trzymaj się planu i celebruj każdy spłacony dług jako krok w kierunku wolności finansowej'
      }
    ]
  }
];

// Dane checklisty finansowej
const financialChecklists = [
  {
    id: 1,
    title: 'Codzienna kontrola finansów',
    icon: <FaChartLine />,
    items: [
      { id: 'daily-1', text: 'Zapisz wszystkie dzisiejsze wydatki' },
      { id: 'daily-2', text: 'Sprawdź saldo konta bankowego' },
      { id: 'daily-3', text: 'Zaplanuj wydatki na jutro' },
      { id: 'daily-4', text: 'Zidentyfikuj jeden zbędny wydatek, którego można uniknąć' },
      { id: 'daily-5', text: 'Sprawdź postęp w realizacji celów oszczędnościowych' }
    ]
  },
  {
    id: 2,
    title: 'Miesięczny przegląd finansów',
    icon: <FaMoneyBillWave />,
    items: [
      { id: 'monthly-1', text: 'Porównaj wydatki z budżetem' },
      { id: 'monthly-2', text: 'Zweryfikuj wszystkie subskrypcje i usługi cykliczne' },
      { id: 'monthly-3', text: 'Opłać wszystkie rachunki' },
      { id: 'monthly-4', text: 'Przeznacz określoną kwotę na oszczędności' },
      { id: 'monthly-5', text: 'Sprawdź stan zadłużenia i postęp w spłacie' },
      { id: 'monthly-6', text: 'Zidentyfikuj kategorie, w których można zaoszczędzić' },
      { id: 'monthly-7', text: 'Zaplanuj budżet na następny miesiąc' }
    ]
  },
  {
    id: 3,
    title: 'Roczny plan finansowy',
    icon: <FaHome />,
    items: [
      { id: 'yearly-1', text: 'Wykonaj roczne zestawienie dochodów i wydatków' },
      { id: 'yearly-2', text: 'Ustaw cele finansowe na nadchodzący rok' },
      { id: 'yearly-3', text: 'Przejrzyj i zaktualizuj ubezpieczenia' },
      { id: 'yearly-4', text: 'Sprawdź raport kredytowy' },
      { id: 'yearly-5', text: 'Zaktualizuj testament i inne dokumenty finansowe' },
      { id: 'yearly-6', text: 'Przeanalizuj inwestycje i dostosuj portfolio' },
      { id: 'yearly-7', text: 'Zaplanuj większe wydatki na nadchodzący rok' },
      { id: 'yearly-8', text: 'Sprawdź możliwości optymalizacji podatkowej' }
    ]
  }
];

const EducationHub = ({ ui: { theme } }) => {
  const [activeTab, setActiveTab] = useState('articles');
  const [checkedItems, setCheckedItems] = useState({});

  // Obsługa zmiany zakładki
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Obsługa zaznaczania elementów na checkliście
  const handleCheckItem = (itemId) => {
    setCheckedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  return (
    <Container>
      <Header>
        <Title theme={theme}>
          <FaGraduationCap />
          Strefa Edukacyjna
        </Title>
      </Header>

      {/* Zakładki */}
      <TabsContainer theme={theme}>
        <Tab 
          active={activeTab === 'articles'} 
          onClick={() => handleTabChange('articles')}
          theme={theme}
        >
          <FaBook />
          Artykuły edukacyjne
        </Tab>
        <Tab 
          active={activeTab === 'guides'} 
          onClick={() => handleTabChange('guides')}
          theme={theme}
        >
          <FaChartLine />
          Przewodniki krok po kroku
        </Tab>
        <Tab 
          active={activeTab === 'checklists'} 
          onClick={() => handleTabChange('checklists')}
          theme={theme}
        >
          <FaCheck />
          Checklisty finansowe
        </Tab>
      </TabsContainer>

      {/* Zawartość zakładki Artykuły */}
      {activeTab === 'articles' && (
        <ArticlesGrid>
          {educationalArticles.map(article => (
            <ArticleCard key={article.id} theme={theme}>
              <ArticleImage theme={theme}>
                {article.icon}
              </ArticleImage>
              <ArticleContent>
                <ArticleTitle theme={theme}>{article.title}</ArticleTitle>
                <ArticleDescription theme={theme}>
                  {article.description}
                </ArticleDescription>
                <ArticleFooter>
                  <ArticleCategory>{article.category}</ArticleCategory>
                  <ReadButton>
                    Czytaj
                    <FaArrowRight />
                  </ReadButton>
                </ArticleFooter>
              </ArticleContent>
            </ArticleCard>
          ))}
        </ArticlesGrid>
      )}

      {/* Zawartość zakładki Przewodniki */}
      {activeTab === 'guides' && (
        <GuidesList>
          {financialGuides.map(guide => (
            <GuideCard key={guide.id} theme={theme}>
              <GuideHeader>
                <GuideIcon>{guide.icon}</GuideIcon>
                <GuideTitle theme={theme}>{guide.title}</GuideTitle>
              </GuideHeader>
              <GuideSteps>
                {guide.steps.map((step, index) => (
                  <GuideStep key={index}>
                    <StepNumber>{index + 1}</StepNumber>
                    <StepContent>
                      <StepTitle theme={theme}>{step.title}</StepTitle>
                      <StepDescription theme={theme}>{step.description}</StepDescription>
                    </StepContent>
                  </GuideStep>
                ))}
              </GuideSteps>
            </GuideCard>
          ))}
        </GuidesList>
      )}

      {/* Zawartość zakładki Checklisty */}
      {activeTab === 'checklists' && (
        <ChecklistGrid>
          {financialChecklists.map(checklist => (
            <ChecklistCard key={checklist.id} theme={theme}>
              <ChecklistTitle theme={theme}>
                {checklist.icon}
                {checklist.title}
              </ChecklistTitle>
              <ChecklistItems>
                {checklist.items.map(item => (
                  <ChecklistItem key={item.id} theme={theme}>
                    <CheckBox 
                      checked={checkedItems[item.id]} 
                      onClick={() => handleCheckItem(item.id)}
                      theme={theme}
                    >
                      {checkedItems[item.id] && <FaCheck />}
                    </CheckBox>
                    <ItemText checked={checkedItems[item.id]} theme={theme}>{item.text}</ItemText>
                  </ChecklistItem>
                ))}
              </ChecklistItems>
            </ChecklistCard>
          ))}
        </ChecklistGrid>
      )}
    </Container>
  );
};

EducationHub.propTypes = {
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps)(EducationHub);