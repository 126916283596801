// client/src/actions/types.js

// Alerty
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Autentykacja
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const TWO_FACTOR_SUCCESS = 'TWO_FACTOR_SUCCESS';
export const TWO_FACTOR_FAIL = 'TWO_FACTOR_FAIL';
export const TWO_FACTOR_REQUIRED = 'TWO_FACTOR_REQUIRED';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

// Profile
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// UI
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_THEME = 'SET_THEME';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_LOADING = 'SET_LOADING';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';

// Transakcje
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';

// Kategorie
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

// Budżety
export const GET_BUDGETS = 'GET_BUDGETS';
export const GET_BUDGET = 'GET_BUDGET';
export const BUDGET_ERROR = 'BUDGET_ERROR';
export const ADD_BUDGET = 'ADD_BUDGET';
export const DELETE_BUDGET = 'DELETE_BUDGET';
export const UPDATE_BUDGET = 'UPDATE_BUDGET';
export const CLEAR_BUDGET = 'CLEAR_BUDGET';

// Cele
export const GET_GOALS = 'GET_GOALS';
export const GET_GOAL = 'GET_GOAL';
export const GOAL_ERROR = 'GOAL_ERROR';
export const ADD_GOAL = 'ADD_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const CONTRIBUTE_TO_GOAL = 'CONTRIBUTE_TO_GOAL';
export const CLEAR_GOAL = 'CLEAR_GOAL';

// Paragony
export const SCAN_RECEIPT = 'SCAN_RECEIPT';
export const SCAN_ERROR = 'SCAN_ERROR';
export const CLEAR_SCAN_DATA = 'CLEAR_SCAN_DATA';

// Raporty
export const GET_REPORTS = 'GET_REPORTS';
export const REPORT_ERROR = 'REPORT_ERROR';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const CLEAR_REPORT = 'CLEAR_REPORT';

// Osiągnięcia
export const GET_ACHIEVEMENTS = 'GET_ACHIEVEMENTS';
export const ACHIEVEMENT_ERROR = 'ACHIEVEMENT_ERROR';
export const CHECK_ACHIEVEMENTS = 'CHECK_ACHIEVEMENTS';
export const UNLOCK_ACHIEVEMENT = 'UNLOCK_ACHIEVEMENT';
export const SET_ACHIEVEMENT_LOADING = 'SET_ACHIEVEMENT_LOADING';
export const UPDATE_ACHIEVEMENT_PREFERENCES = 'UPDATE_ACHIEVEMENT_PREFERENCES';

// AI
export const UPDATE_AI_PREFERENCES = 'UPDATE_AI_PREFERENCES';
export const GET_AI_PREFERENCES = 'GET_AI_PREFERENCES';
export const AI_ERROR = 'AI_ERROR';
export const SEND_AI_MESSAGE = 'SEND_AI_MESSAGE';
export const RECEIVE_AI_MESSAGE = 'RECEIVE_AI_MESSAGE';
export const CLEAR_AI_CHAT = 'CLEAR_AI_CHAT';
export const SET_AI_LOADING = 'SET_AI_LOADING';
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';

// Grupy
export const GET_GROUP = 'GET_GROUP';
export const GROUP_ERROR = 'GROUP_ERROR';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const ADD_MEMBER = 'ADD_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const LEAVE_GROUP = 'LEAVE_GROUP';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const REJECT_INVITATION = 'REJECT_INVITATION';
export const GET_GROUPS = 'GET_GROUPS';
export const CLEAR_GROUP = 'CLEAR_GROUP';
export const GENERATE_INVITE = 'GENERATE_INVITE';
export const JOIN_GROUP = 'JOIN_GROUP';

// Współdzielone transakcje
export const GET_SHARED_TRANSACTIONS = 'GET_SHARED_TRANSACTIONS';
export const GET_SHARED_TRANSACTION = 'GET_SHARED_TRANSACTION';
export const ADD_SHARED_TRANSACTION = 'ADD_SHARED_TRANSACTION';
export const UPDATE_SHARED_TRANSACTION = 'UPDATE_SHARED_TRANSACTION';
export const DELETE_SHARED_TRANSACTION = 'DELETE_SHARED_TRANSACTION';
export const SHARED_TRANSACTION_ERROR = 'SHARED_TRANSACTION_ERROR';
export const CLEAR_SHARED_TRANSACTION = 'CLEAR_SHARED_TRANSACTION';
export const APPROVE_TRANSACTION = 'APPROVE_TRANSACTION';

// Rozliczenia
export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export const GENERATE_SETTLEMENTS = 'GENERATE_SETTLEMENTS';
export const CONFIRM_SETTLEMENT = 'CONFIRM_SETTLEMENT';
export const REJECT_SETTLEMENT = 'REJECT_SETTLEMENT';
export const CANCEL_SETTLEMENT = 'CANCEL_SETTLEMENT';
export const SETTLEMENT_ERROR = 'SETTLEMENT_ERROR';
export const CLEAR_SETTLEMENT = 'CLEAR_SETTLEMENT';