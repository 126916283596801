// client/src/actions/receipt.js
import axios from 'axios';
import { setAlert } from './alert';
import {
  SCAN_RECEIPT,
  SCAN_ERROR,
  CLEAR_SCAN_DATA
} from './types';

// Scan receipt
export const scanReceipt = formData => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const res = await axios.post('/api/receipts/scan', formData, config);

    dispatch({
      type: SCAN_RECEIPT,
      payload: res.data
    });

    dispatch(setAlert('Paragon został pomyślnie przeskanowany', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    } else {
      dispatch(setAlert('Błąd skanowania paragonu', 'danger'));
    }

    dispatch({
      type: SCAN_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Clear scan data
export const clearScanData = () => dispatch => {
  dispatch({ type: CLEAR_SCAN_DATA });
};