// client/src/components/dashboard/UpcomingTransactions.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, isToday, isTomorrow, isThisWeek, differenceInDays } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { FaArrowDown, FaArrowUp, FaSync, FaCalendarAlt, FaInfoCircle } from 'react-icons/fa';

const Container = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 1.5rem 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const TransactionList = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Transaction = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-left: 3px solid ${props => {
    if (props.isToday) return '#e74c3c';
    if (props.isThisWeek) return '#f39c12';
    return 'transparent';
  }};
  background-color: ${props => {
    if (props.isToday) return props.theme === 'dark' ? 'rgba(231, 76, 60, 0.1)' : 'rgba(231, 76, 60, 0.05)';
    if (props.isThisWeek) return props.theme === 'dark' ? 'rgba(243, 156, 18, 0.1)' : 'rgba(243, 156, 18, 0.05)';
    return 'transparent';
  }};
  border-radius: 4px;
  margin-bottom: 0.75rem;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f9f9f9'};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => {
    if (props.type === 'income') return 'rgba(39, 174, 96, 0.1)';
    if (props.type === 'expense') return 'rgba(231, 76, 60, 0.1)';
    return 'rgba(108, 92, 231, 0.1)';
  }};
  color: ${props => {
    if (props.type === 'income') return '#27ae60';
    if (props.type === 'expense') return '#e74c3c';
    return '#6c5ce7';
  }};
  margin-right: 0.75rem;
  flex-shrink: 0;
`;

const TransactionDetails = styled.div`
  flex: 1;
  min-width: 0;
`;

const TransactionTitle = styled.div`
  font-weight: 500;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

const RecurringBadge = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 0.7rem;
  margin-left: 0.5rem;
  padding: 0.1rem 0.3rem;
  background-color: ${props => props.theme === 'dark' ? 'rgba(108, 92, 231, 0.2)' : 'rgba(108, 92, 231, 0.1)'};
  color: #6c5ce7;
  border-radius: 4px;

  svg {
    margin-right: 0.2rem;
    font-size: 0.7rem;
  }
`;

const TransactionDate = styled.div`
  font-size: 0.8rem;
  color: ${props => {
    if (props.isToday) return '#e74c3c';
    if (props.isTomorrow) return '#f39c12';
    return props.theme === 'dark' ? '#ccc' : '#888';
  }};
  font-weight: ${props => (props.isToday || props.isTomorrow) ? '500' : 'normal'};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.3rem;
  }
`;

const Amount = styled.div`
  font-weight: 600;
  color: ${props => {
    if (props.type === 'income') return '#27ae60';
    if (props.type === 'expense') return '#e74c3c';
    return props.theme === 'dark' ? 'white' : '#333';
  }};
  margin-left: 0.75rem;
  white-space: nowrap;
`;

const NoTransactionsMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  
  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: ${props => props.theme === 'dark' ? '#666' : '#ddd'};
  }
`;

const ViewAllLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 0.75rem;
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#383f41' : '#f5f5f5'};
    border-radius: 4px;
  }
`;

const UpcomingTransactions = ({ transactions, limit = 5, theme }) => {
  // Formatowanie kwot
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN'
    }).format(amount);
  };

  // Formatowanie daty
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    if (isToday(date)) {
      return 'Dzisiaj';
    } else if (isTomorrow(date)) {
      return 'Jutro';
    } else if (isThisWeek(date)) {
      return format(date, 'EEEE', { locale: pl }); // np. 'Poniedziałek'
    } else {
      return format(date, 'd MMMM', { locale: pl }); // np. '15 kwietnia'
    }
  };

  // Sortowanie transakcji według daty
  const sortedTransactions = [...transactions]
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .slice(0, limit);

  // Czy transakcja jest na dzisiaj/w tym tygodniu
  const checkIsToday = (dateString) => isToday(new Date(dateString));
  const checkIsTomorrow = (dateString) => isTomorrow(new Date(dateString));
  const checkIsThisWeek = (dateString) => isThisWeek(new Date(dateString), { weekStartsOn: 1 });

  return (
    <Container theme={theme}>
      <Title theme={theme}>Nadchodzące transakcje</Title>
      <TransactionList>
        {sortedTransactions.length > 0 ? (
          sortedTransactions.map((transaction) => {
            const isTransactionToday = checkIsToday(transaction.date);
            const isTransactionTomorrow = checkIsTomorrow(transaction.date);
            const isTransactionThisWeek = checkIsThisWeek(transaction.date);

            return (
              <Transaction 
                key={transaction._id} 
                isToday={isTransactionToday}
                isThisWeek={isTransactionThisWeek}
                theme={theme}
              >
                <IconWrapper type={transaction.type}>
                  {transaction.type === 'income' ? <FaArrowUp /> : <FaArrowDown />}
                </IconWrapper>
                
                <TransactionDetails>
                  <TransactionTitle theme={theme}>
                    {transaction.description}
                    {transaction.isRecurring && (
                      <RecurringBadge theme={theme}>
                        <FaSync />
                        Cykliczne
                      </RecurringBadge>
                    )}
                  </TransactionTitle>
                  
                  <TransactionDate 
                    isToday={isTransactionToday}
                    isTomorrow={isTransactionTomorrow}
                    theme={theme}
                  >
                    <FaCalendarAlt />
                    {formatDate(transaction.date)}
                  </TransactionDate>
                </TransactionDetails>
                
                <Amount type={transaction.type} theme={theme}>
                  {transaction.type === 'income' ? '+' : '-'} {formatCurrency(transaction.amount)}
                </Amount>
              </Transaction>
            );
          })
        ) : (
          <NoTransactionsMessage theme={theme}>
            <FaInfoCircle />
            <p>Brak nadchodzących transakcji w najbliższym czasie.</p>
          </NoTransactionsMessage>
        )}
      </TransactionList>
      
      {transactions.length > limit && (
        <ViewAllLink to="/dashboard/calendar">
          Zobacz wszystkie nadchodzące transakcje
        </ViewAllLink>
      )}
    </Container>
  );
};

UpcomingTransactions.propTypes = {
  transactions: PropTypes.array,
  limit: PropTypes.number,
  theme: PropTypes.string.isRequired
};

UpcomingTransactions.defaultProps = {
  transactions: [],
  limit: 5
};

export default UpcomingTransactions;