// client/src/components/dashboard/BudgetProgress.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaInfoCircle, FaPlus } from 'react-icons/fa';

const Container = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const AddButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: #6c5ce7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5649c0;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const BudgetCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#eee'};
  padding: 1.25rem;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
`;

const BudgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const BudgetInfo = styled.div`
  flex: 1;
`;

const BudgetTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const BudgetCategory = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
  display: flex;
  align-items: center;
`;

const CategoryBadge = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.color || '#6c5ce7'};
  margin-right: 0.5rem;
`;

const BudgetAmount = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const SpentText = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#666'};
`;

const RemainingText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${props => {
    if (props.danger) return '#e74c3c';
    if (props.warning) return '#f39c12';
    return props.theme === 'dark' ? 'white' : '#333';
  }};
`;

const ProgressBarContainer = styled.div`
  height: 8px;
  background-color: ${props => props.theme === 'dark' ? '#444' : '#f1f1f1'};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${props => Math.min(props.percentage, 100)}%;
  background-color: ${props => {
    if (props.percentage >= 90) return '#e74c3c';
    if (props.percentage >= 75) return '#f39c12';
    return '#27ae60';
  }};
  border-radius: 4px;
  transition: width 0.3s ease;
`;

const NoBudgetsMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${props => props.theme === 'dark' ? '#ccc' : '#888'};
  display: flex;
  flex-direction: column;
  align-items: center;
  
  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: ${props => props.theme === 'dark' ? '#666' : '#ddd'};
  }
`;

const BudgetProgress = ({ budgets, theme }) => {
  // Funkcja formatująca kwoty
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN'
    }).format(amount);
  };

  // Funkcja obliczająca procent wykorzystania budżetu
  const calculatePercentage = (spent, total) => {
    if (total === 0) return 0;
    return (spent / total) * 100;
  };

  // Sortowanie budżetów według procentu wykorzystania (od najwyższego)
  const sortedBudgets = [...budgets].sort((a, b) => {
    const percentA = calculatePercentage(a.spent || 0, a.amount);
    const percentB = calculatePercentage(b.spent || 0, b.amount);
    return percentB - percentA;
  });

  return (
    <Container theme={theme}>
      <Header>
        <Title theme={theme}>Postęp budżetów</Title>
        <AddButton to="/dashboard/budgets/add">
          <FaPlus />
          Dodaj budżet
        </AddButton>
      </Header>

      {sortedBudgets.length > 0 ? (
        <Grid>
          {sortedBudgets.map((budget) => {
            const spent = budget.spent || 0;
            const remaining = budget.amount - spent;
            const percentage = calculatePercentage(spent, budget.amount);
            const isWarning = percentage >= 75;
            const isDanger = percentage >= 90;

            return (
              <BudgetCard key={budget._id} theme={theme}>
                <BudgetHeader>
                  <BudgetInfo>
                    <BudgetTitle theme={theme}>{budget.name}</BudgetTitle>
                    <BudgetCategory theme={theme}>
                      {budget.category && (
                        <>
                          <CategoryBadge color={budget.category.color} />
                          {budget.category.name}
                        </>
                      )}
                    </BudgetCategory>
                  </BudgetInfo>
                  <BudgetAmount theme={theme}>{formatCurrency(budget.amount)}</BudgetAmount>
                </BudgetHeader>

                <ProgressDetails>
                  <SpentText theme={theme}>
                    Wydano: {formatCurrency(spent)}
                  </SpentText>
                  <RemainingText 
                    warning={isWarning && !isDanger} 
                    danger={isDanger}
                    theme={theme}
                  >
                    Pozostało: {formatCurrency(remaining)}
                  </RemainingText>
                </ProgressDetails>

                <ProgressBarContainer theme={theme}>
                  <ProgressBar percentage={percentage} />
                </ProgressBarContainer>
              </BudgetCard>
            );
          })}
        </Grid>
      ) : (
        <NoBudgetsMessage theme={theme}>
          <FaInfoCircle />
          <p>Nie masz jeszcze żadnych budżetów. Dodaj swój pierwszy budżet, aby śledzić wydatki!</p>
          <AddButton to="/dashboard/budgets/add">
            <FaPlus />
            Dodaj pierwszy budżet
          </AddButton>
        </NoBudgetsMessage>
      )}
    </Container>
  );
};

BudgetProgress.propTypes = {
  budgets: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired
};

// Wartości domyślne
BudgetProgress.defaultProps = {
  budgets: []
};

export default BudgetProgress;