import React from 'react';
import styled, { useTheme } from 'styled-components';
import { 
  BarChart as RechartsBarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  ReferenceLine,
  Cell
} from 'recharts';
import { useSelector } from 'react-redux';

const ChartContainer = styled.div`
  width: 100%;
  height: ${props => props.height || '300px'};
  background-color: ${props => props.theme.cardBg};
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  color: ${props => props.theme.textPrimary};
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
`;

const CustomTooltip = styled.div`
  background-color: ${props => props.theme.cardBg};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  padding: 10px;
  color: ${props => props.theme.textPrimary};
`;

const TooltipLabel = styled.p`
  margin: 0;
  font-weight: bold;
  margin-bottom: 8px;
`;

const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const ColorIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 8px;
`;

const ItemName = styled.span`
  margin-right: 8px;
  color: ${props => props.theme.textSecondary};
`;

const ItemValue = styled.span`
  font-weight: 500;
`;

const NoDataMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.textSecondary};
  font-size: 16px;
`;

const BarChart = ({ 
  data, 
  bars, 
  xAxisDataKey = "name", 
  title, 
  height,
  showGrid = true,
  stacked = false,
  layout = 'vertical', // 'vertical' or 'horizontal'
  referenceLines = [],
  xAxisTickFormatter,
  yAxisTickFormatter = (value) => `${value} zł`,
  barColors, // For custom individual bar colors when only one bar series is used
  barGap = 0,
  barSize
}) => {
  const theme = useTheme();
  const isDarkMode = useSelector(state => state.settings.darkMode);

  // Default colors if not provided in bars
  const defaultColors = isDarkMode 
    ? ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088fe']
    : ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088fe'];

  // Custom tooltip component
  const CustomChartTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltip>
          <TooltipLabel>{label}</TooltipLabel>
          {payload.map((entry, index) => (
            <TooltipItem key={`item-${index}`}>
              <ColorIndicator color={entry.color} />
              <ItemName>{entry.name}:</ItemName>
              <ItemValue>
                {typeof entry.value === 'number' 
                  ? `${entry.value.toLocaleString()} zł` 
                  : entry.value}
              </ItemValue>
            </TooltipItem>
          ))}
        </CustomTooltip>
      );
    }
    return null;
  };

  if (!data || data.length === 0) {
    return (
      <ChartContainer height={height}>
        {title && <Title>{title}</Title>}
        <NoDataMessage>Brak danych do wyświetlenia</NoDataMessage>
      </ChartContainer>
    );
  }

  // Determine which components to render based on layout
  const VerticalOrHorizontal = ({ children }) => {
    const isVertical = layout === 'vertical';
    
    return (
      <RechartsBarChart
        data={data}
        layout={isVertical ? 'vertical' : 'horizontal'}
        margin={{
          top: 5,
          right: 30,
          left: isVertical ? 60 : 20, // More space for vertical layout
          bottom: 5,
        }}
        barGap={barGap}
        barSize={barSize}
      >
        {showGrid && (
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={isDarkMode ? '#444' : '#eee'} 
          />
        )}
        
        {isVertical ? (
          <>
            <XAxis 
              type="number" 
              stroke={theme.textSecondary}
              tickFormatter={yAxisTickFormatter}
              tick={{ fill: theme.textSecondary }}
            />
            <YAxis 
              dataKey={xAxisDataKey} 
              type="category" 
              scale="band"
              stroke={theme.textSecondary}
              tickFormatter={xAxisTickFormatter}
              tick={{ fill: theme.textSecondary }}
              width={75} // Give more space for category labels
            />
          </>
        ) : (
          <>
            <XAxis 
              dataKey={xAxisDataKey} 
              scale="band"
              stroke={theme.textSecondary}
              tickFormatter={xAxisTickFormatter}
              tick={{ fill: theme.textSecondary }}
            />
            <YAxis 
              stroke={theme.textSecondary} 
              tickFormatter={yAxisTickFormatter}
              tick={{ fill: theme.textSecondary }}
            />
          </>
        )}
        
        {children}
      </RechartsBarChart>
    );
  };

  return (
    <ChartContainer height={height}>
      {title && <Title>{title}</Title>}
      <ResponsiveContainer width="100%" height="100%">
        <VerticalOrHorizontal>
          <Tooltip content={<CustomChartTooltip />} />
          <Legend wrapperStyle={{ color: theme.textPrimary }} />
          
          {/* Render each bar series */}
          {bars.map((bar, index) => {
            // If only one bar and individual bar colors are provided
            if (bars.length === 1 && barColors && barColors.length > 0) {
              return (
                <Bar
                  key={bar.dataKey}
                  dataKey={bar.dataKey}
                  name={bar.name || bar.dataKey}
                  fill={bar.color || defaultColors[0]}
                  stackId={stacked ? "stack" : null}
                >
                  {data.map((entry, entryIndex) => (
                    <Cell 
                      key={`cell-${entryIndex}`} 
                      fill={barColors[entryIndex % barColors.length]} 
                    />
                  ))}
                </Bar>
              );
            }
            
            return (
              <Bar
                key={bar.dataKey}
                dataKey={bar.dataKey}
                name={bar.name || bar.dataKey}
                fill={bar.color || defaultColors[index % defaultColors.length]}
                stackId={stacked ? "stack" : null}
                radius={bar.radius || [0, 0, 0, 0]}
              />
            );
          })}

          {/* Render reference lines */}
          {referenceLines.map((line, index) => (
            <ReferenceLine
              key={`ref-line-${index}`}
              y={line.y}
              x={line.x}
              stroke={line.color || '#ff7300'}
              strokeDasharray={line.dashArray || '3 3'}
              label={{
                value: line.label,
                position: line.labelPosition || 'right',
                fill: theme.textPrimary
              }}
            />
          ))}
        </VerticalOrHorizontal>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default BarChart;