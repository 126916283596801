import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaArrowLeft, FaUser, FaLock, FaTrash, FaBell, FaPalette } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { updateProfile, getCurrentProfile, updatePassword, deleteAccount } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import Spinner from '../../components/ui/Spinner';
import Modal from '../../components/ui/Modal';

const UserProfile = ({
  auth: { user, profile, loading },
  updateProfile,
  getCurrentProfile,
  updatePassword,
  deleteAccount,
  setAlert,
  ui: { theme }
}) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    currency: '',
    language: '',
    theme: 'light',
    notificationsEnabled: true,
    emailNotifications: true,
    pushNotifications: false,
    twoFactorEnabled: false
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  // Populate form data when profile is loaded
  useEffect(() => {
    if (profile && !loading && user) {
      const profileData = {
        name: user.name || '',
        email: user.email || '',
        currency: profile.preferences?.currency || 'PLN',
        language: profile.preferences?.language || 'pl',
        theme: profile.preferences?.theme || 'light',
        notificationsEnabled: profile.preferences?.notificationsEnabled !== false,
        emailNotifications: profile.preferences?.emailNotifications !== false,
        pushNotifications: profile.preferences?.pushNotifications || false,
        twoFactorEnabled: user.twoFactorEnabled || false
      };
      setFormData(profileData);
    }
  }, [profile, loading, user]);

  const {
    name,
    email,
    currency,
    language,
    theme: userTheme,
    notificationsEnabled,
    emailNotifications,
    pushNotifications,
    twoFactorEnabled
  } = formData;

  const onChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const onPasswordChange = e => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value
    });
  };

  const handleProfileUpdate = e => {
    e.preventDefault();
    
    updateProfile(formData);
    setAlert('Profil został zaktualizowany', 'success');
  };

  const handlePasswordUpdate = e => {
    e.preventDefault();
    
    const { currentPassword, newPassword, confirmPassword } = passwordData;
    
    // Validate passwords
    if (newPassword !== confirmPassword) {
      setAlert('Hasła nie są identyczne', 'danger');
      return;
    }
    
    if (newPassword.length < 6) {
      setAlert('Hasło musi mieć co najmniej 6 znaków', 'danger');
      return;
    }
    
    updatePassword(currentPassword, newPassword);
    setShowPasswordModal(false);
    setPasswordData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  };

  const handleDeleteAccount = () => {
    deleteAccount();
    navigate('/');
  };

  if (loading) {
    return (
      <Container theme={theme}>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Container>
    );
  }

  return (
    <Container theme={theme}>
      <Header theme={theme}>
        <BackButton theme={theme} onClick={() => navigate('/dashboard')}>
          <FaArrowLeft /> Wróć
        </BackButton>
        <h1>Twój Profil</h1>
      </Header>

      <ProfileContainer theme={theme}>
        <ProfileHeader theme={theme}>
          <ProfileAvatar>
            {user?.avatar ? (
              <AvatarImage src={user.avatar} alt={user?.name} />
            ) : (
              <DefaultAvatar theme={theme}>
                <FaUser />
              </DefaultAvatar>
            )}
          </ProfileAvatar>
          <ProfileInfo theme={theme}>
            <h2>{user?.name}</h2>
            <p>{user?.email}</p>
            <AccountType theme={theme}>
              {user?.subscription?.type === 'pro' ? 'Konto Premium' : 'Konto Standard'}
            </AccountType>
          </ProfileInfo>
        </ProfileHeader>

        <Form onSubmit={handleProfileUpdate}>
          <FormSection theme={theme}>
            <SectionHeading theme={theme}>
              <FaUser /> Dane osobowe
            </SectionHeading>
            
            <FormGroup>
              <Label theme={theme} htmlFor="name">Imię i nazwisko</Label>
              <Input
                theme={theme}
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={onChange}
                placeholder="Twoje imię i nazwisko"
              />
            </FormGroup>
            
            <FormGroup>
              <Label theme={theme} htmlFor="email">Email</Label>
              <Input
                theme={theme}
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                disabled
              />
              <HelpText theme={theme}>
                Adres email jest używany do logowania i nie może być zmieniony
              </HelpText>
            </FormGroup>
            
            <ButtonsGroup>
              <ActionButton
                theme={theme}
                type="button"
                onClick={() => setShowPasswordModal(true)}
              >
                <FaLock /> Zmień hasło
              </ActionButton>
            </ButtonsGroup>
          </FormSection>
          
          <FormSection theme={theme}>
            <SectionHeading theme={theme}>
              <FaPalette /> Preferencje
            </SectionHeading>
            
            <FormGroup>
              <Label theme={theme} htmlFor="currency">Waluta</Label>
              <Select
                theme={theme}
                id="currency"
                name="currency"
                value={currency}
                onChange={onChange}
              >
                <option value="PLN">Polski złoty (PLN)</option>
                <option value="EUR">Euro (EUR)</option>
                <option value="USD">Dolar amerykański (USD)</option>
                <option value="GBP">Funt brytyjski (GBP)</option>
                <option value="CHF">Frank szwajcarski (CHF)</option>
                <option value="CZK">Korona czeska (CZK)</option>
              </Select>
            </FormGroup>
            
            <FormGroup>
              <Label theme={theme} htmlFor="language">Język</Label>
              <Select
                theme={theme}
                id="language"
                name="language"
                value={language}
                onChange={onChange}
              >
                <option value="pl">Polski</option>
                <option value="en">Angielski</option>
                <option value="de">Niemiecki</option>
                <option value="fr">Francuski</option>
              </Select>
            </FormGroup>
            
            <FormGroup>
              <Label theme={theme} htmlFor="theme">Motyw</Label>
              <Select
                theme={theme}
                id="theme"
                name="theme"
                value={userTheme}
                onChange={onChange}
              >
                <option value="light">Jasny</option>
                <option value="dark">Ciemny</option>
                <option value="system">Systemowy</option>
              </Select>
            </FormGroup>
          </FormSection>
          
          <FormSection theme={theme}>
            <SectionHeading theme={theme}>
              <FaBell /> Powiadomienia
            </SectionHeading>
            
            <CheckboxGroup>
              <CheckboxLabel theme={theme}>
                <CheckboxInput
                  type="checkbox"
                  name="notificationsEnabled"
                  checked={notificationsEnabled}
                  onChange={onChange}
                />
                <CheckboxText theme={theme}>Włącz powiadomienia</CheckboxText>
              </CheckboxLabel>
              <HelpText theme={theme}>
                Otrzymuj powiadomienia o zbliżających się płatnościach, przekroczeniu budżetu itp.
              </HelpText>
            </CheckboxGroup>
            
            {notificationsEnabled && (
              <>
                <CheckboxGroup>
                  <CheckboxLabel theme={theme}>
                    <CheckboxInput
                      type="checkbox"
                      name="emailNotifications"
                      checked={emailNotifications}
                      onChange={onChange}
                    />
                    <CheckboxText theme={theme}>Powiadomienia email</CheckboxText>
                  </CheckboxLabel>
                </CheckboxGroup>
                
                <CheckboxGroup>
                  <CheckboxLabel theme={theme}>
                    <CheckboxInput
                      type="checkbox"
                      name="pushNotifications"
                      checked={pushNotifications}
                      onChange={onChange}
                    />
                    <CheckboxText theme={theme}>Powiadomienia push</CheckboxText>
                  </CheckboxLabel>
                </CheckboxGroup>
              </>
            )}
          </FormSection>
          
          <FormSection theme={theme}>
            <SectionHeading theme={theme}>
              <FaLock /> Bezpieczeństwo
            </SectionHeading>
            
            <CheckboxGroup>
              <CheckboxLabel theme={theme}>
                <CheckboxInput
                  type="checkbox"
                  name="twoFactorEnabled"
                  checked={twoFactorEnabled}
                  onChange={onChange}
                />
                <CheckboxText theme={theme}>Weryfikacja dwuetapowa (2FA)</CheckboxText>
              </CheckboxLabel>
              <HelpText theme={theme}>
                Zwiększ bezpieczeństwo swojego konta, wymagając dodatkowego kodu podczas logowania
              </HelpText>
            </CheckboxGroup>
          </FormSection>
          
          <ButtonsContainer>
            <SubmitButton type="submit">Zapisz zmiany</SubmitButton>
            <DeleteButton 
              type="button" 
              onClick={() => setShowDeleteModal(true)}
            >
              <FaTrash /> Usuń konto
            </DeleteButton>
          </ButtonsContainer>
        </Form>
      </ProfileContainer>

      {/* Modal do zmiany hasła */}
      <Modal 
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        title="Zmiana hasła"
      >
        <PasswordForm onSubmit={handlePasswordUpdate}>
          <FormGroup>
            <Label theme={theme} htmlFor="currentPassword">Obecne hasło</Label>
            <Input
              theme={theme}
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={passwordData.currentPassword}
              onChange={onPasswordChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label theme={theme} htmlFor="newPassword">Nowe hasło</Label>
            <Input
              theme={theme}
              type="password"
              id="newPassword"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={onPasswordChange}
              required
              minLength="6"
            />
            <HelpText theme={theme}>Minimum 6 znaków</HelpText>
          </FormGroup>
          
          <FormGroup>
            <Label theme={theme} htmlFor="confirmPassword">Powtórz nowe hasło</Label>
            <Input
              theme={theme}
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={onPasswordChange}
              required
            />
          </FormGroup>
          
          <ButtonsGroup>
            <SubmitButton type="submit">Zmień hasło</SubmitButton>
            <CancelButton 
              theme={theme}
              type="button" 
              onClick={() => setShowPasswordModal(false)}
            >
              Anuluj
            </CancelButton>
          </ButtonsGroup>
        </PasswordForm>
      </Modal>

      {/* Modal do usuwania konta */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Usuwanie konta"
      >
        <DeleteAccountContent>
          <p>Czy na pewno chcesz usunąć swoje konto? To działanie jest nieodwracalne i spowoduje utratę wszystkich danych.</p>
          
          <ButtonsGroup>
            <DeleteConfirmButton 
              type="button" 
              onClick={handleDeleteAccount}
            >
              Tak, usuń moje konto
            </DeleteConfirmButton>
            <CancelButton 
              theme={theme}
              type="button" 
              onClick={() => setShowDeleteModal(false)}
            >
              Anuluj
            </CancelButton>
          </ButtonsGroup>
        </DeleteAccountContent>
      </Modal>
    </Container>
  );
};

UserProfile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#4a90e2' : '#3a70b2'};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? 'rgba(74, 144, 226, 0.1)' : 'rgba(58, 112, 178, 0.1)'};
  }
`;

const ProfileContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#222' : '#f0f7ff'};
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
`;

const ProfileAvatar = styled.div`
  margin-right: 2rem;
`;

const AvatarImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #4a90e2;
`;

const DefaultAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  border: 3px solid #4a90e2;
`;

const ProfileInfo = styled.div`
  h2 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0 0 0.5rem 0;
  }

  p {
    color: ${props => props.theme === 'dark' ? '#bbb' : '#666'};
    margin: 0 0 1rem 0;
  }
`;

const AccountType = styled.div`
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: ${props => props.theme === 'dark' ? '#4a90e2' : '#e1f0ff'};
  color: ${props => props.theme === 'dark' ? 'white' : '#3a70b2'};
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
`;

const Form = styled.form`
  padding: 0 2rem 2rem;
`;

const FormSection = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};

  &:last-child {
    border-bottom: none;
  }
`;

const SectionHeading = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin: 0 0 1.5rem 0;
  font-size: 1.1rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};

  &:disabled {
    background-color: ${props => props.theme === 'dark' ? '#222' : '#f5f5f5'};
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  border-radius: 4px;
  background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};

  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const CheckboxGroup = styled.div`
  margin-bottom: 1rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
`;

const CheckboxText = styled.span`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const HelpText = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#888'};
  margin-top: 0.25rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }
`;

const CancelButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#555' : '#d0d0d0'};
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1rem;
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f0f0f0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
  }
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const PasswordForm = styled.form`
  padding: 1rem 0;
`;

const DeleteAccountContent = styled.div`
  p {
    margin-bottom: 1.5rem;
    color: #d32f2f;
    font-weight: 500;
  }
`;

const DeleteConfirmButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

export default connect(
  mapStateToProps, 
  { updateProfile, getCurrentProfile, updatePassword, deleteAccount, setAlert }
)(UserProfile);