// client/src/layouts/AuthLayout.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaPiggyBank } from 'react-icons/fa';

// Components
import Alert from '../components/layout/Alert';
import Spinner from '../components/layout/Spinner';

const AuthLayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f5f8fa;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftPanel = styled.div`
  flex: 1;
  background-color: #6c5ce7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
    order: 1;
  }
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
    order: 2;
  }
`;

const Logo = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
`;

const Tagline = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Features = styled.div`
  margin-top: 2rem;
`;

const Feature = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 1rem;
  }
`;

const AuthLayout = ({ auth: { isAuthenticated, loading } }) => {
  // Przekierowanie do dashboardu, jeśli użytkownik jest zalogowany
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthLayoutWrapper>
      <LeftPanel>
        <Logo>
          <FaPiggyBank />
          Oszczędzam.com
        </Logo>
        <Tagline>
          Twoje finanse pod kontrolą - zacznij oszczędzać mądrze już dziś!
        </Tagline>
        <Features>
          <Feature>
            <i className="fas fa-chart-line"></i>
            <span>Łatwe śledzenie przychodów i wydatków</span>
          </Feature>
          <Feature>
            <i className="fas fa-bullseye"></i>
            <span>Ustalaj cele oszczędnościowe i realizuj marzenia</span>
          </Feature>
          <Feature>
            <i className="fas fa-receipt"></i>
            <span>Skanowanie paragonów i automatyczna kategoryzacja</span>
          </Feature>
          <Feature>
            <i className="fas fa-chart-pie"></i>
            <span>Szczegółowe raporty i analizy budżetu</span>
          </Feature>
          <Feature>
            <i className="fas fa-brain"></i>
            <span>Asystent AI - inteligentne porady finansowe</span>
          </Feature>
        </Features>
      </LeftPanel>
      <RightPanel>
        <Alert />
        <Outlet />
      </RightPanel>
    </AuthLayoutWrapper>
  );
};

AuthLayout.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AuthLayout);