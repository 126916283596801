import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaPlus, FaEdit, FaTrash, FaChartBar } from 'react-icons/fa';
import { getBudgets, deleteBudget } from '../../actions/budget';
import { getCategories } from '../../actions/category';
import { getTransactions } from '../../actions/transaction';
import { setAlert } from '../../actions/alert';
import Spinner from '../../components/ui/Spinner';
import Modal from '../../components/ui/Modal';
import BudgetForm from '../../components/forms/BudgetForm';
import ProgressBar from '../../components/ui/ProgressBar';
import { formatCurrency } from '../../utils/formatting';

const Budgets = ({
  getBudgets,
  deleteBudget,
  getCategories,
  getTransactions,
  setAlert,
  budget: { budgets, loading: budgetsLoading },
  category: { categories, loading: categoriesLoading },
  transaction: { transactions, loading: transactionsLoading },
  ui: { theme }
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentBudget, setCurrentBudget] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState('month'); // 'month', 'quarter', 'year'
  const [budgetProgress, setBudgetProgress] = useState({});

  useEffect(() => {
    getBudgets();
    getCategories();
    getTransactions();
  }, [getBudgets, getCategories, getTransactions]);

  // Obliczanie postępu budżetu
  useEffect(() => {
    if (budgets.length && transactions.length) {
      const currentDate = new Date();
      const progress = {};
      
      budgets.forEach(budget => {
        // Określenie dat początkowej i końcowej dla danego okresu
        let startDate, endDate;
        
        if (budget.period === 'month') {
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        } else if (budget.period === 'quarter') {
          const currentQuarter = Math.floor(currentDate.getMonth() / 3);
          startDate = new Date(currentDate.getFullYear(), currentQuarter * 3, 1);
          endDate = new Date(currentDate.getFullYear(), (currentQuarter + 1) * 3, 0);
        } else if (budget.period === 'year') {
          startDate = new Date(currentDate.getFullYear(), 0, 1);
          endDate = new Date(currentDate.getFullYear(), 11, 31);
        }
        
        // Filtrowanie transakcji dla danej kategorii i okresu
        const relevantTransactions = transactions.filter(transaction => {
          // Sprawdzenie czy transakcja jest wydatkiem (budżety dotyczą wydatków)
          if (transaction.type !== 'expense') return false;
          
          // Sprawdzenie kategorii
          const transactionCategoryId = 
            typeof transaction.category === 'object' 
              ? transaction.category._id 
              : transaction.category;
          
          if (transactionCategoryId !== budget.category) return false;
          
          // Sprawdzenie daty
          const transactionDate = new Date(transaction.date);
          return transactionDate >= startDate && transactionDate <= endDate;
        });
        
        // Suma wydatków dla danej kategorii
        const spent = relevantTransactions.reduce(
          (sum, transaction) => sum + transaction.amount, 
          0
        );
        
        // Obliczenie procentowego wykorzystania budżetu
        const percentage = (spent / budget.amount) * 100;
        
        progress[budget._id] = {
          spent,
          percentage,
          remaining: budget.amount - spent,
          status: percentage < 80 ? 'good' : percentage < 100 ? 'warning' : 'danger'
        };
      });
      
      setBudgetProgress(progress);
    }
  }, [budgets, transactions]);

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (budget) => {
    setCurrentBudget(budget);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setCurrentBudget(null);
  };

  const openDeleteModal = (budget) => {
    setCurrentBudget(budget);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentBudget(null);
  };

  const handleDeleteBudget = async () => {
    try {
      await deleteBudget(currentBudget._id);
      closeDeleteModal();
      setAlert('Budżet został usunięty', 'success');
    } catch (err) {
      setAlert('Błąd usuwania budżetu', 'danger');
    }
  };

  const handlePeriodChange = (period) => {
    setCurrentPeriod(period);
  };

  // Filtrowanie budżetów po okresie
  const filteredBudgets = budgets.filter(budget => 
    currentPeriod === 'all' || budget.period === currentPeriod
  );

  // Grupowanie budżetów po kategorii
  const budgetsByCategory = {};
  
  if (filteredBudgets.length > 0 && categories.length > 0) {
    filteredBudgets.forEach(budget => {
      const category = categories.find(
        cat => cat._id === budget.category
      );
      
      if (category) {
        if (!budgetsByCategory[category.name]) {
          budgetsByCategory[category.name] = {
            category,
            budgets: []
          };
        }
        
        budgetsByCategory[category.name].budgets.push(budget);
      }
    });
  }

  if (budgetsLoading || categoriesLoading || transactionsLoading) {
    return (
      <Container>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Budżety</h1>
        <ButtonPrimary onClick={openAddModal}>
          <FaPlus /> Dodaj budżet
        </ButtonPrimary>
      </Header>

      <ControlsRow>
        <PeriodSwitcher>
          <PeriodButton
            active={currentPeriod === 'month'}
            onClick={() => handlePeriodChange('month')}
          >
            Miesięczne
          </PeriodButton>
          <PeriodButton
            active={currentPeriod === 'quarter'}
            onClick={() => handlePeriodChange('quarter')}
          >
            Kwartalne
          </PeriodButton>
          <PeriodButton
            active={currentPeriod === 'year'}
            onClick={() => handlePeriodChange('year')}
          >
            Roczne
          </PeriodButton>
          <PeriodButton
            active={currentPeriod === 'all'}
            onClick={() => handlePeriodChange('all')}
          >
            Wszystkie
          </PeriodButton>
        </PeriodSwitcher>

        <BudgetSummary>
          <SummaryText>
            Liczba budżetów: <SummaryValue>{filteredBudgets.length}</SummaryValue>
          </SummaryText>
          <SummaryText>
            Łączna kwota budżetów: 
            <SummaryValue>
              {formatCurrency(
                filteredBudgets.reduce((sum, budget) => sum + budget.amount, 0)
              )}
            </SummaryValue>
          </SummaryText>
        </BudgetSummary>
      </ControlsRow>

      {filteredBudgets.length === 0 ? (
        <NoBudgetsContainer>
          <NoItemsMessage>
            <div>Brak budżetów dla wybranego okresu</div>
            <ButtonPrimary onClick={openAddModal}>Dodaj pierwszy budżet</ButtonPrimary>
          </NoItemsMessage>
        </NoBudgetsContainer>
      ) : (
        <BudgetsGrid>
          {Object.values(budgetsByCategory).map(({ category, budgets }) => (
            <CategorySection key={category._id}>
              <CategoryHeader>
                <CategoryIcon color={category.color || '#666'} />
                <CategoryName>{category.name}</CategoryName>
              </CategoryHeader>
              
              {budgets.map(budget => {
                const progress = budgetProgress[budget._id] || {
                  spent: 0,
                  percentage: 0,
                  remaining: budget.amount,
                  status: 'good'
                };
                
                return (
                  <BudgetCard key={budget._id}>
                    <BudgetPeriod>
                      {budget.period === 'month' && 'Miesięczny'}
                      {budget.period === 'quarter' && 'Kwartalny'}
                      {budget.period === 'year' && 'Roczny'}
                    </BudgetPeriod>
                    
                    <BudgetAmount>
                      <span>Limit: </span>
                      {formatCurrency(budget.amount)}
                    </BudgetAmount>
                    
                    <ProgressSection>
                      <ProgressBar
                        percentage={progress.percentage}
                        status={progress.status}
                      />
                      <ProgressText>
                        {formatCurrency(progress.spent)} z {formatCurrency(budget.amount)}
                        {' '}({progress.percentage.toFixed(0)}%)
                      </ProgressText>
                    </ProgressSection>
                    
                    <BudgetDetail
                      className={progress.remaining >= 0 ? 'positive' : 'negative'}
                    >
                      <span>Pozostało: </span>
                      {formatCurrency(progress.remaining)}
                    </BudgetDetail>
                    
                    {budget.notes && (
                      <BudgetNotes>{budget.notes}</BudgetNotes>
                    )}
                    
                    <BudgetActions>
                      <ActionButton onClick={() => openEditModal(budget)}>
                        <FaEdit />
                      </ActionButton>
                      <ActionButton onClick={() => openDeleteModal(budget)}>
                        <FaTrash />
                      </ActionButton>
                    </BudgetActions>
                  </BudgetCard>
                );
              })}
            </CategorySection>
          ))}
        </BudgetsGrid>
      )}

      {/* Modal dodawania budżetu */}
      <Modal
        isOpen={showAddModal}
        onClose={closeAddModal}
        title="Dodaj nowy budżet"
      >
        <BudgetForm
          onClose={closeAddModal}
          categories={categories}
        />
      </Modal>

      {/* Modal edycji budżetu */}
      <Modal
        isOpen={showEditModal}
        onClose={closeEditModal}
        title="Edytuj budżet"
      >
        {currentBudget && (
          <BudgetForm
            onClose={closeEditModal}
            categories={categories}
            editMode={true}
            budgetData={currentBudget}
          />
        )}
      </Modal>

      {/* Modal usuwania budżetu */}
      <Modal
        isOpen={showDeleteModal}
        onClose={closeDeleteModal}
        title="Usuń budżet"
      >
        <ConfirmDeleteContent>
          <p>Czy na pewno chcesz usunąć ten budżet?</p>
          {currentBudget && (
            <p>
              <strong>Kategoria:</strong>{' '}
              {categories.find(c => c._id === currentBudget.category)?.name}
              <br />
              <strong>Kwota:</strong> {formatCurrency(currentBudget.amount)}
              <br />
              <strong>Okres:</strong>{' '}
              {currentBudget.period === 'month' && 'Miesięczny'}
              {currentBudget.period === 'quarter' && 'Kwartalny'}
              {currentBudget.period === 'year' && 'Roczny'}
            </p>
          )}
          <ConfirmDeleteButtons>
            <ButtonDanger onClick={handleDeleteBudget}>
              Tak, usuń
            </ButtonDanger>
            <ButtonSecondary onClick={closeDeleteModal}>
              Anuluj
            </ButtonSecondary>
          </ConfirmDeleteButtons>
        </ConfirmDeleteContent>
      </Modal>
    </Container>
  );
};

Budgets.propTypes = {
  getBudgets: PropTypes.func.isRequired,
  deleteBudget: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  budget: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  budget: state.budget,
  category: state.category,
  transaction: state.transaction,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonPrimary = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;

  &:hover {
    background-color: #3a70b2;
  }
`;

const ButtonSecondary = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const ButtonDanger = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const PeriodSwitcher = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const PeriodButton = styled.button`
  background-color: ${props => 
    props.active 
      ? props.theme === 'dark' ? '#4a90e2' : '#4a90e2' 
      : props.theme === 'dark' ? '#333' : '#e0e0e0'
  };
  color: ${props => 
    props.active 
      ? 'white' 
      : props.theme === 'dark' ? 'white' : '#333'
  };
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => 
      props.active 
        ? '#3a70b2' 
        : props.theme === 'dark' ? '#444' : '#ccc'
    };
  }
`;

const BudgetSummary = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

const SummaryText = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SummaryValue = styled.span`
  font-weight: 600;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const NoBudgetsContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const NoItemsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  text-align: center;
`;

const BudgetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const CategorySection = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
`;

const CategoryIcon = styled(FaChartBar)`
  color: ${props => props.color};
  font-size: 1.2rem;
`;

const CategoryName = styled.h2`
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.2rem;
`;

const BudgetCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 6px;
  padding: 1rem;
  position: relative;
`;

const BudgetPeriod = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
`;

const BudgetAmount = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  
  span {
    font-weight: normal;
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  }
`;

const ProgressSection = styled.div`
  margin-bottom: 1rem;
`;

const ProgressText = styled.div`
  font-size: 0.85rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  margin-top: 0.5rem;
  text-align: right;
`;

const BudgetDetail = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  margin-bottom: 0.5rem;
  
  span {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  }
  
  &.positive {
    color: #4caf50;
  }
  
  &.negative {
    color: #f44336;
  }
`;

const BudgetNotes = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  font-size: 0.9rem;
  margin-top: 0.75rem;
  font-style: italic;
`;

const BudgetActions = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#999'};
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  
  &:hover {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    background-color: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
  }
`;

const ConfirmDeleteContent = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  
  p {
    margin-bottom: 1rem;
  }
`;

const ConfirmDeleteButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export default connect(mapStateToProps, {
  getBudgets,
  deleteBudget,
  getCategories,
  getTransactions,
  setAlert
})(Budgets);