import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaTrophy, FaMedal, FaStar, FaCheck, FaLock } from 'react-icons/fa';
import { getAchievements, checkAchievements } from '../../actions/achievement';
import Spinner from '../../components/ui/Spinner';

const Achievements = ({
  getAchievements,
  checkAchievements,
  achievement: { achievements, loading },
  ui: { theme }
}) => {
  useEffect(() => {
    getAchievements();
  }, [getAchievements]);

  useEffect(() => {
    // Sprawdź nowe osiągnięcia przy ładowaniu
    checkAchievements();
  }, [checkAchievements]);

  // Grupowanie osiągnięć według kategorii
  const achievementsByCategory = achievements.reduce((acc, achievement) => {
    if (!acc[achievement.category]) {
      acc[achievement.category] = [];
    }
    acc[achievement.category].push(achievement);
    return acc;
  }, {});

  if (loading) {
    return (
      <Container>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Osiągnięcia</h1>
        <AchievementsSummary>
          <SummaryItem>
            <SummaryLabel>Zdobyte</SummaryLabel>
            <SummaryValue>
              {achievements.filter(a => a.unlocked).length} / {achievements.length}
            </SummaryValue>
          </SummaryItem>
        </AchievementsSummary>
      </Header>

      <AchievementsGrid>
        {Object.entries(achievementsByCategory).map(([category, categoryAchievements]) => (
          <CategorySection key={category}>
            <CategoryHeader>
              {getCategoryIcon(category)}
              <CategoryName>{getCategoryName(category)}</CategoryName>
            </CategoryHeader>

            <AchievementsList>
              {categoryAchievements.map(achievement => (
                <AchievementCard
                  key={achievement._id}
                  unlocked={achievement.unlocked}
                >
                  <AchievementIconContainer unlocked={achievement.unlocked}>
                    {achievement.unlocked ? (
                      getAchievementIcon(achievement.icon)
                    ) : (
                      <FaLock />
                    )}
                  </AchievementIconContainer>

                  <AchievementContent>
                    <AchievementTitle unlocked={achievement.unlocked}>
                      {achievement.name}
                    </AchievementTitle>
                    <AchievementDescription>
                      {achievement.description}
                    </AchievementDescription>
                    
                    {achievement.unlocked && (
                      <AchievementDate>
                        Zdobyto: {new Date(achievement.unlockedAt).toLocaleDateString('pl-PL')}
                      </AchievementDate>
                    )}
                    
                    {!achievement.unlocked && achievement.progress && (
                      <AchievementProgress>
                        <ProgressTrack>
                          <ProgressBar
                            width={`${Math.min(
                              100,
                              (achievement.progress.current / achievement.progress.target) * 100
                            )}%`}
                          />
                        </ProgressTrack>
                        <ProgressText>
                          {achievement.progress.current} / {achievement.progress.target}
                        </ProgressText>
                      </AchievementProgress>
                    )}
                  </AchievementContent>
                </AchievementCard>
              ))}
            </AchievementsList>
          </CategorySection>
        ))}
      </AchievementsGrid>
    </Container>
  );
};

// Pomocnicze funkcje do ikon i nazw kategorii
const getCategoryIcon = (category) => {
  switch (category) {
    case 'savings':
      return <FaStar />;
    case 'budget':
      return <FaMedal />;
    case 'transactions':
      return <FaCheck />;
    default:
      return <FaTrophy />;
  }
};

const getCategoryName = (category) => {
  switch (category) {
    case 'savings':
      return 'Oszczędzanie';
    case 'budget':
      return 'Budżetowanie';
    case 'transactions':
      return 'Transakcje';
    case 'streak':
      return 'Regularność';
    case 'goals':
      return 'Cele';
    default:
      return 'Inne';
  }
};

const getAchievementIcon = (iconName) => {
  switch (iconName) {
    case 'trophy':
      return <FaTrophy />;
    case 'medal':
      return <FaMedal />;
    case 'star':
      return <FaStar />;
    case 'check':
      return <FaCheck />;
    default:
      return <FaTrophy />;
  }
};

Achievements.propTypes = {
  getAchievements: PropTypes.func.isRequired,
  checkAchievements: PropTypes.func.isRequired,
  achievement: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  achievement: state.achievement,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const AchievementsSummary = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SummaryLabel = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
`;

const SummaryValue = styled.div`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 600;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const AchievementsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const CategorySection = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.2rem;
  font-weight: 600;
`;

const CategoryName = styled.h2`
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-size: 1.2rem;
`;

const AchievementsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
`;

const AchievementCard = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f7f9fc'};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  opacity: ${props => props.unlocked ? 1 : 0.7};
`;

const AchievementIconContainer = styled.div`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-color: ${props => 
    props.unlocked 
      ? props.theme === 'dark' ? '#4a90e2' : '#4a90e2'
      : props.theme === 'dark' ? '#555' : '#ddd'
  };
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

const AchievementContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const AchievementTitle = styled.div`
  margin-bottom: 0.25rem;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const AchievementDescription = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const AchievementDate = styled.div`
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  font-size: 0.8rem;
  margin-top: auto;
`;

const AchievementProgress = styled.div`
  margin-top: 0.5rem;
`;

const ProgressTrack = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  background-color: #4a90e2;
  height: 100%;
  width: ${props => props.width};
  border-radius: 3px;
`;

const ProgressText = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
  text-align: right;
  margin-top: 0.25rem;
`;

export default connect(
  mapStateToProps,
  { getAchievements, checkAchievements }
)(Achievements);