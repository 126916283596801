// client/src/reducers/sharing.js
import {
  GET_SHARED_USERS,
  ADD_SHARED_USER,
  UPDATE_SHARED_USER,
  REMOVE_SHARED_USER,
  SHARING_ERROR,
  SET_LOADING
} from '../redux/types';

const initialState = {
  sharedUsers: [],
  isLoading: false,
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case GET_SHARED_USERS:
      return {
        ...state,
        sharedUsers: payload,
        isLoading: false,
        error: null
      };
    case ADD_SHARED_USER:
      return {
        ...state,
        sharedUsers: [...state.sharedUsers, payload],
        isLoading: false,
        error: null
      };
    case UPDATE_SHARED_USER:
      return {
        ...state,
        sharedUsers: state.sharedUsers.map(user => 
          user.id === payload.id ? payload : user
        ),
        isLoading: false,
        error: null
      };
    case REMOVE_SHARED_USER:
      return {
        ...state,
        sharedUsers: state.sharedUsers.filter(user => user.id !== payload),
        isLoading: false,
        error: null
      };
    case SHARING_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    default:
      return state;
  }
}