import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pl';
import { FaPlus, FaEdit, FaTrash, FaInfoCircle } from 'react-icons/fa';
import { getTransactions, deleteTransaction } from '../../actions/transaction';
import { setAlert } from '../../actions/alert';
import Modal from '../../components/ui/Modal';
import Spinner from '../../components/ui/Spinner';
import { formatCurrency } from '../../utils/formatting';

// Ustawienie polskiej lokalizacji dla momentjs
moment.locale('pl');
const localizer = momentLocalizer(moment);

const Calendar = ({
  getTransactions,
  deleteTransaction,
  setAlert,
  transaction: { transactions, loading },
  auth: { user },
  ui: { theme }
}) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [view, setView] = useState('month');
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  // Konwersja transakcji na wydarzenia kalendarza
  useEffect(() => {
    if (transactions && transactions.length > 0) {
      const calendarEvents = [];
      
      transactions.forEach(transaction => {
        // Dodanie pojedynczej transakcji
        const eventDate = new Date(transaction.date);
        
        const baseEvent = {
          id: transaction._id,
          title: transaction.name,
          start: eventDate,
          end: new Date(eventDate.getTime() + 60 * 60 * 1000), // 1 godzina później
          allDay: true,
          type: transaction.type,
          amount: transaction.amount,
          category: transaction.category,
          description: transaction.description,
          isRecurring: transaction.isRecurring,
          recurringFrequency: transaction.recurringFrequency,
          recurringEndDate: transaction.recurringEndDate,
          tags: transaction.tags,
          person: transaction.person,
          receipt: transaction.receipt,
          // Dodatkowe dane dla kalendarza
          resource: transaction
        };
        
        calendarEvents.push(baseEvent);
        
        // Jeśli to wydarzenie cykliczne, dodaj przyszłe wydarzenia
        if (transaction.isRecurring && transaction.recurringEndDate) {
          const endDate = new Date(transaction.recurringEndDate);
          let nextDate = new Date(eventDate);
          
          // Dodajemy przyszłe wydarzenia w zależności od częstotliwości
          while (true) {
            if (transaction.recurringFrequency === 'daily') {
              nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
            } else if (transaction.recurringFrequency === 'weekly') {
              nextDate = new Date(nextDate.setDate(nextDate.getDate() + 7));
            } else if (transaction.recurringFrequency === 'biweekly') {
              nextDate = new Date(nextDate.setDate(nextDate.getDate() + 14));
            } else if (transaction.recurringFrequency === 'monthly') {
              nextDate = new Date(nextDate.setMonth(nextDate.getMonth() + 1));
            } else if (transaction.recurringFrequency === 'quarterly') {
              nextDate = new Date(nextDate.setMonth(nextDate.getMonth() + 3));
            } else if (transaction.recurringFrequency === 'yearly') {
              nextDate = new Date(nextDate.setFullYear(nextDate.getFullYear() + 1));
            }
            
            // Jeśli przekroczyliśmy datę końcową, przerywamy
            if (nextDate > endDate) break;
            
            // Dodajemy nowe wydarzenie z flagą "future"
            calendarEvents.push({
              ...baseEvent,
              id: `${transaction._id}_${nextDate.getTime()}`,
              start: new Date(nextDate),
              end: new Date(nextDate.getTime() + 60 * 60 * 1000),
              isFutureRecurring: true
            });
          }
        }
      });
      
      setEvents(calendarEvents);
    }
  }, [transactions]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowEventModal(true);
  };

  const closeEventModal = () => {
    setShowEventModal(false);
    setSelectedEvent(null);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteTransaction = async () => {
    try {
      if (selectedEvent) {
        await deleteTransaction(selectedEvent.id);
        closeDeleteModal();
        closeEventModal();
        setAlert('Transakcja usunięta pomyślnie', 'success');
      }
    } catch (err) {
      setAlert('Błąd usuwania transakcji', 'danger');
    }
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.type === 'income' ? '#4caf50' : '#f44336';
    
    const style = {
      backgroundColor,
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: '0',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    };
    
    // Dla przyszłych wydarzeń cyklicznych używamy wzoru kropkowanego
    if (event.isFutureRecurring) {
      style.backgroundColor = `${backgroundColor}99`; // Dodajemy przezroczystość
      style.borderStyle = 'dashed';
      style.borderWidth = '1px';
      style.borderColor = backgroundColor;
    }
    
    return {
      style
    };
  };
  
  const onNavigate = (newDate) => {
    setDate(newDate);
  };
  
  const onView = (newView) => {
    setView(newView);
  };

  return (
    <Container>
      <Header>
        <h1>Kalendarz finansowy</h1>
        <ButtonPrimary as={Link} to="/transactions/add">
          <FaPlus /> Dodaj transakcję
        </ButtonPrimary>
      </Header>

      <CalendarLegend>
        <LegendItem>
          <LegendColor className="income" />
          <span>Przychody</span>
        </LegendItem>
        <LegendItem>
          <LegendColor className="expense" />
          <span>Wydatki</span>
        </LegendItem>
        <LegendItem>
          <LegendColor className="recurring" />
          <span>Transakcje cykliczne (przyszłe)</span>
        </LegendItem>
      </CalendarLegend>

      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <CalendarContainer theme={theme}>
          <BigCalendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
            views={['month', 'week', 'day', 'agenda']}
            view={view}
            onView={onView}
            date={date}
            onNavigate={onNavigate}
            messages={{
              month: 'Miesiąc',
              week: 'Tydzień',
              day: 'Dzień',
              agenda: 'Lista',
              today: 'Dziś',
              previous: 'Poprzedni',
              next: 'Następny',
              date: 'Data',
              time: 'Czas',
              event: 'Wydarzenie',
              noEventsInRange: 'Brak transakcji w tym okresie'
            }}
            formats={{
              dayHeaderFormat: (date) => moment(date).format('dddd, D MMMM'),
              dayFormat: (date) => moment(date).format('D'),
              monthHeaderFormat: (date) => moment(date).format('MMMM YYYY')
            }}
          />
        </CalendarContainer>
      )}

      {/* Modal ze szczegółami wydarzenia */}
      {selectedEvent && (
        <Modal
          isOpen={showEventModal}
          onClose={closeEventModal}
          title={selectedEvent.title}
        >
          <EventDetails>
            <EventDetail>
              <EventDetailLabel>Kwota:</EventDetailLabel>
              <EventDetailValue className={selectedEvent.type}>
                {formatCurrency(selectedEvent.amount)}
                {selectedEvent.type === 'income' ? ' (przychód)' : ' (wydatek)'}
              </EventDetailValue>
            </EventDetail>

            <EventDetail>
              <EventDetailLabel>Data:</EventDetailLabel>
              <EventDetailValue>
                {moment(selectedEvent.start).format('D MMMM YYYY')}
                {selectedEvent.isRecurring && ' (transakcja cykliczna)'}
              </EventDetailValue>
            </EventDetail>

            {selectedEvent.category && (
              <EventDetail>
                <EventDetailLabel>Kategoria:</EventDetailLabel>
                <EventDetailValue>
                  {typeof selectedEvent.category === 'object'
                    ? selectedEvent.category.name
                    : selectedEvent.category}
                </EventDetailValue>
              </EventDetail>
            )}

            {selectedEvent.description && (
              <EventDetail>
                <EventDetailLabel>Opis:</EventDetailLabel>
                <EventDetailValue>{selectedEvent.description}</EventDetailValue>
              </EventDetail>
            )}

            {selectedEvent.isRecurring && (
              <EventDetail>
                <EventDetailLabel>Cykliczność:</EventDetailLabel>
                <EventDetailValue>
                  {selectedEvent.recurringFrequency === 'daily' && 'Codziennie'}
                  {selectedEvent.recurringFrequency === 'weekly' && 'Co tydzień'}
                  {selectedEvent.recurringFrequency === 'biweekly' && 'Co dwa tygodnie'}
                  {selectedEvent.recurringFrequency === 'monthly' && 'Co miesiąc'}
                  {selectedEvent.recurringFrequency === 'quarterly' && 'Co kwartał'}
                  {selectedEvent.recurringFrequency === 'yearly' && 'Co rok'}
                  {selectedEvent.recurringEndDate && 
                    ` do ${moment(selectedEvent.recurringEndDate).format('D MMMM YYYY')}`}
                </EventDetailValue>
              </EventDetail>
            )}

            {selectedEvent.tags && selectedEvent.tags.length > 0 && (
              <EventDetail>
                <EventDetailLabel>Tagi:</EventDetailLabel>
                <EventDetailValue>
                  <TagsList>
                    {selectedEvent.tags.map(tag => (
                      <Tag key={tag}>{tag}</Tag>
                    ))}
                  </TagsList>
                </EventDetailValue>
              </EventDetail>
            )}

            {selectedEvent.person && (
              <EventDetail>
                <EventDetailLabel>Osoba:</EventDetailLabel>
                <EventDetailValue>
                  {typeof selectedEvent.person === 'object'
                    ? selectedEvent.person.name
                    : selectedEvent.person === user?._id
                      ? `Ja (${user.name})`
                      : selectedEvent.person === 'shared'
                        ? 'Wspólne'
                        : selectedEvent.person}
                </EventDetailValue>
              </EventDetail>
            )}

            {selectedEvent.isFutureRecurring && (
              <RecurringInfo>
                <FaInfoCircle />
                <span>
                  To jest przyszłe wydarzenie wygenerowane na podstawie transakcji cyklicznej.
                  Aby je edytować, musisz przejść do oryginalnej transakcji.
                </span>
              </RecurringInfo>
            )}

            <EventButtonsGroup>
              {!selectedEvent.isFutureRecurring && (
                <>
                  <ButtonPrimary as={Link} to={`/transactions/edit/${selectedEvent.id}`}>
                    <FaEdit /> Edytuj
                  </ButtonPrimary>
                  <ButtonDanger onClick={openDeleteModal}>
                    <FaTrash /> Usuń
                  </ButtonDanger>
                </>
              )}
              <ButtonSecondary onClick={closeEventModal}>
                Zamknij
              </ButtonSecondary>
            </EventButtonsGroup>
          </EventDetails>
        </Modal>
      )}

      {/* Modal potwierdzenia usunięcia */}
      <Modal
        isOpen={showDeleteModal}
        onClose={closeDeleteModal}
        title="Potwierdzenie usunięcia"
      >
        <ConfirmDeleteContent>
          <p>Czy na pewno chcesz usunąć tę transakcję?</p>
          {selectedEvent?.isRecurring && (
            <p>
              Jest to transakcja cykliczna. Usunięcie spowoduje usunięcie tylko
              tego wystąpienia.
            </p>
          )}
          <ConfirmDeleteButtons>
            <ButtonDanger onClick={handleDeleteTransaction}>
              Tak, usuń
            </ButtonDanger>
            <ButtonSecondary onClick={closeDeleteModal}>
              Anuluj
            </ButtonSecondary>
          </ConfirmDeleteButtons>
        </ConfirmDeleteContent>
      </Modal>
    </Container>
  );
};

Calendar.propTypes = {
  getTransactions: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  transaction: state.transaction,
  auth: state.auth,
  ui: state.ui
});

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonPrimary = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    background-color: #3a70b2;
  }
`;

const ButtonSecondary = styled.button`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#e0e0e0'};
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#ccc'};
  }
`;

const ButtonDanger = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const CalendarLegend = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
`;

const LegendColor = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 3px;

  &.income {
    background-color: #4caf50;
  }

  &.expense {
    background-color: #f44336;
  }

  &.recurring {
    background-color: #f4433699;
    border: 1px dashed #f44336;
  }
`;

const CalendarContainer = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  overflow: hidden;

  .rbc-calendar {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  }

  .rbc-toolbar {
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .rbc-toolbar button {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
    background-color: ${props => props.theme === 'dark' ? '#333' : '#f5f5f5'};
    border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-toolbar button:hover {
    background-color: ${props => props.theme === 'dark' ? '#444' : '#e0e0e0'};
  }

  .rbc-toolbar button.rbc-active {
    background-color: ${props => props.theme === 'dark' ? '#4a90e2' : '#4a90e2'};
    color: white;
  }

  .rbc-toolbar button.rbc-active:hover {
    background-color: ${props => props.theme === 'dark' ? '#3a70b2' : '#3a70b2'};
  }

  .rbc-header {
    padding: 0.5rem;
    font-weight: 600;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-day-bg.rbc-today {
    background-color: ${props => props.theme === 'dark' ? 'rgba(74, 144, 226, 0.1)' : 'rgba(74, 144, 226, 0.1)'};
  }

  .rbc-off-range-bg {
    background-color: ${props => props.theme === 'dark' ? '#252525' : '#f5f5f5'};
  }

  .rbc-agenda-view table.rbc-agenda-table {
    border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-agenda-view table.rbc-agenda-table thead {
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-time-view {
    border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-time-header {
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-time-content {
    border-top: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const EventDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const EventDetailLabel = styled.span`
  font-size: 0.85rem;
  color: ${props => props.theme === 'dark' ? '#aaa' : '#666'};
`;

const EventDetailValue = styled.span`
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  
  &.income {
    color: #4caf50;
  }
  
  &.expense {
    color: #f44336;
  }
`;

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background-color: ${props => props.theme === 'dark' ? '#333' : '#f0f0f0'};
  color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.85rem;
`;

const RecurringInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${props => props.theme === 'dark' ? 'rgba(74, 144, 226, 0.1)' : 'rgba(74, 144, 226, 0.1)'};
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  color: ${props => props.theme === 'dark' ? '#4a90e2' : '#3a70b2'};
  font-size: 0.9rem;
`;

const EventButtonsGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const ConfirmDeleteContent = styled.div`
  color: ${props => props.theme === 'dark' ? '#ddd' : '#333'};
  
  p {
    margin-bottom: 1rem;
  }
`;

const ConfirmDeleteButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export default connect(mapStateToProps, {
  getTransactions,
  deleteTransaction,
  setAlert
})(Calendar);