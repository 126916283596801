// client/src/components/layout/Spinner.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: ${props => props.theme === 'dark' ? '#2d3436' : 'white'};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const SpinnerCircle = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  border-top: 4px solid #6c5ce7;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 1rem;
`;

const SpinnerText = styled.p`
  margin: 0;
  color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  font-weight: 500;
`;

const Spinner = ({ message = 'Ładowanie...', ui: { theme } }) => {
  return (
    <SpinnerOverlay theme={theme}>
      <SpinnerContainer theme={theme}>
        <SpinnerCircle />
        <SpinnerText theme={theme}>{message}</SpinnerText>
      </SpinnerContainer>
    </SpinnerOverlay>
  );
};

Spinner.propTypes = {
  message: PropTypes.string,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps)(Spinner);