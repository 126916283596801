import axios from 'axios';
import { setAlert } from './alert';
import {
  UPDATE_AI_PREFERENCES,
  GET_AI_PREFERENCES,
  AI_ERROR,
  SEND_AI_MESSAGE,
  RECEIVE_AI_MESSAGE,
  CLEAR_AI_CHAT,
  GET_CONVERSATIONS,
  GET_CONVERSATION,
  CREATE_CONVERSATION,
  DELETE_CONVERSATION
} from './types';

// Get AI preferences
export const getAIPreferences = () => async dispatch => {
  try {
    const res = await axios.get('/api/ai/preferences');

    dispatch({
      type: GET_AI_PREFERENCES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Update AI preferences
export const updateAIPreferences = (formData) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.put('/api/ai/preferences', formData, config);

    dispatch({
      type: UPDATE_AI_PREFERENCES,
      payload: res.data
    });

    dispatch(setAlert('Preferencje AI zaktualizowane pomyślnie', 'success'));
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Send message to AI
export const sendMessage = (message, conversationId = null) => async dispatch => {
  try {
    dispatch({
      type: SEND_AI_MESSAGE,
      payload: message
    });

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ 
      message,
      conversationId
    });

    const res = await axios.post('/api/ai/message', body, config);

    dispatch({
      type: RECEIVE_AI_MESSAGE,
      payload: res.data.response
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
    
    dispatch(setAlert('Błąd podczas komunikacji z asystentem AI', 'danger'));
  }
};

// Clear AI chat
export const clearAIChat = () => dispatch => {
  dispatch({ type: CLEAR_AI_CHAT });
};

// Get all conversations
export const getConversations = () => async dispatch => {
  try {
    const res = await axios.get('/api/ai/conversations');

    dispatch({
      type: GET_CONVERSATIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Get conversation by ID
export const getConversation = (id) => async dispatch => {
  try {
    const res = await axios.get(`/api/ai/conversations/${id}`);

    dispatch({
      type: GET_CONVERSATION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};

// Create new conversation
export const createConversation = (title) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ title });

    const res = await axios.post('/api/ai/conversations', body, config);

    dispatch({
      type: CREATE_CONVERSATION,
      payload: res.data
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
    
    throw err;
  }
};

// Delete conversation
export const deleteConversation = (id) => async dispatch => {
  try {
    await axios.delete(`/api/ai/conversations/${id}`);

    dispatch({
      type: DELETE_CONVERSATION,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response?.statusText, status: err.response?.status }
    });
  }
};