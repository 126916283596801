import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { resetPassword, verifyResetToken } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const ResetPassword = ({ resetPassword, verifyResetToken, setAlert }) => {
  const { token } = useParams();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    password: '',
    password2: ''
  });
  const [tokenVerified, setTokenVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  const { password, password2 } = formData;

  useEffect(() => {
    const checkToken = async () => {
      try {
        // Zakładamy, że mamy akcję verifyResetToken w auth.js
        const isValid = await verifyResetToken(token);
        setTokenVerified(isValid);
        setLoading(false);
        
        if (!isValid) {
          setAlert('Link resetowania hasła jest nieprawidłowy lub wygasł', 'danger');
        }
      } catch (err) {
        setTokenVerified(false);
        setLoading(false);
        setAlert('Błąd weryfikacji tokenu', 'danger');
      }
    };

    checkToken();
  }, [token, verifyResetToken, setAlert]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== password2) {
      setAlert('Hasła nie pasują do siebie', 'danger');
      return;
    }
    
    if (password.length < 6) {
      setAlert('Hasło musi mieć co najmniej 6 znaków', 'danger');
      return;
    }
    
    try {
      const success = await resetPassword(token, password);
      if (success) {
        setAlert('Hasło zostało zmienione', 'success');
        navigate('/login');
      }
    } catch (err) {
      setAlert('Nie udało się zresetować hasła', 'danger');
    }
  };

  if (loading) {
    return (
      <Container>
        <Card>
          <h1>Weryfikacja linku resetowania...</h1>
        </Card>
      </Container>
    );
  }

  if (!tokenVerified) {
    return (
      <Container>
        <Card>
          <h1>Nieprawidłowy link resetowania</h1>
          <p>Link resetowania hasła jest nieprawidłowy lub wygasł.</p>
          <Link to="/forgot-password">Zażądaj nowego linku</Link>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <h1>Ustaw nowe hasło</h1>
        <p>Wprowadź i potwierdź swoje nowe hasło</p>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <label htmlFor="password">Nowe hasło</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={onChange}
              required
              minLength="6"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="password2">Potwierdź hasło</label>
            <input
              type="password"
              id="password2"
              name="password2"
              value={password2}
              onChange={onChange}
              required
              minLength="6"
            />
          </FormGroup>
          <ButtonGroup>
            <Button type="submit">Zresetuj hasło</Button>
            <Link to="/login">Wróć do logowania</Link>
          </ButtonGroup>
        </Form>
      </Card>
    </Container>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  verifyResetToken: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f7f9fc'};
`;

const Card = styled.div`
  background-color: ${props => props.theme === 'dark' ? '#2c2c2c' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 480px;
  text-align: center;

  h1 {
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
    margin-bottom: 1rem;
  }

  p {
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
    margin-bottom: 2rem;
  }
`;

const Form = styled.form`
  text-align: left;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: ${props => props.theme === 'dark' ? '#ddd' : '#666'};
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid ${props => props.theme === 'dark' ? '#444' : '#ddd'};
    border-radius: 4px;
    background-color: ${props => props.theme === 'dark' ? '#333' : 'white'};
    color: ${props => props.theme === 'dark' ? 'white' : '#333'};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    text-align: center;
    color: ${props => props.theme === 'dark' ? '#4a90e2' : '#3a70b2'};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Button = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3a70b2;
  }
`;

export default connect(null, { resetPassword, verifyResetToken, setAlert })(ResetPassword);